import format_date_formal from "../../validation/format_date_formal";
import { Popup } from "@urbica/react-map-gl";

const PopupValue = (params) => {
  const { data, dataPopUP } = params;
  console.log(params);
  return (
    <div className="valueNewPopup">
      {dataPopUP[data?.key] ? (
        <>
          <p>{data?.name}</p>
          {data?.type === "text" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "textarea" ? (
            <h3 className="popup_textarea">{dataPopUP[data?.key]}</h3>
          ) : data?.type === "number" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "phone_number" ? (
            <h3>+{dataPopUP[data?.key]}</h3>
          ) : data?.type === "currency" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "year" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "date" ? (
            <h3>{format_date_formal(dataPopUP[data?.key])}</h3>
          ) : data?.type === "time" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "selection" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "radio_button" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "checklist" ? (
            <h3>{dataPopUP[data?.key].split(" ")}</h3>
          ) : data?.type === "image" ? (
            <img
              src={dataPopUP[data.key]}
              alt={data?.name}
              className="photo_popup"
            />
          ) : data?.type === "document" ? (
            <a
              href={dataPopUP[data?.key]}
              className="button_very_small"
              id="blue"
            >
              Open File
            </a>
          ) : data?.type === "link" ? (
            <a
              href={`${dataPopUP[data?.key]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {dataPopUP[data?.key]}
            </a>
          ) : data?.type === "auto_address" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "auto_address_poi" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : data?.type === "table" ? (
            <h3>{dataPopUP[data?.key]}</h3>
          ) : (
            <h3>{dataPopUP[data?.key]}</h3>
          )}
        </>
      ) : null}
    </div>
  );
};

const RenderPopup = ({ long, lat, value, handleClose }) => {
  return (
    <Popup
      tipSize={7}
      anchor="bottom"
      longitude={long}
      latitude={lat}
      closeButton={false}
      closeOnClick={false}
    >
      <div className="close_button_popup" onClick={handleClose}>
        X
      </div>
      <div className="newPopup">{value}</div>
    </Popup>
  );
};

export { PopupValue, RenderPopup };
