/** menerima files[0] */
export const convert_file_to_base_64 = async (file) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    return await new Promise((resolve, reject) => {
        reader.addEventListener("load", () => {
            resolve(reader.result);
        });
    });
}

/** type example: "image/jpeg" */
export const convert_base_64_to_file = function ({ filename, base_64, type }) {
    const byteString = atob(base_64?.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    const file = new File([ab], filename, { type });
    return file;
};