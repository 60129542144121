import React, { Component } from "react";
class IconBack extends Component {
  render() {
    return (
      <svg
        width="30px"
        height="30px"
        viewBox="0 0 4.2408905 4.2787553"
        version="1.1"
        id="icon_back"
      >
        <defs id="defs2" />
        <g id="layer1" transform="translate(-97.724327,-192.37662)">
          <rect
            style={{
              fill: "#ffffff",
              stroke: "none",
              strokeWidth: 0.396875,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              paintOrder: "stroke fill markers",
            }}
            id="rect839"
            width="4.2408905"
            height="4.2787552"
            x="97.724327"
            y="192.37662"
            rx="0.76266044"
            ry="0.76266044"
          />
          <path
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: 0.396875,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            d="m 100.45898,193.24033 -1.22842,1.27566 1.22842,1.27566"
            id="path847"
          />
        </g>
      </svg>
    );
  }
}

export default IconBack;
