//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import PaidList from "./PaidList";
import TaskList from "./TaskList";

//Redux function
import {
  getProjectList,
  getPaidFormList,
  getPublicFormList,
  getListLayerBySurveyor,
  getListLayerTypePoint,
} from "../../actions/projectActions";
import { setLayerWOgeojson } from "../../actions/formActions";
import { setLanguage } from "../../actions/authActions";

//Picture Asset
import icon_offline from "../../img/icon_offline.svg";
import icon_personal from "../../img/icon_personal.svg";
import icon_paid from "../../img/icon_paid.svg";
import icon_public from "../../img/icon_public.svg";

//General Function
import dict from "../../validation/dict.json";
import domain_list from "../../validation/domain_list";

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    this.props.getProjectList();
    this.props.getPaidFormList();
    this.props.getPublicFormList();
    this.props.getListLayerBySurveyor();
    this.props.getListLayerTypePoint();
  }

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  onClickLayer = (layer) => {
    this.props.setLayerWOgeojson({
      geo_layer: layer,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { version, domain } = this.props.auth;
    const {
      geo_project_list,
      form_list_public,
      form_list_paid,
      form_list_surveyor,
      layer_list,
      loading,
      itemLoading,
    } = this.props.project;

    //content
    const icon = domain_list?.[domain]?.logo;
    const status = domain_list?.[domain]?.status;
    const title = domain_list?.[domain]?.title;

    const form_list_offline = localStorage.form_list_offline
      ? JSON.parse(localStorage.form_list_offline)
      : [];

    const earning_success_list = form_list_surveyor.map((layer) => {
      const { geojson, formPayment } = layer;
      const { per_insentif } = formPayment;
      const features = geojson?.features ? geojson?.features : [];
      let features_success = [];
      let earning_success = 0;
      if (features.length > 0) {
        features_success = features.filter((feature) =>
          ["success"].includes(feature?.formStatus?.status)
        );
        earning_success = features_success.length * per_insentif;
      }
      return earning_success;
    });
    const earning_paid_off_list = form_list_surveyor.map((layer) => {
      const { geojson, formPayment } = layer;
      const { per_insentif } = formPayment;
      const features = geojson?.features ? geojson?.features : [];
      let features_success = [];
      let earning_success = 0;
      if (features.length > 0) {
        features_success = features.filter((feature) =>
          ["paid_off"].includes(feature?.formStatus?.status)
        );
        earning_success = features_success.length * per_insentif;
      }
      return earning_success;
    });
    const earning_success = earning_success_list.reduce((a, b) => a + b, 0);
    const earning_paid_off = earning_paid_off_list.reduce((a, b) => a + b, 0);
    const earning_total = earning_success + earning_paid_off;
    let earning_text = "Memuat...";
    if (
      !(loading && itemLoading === "get_list_layer_by_surveyor") ||
      earning_total
    ) {
      earning_text = `Rp. ${new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(earning_total)}`;
    }
    const { user } = this.props.auth;
    const full_name = user?.full_name ? user.full_name : user.name;
    let top_content = (
      <main
        className="two_container_dashboard"
        style={{
          backgroundColor: "#fff",
          borderRadius: "0px 0px 30px 30px",
          padding: "25px",
          marginTop: "-30px",
          marginBottom: "30px",
        }}
      >
        <section style={{ marginTop: "auto", marginBottom: "auto" }}>
          <div style={{ marginBottom: "10px" }}>
            {dict["Welcome"][language]}
          </div>
          <div className="text_big">{full_name}</div>
          <div style={{ marginTop: "50px" }}>
            <div style={{ marginBottom: "10px" }}>
              {dict["Your Earning"][language]}
            </div>
            <div style={{ marginBottom: "10px" }} className="text_bold">
              {earning_text}
            </div>
            <Link to="/menu/income">
              <div className="button_standard">Detail</div>
            </Link>
          </div>
        </section>
        <section style={{ marginTop: "auto", marginBottom: "auto" }}>
          <img alt="FORM MAPID" src={icon} width="100%" />
        </section>
      </main>
    );
    const menu_array = [
      {
        name: "Form Offline",
        eng: "Offline Form",
        link: "/menu/offline",
        icon: icon_offline,
        list: form_list_offline,
      },
      {
        name: "Form Personal",
        eng: "Personal Form",
        link: "/menu/project_list",
        icon: icon_personal,
        list: geo_project_list,
      },
      {
        name: "Form Berbayar",
        eng: "Paid Form",
        link: "/menu/paid_form",
        icon: icon_paid,
        list: form_list_paid,
      },
      {
        name: "Form Publik",
        eng: "Public Form",
        link: "/menu/public_form",
        icon: icon_public,
        list: form_list_public,
      },
    ];
    let main_content = (
      <main>
        {menu_array.map((menu, idx) => {
          const { name, eng, link, icon, list } = menu;
          return (
            <Link to={link} key={idx}>
              <main
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "15px",
                  paddingBottom: "20%",
                  display: "grid",
                  padding: "15px",
                  marginBottom: "20px",
                  boxShadow: "0 0 6px #f1f1f1",
                  color: "#5d5d5d",
                }}
              >
                <main className="dashboard_box_container">
                  <img
                    alt="Personal form"
                    src={icon}
                    width="50px"
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  />
                  <section
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      fontSize: "18px",
                    }}
                  >
                    {language === "ina" ? name : eng}
                  </section>
                  <section
                    style={{
                      marginTop: "auto",
                      marginBottom: "auto",
                      textAlign: "right",
                    }}
                  >
                    {list.length}
                  </section>
                </main>
              </main>
            </Link>
          );
        })}
      </main>
    );

    if (status === "premium") {
      top_content = (
        <main
          className="two_container_dashboard"
          style={{
            backgroundColor: "#fff",
            borderRadius: "0px 0px 30px 30px",
            padding: "25px",
            marginTop: "-30px",
            marginBottom: "30px",
          }}
        >
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div style={{ marginBottom: "10px" }}>
              {dict["Welcome"][language]}
            </div>
            <div className="text_big">{full_name}</div>
            <div style={{ marginTop: "50px" }}>
              <div style={{ marginBottom: "10px" }}>
                {dict["Enjoy Your Duty"][language]}
              </div>
              <div style={{ marginBottom: "10px" }} className="text_bold">
                {title}
              </div>
              <Link
                to="/menu/project_list"
                className="button_very_small"
                id="blue"
              >
                {dict["View all survey projects"][language]}
              </Link>
              <br />
              <Link
                to="/menu/offline"
                className="button_very_small"
                id="blue"
                style={{ marginTop: "10px" }}
              >
                {dict["View offline form"][language]}
              </Link>
            </div>
          </section>
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <img alt={title} src={icon} width="100%" />
          </section>
        </main>
      );
    } else if (status === "premium") {
      top_content = (
        <main
          className="two_container_dashboard"
          style={{
            backgroundColor: "#fff",
            borderRadius: "0px 0px 30px 30px",
            padding: "25px",
            marginTop: "-30px",
            marginBottom: "30px",
          }}
        >
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div style={{ marginBottom: "10px" }}>
              {dict["Welcome"][language]}
            </div>
            <div className="text_big">{full_name}</div>
            <div style={{ marginTop: "50px" }}>
              <div style={{ marginBottom: "10px" }}>
                {dict["Enjoy Your Duty"][language]}
              </div>
              <div style={{ marginBottom: "10px" }} className="text_bold">
                {title}
              </div>
              <Link
                to="/menu/project_list"
                className="button_very_small"
                id="blue"
              >
                {dict["View all survey projects"][language]}
              </Link>
              <br />
              <Link
                to="/menu/offline"
                className="button_very_small"
                id="blue"
                style={{ marginTop: "10px" }}
              >
                {dict["View offline form"][language]}
              </Link>
            </div>
          </section>
          <section style={{ marginTop: "auto", marginBottom: "auto" }}>
            <img alt={title} src={icon} width="100%" />
          </section>
        </main>
      );
    }

    if (status === "premium") {
      main_content = (
        <div className="container_light">
          {dict["Loading, please wait"][loading]}
        </div>
      );
      if (layer_list.length > 0) {
        main_content = (
          <main style={{ marginTop: "30px" }}>
            {layer_list.map((layer, idx) => {
              const { link, name } = layer;
              return (
                <Link
                  to={`/${link}`}
                  onClick={this.onClickLayer.bind(this, layer)}
                  key={idx}
                >
                  <main
                    style={{
                      backgroundColor: "#fff",
                      marginBottom: "20px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      boxShadow: "0 0 3px #9d9d9d01",
                    }}
                  >
                    <section style={{ padding: "15px" }}>
                      <div
                        className="text_medium"
                        style={{ maxHeight: "40px", overflowY: "hidden" }}
                      >
                        {name}
                      </div>
                      <div className="button_standard">
                        {dict["Fill the form"][language]}
                      </div>
                    </section>
                  </main>
                </Link>
              );
            })}
          </main>
        );
      }
    }
    return (
      <div className="main_container">
        {top_content}
        <section className="button">
          {dict["Version"][language]}: {version}
        </section>
        <section>
          <p className="text_inferior">{dict?.["Pilih bahasa"]?.[language]}</p>
          <button
            className="button_small"
            id={language === "ina" ? "green" : "grey"}
            onClick={this.onSetLanguage.bind(this, "ina")}
          >
            INA
          </button>{" "}
          <button
            className="button_small"
            id={language === "eng" ? "green" : "grey"}
            onClick={this.onSetLanguage.bind(this, "eng")}
          >
            ENG
          </button>
        </section>

        <TaskList language={language} />

        {status !== "premium" && <PaidList />}

        {main_content}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});
export default connect(mapStateToProps, {
  getProjectList,
  getPaidFormList,
  getPublicFormList,
  getListLayerBySurveyor,
  getListLayerTypePoint,
  setLayerWOgeojson,
  setLanguage,
})(Dashboard);
