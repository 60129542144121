//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

//Personal Component

//Redux function
import { push_feature, stright_push_file } from "../../actions/formActions";

//Picture Asset

//General Function
import FieldsRender from "../universal_form_sub_components/FieldsRender";
import { is_point_type } from "../../validation/check_geometry_type";
import dict from "../../validation/dict.json";
import { convert_base_64_to_file } from "../../validation/base_64";
import is_not_empty from "../../validation/is_not_empty";

// data
import hidden_data_types from "../../validation/hidden_data_types.json"

class STEP_3_OFFLINE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
    };
  }

  on_submit = async () => {
    //local storage

    //global props
    const { geo_layer_wo_geojson, latitude, longitude } = this.props.form;

    //content
    const counting_custom_array =
      geo_layer_wo_geojson?.counting_custom_array || [];

    const fields = geo_layer_wo_geojson?.fields || [];
    const fields_general = fields.filter(
      (f) =>
        !f.parent_uuid &&
        f.type !== "table" &&
        !f.isHide &&
        !f.isStyle &&
        !hidden_data_types.includes(f?.type)
    );

    //STEP 1.a konversikan fields_general menjadi properties
    let properties = {};

    for (const f of fields_general) {
      let value = f.value;
      if (f.type === "number" || f.type === "currency" || f.type === "year") {
        value = parseFloat(f.value);
      } else if (f.type === "checklist") {
        let value_array = f?.value_array || [];
        value_array = value_array.filter((v) => v !== "");
        value = value_array.join("\n");
      } else if (f.type === "date") {
        const date_now = new Date(value);
        const date =
          String(date_now.getDate()).length > 1
            ? date_now.getDate()
            : "0" + String(date_now.getDate());
        const month =
          String(date_now.getMonth() + 1).length > 1
            ? date_now.getMonth() + 1
            : "0" + String(date_now.getMonth() + 1);
        const year = date_now.getFullYear();
        value = `${year}-${month}-${date}`;
      } else if (f.type === "phone_number") {
        const phone_code = f.phone_code || 62;
        value = `${phone_code}${f.value}`;
      } else if (f.type === "image") {
        if (typeof value === "object" && value?.base_64) {
          const { filename, base_64, type, extension } = value;

          const key = "file_form_mapid/" + filename;
          const file = convert_base_64_to_file({ filename, base_64, type });

          const objParams = {
            Bucket: "mapidstorage",
            Key: key,
            Body: file,
            ContentType: file.type,
          };

          value = await this.props.stright_push_file(objParams, extension);
        }
      } else if (f.type === "document") {
        if (typeof value === "object" && value?.base_64) {
          const { filename, base_64, type, extension } = value;

          const key = "file_form_mapid/" + filename;
          const file = convert_base_64_to_file({ filename, base_64, type });

          const objParams = {
            Bucket: "mapidstorage",
            Key: key,
            Body: file,
            ContentType: file.type,
          };

          value = await this.props.stright_push_file(objParams, extension);
        }
      } else {
        value = f.value;
      }

      properties[f.key] = value;
    }

    //STEP 1.b tambahkan property default untuk geometry type Point
    const properties_default = {
      icon_image: "museum",
      text_field: "point",
      circle_color: "#1E5E96",
      circle_radius: 5,
      circle_stroke_width: 1,
      circle_stroke_color: "#fff",
    };
    properties = Object.assign(properties_default, properties);

    //STEP 2 konversikan child_array fields menjadi child array properties
    let child_array = geo_layer_wo_geojson?.child_array || [];
    child_array = child_array.map((c) => {
      let properties = {};

      const fields_child = c?.fields_child || [];

      fields_child.forEach((f) => {
        let value = f.value;
        if (f.type === "number" || f.type === "currency" || f.type === "year") {
          value = parseFloat(f.value);
        } else if (f.type === "checklist") {
          let value_array = f?.value_array || [];
          value_array = value_array.filter((v) => v !== "");
          value = value_array.join("\n");
        } else if (f.type === "date") {
          const date_now = new Date(value);
          const date =
            String(date_now.getDate()).length > 1
              ? date_now.getDate()
              : "0" + String(date_now.getDate());
          const month =
            String(date_now.getMonth() + 1).length > 1
              ? date_now.getMonth() + 1
              : "0" + String(date_now.getMonth() + 1);
          const year = date_now.getFullYear();
          value = `${year}-${month}-${date}`;
        } else if (f.type === "phone_number") {
          value = `${f.phone_code}${f.value}`;
        } else {
          value = f.value;
        }
        properties[f.key] = value;
      });

      const item = {
        properties,
        parent_uuid: c.parent_uuid,
        parent_uuid_temp: c.parent_uuid_temp,
        child_uuid: c.child_uuid,
      };

      return item;
    });

    //MODE TAMBAH
    const feature = {
      key: geo_layer_wo_geojson?.id_offline,
      id: geo_layer_wo_geojson?.id_offline,
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [parseFloat(longitude), parseFloat(latitude)],
      },
      properties,
      child_array,
      counting_custom_array,
      isHide: false,
    };

    const body = {
      geo_layer_id: geo_layer_wo_geojson._id,
      link: geo_layer_wo_geojson.link,
      feature: feature,
    };

    await this.props.push_feature(body);
  };

  is_filled_all_questions = () => {

    const geo_layer_wo_geojson = this.props.form?.geo_layer_wo_geojson
    let fields_test_empty;
    let isComplete = true;
    if (geo_layer_wo_geojson?.fields) {

      const fields_required = geo_layer_wo_geojson?.fields.filter(
        (field) => field.isRequired && !field?.parent_uuid && !hidden_data_types.includes(field?.type)
      );

      fields_test_empty = fields_required.map((field) => {
        if (field?.type === "table") {
          const array = geo_layer_wo_geojson?.child_array?.filter((c) => c?.parent_uuid === field?.uuid);
          if (array) {
            for (let table_array of array) {
              const filtered_table_array = table_array?.fields_child?.filter(f =>
                !hidden_data_types.includes(f?.type))
              const any_empty = filtered_table_array?.some(f =>
                !is_not_empty(f?.value, [""]))
              return (any_empty) ? "" : "filled";
            }
          }
          return "";
        } else if (field?.type === "checklist") {
          return field?.value_array?.filter(item => item !== "").length > 0 ? "filled" : ""
        } else {
          const value = is_not_empty(field.value) ? field.value : "";
          return value;
        }
      });
      isComplete = !fields_test_empty.includes("");
    }
    return isComplete
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { type } = this.props.form?.geo_layer_wo_geojson;
    const { toggleOffline, set_screen_status } = this.props;
    const { fields } = this.props?.form?.geo_layer_wo_geojson;
    const fields_parent = fields.filter(
      (f) =>
        !f.parent_uuid &&
        f.type !== "section" &&
        !f.isStyle &&
        !f.isHide &&
        !hidden_data_types.includes(f?.type)
    );

    const { loading, itemLoading } = this.props.form;

    let isComplete = this.is_filled_all_questions()

    let buttonContent;
    if (!window.navigator.onLine) {
      buttonContent = null;
    } else if (loading && itemLoading === "push_feature") {
      buttonContent = (
        <div className="button_standard">{dict["Mengirim"][language]}</div>
      );
    } else if (isComplete) {
      buttonContent = (
        <div className="button_standard" onClick={this.on_submit}>
          {dict["Submit form now"][language]}
        </div>
      );
    } else {
      buttonContent = (
        <main>
          <section className="button_standard" id="grey">
            {dict["Submit form now"][language]}
          </section>
          <section
            className="text_medium"
            style={{ width: "150px", margin: "auto" }}
          >
            {
              dict[
              "Submit button will be active after all required questions are filled in"
              ][language]
            }
          </section>
        </main>
      );
    }

    const screen_3 = (
      <main>
        <FieldsRender
          fields_parent={fields_parent}
          fields={fields}
          is_parent={true}
          connection_mode="offline"
        />
        <section style={{ textAlign: "center" }}>
          {is_point_type(type) && (
            <button
              className="button_standard"
              style={{ marginRight: "5px", verticalAlign: "top" }}
              onClick={() => set_screen_status(1)}
            >
              {"<"}
            </button>
          )}
          <section
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginRight: "5px",
            }}
          >
            {buttonContent}
          </section>
          <button
            onClick={toggleOffline}
            className="button_standard"
            style={{ marginBottom: "5px" }}
          >
            {dict["Save in local first"][language]}
          </button>
        </section>
      </main>
    );

    return screen_3;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  push_feature,
  stright_push_file,
})(STEP_3_OFFLINE);
