//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import IconBack from "../common/IconBack";
import Modal from "../common/Modal";

//Redux function
import { getListLayerBySurveyor } from "../../actions/projectActions";
import { getReceipt } from "../../actions/authActions";

//Picture Asset

//General Function
import formatDate from "../../validation/format-date";
import dict from "../../validation/dict.json";

class Income extends Component {
  state = {
    modal_receipt: false,
    picture_url: "",
  };
  componentDidMount() {
    this.props.getListLayerBySurveyor();
    this.props.getReceipt();
  }
  toggleReceipt = (picture_url) => {
    this.setState({ modal_receipt: !this.state.modal_receipt });
    if (picture_url) {
      this.setState({ picture_url });
    }
  };
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_receipt } = this.state;

    //global props
    const { receipt_list } = this.props.auth;
    const loadingUser = this.props.auth.loading;
    const itemLoadingUser = this.props.auth.itemLoading;
    const { form_list_surveyor, loading, itemLoading } = this.props.project;

    //content
    const earning_success_list = form_list_surveyor.map((layer) => {
      const { geojson, formPayment } = layer;
      const { per_insentif } = formPayment;
      const features = geojson?.features ? geojson?.features : [];
      let features_success = [];
      let earning_success = 0;
      if (features.length > 0) {
        features_success = features.filter((feature) =>
          ["success"].includes(feature?.formStatus?.status)
        );
        earning_success = features_success.length * per_insentif;
      }
      return earning_success;
    });
    const earning_paid_off_list = form_list_surveyor.map((layer) => {
      const { geojson, formPayment } = layer;
      const { per_insentif } = formPayment;
      const features = geojson?.features ? geojson?.features : [];
      let features_success = [];
      let earning_success = 0;
      if (features.length > 0) {
        features_success = features.filter((feature) =>
          ["paid_off"].includes(feature?.formStatus?.status)
        );
        earning_success = features_success.length * per_insentif;
      }
      return earning_success;
    });
    const earning_success = earning_success_list.reduce((a, b) => a + b, 0);
    const earning_paid_off = earning_paid_off_list.reduce((a, b) => a + b, 0);
    const earning_total = earning_success + earning_paid_off;
    let earning_text = `${dict["Memuat"][language]}...`;
    let pending_text = `${dict["Memuat"][language]}...`;
    let paid_off_text = `${dict["Memuat"][language]}...`;
    if (
      !(loading && itemLoading === "get_list_layer_by_surveyor") ||
      earning_total
    ) {
      earning_text = `Rp. ${new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(earning_total ? earning_total : 0)}`;
      pending_text = `Rp. ${new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(earning_success ? earning_success : 0)}`;
      paid_off_text = `Rp. ${new Intl.NumberFormat("id-ID", {
        style: "decimal",
      }).format(earning_paid_off ? earning_paid_off : 0)}`;
    }
    let receipt_content = <div>Memuat...</div>;
    if (
      !(loadingUser && itemLoadingUser === "get_list_receipt_surveyor") ||
      receipt_list.length !== 0
    ) {
      receipt_content = receipt_list.map((e, idx) => {
        const { amount, date_in, picture_url } = e;
        return (
          <main
            key={idx}
            className="two_container_equal"
            style={{ marginTop: "15px", marginBottom: "15px" }}
          >
            <section>
              <div>{formatDate(date_in)}</div>
            </section>
            <section style={{ textAlign: "right" }}>
              <div>
                <b>{`Rp. ${new Intl.NumberFormat("id-ID", {
                  style: "decimal",
                }).format(amount ? amount : 0)}`}</b>
              </div>
              <div>Via {e.bank_data.type_bank}</div>
              <div
                onClick={this.toggleReceipt.bind(this, picture_url)}
                className="button_tight"
                style={{ marginTop: "5px" }}
              >
                Lihat resi
              </div>
            </section>
          </main>
        );
      });
    } else if (receipt_list.length === 0) {
      receipt_content = <div>{dict["No income"][language]}</div>;
    }
    const modal_receipt_content = modal_receipt && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_receipt}
        onClose={this.toggleReceipt}
      >
        <div className="box-body">
          <img
            alt="Resi pembayaran"
            src={this.state.picture_url}
            style={{ width: "100%" }}
          />
        </div>
      </Modal>
    );
    return (
      <main className="main_container" style={{ marginTop: "70px" }}>
        {modal_receipt_content}
        <Link to="/menu/dashboard">
          <IconBack />
        </Link>
        <section style={{ textAlign: "center", marginBottom: "50px" }}>
          <Link to="/menu/income">
            <button
              className="button_tight"
              id="white_blue"
              style={{ marginRight: "5px" }}
            >
              {dict["Earning"][language]}
            </button>
          </Link>
          <Link to="/menu/personal_paid_form">
            <button className="button_tight" id="white_grey">
              {dict["Active Survey"][language]}
            </button>
          </Link>
        </section>
        <section style={{ textAlign: "center", marginBottom: "50px" }}>
          <div className="text_small">{dict["Earning Total"][language]}</div>
          <div className="text_bold">{earning_text}</div>
        </section>
        <section
          className="two_container_equal"
          style={{ marginBottom: "30px" }}
        >
          <div className="container_light" id="white_normal">
            <p>{dict["Pending Tertunda"][language]}</p>
            <p>{pending_text}</p>
          </div>
          <div className="container_light" id="white_normal">
            <p>{dict["Transferred"][language]}</p>
            <p>{paid_off_text}</p>
          </div>
        </section>
        <section
          className="container_light"
          id="white_normal"
          style={{ display: "block" }}
        >
          {dict["Payment History"][language]}
          {receipt_content}
        </section>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, { getListLayerBySurveyor, getReceipt })(
  Income
);
