//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import SpinnerLogo from "../common/SpinnerLogo";

//Redux function
import { getPaidFormList } from "../../actions/projectActions";
import { setLayerWOgeojson } from "../../actions/formActions";

//Picture Asset

//General Function

class ProjectList extends Component {
  state = {};
  componentDidMount() {
    this.props.getPaidFormList();
  }
  onClickLayer = (layer) => {
    this.props.setLayerWOgeojson({
      geo_layer: layer,
    });
  };
  render() {
    const { form_list_paid, loading, itemLoading } = this.props.project;
    let itemContent = (
      <div className="container_light">
        Belum ada survei berbayar, tunggu ya survei berbayar untuk kamu.
      </div>
    );
    if (
      loading &&
      itemLoading === "paid_form_list" &&
      form_list_paid.length === 0
    ) {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (form_list_paid.length > 0) {
      itemContent = (
        <div style={{ marginTop: "30px" }}>
          {form_list_paid.map((layer) => {
            const { _id, link, name, picture_url } = layer;
            const per_insentif = layer?.formPayment?.per_insentif
              ? layer?.formPayment?.per_insentif
              : 0;

            return (
              <Link
                to={`/${link}`}
                onClick={this.onClickLayer.bind(this, layer)}
                key={_id}
              >
                <div
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "20px",
                    cursor: "pointer",
                    borderRadius: "20px",
                    boxShadow: "0 0 6px #9d9d9d53",
                  }}
                >
                  <div
                    style={{
                      backgroundImage: `url(${picture_url})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundColor: "#dedede",
                      width: "100%",
                      paddingBottom: "25%",
                      borderRadius: "20px 20px 0px 0px",
                    }}
                  />
                  <div style={{ padding: "15px" }}>
                    <div
                      className="text_bold"
                      style={{ maxHeight: "40px", overflowY: "hidden" }}
                    >
                      {name}
                    </div>
                    <div
                      className="text_medium"
                      style={{ maxHeight: "40px", overflowY: "hidden" }}
                    >
                      {`Rp ${new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(per_insentif)}`}
                    </div>
                    <div className="button_standard">Ikut survei</div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      );
    }
    return (
      <div className="main_container">
        <div className="text_header">Form Berbayar</div>
        <div className="text_medium">
          Klik banner untuk melihat detail form berbayar
        </div>
        {itemContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
});
export default connect(mapStateToProps, {
  getPaidFormList,
  setLayerWOgeojson,
})(ProjectList);
