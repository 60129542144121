import React, { Component } from "react";
import { connect } from "react-redux";
import { EditorState, RichUtils, convertFromRaw } from "draft-js";
import Editor from "draft-js-plugins-editor";
import moment from "moment";
import { mediaBlockRenderer } from "./plugins/mediaBlockRenderer";
class BlogRead extends Component {
  focus = () => this.refs.editor.focus();
  onChangeEditor = () => {};
  handleKeyCommand = (command) => {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  };
  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };
  toggleInlineStyle = (inlineStyle) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  };
  render() {
    const blog = this.props.blog;
    const { title, editorState, date } = blog;
    const contentState = convertFromRaw(JSON.parse(editorState));
    const editorStateFinal = EditorState.createWithContent(contentState);
    let className = "RichEditor-editor";
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        className += " RichEditor-hidePlaceholder";
      }
    }
    const itemContent = (
      <div>
        <div className="text_medium" style={{ marginBottom: "10px" }}>
          {moment(date).format("MMMM D, YYYY")}
        </div>
        <div className="text_bold" style={{ marginBottom: "10px" }}>
          {title}
        </div>
        <div className="RichEditor-root ">
          <div className={className} onClick={this.focus}>
            <Editor
              blockRendererFn={mediaBlockRenderer}
              blockStyleFn={getBlockStyle}
              customStyleMap={styleMap}
              editorState={editorStateFinal}
              handleKeyCommand={this.handleKeyCommand.bind(this)}
              onChange={this.onChangeEditor}
              placeholder={`Instruksi belum ditulis oleh pembuat survei`}
              ref="editor"
              spellCheck={false}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
const styleMap = {
  CODE: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    case "code-block":
      return "RichEditor-code-block";
    case "center":
      return "RichEditor-center";
    default:
      return null;
  }
}
BlogRead.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(BlogRead);
