import React, { Component } from "react";
class EditIcon extends Component {
  render() {
    const color = this.props.color;
    const home = (
      <g transform="translate(-560.62864,-331.55829)" id="layer1">
        <g id="g1096" transform="translate(-17.052639,-10.569083)">
          <rect
            ry="0.90439141"
            rx="0.90439141"
            y="345.55142"
            x="579.04266"
            height="4.2223721"
            width="5.2147021"
            id="rect982"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
          <path
            id="path984"
            d="m 580.45283,346.91876 h 2.39432"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
          <path
            id="path986"
            d="m 577.97228,345.88686 3.6777,-3.4685 3.67772,3.4685"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
        </g>
      </g>
    );

    const notif = (
      <g transform="translate(-586.81254,-331.61376)" id="layer1">
        <g id="g1140">
          <path
            d="m 590.78131,332.51155 c -1.44815,0 -2.61409,1.17114 -2.61409,2.62579 v 2.911 h 5.22815 v -2.911 c 0,-1.45465 -1.16592,-2.62579 -2.61406,-2.62579 z"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            id="rect1102"
          />
          <path
            id="path1127"
            d="M 587.10358,338.04836 H 594.459"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
          <path
            d="m 591.45839,338.58332 a 0.67707878,0.67707878 0 0 1 -0.33853,0.58637 0.67707878,0.67707878 0 0 1 -0.67708,0 0.67707878,0.67707878 0 0 1 -0.33854,-0.58637"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            id="path1129"
          />
          <path
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 1,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            d="m 590.78131,332.19585 v 0"
            id="path1134"
          />
        </g>
      </g>
    );
    const chat = (
      <g transform="translate(-544.77387,-332.08065)" id="layer1">
        <g id="g1091">
          <path
            id="rect2770"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            d="m 547.09549,332.37169 c -1.12506,0 -2.03058,0.90618 -2.03058,2.0316 v 2.03159 c 0,1.12542 0.90552,2.03123 2.03058,2.03123 h 0.1984 v 1.26102 l 2.16082,-1.26102 h 0.93467 c 1.12506,0 2.03095,-0.90581 2.03095,-2.03123 v -2.03159 c 0,-1.12542 -0.90589,-2.0316 -2.03095,-2.0316 z"
          />
          <path
            id="path1077"
            d="m 546.59849,335.3172 v 0"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 1,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
          <path
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 1,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            d="m 548.70294,335.3172 v 0"
            id="path1083"
          />
          <path
            id="path1085"
            d="m 550.8074,335.3172 v 0"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 1,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
        </g>
      </g>
    );
    const profile = (
      <g transform="translate(-571.48816,-331.87769)" id="layer1">
        <g id="g1100" transform="translate(-23.269746,-9.8585567)">
          <rect
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            id="rect1090"
            width="2.7647789"
            height="4.1939082"
            x="597.3443"
            y="342.02728"
            rx="1.3823894"
            ry="1.3823894"
          />
          <path
            d="m 598.30395,346.24974 -0.84488,0.85074 h -1.26708 c -0.63326,0 -1.14304,0.55593 -1.14304,1.24643 v 0.44735 c 0,0.32585 0.26542,0.58846 0.59478,0.58846 h 6.16585 c 0.32936,0 0.59479,-0.26261 0.59479,-0.58846 v -0.44735 c 0,-0.6905 -0.50977,-1.24643 -1.14305,-1.24643 h -1.26708 l -0.84488,-0.85074 z"
            style={{
              fill: "none",
              stroke: color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
            id="path1772"
          />
        </g>
      </g>
    );
    let selected_icon = home;
    const props_selected = this.props.icon;
    switch (props_selected) {
      case "home":
        selected_icon = home;
        break;
      case "notif":
        selected_icon = notif;
        break;
      case "chat":
        selected_icon = chat;
        break;
      case "profile":
        selected_icon = profile;
        break;
      default:
        selected_icon = home;
        break;
    }
    return (
      <svg
        width="29.999697"
        height="29.999866"
        viewBox="0 0 7.9374196 7.9374649"
        version="1.1"
        id="svg8"
      >
        <defs id="defs2" />
        {selected_icon}
      </svg>
    );
  }
}

export default EditIcon;
