/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { add_child, set_child_index } from "../../actions/formActions";
import {
  set_child_index_new,
  set_value_properties,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

class TableInfinity extends Component {
  state = {};

  on_set_child_index = (child_uuid, parent_uuid_temp, field) => {
    this.props.set_value_properties({
      key: "modal_edit_child",
      value: true,
    });

    const body = {
      parent_uuid: parent_uuid_temp,
      child_uuid,
      level: this.props.level_child,
      field,
    };
    this.props.set_child_index_new(body);
  };

  on_add_child = (field) => {
    if (field?.uuid) {
      const parent_uuid_temp = this.props.parent_uuid_temp;
      const uuid = field.uuid;
      const body = {
        parent_uuid: uuid,
        parent_uuid_temp,
        status: "child",
        level: this.props.level_child,
      };
      this.props.add_child(body);
    }
  };

  render() {
    //local storage

    //local state

    //global props
    const { current_level, level_array } = this.props.properties;
    const { geo_layer_wo_geojson } = this.props.form;
    const { field, level_child, parent_uuid_temp } = this.props;

    //content
    const child_uuid_selected =
      level_array?.[current_level]?.child_uuid_selected || "";
    const child_uuid_selected_inf = child_uuid_selected;
    let child_array = geo_layer_wo_geojson?.child_array || [];

    child_array = child_array.filter(
      (item) =>
        item.parent_uuid_temp === parent_uuid_temp &&
        item.parent_uuid === field.uuid
    );

    return (
      <main>
        <div className="badge" id="orange">
          Tabel level: {level_child + 1}
        </div>
        <br />
        {child_array.map((item, idx) => {
          return (
            <section key={idx} className="margin_bottom">
              <div
                className="button"
                id={
                  item.child_uuid === child_uuid_selected_inf
                    ? "black"
                    : "white"
                }
                key={idx}
                onClick={this.on_set_child_index.bind(
                  this,
                  item.child_uuid,
                  item.parent_uuid_temp,
                  field
                )}
              >
                Item {idx + 1}
              </div>
            </section>
          );
        })}
        <br />
        <button
          onClick={this.on_add_child.bind(this, field)}
          className="button"
          id="blue"
        >
          Tambah data
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  add_child,
  set_child_index,
  set_child_index_new,
  set_value_properties,
})(TableInfinity);
