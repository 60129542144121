import axios from "axios";

/*GET METHODE*/

export const get_geocode_address = (params) => async (dispatch) => {
  //type === auto_address
  //get_geocode_address
  /*
  body = {
    lat,
    long,
    uuid,
    child_uuid
  }
  */

  dispatch({
    type: "SET_LOADING_FORM",
    payload: "get_geocode_address",
  });
  const { lat, long, uuid, child_uuid } = params;
  try {
    dispatch({
      type: "set_value",
      payload: {
        key: "uuid_process",
        value: uuid,
      },
    });
    const res = await axios.get(
      `https://api.maptiler.com/geocoding/${long},${lat}.json?key=CVYOeJckfxqURN5wjKEB`
    );
    const features = res?.data?.features;
    const value = features?.[0]?.place_name;

    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: value,
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: value,
          child_uuid,
        },
      });
    }
    dispatch({
      type: "CLEAR_LOADING_FORM",
    });
  } catch (e) {
    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: "-",
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: "-",
          child_uuid,
        },
      });
    }
    dispatch({
      type: "CLEAR_LOADING_FORM",
    });
  }
};

export const get_geocode_address_poi = (params) => async (dispatch) => {
  /*
  type === auto_address_poi

  body={
  lat,
  long,
  uuid,
  child_uuid
  }
  */
  dispatch({
    type: "SET_LOADING_FORM",
    payload: "get_geocode_address_poi",
  });
  const { long, lat, uuid, child_uuid } = params;
  try {
    dispatch({
      type: "set_value",
      payload: {
        key: "uuid_process",
        value: uuid,
      },
    });
    const res = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=poi&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`
    );
    const { features } = res.data;
    let value = features?.[0]?.place_name;

    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: value,
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: value,
          child_uuid,
        },
      });
    }
    dispatch({
      type: "CLEAR_LOADING_FORM",
    });
  } catch (error) {
    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: "-",
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: "-",
          child_uuid,
        },
      });
    }
    dispatch({
      type: "CLEAR_LOADING_FORM",
    });
  }
};

/*POST METHODE*/

/*NON POI*/

export const set_value_properties = (body) => {
  /*
  body: {
  key,
  value
  }
  */

  return {
    type: "set_value_properties",
    payload: body,
  };
};

//set_child_index_new
/*
body = { parent_uuid, child_uuid, level }
*/
export const set_child_index_new = (body) => (dispatch) => {
  dispatch({
    type: "set_child_index_new",
    payload: body,
  });
};

//one_step_back_child
/*
body = { level }
*/
export const one_step_back_child = () => (dispatch) => {
  dispatch({
    type: "one_step_back_child",
  });
};
