const initialState = { status: "" };

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_ERRORS":
      return action.payload;
    case "CLEAR_ERRORS":
      return {};
    case "SET_MESSAGE":
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
}
