/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import CellReturnValue from "./CellReturnValue";

/*REDUX FUNCTION*/
import {
  push_feature_key_pembanding_list,
  pull_feature_key_pembanding_list,
  submit_data_pembanding,
} from "../../actions/formActions";

/*PICTURE ASSET*/

/*GENERAL*/

class DataList extends Component {
  state = {};

  on_push_feature_key_pembanding_list = (feature_key) => {
    this.props.push_feature_key_pembanding_list(feature_key);
  };

  on_pull_feature_key_pembanding_list = (feature_key) => {
    this.props.pull_feature_key_pembanding_list(feature_key);
  };

  on_submit_data_pembanding = () => {
    const { data_pembanding } = this.props.form;
    const data_pembanding_list =
      data_pembanding?.layer?.geojson?.features?.[0]?.data_pembanding_list ||
      [];

    const body = {
      geo_layer_id: data_pembanding._id,
      feature_key: data_pembanding?.layer?.geojson?.features?.[0]?.key,
      data_pembanding_list,
    };

    this.props.toggle_success();
    this.props.submit_data_pembanding(body);
  };

  render() {
    //local storage

    //local state

    //global props
    const { data_pembanding } = this.props.form;
    const radius_pembanding = data_pembanding?.radius_pembanding || 500;

    //content
    const layer = data_pembanding?.layer_data_list?.[0] || {};
    const feature_key_pembanding_list =
      data_pembanding?.layer?.geojson?.features?.[0]?.data_pembanding_list ||
      [];

    let features = layer?.geojson?.features || [];
    let fields = layer?.fields || [];

    const fields_highlight = fields.filter(
      (f) =>
        f.isHighlight &&
        !f.isStyle &&
        ![
          "calculate_dimension",
          // "simple_referring",
          // "math_operators",
          "section",
          "jarak_apraisal",
        ].includes(f?.type) &&
        !["Longitude", "Latitude"].includes(f?.name)
    );

    const fields_all = fields.filter(
      (f) =>
        !f.isStyle &&
        ![
          "calculate_dimension",
          // "simple_referring",
          // "math_operators",
          "section",
          "jarak_apraisal",
        ].includes(f?.type) &&
        !["Longitude", "Latitude"].includes(f?.name)
    );

    let fields_final = [];

    if (fields_highlight.length === 0) {
      fields_final = fields_all;
    } else {
      fields_final = fields_highlight;
    }

    const longitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[0];

    return (
      <main>
        <p className="button_pill margin_bottom" id="blue">
          Opsi 1
        </p>
        <section className="container_light">
          <h1 className="text_bold">Pilih minimal tiga data pembanding</h1>
          <p className="text_inferior margin_bottom">
            Radius: {radius_pembanding} meter
          </p>
          <p className="text_inferior margin_bottom">
            Catatan: data yang sudah tersubmit akan tetap muncul meski berada di
            luar radius yang di atur admin.
          </p>
          {longitude ? (
            <main>
              <section style={{ maxHeight: "400px", overflowY: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <p>Nomor/</p>
                        <p>Jarak/</p>
                      </th>
                      {fields_final.map((item, idx) => {
                        return (
                          <th key={idx} style={{ minWidth: "60px" }}>
                            {String(item.name).slice(0, 15)}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Pilih</th>
                      {fields_final.map((item, idx) => {
                        return (
                          <td key={idx}>
                            <div className="button_small">{item.type}</div>
                          </td>
                        );
                      })}
                    </tr>
                    {features.map((feature, idx) => {
                      return (
                        <tr key={idx}>
                          <th className="text_left">
                            <section>{idx + 1}</section>
                            <section>{feature?.distance_m_string} m</section>
                            <section>
                              <button
                                id={
                                  feature_key_pembanding_list.includes(
                                    feature.key
                                  )
                                    ? "checklist_active"
                                    : "checklist_not"
                                }
                                onClick={
                                  feature_key_pembanding_list.includes(
                                    feature.key
                                  )
                                    ? this.on_pull_feature_key_pembanding_list.bind(
                                        this,
                                        feature.key
                                      )
                                    : this.on_push_feature_key_pembanding_list.bind(
                                        this,
                                        feature.key
                                      )
                                }
                              />
                            </section>
                          </th>
                          {fields_final.map((field, idx) => {
                            return (
                              <td key={idx}>
                                <CellReturnValue
                                  value={feature?.properties?.[field?.key]}
                                  field={field}
                                  feature={feature}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
              <br />
              {feature_key_pembanding_list.length === 0 ? (
                <>
                  <button className="button" id="grey">
                    Submit
                  </button>
                  <p className="text_inferior">
                    Tombol submit akan aktif setelah minimal satu data
                    pembanding dipilih
                  </p>
                </>
              ) : (
                <>
                  <button
                    className="button"
                    id="blue"
                    onClick={this.on_submit_data_pembanding}
                  >
                    Submit
                  </button>
                </>
              )}
            </main>
          ) : (
            <main className="text_inferior">
              Anda belum survei apraisal, pengukuran jarak data pembanding tidak
              bisa dilakukan, submit data pembanding via list belum bisa
              dilakukan.
            </main>
          )}
        </section>
        <br />
        <br />
        <p className="button_pill margin_bottom" id="blue">
          Opsi 2
        </p>
        <section className="container_light">
          <p className="text_bold">
            Atau klik tambah baru jika tidak ada data pembanding yang cocok atau
            dekat.
          </p>
          <br />
          <Link
            to={`/${layer?.link}/step_2?mode_pembanding=pembanding&feature_key_appraisal=${this?.props?.feature_key}&apraisal_id=${data_pembanding?._id}`}
            className="button"
            id="blue"
          >
            Tambah baru
          </Link>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {
  push_feature_key_pembanding_list,
  pull_feature_key_pembanding_list,
  submit_data_pembanding,
})(DataList);
