//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component

//Redux function

//Picture Asset

//General Function

class RevMessage extends Component {
  state = {};
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { task_list } = this.props.form;
    const { manifest_id, feature_key } = this.props.properties;

    //content
    const status_array = [
      {
        name: "upload",
        step: 1,
      },
      {
        name: "digitasi",
        step: 1,
      },
      {
        name: "assign",
        step: 1,
      },
      {
        name: "submit_rev",
        step: 2,
      },
      {
        name: "on_rev",
        step: 3,
      },
      {
        name: "accepted",
        step: 4,
      },
    ];

    const step_array = [
      {
        step: 1,
        name: "Penugasan",
        eng: "Assignment",
      },
      {
        step: 2,
        name: "Submit",
        eng: "Submit",
      },
      {
        step: 3,
        name: "Revisi",
        eng: "Revision",
      },
      {
        step: 4,
        name: "Selesai",
        eng: "Finish",
      },
    ];

    let manifest = {};
    let feature = {};
    let content;

    if (manifest_id) {
      manifest = task_list.find((item) => item._id === manifest_id);
      if (feature_key) {
        const layer = manifest?.layer;
        const fields = layer?.fields || [];
        const features = layer?.geojson?.features || [];
        feature = features.find((item) => item.key === feature_key);
        const status = feature?.formStatus?.status;
        const step = status_array.find((item) => item?.name === status)?.step;

        let revision_list = feature?.formStatus?.revision_list || [];
        revision_list = revision_list.map((item) => {
          const field = fields.find((f) => f?.uuid === item?.field_uuid);
          item.field = field;
          return item;
        });

        revision_list = revision_list.filter((item) => !item?.field?.isStyle);

        content = (
          <main className="container_light">
            <section>
              {step_array.map((item, idx) => {
                let arrow =
                  idx < step_array?.length - 1 ? (
                    <div
                      className="arrow_right grid_item"
                      style={{ marginRight: "2px" }}
                    />
                  ) : null;
                const content = (
                  <div className="grid_item" key={idx}>
                    <button
                      className="button_small text_center grid_item"
                      style={{ marginRight: "2px" }}
                      id={
                        step === item?.step ? "black_absolute" : "outline_black"
                      }
                    >
                      {language === "ina" ? item?.name : item?.eng}
                    </button>
                    {arrow}
                  </div>
                );
                return content;
              })}
            </section>

            <br />
            <section>
              {revision_list.map((item, idx) => {
                const field = item?.field;
                return (
                  <section key={idx} className="margin_bottom">
                    <p className="text_inferior">{field?.name}</p>
                    <p className="text_bold">{item?.message || "-"}</p>
                  </section>
                );
              })}
            </section>
          </main>
        );
      }
    }

    return (
      <div className="max_modal">
        <h1 className="text_header margin_bottom">Pesan revisi</h1>
        <Link
          className="button margin_bottom text_left"
          id="blue"
          to={`/${manifest?.link}/step_3/${feature_key}`}
        >
          Submit revisi sekarang
        </Link>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(RevMessage);
