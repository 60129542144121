import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SpinnerLogo from "../common/SpinnerLogo";
import isEmpty from "../../validation/is-empty";
class LayerList extends Component {
  state = {};
  render() {
    const { geo_project, loading, itemLoading } = this.props.project;
    let geo_layer_list = !isEmpty(geo_project.geo_layer_list)
      ? geo_project.geo_layer_list
      : [];
    geo_layer_list = geo_layer_list.filter(
      (geo_layer) => geo_layer.type === "Point"
    );
    let itemContent;
    if (loading && itemLoading === "pushListLayerToProject") {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else {
      let layer_list_content = (
        <main className="generic_container">
          Tidak ada layer di dalam project
        </main>
      );
      if (geo_layer_list.length > 0) {
        layer_list_content = (
          <main>
            {geo_layer_list.map((geo_layer, idx) => {
              const { name, link } = geo_layer;
              return (
                <div
                  key={idx}
                  className="container_light"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <div className="text_medium" style={{ marginBottom: "10px" }}>
                    {name}
                  </div>
                  <Link to={`/${link}`} className="button_tight">
                    Isi form
                  </Link>
                  <Link
                    to={`/menu/chat_layer/${link}`}
                    className="button_tight"
                    style={{ marginLeft: "5px" }}
                  >
                    Buka obrolan
                  </Link>
                </div>
              );
            })}
          </main>
        );
      }
      itemContent = (
        <div>
          <div className="text_bold">{geo_project.name}</div>
          <div className="text_medium">
            Klik isi form untuk memulai pengisian survei atau klik obrolan untuk
            memulai percakapan mengenai form
          </div>
          {layer_list_content}
        </div>
      );
    }
    return <div style={{ marginBottom: "200px" }}>{itemContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
});
export default connect(mapStateToProps, {})(LayerList);
