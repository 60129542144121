//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
// import Modal from "../common/Modal";
import TextFieldGroup from "../common/TextFieldGroup";

//Redux function
import { loginUser, sendLink } from "../../actions/authActions";

//Picture Asset

//General Function
import history from "../../actions/history";
import dict from "../../validation/dict.json";
import domain_list from "../../validation/domain_list";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      errors: {},
      width: window.innerWidth,
      show_password: false,
      modal_send_link: false,
      modal_success: false,
      code: "",
      password: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (!isNav) {
      if (this.props.auth.isAuthenticated) {
        history.push("/menu/dashboard");
      }
    } else {
      return null;
    }
    if (this.props.auth.verify_link_status === "send") {
      this.setState({ modal_success: true });
    }
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps) {
    if (
      !!(
        this.props.auth.verify_link_status !== prevProps.auth.verify_link_status
      ) &&
      this.props.auth.verify_link_status === "send"
    ) {
      this.setState({ modal_success: true });
    }
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated &&
      this.props.auth.isAuthenticated
    ) {
      if (!isNav) {
        history.push("/");
      } else {
        this.props.toggle_login();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  toggleSuccess = () => {
    this.setState({ modal_success: !this.state.modal_success });
  };

  toggleSendLink = () => {
    this.setState({ modal_send_link: !this.state.modal_send_link });
  };

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {});
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  on_login = (e) => {
    e.preventDefault();
    const isNav = this.props.isNav ? this.props.isNav : null;
    const userData = {
      name: this.state.name,
      password: this.state.password,
      isNav,
    };
    this.props.loginUser(userData);
  };

  onSubmitForgetPass = (e) => {
    e.preventDefault();
    const isNav = this.props.isNav ? this.props.isNav : null;
    const body = {
      email: this.state.email,
      isNav,
    };
    this.props.sendLink(body);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { errors, width, name, password, show_password } = this.state;

    //global props
    const { loading, domain, version } = this.props.auth;

    //content
    const logo_final = domain_list?.[domain]?.logo;
    let iconContent = (
      <div style={{ marginTop: "30px" }}>
        <button className="button" id="blue" type="submit">
          {dict["Sign in"][language]}
        </button>
      </div>
    );

    if (loading) {
      iconContent = (
        <div style={{ marginTop: "30px" }}>
          <button className="button" id="blue" type="submit">
            {dict["Sign in..."][language]}
          </button>
        </div>
      );
    }

    let content = (
      <form noValidate onSubmit={this.on_login}>
        <TextFieldGroup
          placeholder={dict["User name"][language]}
          name="name"
          id="name"
          value={name}
          onChange={this.handleChange}
          error={errors.name}
        />
        <br />
        <TextFieldGroup
          type={show_password ? "text" : "password"}
          isPassword={true}
          placeholder={dict["Password"][language]}
          name="password"
          id="password"
          value={password}
          onChange={this.handleChange}
          toggleShowPassword={this.toggleShowPassword}
          pattern="[0-9a-zA-Z]{8,}"
          error={errors.password}
        />
        <Link to="/menu/forget-password">
          <div className="button_register">
            {dict["Forgot Password"][language]}
          </div>
        </Link>
        {iconContent}
      </form>
    );

    return (
      <div
        style={{
          textAlign: width < 600 || this.props.isNav ? "center" : "left",
          maxWidth: "90vw",
          margin: "auto",
        }}
      >
        <div className="margin_bottom_big">
          <img
            src={logo_final}
            height="70px"
            width="auto"
            alt="FORM MAPID"
            style={{ margin: "0px auto 0px auto" }}
          />
        </div>
        {content}
        <div style={{ marginTop: "10px" }}>
          <div className="button_account">Belum punya akun?</div>
          <Link to="/menu/register" className="button_register">
            Daftar di sini
          </Link>
        </div>
        <style>{`.nav_bottom{ display:none;}`}</style>

        <div className="container_light">Versi: {version}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  sendLink,
})(Login);
