//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import MapGL from "@urbica/react-map-gl";
import Geocoder from "react-map-gl-geocoder";

//Personal Component
import CheckBox from "../common/CheckBox";
import LayerPembanding from "./LayerPembanding";
import LayerTitikUtama from "./LayerTitikUtama";

//Redux function

//Picture Asset

//General Function
import dict from "../../validation/dict.json";
import { PopupValue, RenderPopup } from "./Popup";
import LayerRadiusPembanding from "./LayerRadiusPembanding";

// const style_text = {
//   backgroundColor: "#e31b25",
//   color: "#fff",
//   borderRadius: "2em",
//   padding: "0px 8px 0px 8px",
//   fontSize: "10px",
//   opacity: 0.6,
//   fontFamily: `"Inter", sans-serif`,
// };

const style_point = {
  width: "10px",
  height: "10px",
  borderRadius: "2em",
  background: "#e31b25",
  borderStyle: "solid",
  borderColor: "#fff",
  borderWidth: 2,
};
class Map extends Component {
  geocoderContainerRef = React.createRef();
  _map = React.createRef();
  state = {
    viewport: {
      latitude: -6.1784072398481555,
      longitude: 106.84251626772414,
      zoom: 15,
    },
    // Pop Up
    layer_id_popup: 0,
    dataPopup: [],
    popupInfo: null,
    view_popup: false,
    mapStyle:
      "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
  };

  handleToggleMap = (e) => {
    this.setState({
      isSatellite: e.target.checked,
      mapStyle: e.target.checked
        ? "mapbox://styles/mapbox/satellite-streets-v11"
        : "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
    });
  };

  _onMapLoaded() {
    this.setState({ mapIsLoaded: true });
  }

  layerOnClick = (event) => {
    this.generatePopup(event);
  };

  generatePopup = (event) => {
    const { data_pembanding } = this.props.form;

    this.setState({
      dataPopup: event?.features[0]?.properties,
      layer_id_popup: event?.features[0].layer?.id,
    });

    const layer_attach = data_pembanding?.layer_data_list?.[0];
    const geojson = layer_attach?.geojson;
    // const key_pembanding_list =
    //   data_pembanding?.layer?.geojson?.features?.[0]?.data_pembanding_list ||
    //   [];
    // let features = layer_attach?.geojson?.features || [];

    // mencari data layer dari id layer yang dipilih
    let layer_popup = geojson?.features?.find(
      (e) => e._id === event?.features[0].layer?.id
    );

    // mengambil data id dari layer yang dipilih
    let dataPopUP = {
      ...layer_popup.properties,
      "Jarak (meter)": layer_popup.distance_m_string,
    };

    // dataPopUP = [...dataPopUP];

    // mengambil data fields dari layer yang dipilih
    let fields = [
      ...layer_attach.fields,
      { key: "Jarak (meter)", name: "Jarak (meter)" },
    ];

    // merender semua data dari layer yang dipilih
    let fieldsMap = fields.map((data, index) => {
      return (
        <div key={index}>
          <>
            {data.isStyle !== true ? (
              <PopupValue data={data} dataPopUP={dataPopUP} />
            ) : null}
          </>
        </div>
      );
    });
    //handle untuk titik masukan long lat ke dalam properties supaya bisa diakses lewat pop up, karena long lat di event ketika klik peta bisa jadi beda dengan long lat milik feature
    let long = event?.lngLat?.lng;
    let lat = event?.lngLat?.lat;
    if (event?.features[0]?.properties?.long) {
      long = event?.features[0]?.properties?.long;
    }
    if (event?.features[0]?.properties?.lat) {
      lat = event?.features[0]?.properties?.lat;
    }

    const handleClose = () => {
      this.setState({ popupInfo: null, view_popup: false });
    };

    // menerapkan popup pada mapbox untuk ditampilkan berdasarkan layer yang dipilih
    let popupInfo = (
      <RenderPopup
        lat={lat}
        long={long}
        value={fieldsMap}
        handleClose={() => handleClose()}
      />
    );
    this.setState({ popupInfo: popupInfo, view_popup: true });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      // screen_status,
      mapIsLoaded,
      // modalLogin,
      // loginStatus,
      // full_name,
      // phone_number_state,
      // readStatus,
      // modalEditName,
      // modalApplyPaidSurvey,
      // amount,
      // modalUserData,
      // modalEditCity,
      // modalEditPhone,
      // modalOffline,
      // city,
      // cities_filtered,
      isSatellite,
      // modal_incomplete,
      // fields_incomplete_state,
      // view_popup,
    } = this.state;

    //global props
    const { data_pembanding } = this.props.form;
    const { popupInfo } = this.state;

    //content
    const longitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[0] || 106.84251626772414;
    const latitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[1] || -6.1784072398481555;

    return (
      <main>
        <section
          style={{
            width: "100%",
            height: "350px",
            zIndex: 3,
            position: "relative",
          }}
        >
          <div
            style={{
              width: mapIsLoaded ? 0 : "100%",
              height: mapIsLoaded ? 0 : "350px",
              zIndex: 4,
              visibility: mapIsLoaded ? "hidden" : "visible",
              backgroundColor: "#FAF5DD",
              textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <b>{dict?.["Peta sedang dimuat"]?.[language]}</b>
            </div>
          </div>
          <div
            ref={this.geocoderContainerRef}
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              zIndex: 2,
              width: "90%",
              transform: "translate(-50%,0)",
            }}
          />
          <div
            id="satellite-toggle"
            style={{
              position: "absolute",
              top: 10,
              right: 0,
              zIndex: 2,
              color: this.state.isSatellite ? "white" : "black",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Satellite</b>
                  </td>
                  <td>
                    <CheckBox
                      text="isSatellite"
                      title={""}
                      value={isSatellite}
                      handle={this.handleToggleMap.bind(this)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {longitude && latitude && (
            <>
              <MapGL
                style={{
                  width: "100%",
                  height: "350px",
                  visibility: mapIsLoaded ? "visible" : "hidden",
                }}
                mapStyle={this.state.mapStyle}
                accessToken={
                  "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
                }
                latitude={latitude}
                longitude={longitude}
                zoom={this.state.viewport.zoom}
                onViewportChange={(viewport) => this.setState({ viewport })}
                attributionControl={false}
                {...this.props}
                ref={this._map}
                onLoad={() => {
                  this._onMapLoaded();
                }}
              >
                <Geocoder
                  containerRef={this.geocoderContainerRef}
                  mapRef={this._map}
                  onViewportChange={(viewport) => {
                    this.setState({
                      viewport,
                      latitude: viewport.latitude,
                      longitude: viewport.longitude,
                    });
                  }}
                  mapboxApiAccessToken={
                    "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g"
                  }
                  placeholder={"Search location"}
                />
                <LayerRadiusPembanding />
                <LayerPembanding layerOnClick={this.layerOnClick} />
                <LayerTitikUtama />
                {/* <RenderPopup
                  lat="-6.9734797079361925"
                  long="107.54888935929961"
                  value="Test"
                /> */}
                {popupInfo}
              </MapGL>
            </>
          )}
        </section>
        <section className="container_light margin_top">
          <h1>Information</h1>
          <div className="info_map">
            <p style={style_point}></p>
            <p>titik apraisal</p>
          </div>
        </section>
        {!data_pembanding?.layer?.geojson?.features?.[0]?.geometry
          ?.coordinates?.[0] && (
          <section className="container_light margin_top" id="red_outline">
            Anda belum melakukan survei utama apraisal. Titik tidak terampil,
            jarak tidak bisa diukur.
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {})(Map);
