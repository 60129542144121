//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function
import domain_list from "../../validation/domain_list";

class SpinnerSimpleLogo extends Component {
  render() {
    //local storage

    //local state

    //global props
    const { domain } = this.props.auth;

    //content
    const logo = domain_list?.[domain]?.logo;

    const { width } = this.props;
    const imageWidth = width * 0.8;
    const borderWidth = width * 0.1;
    return (
      <div style={{ textAlign: "center" }}>
        <div className={`image_${this.props.unik}`}>
          <img
            src={logo}
            alt="Mapid Logo"
            style={{
              width: `${imageWidth}px`,
              float: "left",
              position: "relative",
              left: `${(width - imageWidth) * 1}px`,
              top: `${(width - imageWidth) * 1}px`,
              overflow: "hidden",
            }}
          />
          <div className={`loader_${this.props.unik}`}></div>
          <style>
            {`
              .image_${this.props.unik}{
                width: ${width}px;
                height: ${width}px;
                display: inline-block;
                margin-top:${
                  this.props.marginTop ? this.props.marginTop : "0px"
                };
              }
              .loader_${this.props.unik} {
                border: ${borderWidth}px solid #2ca1fdff;
                border-radius: 50%;
                border-top: ${borderWidth}px solid #7eb532ff;
                border-right: ${borderWidth}px solid #1e5e96ff;
                width: ${width}px;
                height: ${width}px;
                -webkit-animation: spin 2s linear infinite; /* Safari */
                animation: spin 2s linear infinite;
              }
              /* Safari */
              @-webkit-keyframes spin {
                0% { -webkit-transform: rotate(0deg); }
                100% { -webkit-transform: rotate(360deg); }
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
          `}
          </style>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(SpinnerSimpleLogo);
