import axios from "axios";
import imageCompression from "browser-image-compression";
import his from "./history";
import { geoServerBaseUrl } from "./baseUrl";
const SERVER_URL = geoServerBaseUrl;

/*PROTOTYPE*/

// Email forget password
export const email_forget_password = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(SERVER_URL + "/mail/forgot_password", body);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

// forget password
export const forget_password = (body) => async (dispatch) => {
  try {
    dispatch(setLoadingProcess("forget_password"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + "/mail/new_password", body, config);
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

// code forget Password
export const code_forget = (body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        SERVER_URL + "/mail/code_forgot_password",
        body
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

//send link forget password
export const sendLink = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("send_link"));
    await axios.post(SERVER_URL + "/users/send_link_forget_pass", body);
    dispatch({
      type: "SEND_LINK",
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

export const clear_full_name_list = () => {
  return {
    type: "clear_full_name_list",
  };
};

//get_user_by_badge
export const get_user_by_badge = (full_name) => async (dispatch) => {
  try {
    dispatch(setLoading("get_user_by_badge"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    const res = await axios.get(
      SERVER_URL + `/users/get_user_by_badge?full_name=${full_name}`,
      config
    );
    dispatch({
      type: "get_user_by_badge",
      payload: res.data,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const setLanguage = (language) => {
  localStorage.setItem("language", language);
  return {
    type: "SET_LANGUAGE",
    payload: language,
  };
};

export const setPathname = (pathname) => {
  return {
    type: "SET_PATHNAME",
    payload: pathname,
  };
};

/*GET*/
//1. Get user from token
export const getUserFromToken = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + "/users", config)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
      localStorage.setItem("mapidUser", JSON.stringify(res.data));
    })
    .catch((err) => {
      dispatch({
        type: "GET_ERRORS",
        payload: err && err.response ? err.response.data : {},
      });
    });
};

//2. Get bank_data
export const getBankData = () => async (dispatch) => {
  try {
    dispatch(setLoading("getBankData"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    const res = await axios.get(SERVER_URL + `/users/bank_data`, config);
    dispatch(clearLoading());
    dispatch({
      type: "GET_BANK_DATA",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//3. Get current bank_data
export const getCurrentBankData = () => async (dispatch) => {
  try {
    dispatch(setLoading("current_bank_data"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/users/current_bank_data`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_CURRENT_BANK_DATA",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

//4. Get bukti pembayaran surveyor
export const getReceipt = () => async (dispatch) => {
  try {
    dispatch(setLoading("get_list_receipt_surveyor"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/users/get_list_receipt_surveyor`,
      config
    );
    dispatch(clearLoading());
    dispatch({
      type: "GET_RECEIPT",
      payload: res.data,
    });
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*POST*/

//1. Register User
export const registerUser = (body) => async (dispatch) => {
  try {
    dispatch(setLoading());
    await axios.post(SERVER_URL + "/users/register", body);
    dispatch(clearLoading());
    dispatch({
      type: "SET_REGISTER_SUCCESS",
    });
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });
    const { name, password } = body;
    const body_new = { name, password };
    dispatch(loginUser(body_new));
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data,
    });
  }
};

// 2. Register Email Invitation
export const RegisterEmailInvitation = (body) => async (dispatch) => {
  try {
    dispatch(setLoading());
    await axios.post(SERVER_URL + "/users/register_invitation", body);
    dispatch(clearLoading());
    dispatch({
      type: "SET_REGISTER_SUCCESS",
    });
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });
    const { name, password } = body;
    const body_new = { name, password };
    dispatch(loginUser(body_new));
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data,
    });
  }
};

export const UpdateStatusGroup = (body) => async (dispatch) => {
  try {
    dispatch(setLoading());
    await axios.get(SERVER_URL + `/users/update_status_group/${body}`);
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: {},
    });
  } catch (error) {
    dispatch(clearLoading());
    dispatch({
      type: "GET_ERRORS",
      payload: error?.response?.data,
    });
  }
};

//5. Login
export const loginUser = (userData) => (dispatch) => {
  dispatch(setLoading("loginUser"));
  const isNav = function (isNav) {
    if (!isNav) {
      return his.push("/menu/dashboard");
    }
  };
  axios
    .post(SERVER_URL + "/users/login", userData)
    .then((res) => {
      localStorage.setItem("jwtTokenMapid", res.data.token);
      localStorage.setItem("mapidUser", JSON.stringify(res.data.user));
      dispatch(setCurrentUser(res.data.user));
      dispatch(setCurrentToken(res.data.token));
      dispatch(clearLoading());
      dispatch(clearErrors());
      isNav(userData.isNav);
    })
    .catch((err) => {
      dispatch({
        type: "GET_ERRORS",
        payload: err && err.response ? err.response.data : {},
      });
      dispatch(clearLoading());
    });
};
//6. Edit user profile
export const updateProfile = (user_data) => (dispatch) => {
  dispatch(setLoading("updateProfile"));
  const config_axos = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/profile", user_data, config_axos)
    .then((res) => {
      dispatch({
        type: "UPDATE_USER_PROFILE",
        payload: res.data,
      });
      his.push("/menu/setting");
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};
//7. Edit full_name
export const updateFullname = (body) => (dispatch) => {
  dispatch(setLoading("full_name"));
  const config_header = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/edit_fullname", body, config_header)
    .then((res) => {
      dispatch({
        type: "UPDATE_FULLNAME",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};
//8. Edit city
export const updateCity = (body) => (dispatch) => {
  dispatch(setLoading("updateCity"));
  const config_header = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/edit_city", body, config_header)
    .then((res) => {
      dispatch({
        type: "UPDATE_CITY",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};
//9. Edit phone_number
export const updatePhone = (body) => (dispatch) => {
  dispatch(setLoading("updatePhone"));
  const config_header = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/edit_phone_number", body, config_header)
    .then((res) => {
      dispatch({
        type: "UPDATE_PHONE",
        payload: res.data,
      });
      dispatch(clearLoading());
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};
//10. Upload Photo profile
export const uploadPhotoProfile = (item) => async (dispatch) => {
  try {
    dispatch(setLoading("photo_profile"));
    const config_header = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };
    //Original file
    const { objParams } = item;
    const file = objParams.file;
    //Compressed file big
    const options_compression_big = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    const file_big = await imageCompression(file, options_compression_big);
    const file_name_big = objParams.file_name_big;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name_big}`);
    formData.append("file", file_big);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url = resultBigUpload.data.data.Location;
    //Compressed file small
    const options_compression_small = {
      maxSizeMB: 0.01,
      maxWidthOrHeight: 50,
      useWebWorker: true,
    };
    const file_small = await imageCompression(file, options_compression_small);
    const file_name_small = objParams.file_name_small;
    const formDataSmall = new FormData();
    formDataSmall.append("original_file_name", `${file_name_small}`);
    formDataSmall.append("file", file_small);
    const resultSmallUpload = await axios.post(
      SERVER_URL + "/upload",
      formDataSmall,
      config_header_upload
    );
    const url_compressed = resultSmallUpload.data.data.Location;
    //Generate body & axios to API
    const body = {
      profile_picture: {
        url,
        name: file_name_big,
        url_compressed,
        name_compressed: file_name_small,
      },
    };
    await axios.post(SERVER_URL + "/users/edit_profile", body, config_header);
    dispatch(clearLoading());
    dispatch({
      type: "UPDATE_PP",
      payload: body,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};
//11. Upload KTP
export const uploadKTP = (item) => async (dispatch) => {
  try {
    dispatch(setLoading("ktp"));
    const config_header = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };
    const options_compression = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    const { objParams } = item;
    //Original file
    const file = objParams.file;
    //Compressed file
    const compressedFile = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", compressedFile);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const picture_url = resultBigUpload.data.data.Location;
    const body = { ktp: picture_url };
    const axios_response = await axios.post(
      SERVER_URL + "/users/edit_ktp",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPDATE_KTP",
      payload: axios_response.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};
//12. Add bank data
export const addBank = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("addBank"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + `/users/add_bank_data`, body, config);
    dispatch({
      type: "ADD_BANK_DATA",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};
//13. Set bank data
export const setCurrentBankData = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("set_bank_data"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + `/users/set_bank_data`, body, config);
    dispatch({
      type: "SET_CURRENT_BANK_DATA",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};
/*NON API*/
export const setLoading = (itemLoading) => {
  return {
    type: "AUTH_LOADING",
    payload: itemLoading,
  };
};
export const clearLoading = () => {
  return {
    type: "CLEAR_AUTH_LOADING",
  };
};
export const setCurrentUser = (user) => {
  return {
    type: "SET_CURRENT_USER",
    payload: user,
  };
};
export const setCurrentToken = (decoded) => {
  return {
    type: "SET_CURRENT_TOKEN",
    payload: decoded,
  };
};
export const clearErrors = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};
export const setLoadingProcess = (itemLoading) => {
  return {
    type: "AUTH_LOADING",
    payload: itemLoading,
  };
};
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtTokenMapid");
  dispatch(setCurrentUser({}));
};
export const resetSearchUser = () => (dispatch) => {
  dispatch({
    type: "SEARCH_USERS",
    payload: [],
  });
};
//Update selfie KTP [NOT USED]
export const uploadSelfieKTP = (item) => async (dispatch) => {
  try {
    dispatch(setLoading("selfie_ktp"));
    const config_header = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };
    const options_compression = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 500,
      useWebWorker: true,
    };
    const { objParams } = item;
    //Original file
    const file = objParams.file;
    //Compressed file
    const compressedFile = await imageCompression(file, options_compression);
    const file_name = objParams.file_name;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", compressedFile);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const picture_url = resultBigUpload.data.data.Location;
    const body = { selfiektp: picture_url };
    const res = await axios.post(
      SERVER_URL + "/users/edit_selfie_ktp",
      body,
      config_header
    );
    dispatch(clearLoading());
    dispatch({
      type: "UPDATE_SELFIE_KTP",
      payload: res.data,
    });
  } catch (e) {
    dispatch(clearLoading());
  }
};
//7. Sub Notification
export const subNotification = (sub_object) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/users/sub", JSON.stringify(sub_object), config)
    .then((res) => {
      dispatch({
        type: "SUB_NOTIF",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "GET_ERRORS",
        payload: err && err.response ? err.response.data : {},
      });
    });
};
