/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import FieldsRender from "../universal_form_sub_components/FieldsRender";
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import {
  add_child,
  set_child_index,
  delete_child,
} from "../../actions/formActions";
import { set_child_index_new } from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

class TableParent extends Component {
  state = {
    modal_delete_child: false,
    child_uuid_state: "",
  };

  toggle_delete_child = (child_uuid_state) => {
    this.setState({ modal_delete_child: !this.state.modal_delete_child });
    if (child_uuid_state) {
      this.setState({ child_uuid_state });
    }
  };

  on_set_child_index = (child_uuid, parent_uuid_temp, field) => {
    const body = {
      parent_uuid: parent_uuid_temp,
      child_uuid,
      level: this.props.level_child,
      field,
    };
    this.props.set_child_index(body);
    this.props.set_child_index_new(body);
  };

  on_close_set_child_index = () => {
    const body = {
      parent_uuid: "",
      child_uuid: "",
      level: this.props.level_child,
    };
    this.props.set_child_index(body);
  };

  on_add_child = (field) => {
    const level = 1;
    if (field?.uuid) {
      const uuid = field.uuid;
      const body = {
        parent_uuid: uuid,
        level,
      };
      this.props.add_child(body);
    }
  };

  on_delete_child = (child_uuid) => {
    const body = {
      child_uuid,
    };
    this.props.delete_child(body);
    this.setState({ modal_delete_child: false });
  };

  render() {
    //local storage

    //local state
    const { modal_delete_child, child_uuid_state } = this.state;

    //global props
    const { geo_layer_wo_geojson } = this.props.form;
    const { parent_uuid_temp, field, level_child } = this.props;
    const parent_uuid = field.uuid;

    //content
    const { parent_uuid_selected, child_uuid_selected } = geo_layer_wo_geojson;
    let child_array = geo_layer_wo_geojson?.child_array || [];
    child_array = child_array.filter(
      (item) => item.parent_uuid === parent_uuid
    );
    if (parent_uuid_temp) {
      child_array = child_array.filter(
        (item) => item.parent_uuid_temp === parent_uuid_temp
      );
    }

    const modal_delete_child_content = modal_delete_child && (
      <Modal
        modalSize="small"
        id="modal_delete_child"
        isOpen={modal_delete_child}
        onClose={this.toggle_delete_child}
      >
        <div className="box-body text_center">
          <h1 className="text_bold">Konfirmasi hapus item dari tabel</h1>
          <br />
          <br />
          <button
            className="button"
            id="red"
            onClick={this.on_delete_child.bind(this, child_uuid_state)}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_delete_child_content}
        <div className="badge" id="orange">
          Tabel level: {level_child + 1}
        </div>
        <section>
          {child_array.map((item, idx) => {
            const logic =
              child_uuid_selected === item.child_uuid ||
              parent_uuid_selected === item.child_uuid;
            return (
              <main key={idx} className="margin_bottom_x">
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <p className="button" id="white">
                          {field.name} {idx + 1}{" "}
                          <button
                            className="button_small"
                            id="red"
                            style={{ marginLeft: "9px" }}
                            onClick={this.toggle_delete_child.bind(
                              this,
                              item.child_uuid
                            )}
                          >
                            Hapus
                          </button>
                        </p>
                      </td>
                      <td className="text_right">
                        {logic ? (
                          <button
                            className="button"
                            id={logic ? "blue" : "white"}
                            onClick={this.on_close_set_child_index}
                          >
                            Tutup
                          </button>
                        ) : (
                          <button
                            className="button"
                            id={logic ? "blue" : "white"}
                            onClick={this.on_set_child_index.bind(
                              this,
                              item.child_uuid,
                              "",
                              field
                            )}
                          >
                            Buka
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <section
                  className="fields_general"
                  id={logic ? "item_open" : "item_close"}
                >
                  <FieldsRender
                    field={field}
                    fields={item.fields_child}
                    child_uuid={item.child_uuid}
                    is_parent={false}
                  />
                </section> */}

                {logic && (
                  <FieldsRender
                    field={field}
                    fields={item.fields_child}
                    child_uuid={item.child_uuid}
                    is_parent={false}
                  />
                )}
              </main>
            );
          })}
        </section>

        <br />

        <button
          onClick={this.on_add_child.bind(this, field)}
          className="button"
          id="green"
        >
          Tambah {field.name}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {
  add_child,
  set_child_index,
  delete_child,
  set_child_index_new,
})(TableParent);
