const initialState = {
  loading_status: false,
  loading_item: "",
  manifest_id: "",
  feature_key: "",
  overlay_visibility_status: true,
  current_level: 0,
  level_array: [],
  /*
  contoh level_array:
  [
  {
  level: 0,
  parent_uuid_selected: String,
  child_uuid_selected: String
  }
  ]
  */
  modal_edit_child: false,
  latitude: -6.1784072398481555,
  longitude: 106.84251626772414,
};

export default function propertiesReducer(state = initialState, action) {
  switch (action.type) {
    case "set_value_properties":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_child_index_new":
      return {
        ...state,
        level_array: set_child_index_new(state.level_array, action.payload),
        current_level: action.payload.level,
      };
    case "one_step_back_child":
      return {
        ...state,
        level_array: one_step_back_child(
          state.level_array,
          state.current_level
        ),
        current_level: state.current_level - 1,
      };
    default:
      return state;
  }
}

const set_child_index_new = (list, body) => {
  const { parent_uuid, child_uuid, level, field } = body;
  list[level] = {
    level,
    parent_uuid_selected: parent_uuid,
    child_uuid_selected: child_uuid,
    field_selected: field,
  };

  return list;
};

const one_step_back_child = (list, current_level) => {
  list = list.filter((item) => item.level !== current_level);
  return list;
};
