import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import { getNotifications } from "../../actions/formActions";

import SpinnerLogo from "../common/SpinnerLogo";

import isEmpty from "../../validation/is-empty";
// import pic_static from "../../img/profile_pic_comment.svg";

class Notifications extends Component {
  state = {};
  componentDidMount() {
    this.props.getNotifications();
  }
  render() {
    const { loadingDetail } = this.props.form;
    const notifications = this.props.form.notifications
      ? this.props.form.notifications
      : [];
    const notification_comment = notifications.filter(
      (notif) => notif.type === "layer_comment"
    );
    let itemContent;
    if (loadingDetail && isEmpty(notifications)) {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (notifications.length === 0) {
      itemContent = (
        <div className="container_light">Kamu belum memiliki obrolan</div>
      );
    } else {
      const notification_comment_filtered = notification_comment.filter(
        (notification) => notification.gis_layers[0].gis_layer
      );
      itemContent = (
        <div>
          {notification_comment_filtered.map((notification, idx) => {
            const { _id, gis_layers, date } = notification;
            const gis_layer = gis_layers[0].gis_layer;
            const comments =
              gis_layer && gis_layer.comments ? gis_layer.comments : [];
            const link = gis_layer && gis_layer.link ? gis_layer.link : "";
            const latest_comment = comments[comments.length - 1];

            const user_comment =
              latest_comment && latest_comment.user.full_name
                ? latest_comment?.user?.full_name
                : latest_comment?.user?.name
                ? latest_comment?.user?.name
                : "";
            // const profile_picture = latest_comment.user.profile_picture;
            const text_comment = latest_comment?.text;
            return (
              <div key={_id} className="generic_container">
                <Link to={`/menu/chat_layer/${link}`}>
                  <div className="button_tight" id="edit">
                    {gis_layer?.name
                      ? gis_layer?.name
                      : "(Layer telah dihapus)"}
                  </div>
                  <div>
                    {user_comment}
                    {" : "} {text_comment}
                  </div>
                  <div className="button_tight" id="edit">
                    {moment(date).from()}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="main_container">
        <div className="text_bold">Obrolan</div>
        {itemContent}
      </div>
    );
  }
}
Notifications.propTypes = {
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  getNotifications: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});
export default connect(mapStateToProps, { getNotifications })(Notifications);
