/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LayerPembanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: null,
      popupInfo: false,
      layerOnClick: this.togglePopup.bind(this),
      dataPopup: [],
      view_list_state: [],
    };
  }

  // ngatur popUP
  togglePopup() {
    this.setState({
      popupInfo: true,
    });
  }

  render() {
    //local storage

    //local state

    //global props
    const { data_pembanding } = this.props.form;

    //content
    const layer_attach = data_pembanding?.layer_data_list?.[0];
    const geojson = layer_attach?.geojson;
    const features = geojson?.features || [];

    const layer_render = features.map((data, index) => {
      return (
        <section key={index}>
          <Source
            key={data._id}
            id={data._id}
            type="geojson"
            data={data.geometry}
          />
          <Layer
            id={data._id}
            type="circle"
            source={data._id}
            paint={{
              "circle-color": "#434343",
              "circle-radius": 4,
              "circle-stroke-width": 2,
              "circle-stroke-color": "#fff",
            }}
            layout={{ visibility: "visible" }}
            onClick={this.props.layerOnClick.bind(this)}
          />
        </section>
      );
    });

    return layer_render;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});

export default connect(mapStateToProps, {})(LayerPembanding);
