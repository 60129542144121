import logo_form from "../img/logo_form.svg";
import logo_befa from "../img/logo_befa.png";
import logo_brantas from "../img/logo_brantas.png";
import logo_cilicis from "../img/logo_cilicis.png";
import logo_danamas from "../img/logo_danamas.png";

const domain_list = {
  localhost: {
    logo: logo_form,
    title: "FORM LOCALHOST",
    short_title: "FORM MAPID",
    sub_title: "Hanya untuk local tester",
    status: "basic",
  },
  "form.mapid.io": {
    logo: logo_form,
    title: "FORM MAPID",
    short_title: "FORM MAPID",
    sub_title: "FORM MAPID",
    status: "basic",
  },
  "form-alpha.mapid.io": {
    logo: logo_form,
    title: "FORM MAPID ALPHA",
    short_title: "FORM MAPID ALPHA",
    sub_title: "FORM MAPID ALPHA",
    status: "basic",
  },
  "form-beta.mapid.io": {
    logo: logo_form,
    title: "FORM MAPID BETA",
    short_title: "FORM MAPID BETA",
    sub_title: "FORM MAPID BETA",
    status: "basic",
  },
  "form-befa.mapid.io": {
    logo: logo_befa,
    title: "BEFA FORM",
    short_title: "BEFA FORM",
    sub_title: "BEFA FORM",
    status: "premium",
  },
  "brantas.mapid.io": {
    logo: logo_brantas,
    title: "BBWS BRANTAS",
    short_title: "BBWS BRANTAS",
    sub_title: "APLIKASI FORM BBWS BRANTAS",
    status: "premium",
  },
  "cilicis.mapid.io": {
    logo: logo_cilicis,
    title: "BBWS CILICIS",
    short_title: "BBWS CILICIS",
    sub_title: "Aplikasi form BBWS Ciliwung Cisadane",
    status: "premium",
  },
  "danamas-survey.mapid.io": {
    logo: logo_danamas,
    title: "DANAMAS FORM",
    short_title: "DANAMAS FORM",
    sub_title: "DANAMAS FORM",
    status: "premium",
  },
};

export default domain_list;
