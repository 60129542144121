import uuid_f from "../validation/uuid";

const initialState = {
  geo_layer_wo_geojson: {},
  notifications: [],
  image_field: {},
  loading: false,
  itemLoading: "",
  form_list_offline: [],

  latitude: -6.1784072398481555,
  longitude: 106.84251626772414,

  latitude_init: -6.1784072398481555,
  longitude_init: 106.84251626772414,
  zoom: 7,

  task_list: [],
  fields_section: [], //array fields dengan type section, di dalamnya ada atribut sub yang berisi array uuid dari tiap fields yang merupakan childnya
  fields_no_section: [], //array uuid dari fields yang tidak memiliki section
  is_location_exist: false,
  data_pembanding: {},
  feature_key_pembanding_list: [],
  geojson_polygon_filtered: {},
  fields_polygon: [],

  data_location: [],
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case "edit_counting_custom_array":
      return {
        ...state,
        geo_layer_wo_geojson: edit_counting_custom_array(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };

    case "get_data_location":
      return {
        ...state,
        data_location: get_data_location(state.data_location, action.payload),
      };
    case "push_feature_key_pembanding_list":
      return {
        ...state,
        data_pembanding: push_feature_key_pembanding_list(
          state.data_pembanding,
          action.payload
        ),
      };

    case "pull_feature_key_pembanding_list":
      return {
        ...state,
        data_pembanding: pull_feature_key_pembanding_list(
          state.data_pembanding,
          action.payload
        ),
      };

    case "get_data_pembanding":
      return {
        ...state,
        data_pembanding: action.payload,
      };

    case "get_feature":
      return {
        ...state,
        geo_layer_wo_geojson: get_feature(action.payload).layer,
        latitude: get_feature(action.payload).latitude,
        longitude: get_feature(action.payload).longitude,
      };

    case "get_task_list":
      return {
        ...state,
        task_list: action.payload,
      };
    case "set_value":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_child_array":
      return {
        ...state,
        geo_layer_wo_geojson: set_child_array(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "set_child_index":
      return {
        ...state,
        geo_layer_wo_geojson: set_child_index(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "add_child":
      return {
        ...state,
        geo_layer_wo_geojson: add_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "delete_child":
      return {
        ...state,
        geo_layer_wo_geojson: delete_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };

    //1
    case "change_child":
      return {
        ...state,
        geo_layer_wo_geojson: change_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //2
    case "click_check_child":
      return {
        ...state,
        geo_layer_wo_geojson: click_check_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //3
    case "remove_check_child":
      return {
        ...state,
        geo_layer_wo_geojson: remove_check_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //4
    case "select_other_child":
      return {
        ...state,
        geo_layer_wo_geojson: select_other_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //5
    case "change_phone_code_child":
      return {
        ...state,
        geo_layer_wo_geojson: change_phone_code_child(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };

    case "check_poi_status":
      return {
        ...state,
        geo_layer_wo_geojson: check_poi_status(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //1
    case "change_text":
      return {
        ...state,
        geo_layer_wo_geojson: change_text(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //2
    case "click_check":
      return {
        ...state,
        geo_layer_wo_geojson: click_check(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //3
    case "remove_check":
      return {
        ...state,
        geo_layer_wo_geojson: remove_check(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //4
    case "select_other":
      return {
        ...state,
        geo_layer_wo_geojson: select_other(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    //5
    case "change_phone_code":
      return {
        ...state,
        geo_layer_wo_geojson: change_phone_code(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };

    //Push layer to offline list
    case "SET_FORM_OFFLINE":
      return {
        ...state,
        form_list_offline: action.payload,
      };
    case "GET_NOTIFICATION":
      return {
        ...state,
        notifications: action.payload,
      };
    case "SET_IMAGE":
      return {
        ...state,
        image_field: action.payload,
      };
    case "SET_LOADING_FORM":
      return {
        ...state,
        loading: true,
        itemLoading: action.payload,
      };
    case "SET_LAYER_WO_GEOJSON":
      return {
        ...state,
        geo_layer_wo_geojson: action.payload,
      };
    case "SET_BANK":
      return {
        ...state,
        geo_layer_wo_geojson: surveyorSetBank(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "EDIT_PROPERTIES":
      return {
        ...state,
        geo_layer_wo_geojson: editProperties(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "GET_DOC_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: getBlogLayer(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "APPLY_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: applyForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "CLEAR_LOADING_FORM":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

//edit_counting_custom_array
const edit_counting_custom_array = (layer, item) => {
  const {
    field_uuid,
    // feature_key_array
  } = item;
  let counting_custom_array = layer?.counting_custom_array || [];
  const index = counting_custom_array.findIndex(
    (c) => c?.field_uuid === field_uuid
  );
  if (index === -1) {
    counting_custom_array.push(item);
  } else {
    counting_custom_array[index] = item;
  }
  layer.counting_custom_array = counting_custom_array;
  return layer;
};

//push_feature_key_pembanding_list
const push_feature_key_pembanding_list = (object, item) => {
  let data_pembanding_list =
    object?.layer?.geojson?.features?.[0]?.data_pembanding_list || [];
  data_pembanding_list.push(item);
  object.layer.geojson.features[0].data_pembanding_list = data_pembanding_list;
  return object;
};

//pull_feature_key_pembanding_list
const pull_feature_key_pembanding_list = (object, item) => {
  let data_pembanding_list =
    object?.layer?.geojson?.features?.[0]?.data_pembanding_list || [];
  data_pembanding_list = data_pembanding_list.filter((e) => e !== item);
  object.layer.geojson.features[0].data_pembanding_list = data_pembanding_list;
  return object;
};

//set_child_array
const set_child_array = (layer, payload) => {
  const { child_array } = payload;
  layer.child_array = child_array;
  return layer;
};

//get_feature
const get_feature = (layer) => {
  let longitude = 106.84251626772414;
  let latitude = -6.1784072398481555;

  if (layer?.geojson?.features?.[0]?.geometry?.type === "Point") {
    const longitude_test =
      layer?.geojson?.features?.[0]?.geometry?.coordinates?.[0];
    const latitude_test =
      layer?.geojson?.features?.[0]?.geometry?.coordinates?.[1];
    if (!isNaN(longitude_test)) {
      longitude = longitude_test;
    }
    if (!isNaN(latitude_test)) {
      latitude = latitude_test;
    }
  }

  // let fields = layer?.fields || [];
  // const properties = layer?.geojson?.features?.[0]?.properties || {};

  // fields = fields.map((field) => {
  //   const value = properties?.[field.key] || "";
  //   field.value = value;
  //   return field;
  // });

  // layer.fields = fields;

  return { layer, latitude, longitude };
};

//set_child_index
const set_child_index = (layer, payload) => {
  const { parent_uuid, child_uuid, level } = payload;
  layer.level = level;
  if (level < 2) {
    layer.parent_uuid_selected = parent_uuid;
    layer.child_uuid_selected = child_uuid;
  } else {
    layer.parent_uuid_selected_inf = parent_uuid;
    layer.child_uuid_selected_inf = child_uuid;
  }
  return layer;
};

//add_child
const add_child = (layer, payload) => {
  const { parent_uuid, parent_uuid_temp, level } = payload; //parent_uuid
  const child_uuid = uuid_f(); //child_uuid

  let fields = layer.fields;
  let fields_child = fields
    .filter((f) => f.parent_uuid === parent_uuid)
    .map((f) => {
      return { ...f };
    });

  const child_item = {
    parent_uuid,
    parent_uuid_temp,
    child_uuid,
    fields_child,
  };

  let child_array = layer?.child_array || [];
  child_array.push(child_item);

  layer.child_array = child_array;

  if (level < 2) {
    //hanya melakukan set selected untuk level < 3 karena untuk level >= 3 dilakukan mode edit reusable supaya berapapun level tablenya masih bisa ke handle
    layer.parent_uuid_selected = parent_uuid_temp;
    layer.child_uuid_selected = child_uuid;
  }

  return layer;
};

//delete_child
const delete_child = (layer, payload) => {
  const { child_uuid, level } = payload; //parent_uuid
  let child_array = layer?.child_array || [];
  child_array = child_array.filter((c) => c.child_uuid !== child_uuid);
  layer.child_array = child_array;
  if (level < 3) {
    layer.child_uuid_selected = "";
  }
  return layer;
};

//1. change_child
const change_child = (layer, payload) => {
  const { uuid, value, child_uuid } = payload;
  let child_array = layer?.child_array || [];
  let fields = child_array.find(
    (f) => f.child_uuid === child_uuid
  ).fields_child;
  let field = fields.find((f) => f.uuid === uuid);
  field.value = value;
  return layer;
};

//2. click_check_child
const click_check_child = (layer, payload) => {
  const { uuid, value, child_uuid } = payload;
  let child_array = layer?.child_array || [];
  let fields = child_array.find(
    (f) => f.child_uuid === child_uuid
  ).fields_child;
  let field = fields.find((f) => f.uuid === uuid);
  let value_array = field?.value_array ? field?.value_array : [];
  value_array.push(value);
  field.value_array = value_array;
  return layer;
};

//3. remove_check_child
const remove_check_child = (layer, payload) => {
  const { uuid, value, child_uuid } = payload;

  let child_array = layer?.child_array || [];

  let fields = child_array.find(
    (f) => f.child_uuid === child_uuid
  ).fields_child;
  let field = fields.find((f) => f.uuid === uuid);

  let value_array = field?.value_array ? field?.value_array : [];
  value_array = value_array.filter((v) => v !== value);

  field.value_array = value_array;
  return layer;
};

//4. select_other_child
const select_other_child = (layer, payload) => {
  const { uuid, value, child_uuid } = payload;

  let child_array = layer?.child_array || [];

  let fields = child_array.find(
    (f) => f.child_uuid === child_uuid
  ).fields_child;
  let field = fields.find((f) => f.uuid === uuid);

  field.select_other = value;
  return layer;
};

//5. change_phone_code_child
const change_phone_code_child = (layer, payload) => {
  const { uuid, value, child_uuid } = payload;

  let child_array = layer?.child_array || [];

  let fields = child_array.find(
    (f) => f.child_uuid === child_uuid
  ).fields_child;
  let field = fields.find((f) => f.uuid === uuid);

  field.phone_code = value;
  return layer;
};

//check_poi_status
const check_poi_status = (layer, payload) => {
  const { uuid } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);

  field.check_poi_status = true;
  return layer;
};

//1. change_text
const change_text = (layer, payload) => {
  const { uuid, value } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);
  field.value = value;
  return layer;
};

//2. click_check
const click_check = (layer, payload) => {
  const { uuid, value } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);
  let value_array = field?.value_array ? field?.value_array : [];
  value_array.push(value);

  field.value_array = value_array;
  return layer;
};

//3. remove_check
const remove_check = (layer, payload) => {
  const { uuid, value } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);
  let value_array = field?.value_array ? field?.value_array : [];
  value_array = value_array.filter((v) => v !== value);

  field.value_array = value_array;
  return layer;
};

//4. select_other
const select_other = (layer, payload) => {
  const { uuid, value } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);

  field.select_other = value;
  return layer;
};

//5. change_phone_code
const change_phone_code = (layer, payload) => {
  const { uuid, value } = payload;
  let fields = layer.fields;
  let field = fields.find((f) => f.uuid === uuid);

  field.phone_code = value;
  return layer;
};

const getBlogLayer = (layer, payload) => {
  const new_layer = { ...layer };
  new_layer.blog = payload;
  return new_layer;
};

const applyForm = (layer, payload) => {
  const new_layer = { ...layer };
  const { user, amount } = payload;
  const surveyor = { user, amount };
  let surveyors = new_layer?.formPayment?.surveyors
    ? new_layer.formPayment.surveyors
    : [];
  surveyors.push(surveyor);
  new_layer.formPayment.surveyors = surveyors;
  return new_layer;
};

//FORM VALIDATION
const surveyorSetBank = (geo_layer, payload) => {
  const new_geo_layer = { ...geo_layer };
  const { surveyor_id, payment } = payload;
  let { surveyors } = new_geo_layer.formPayment;
  const indexSurveyor = surveyors.findIndex(
    (surveyor) => surveyor._id === surveyor_id
  );
  let new_surveyor = surveyors[indexSurveyor];
  new_surveyor.payment = payment;
  surveyors.splice(indexSurveyor, 1, new_surveyor);
  new_geo_layer.formPayment.surveyors = surveyors;
  return new_geo_layer;
};

//FORM EDIT
const editProperties = (geo_layer, payload) => {
  const new_geo_layer = { ...geo_layer };
  const { feature_key, properties } = payload;
  let { features } = new_geo_layer.geojson;
  const indexFeature = features.findIndex(
    (feature) => feature.key === feature_key
  );
  let new_feature = features[indexFeature];
  new_feature.properties = properties; //Update properties
  new_feature.date_modified = Date.now(); //Update date_modified
  new_feature.formStatus.status = "pending"; //Update status
  features.splice(indexFeature, 1, new_feature); //replace item
  new_geo_layer.geojson.features = features;
  return new_geo_layer;
};

// push_data_location
const get_data_location = (geo_layer, payload) => {
  const { data, key } = payload;

  let body = [];
  if (key.length >= 2) {
    body = data;
  } else {
    body = [];
  }

  return body;
};
