const initialState = {
  geo_project_list: [],
  geo_project: {},
  form_list_paid: [],
  form_list_public: [],
  form_list_surveyor: [],
  layer_list: [],
  loading: false,
  itemLoading: "",
};
export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LAYER_LIST":
      return {
        ...state,
        layer_list: action.payload,
      };
    case "GET_PROJECT_LIST":
      return {
        ...state,
        geo_project_list: action.payload,
      };
    case "GET_PROJECT_DETAIL":
      return {
        ...state,
        geo_project: action.payload,
      };
    case "GET_PAID_FORM_LIST":
      return {
        ...state,
        form_list_paid: action.payload,
      };
    case "GET_PUBLIC_FORM_LIST":
      return {
        ...state,
        form_list_public: action.payload,
      };
    case "GET_FORM_LIST_SURVEYOR":
      return {
        ...state,
        form_list_surveyor: action.payload,
      };
    case "PUSH_LAYER_LIST":
      return {
        ...state,
        geo_project_list: pushListLayerToProjectList(
          state.geo_project_list,
          action.payload
        ),
        geo_project: pushListLayerToProject(
          state.geo_project_list,
          action.payload
        ),
      };
    case "SET_LOADING_PROJECT":
      return {
        ...state,
        loading: true,
        itemLoading: action.payload,
      };
    case "CLEAR_LOADING_PROJECT":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
const pushListLayerToProjectList = (state, payload) => {
  const new_geo_project_list = state.slice();
  const { geo_project, geo_layer_list } = payload;
  const geo_project_id = geo_project._id;
  //Geo project
  let new_geo_project = new_geo_project_list.filter(
    (geo_project) => geo_project._id === geo_project_id
  )[0];
  new_geo_project.geo_layer_list = geo_layer_list;
  //Get index
  const index = new_geo_project_list.findIndex(
    (geo_project_element) => geo_project_element._id === geo_project_id
  );
  //Replace geo_project inside geo_project_list
  new_geo_project_list.splice(index, 1, new_geo_project);
  return new_geo_project_list;
};
const pushListLayerToProject = (state, payload) => {
  const new_geo_project_list = state.slice();
  const { geo_project, geo_layer_list } = payload;
  const geo_project_id = geo_project._id;
  //Geo project
  let new_geo_project = new_geo_project_list.filter(
    (geo_project) => geo_project._id === geo_project_id
  )[0];
  new_geo_project.geo_layer_list = geo_layer_list;
  return new_geo_project;
};
