//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function

class NotePembanding extends Component {
  state = {};

  render() {
    //local storage
    const search = window?.location?.search?.substring(1);
    let object_param = {};
    let mode_pembanding;
    if (search) {
      object_param = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      mode_pembanding = object_param?.mode_pembanding;
      // feature_key_appraisal = object_param?.feature_key_appraisal;
      // apraisal_id = object_param?.apraisal_id;
    }

    //local state

    //global props

    //content
    let content;
    if (mode_pembanding === "pembanding") {
      content = (
        <section className="container_light">
          Setelah submit, data akan masuk sebagai data pembanding pada appraisal
          yang menjadi tugas Anda.
        </section>
      );
    }
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(NotePembanding);
