/*eslint-disable*/

export function calculate_formula(formula, properties) {
  try {
    const calculateable_formula = formula
      .map((f) => {
        if (f.type === "special_operator") {
          return `Math.${f.content}`;
        } else if (f.type === "variable") {
          return properties[f.ref_key];
        } else if (f.content === "^") {
          return "**";
        } else if (f.content === "%") {
          return "/100";
        }
        return f.content;
      })
      .join(" ");

    let result = "";
    if (
      !isNaN(calculateable_formula) ||
      calculateable_formula !== undefined ||
      calculateable_formula !== null ||
      calculateable_formula !== "" ||
      calculateable_formula !== NaN ||
      calculateable_formula !== false
    ) {
      result = eval(calculateable_formula);
    }
    return result;
  } catch (e) {
    return "";
  }
}

export function check_formula_validity(formula) {
  try {
    const string_formula = formula.map((f) => f.content).join("");
    const is_bracket_valid =
      check_brackets(string_formula) === 0 ? true : false;
    if (!is_bracket_valid) {
      throw "invalid";
    }

    let variabel_gantet = 0;

    const calculateable_formula = formula
      .map((f) => {
        if (f.type === "special_operator") {
          variabel_gantet = 0;
          return `Math.${f.content}`;
        } else if (f.type === "variable") {
          variabel_gantet += 1;
          if (variabel_gantet > 1) {
            throw 0;
          }
          return 1;
        } else if (f.content === "^" && f.type === "operator") {
          variabel_gantet = 0;
          return "**";
        } else if (f.content === "%" && f.type === "operator") {
          variabel_gantet = 0;
          return "/100";
        } else if (f.type === "constant") {
          variabel_gantet += 1;
          if (variabel_gantet > 1) {
            throw 0;
          }
        } else if (f.type === "operator") {
          variabel_gantet = 0;
        }
        return f.content;
      })
      .join("");
    const result = eval(calculateable_formula);
    if (
      isNaN(result) ||
      result === undefined ||
      result === null ||
      result === "" ||
      result === NaN ||
      result === false
    )
      throw 0;
    return "valid";
  } catch (error) {
    return "invalid";
  }
}

export function check_brackets(string) {
  var value = 0;
  for (var i = 0; i < string.length; i++) {
    if (string[i] === "(") value++;
    else if (string[i] === ")") value--;
  }
  return value;
}

export function convert_conditional_statement_operator_to_readable(
  content,
  language,
  dict
) {
  if (content === "===") {
    return "=";
  } else if (content === ">=") {
    return "≥";
  } else if (content === "<=") {
    return "≤";
  } else if (content === "&&") {
    return " AND ";
  } else if (content === "||") {
    return " OR ";
  } else if (content === "!=") {
    return "≠";
  } else if (content === "true") {
    return `${dict["True"][language]}`;
  } else if (content === "false") {
    return `${dict["False"][language]}`;
  } else {
    return content;
  }
}
