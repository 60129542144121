//Library modul
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Personal Component
import LayerList from "./LayerList";
import SpinnerLogo from "../common/SpinnerLogo";
import Modal from "../common/Modal";

//Redux function
import { getProjectList, setProject } from "../../actions/projectActions";
//Picture Asset

//General Function

class ProjectList extends Component {
  state = {
    modalListLayer: false,
  };
  componentDidMount() {
    this.props.getProjectList();
  }
  toggleOpenListLayer = (geo_project) => {
    this.setState({ modalListLayer: !this.state.modalListLayer });
    if (geo_project?._id) {
      this.props.setProject(geo_project);
    }
  };
  render() {
    const { geo_project_list, loading, itemLoading } = this.props.project;
    const { modalListLayer } = this.state;
    let itemContent;
    if (
      loading &&
      itemLoading === "get_list_new" &&
      geo_project_list.length === 0
    ) {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (geo_project_list.length === 0) {
      itemContent = (
        <div className="container_light">Kamu belum memiliki project.</div>
      );
    } else {
      itemContent = (
        <div>
          {geo_project_list.map((geo_project, idx) => {
            const { _id, name } = geo_project;
            return (
              <div
                className="generic_container"
                key={_id}
                onClick={this.toggleOpenListLayer.bind(this, geo_project)}
                style={{ cursor: "pointer" }}
              >
                {name}
              </div>
            );
          })}
        </div>
      );
    }
    const modalListLayerContent = modalListLayer && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalListLayer}
        onClose={this.toggleOpenListLayer}
      >
        <div className="box-body">
          <LayerList />
        </div>
      </Modal>
    );
    return (
      <div className="main_container">
        <div className="text_bold">Proyek Form Personal</div>
        <div className="text_medium">
          Klik nama proyek untuk melihat list form yang ada di dalamnya
        </div>
        {itemContent}
        {modalListLayerContent}
      </div>
    );
  }
}
ProjectList.propTypes = {
  project: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getProjectList: PropTypes.func.isRequired,
  setProject: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getProjectList,
  setProject,
})(ProjectList);
