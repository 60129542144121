//Library
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { push_file } from "../../actions/formActions";

//Picture Asset
import icon_plus from "../../img/icon_plus.svg";

//General Function
import uuid from "../../validation/uuid";
import { convert_file_to_base_64 } from "../../validation/base_64";

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileInput = React.createRef();
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const file = this.fileInput.current.files[0];
    const { on_change_file } = this.props;
    if (this.props.connection_mode === "offline") {
      const base_64 = await convert_file_to_base_64(file);
      const _uuid = this.props.field?.uuid;
      const filename = `doc_%${file?.name?.split(".")[0]}_%${uuid()}`;
      const type = file?.type;
      const extension = file?.name?.split(".").at(-1);
      if (on_change_file) {
        on_change_file(_uuid, { filename, base_64, type, extension });
      }
    } else {
      const file_name = `doc_%${file?.name?.split(".")[0]}_%${uuid()}`;
      const src_name = file.name;
      const key = "file_form_mapid/" + file_name;
      const objParams = {
        Bucket: "mapidstorage",
        Key: key,
        Body: file,
        ContentType: file.type,
      };
      let field = this.props.field;
      field.src_name = src_name;

      const parent_uuid = field?.uuid;
      const child_uuid = this?.props?.child_uuid;

      let body;
      body = {
        objParams,
        field,
        parent_uuid,
        child_uuid,
        file_name,
      };
      this.props.push_file(body);
    }
  };

  render() {
    return (
      <label
        className="fileContainer upload_container vertical_center"
        style={{
          backgroundImage: `url(${icon_plus})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="vertical_horizontal_center_child"
          style={{ cursor: "pointer", fontSize: "20px", width: "100%" }}
        >
          <input
            style={{ cursor: "pointer" }}
            type="file"
            ref={this.fileInput}
            onChange={this.handleSubmit}
          />
        </div>
      </label>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {
  push_file,
})(UploadFile);
