//Library Modul
import React, { Component } from "react";
import { connect } from "react-redux";
// import { bbox } from "@turf/turf";

//Personal Component
import { CircleColor, Display, FillColor, LineColor } from "./StyleGenerator";

//Redux function

//Picture Asset

//General Function
import generate_color_map from "../../validation/generate_color_map";
import generate_color_step from "../../validation/generate_color_step";

class LayerBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: null,
      popupInfo: false,
      layerOnClick: this.togglePopup.bind(this),
      dataPopup: [],
      view_list_state: [],
    };
  }

  // ngatur popUP
  togglePopup() {
    this.setState({
      popupInfo: true,
    });
  }

  render() {
    //local storage

    //local state

    //global props
    const { overlay_visibility_status } = this.props.properties;
    const layer_attach = this?.props?.layer_attach;

    //content

    const geojson = layer_attach?.geojson;

    let type_new = "circle";

    //convert tipe geojson (point, line, poligon) ke tipe visual peta (circle, line, fill, symbol)
    type_new =
      layer_attach?.type_2 === "pch"
        ? "symbol"
        : layer_attach?.type === "Point" || layer_attach?.type === "MultiPoint"
        ? "circle"
        : layer_attach?.type === "LineString" ||
          layer_attach?.type === "MultiLineString"
        ? "line"
        : layer_attach?.type === "Polygon" ||
          layer_attach?.type === "MultiPolygon"
        ? "fill"
        : "circle";

    //atur warna default untuk masing-masing tipe layer (poin, line, poligon)
    let paint = {};
    // paint
    // selected_column mengambil nilai dari warna yang mau difilter
    let selected_column = layer_attach?.valueStyleProps?.selected_column;
    // classify_mode jenis text yang dipakai buat warna
    let classify_mode = layer_attach?.valueStyleProps?.classify_mode;
    // mode_color menandakan warna solid atau bukan
    let mode_color = layer_attach?.globalStyleMode;
    // global_style buat menandakan layer costume
    let global_style = layer_attach?.isGlobalStyle;
    // color mengabil warna yang akan ditampilkan
    let color = layer_attach?.valueStyleProps?.color
      ? layer_attach?.valueStyleProps?.color
      : [];
    let range = layer_attach?.valueStyleProps?.range
      ? layer_attach?.valueStyleProps?.range
      : [];
    let rangeFilter = range?.map((d) => d?.max); // kalo ga diginiin ada 2 array {min, max}

    let valueColor = "#1b659d";

    // Normal
    //styling dengan nilai bawaan dari geojson
    if (layer_attach?.type === "Point" || layer_attach?.type === "MultiPoint") {
      paint = CircleColor(["get", "circle_color"]);
    } else if (
      layer_attach?.type === "LineString" ||
      layer_attach?.type === "MultiLineString"
    ) {
      paint = LineColor(["get", "color"]);
    } else if (
      layer_attach?.type === "Polygon" ||
      layer_attach?.type === "MultiPolygon"
    ) {
      paint = FillColor(["get", "color"], ["get", "color"]);
    }

    //styling dengan konfigurasi yang disimpan di database
    if (global_style === false) {
      if (
        layer_attach?.type === "Point" ||
        layer_attach?.type === "MultiPoint"
      ) {
        paint = CircleColor(["get", "circle_color"]);
      } else if (
        layer_attach?.type === "LineString" ||
        layer_attach?.type === "MultiLineString"
      ) {
        paint = LineColor(["get", "color"]);
      } else if (
        layer_attach?.type === "Polygon" ||
        layer_attach?.type === "MultiPolygon"
      ) {
        paint = FillColor(["get", "color"], ["get", "color"]);
      }
    } else if (global_style === true) {
      if (mode_color === "solid") {
        if (
          layer_attach?.type === "Point" ||
          layer_attach?.type === "MultiPoint"
        ) {
          paint = CircleColor(layer_attach?.properties[2]?.defaultValue);
        } else if (
          layer_attach?.type === "LineString" ||
          layer_attach?.type === "MultiLineString"
        ) {
          paint = LineColor(layer_attach?.properties[0]?.defaultValue);
        } else if (
          layer_attach?.type === "Polygon" ||
          layer_attach?.type === "MultiPolygon"
        ) {
          paint = FillColor(layer_attach?.properties[0]?.defaultValue);
        }
      } else if (mode_color === "by_value") {
        if (classify_mode === "text") {
          // mode classify mode match by text
          valueColor = generate_color_map(range, color, selected_column);
          if (
            layer_attach?.type === "Point" ||
            layer_attach?.type === "MultiPoint"
          ) {
            paint = CircleColor(valueColor);
          } else if (
            layer_attach?.type === "LineString" ||
            layer_attach?.type === "MultiLineString"
          ) {
            paint = LineColor(valueColor);
          } else if (
            layer_attach?.type === "Polygon" ||
            layer_attach?.type === "MultiPolygon"
          ) {
            paint = FillColor(valueColor);
          }
        } else if (classify_mode === "number") {
          // mode classify mode step by number
          valueColor = generate_color_step(rangeFilter, color, selected_column);
          if (
            layer_attach?.type === "Point" ||
            layer_attach?.type === "MultiPoint"
          ) {
            paint = CircleColor(valueColor);
          } else if (
            layer_attach?.type === "LineString" ||
            layer_attach?.type === "MultiLineString"
          ) {
            paint = LineColor(valueColor);
          } else if (
            layer_attach?.type === "Polygon" ||
            layer_attach?.type === "MultiPolygon"
          ) {
            paint = FillColor(valueColor);
          }
        }
      }
    }

    // Khusus
    //styling khusus untuk pos duga air
    if (layer_attach.type_2 === "pda") {
      paint = CircleColor("#000000", "pda");
    }

    //styling khusus untuk pos curah hujan
    if (layer_attach.type_2 === "pch") {
      paint = CircleColor("#000000", "pch");
    }

    return (
      <Display
        title="layer_child"
        viewStatus={overlay_visibility_status}
        type={type_new}
        data={geojson}
        paint={paint}
        // layerOnClick={this.props.layerOnClick.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LayerBasic);
