//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Login from "../auth/Login";
import Register from "../auth/Register";
import Modal from "../common/Modal";

//Redux function
import { setLanguage } from "../../actions/authActions";
import { pushFormOffline } from "../../actions/formActions";

//Picture Asset

//General Function
import dict from "../../validation/dict.json";
import uuid from "../../validation/uuid";

class Title extends Component {
  state = {
    modal_login: false,
    mode_input: "login", //login || register
  };

  set_mode_input = (mode_input) => {
    this.setState({ mode_input });
  };

  toggle_login = () => {
    this.setState({ modal_login: !this.state.modal_login });
  };

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  onClickOffline = (is_saved_offline) => {
    if (!is_saved_offline) {
      let geo_layer_wo_geojson = this.props.form.geo_layer_wo_geojson;
      geo_layer_wo_geojson.id_offline = uuid();
      this.props.pushFormOffline(geo_layer_wo_geojson);
    }
  };

  get_color_style = (current_step, number_step) => {
    if (current_step === number_step) {
      return {
        backgroundColor: "#7cc735ff",
        color: "#ffffff",
      };
    } else {
      return {
        backgroundColor: "#ffffff",
        color: "#909598",
      };
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { modal_login, mode_input } = this.state;

    //global props
    const { step } = this.props;
    const { geo_layer_wo_geojson, loading, itemLoading } = this.props.form;
    const { isAuthenticated } = this.props.auth;
    const form_list_offline = localStorage.form_list_offline
      ? JSON.parse(localStorage.form_list_offline)
      : [];
    const is_saved_offline = form_list_offline?.some(
      (item) => item?.geo_layer_wo_geojson?._id === geo_layer_wo_geojson?._id
    );

    //content

    let text;
    let login_button;

    if (
      loading &&
      (itemLoading === "getFieldsPrivate" || itemLoading === "get_feature")
    ) {
      text = "Loading...";
    } else if (geo_layer_wo_geojson?._id) {
      text = geo_layer_wo_geojson?.name;
    } else {
      if (isAuthenticated) {
        text =
          "Form tidak bisa dimuat, ada kemungkinan form tidak publik atau tautan form yang Anda masukkan salah.";
      } else {
        text =
          "Form tidak bisa dimuat, ada kemungkinan form tidak publik atau tautan form yang Anda masukkan salah. Silakan login terlebih dahulu.";
        login_button = (
          <button className="button" id="blue" onClick={this.toggle_login}>
            Login
          </button>
        );
      }
    }

    const modal_login_content = modal_login && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_login}
        onClose={this.toggle_login}
      >
        <div className="box-body">
          <main className="max_modal">
            <section>
              <button
                className="button margin"
                id={mode_input === "login" ? "black_absolute" : "outline_black"}
                onClick={this.set_mode_input.bind(this, "login")}
              >
                Login
              </button>
              <button
                className="button"
                id={
                  mode_input === "register" ? "black_absolute" : "outline_black"
                }
                onClick={this.set_mode_input.bind(this, "register")}
              >
                Register
              </button>
            </section>
            {mode_input === "login" ? (
              <Login isNav={true} toggle_login={this.toggle_login} />
            ) : (
              <Register isNav={true} toggle_login={this.toggle_login} />
            )}
          </main>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_login_content}
        <section className="status_box">
          <section className="parent_center" style={{ width: "100%" }}>
            <button
              className="button_lingkaran margin"
              style={this.get_color_style(step, 1)}
            >
              1
            </button>
            <button
              className="button_lingkaran margin"
              style={this.get_color_style(step, 2)}
            >
              2
            </button>
            <button
              className="button_lingkaran margin"
              style={this.get_color_style(step, 3)}
            >
              3
            </button>
          </section>

          <section>
            <div className="text_inferior" id="white_text">
              {dict?.["Pilih bahasa"]?.[language]}{" "}
            </div>
            <button
              className="button_small"
              id={language === "ina" ? "green" : "grey"}
              onClick={this.onSetLanguage.bind(this, "ina")}
            >
              INA
            </button>{" "}
            <button
              className="button_small"
              id={language === "eng" ? "green" : "grey"}
              onClick={this.onSetLanguage.bind(this, "eng")}
            >
              ENG
            </button>
            <br />
            <div className="button_small margin_top" id="white">
              {dict["Version"][language]} {this.props.auth.version}
            </div>
            <Link
              to="/menu/dashboard"
              className="button_small margin_top"
              id="white"
            >
              Home
            </Link>
          </section>
        </section>

        <section
          className="container_light margin_bottom"
          id="white_background"
        >
          <div>
            <h1 className="text_big text_left">{text}</h1>
            <h1
              className="text_inferior text_left"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {geo_layer_wo_geojson?.description}
            </h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <button
              onClick={() => this.onClickOffline(is_saved_offline)}
              className="button_save_offline margin_top"
              id={is_saved_offline ? "button_save_offline_active" : ""}
            >
              {!is_saved_offline
                ? dict["Simpan offline"][language]
                : dict["This form is available offline"][language]}
            </button>
          </div>
        </section>

        {geo_layer_wo_geojson?.picture_url && (
          <div
            className="margin_bottom"
            style={{
              backgroundImage: `url(${geo_layer_wo_geojson?.picture_url})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#dedede",
              width: "100%",
              paddingBottom: "25%",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          />
        )}
        {geo_layer_wo_geojson?.picture_url_square?.[0] && (
          <div
            className="margin_bottom"
            style={{
              backgroundImage: `url(${
                geo_layer_wo_geojson?.picture_url_square
                  ? geo_layer_wo_geojson?.picture_url_square[0]?.url
                  : ""
              })`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#dedede",
              width: "100%",
              paddingBottom: "100%",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          />
        )}
        {login_button}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});

export default connect(mapStateToProps, {
  setLanguage,
  pushFormOffline,
})(Title);
