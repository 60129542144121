//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function
import { set_value, get_data_location } from "../../actions/formActions";

//General Function

class SearchLocation extends Component {
  geocoderContainerRef = React.createRef();
  _map = React.createRef();
  state = { search_params: "" };

  handleChange = (e) => {
    const { search_params } = this.state;

    e.preventDefault();
    clearTimeout(this.timer);
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (search_params === "" || search_params === " ") {
      this.props.get_data_location(" ");
    } else {
      this.props.get_data_location(search_params);
    }
  };

  handleClick = (data) => {
    this.props.get_data_location(" ");
    this.props.set_value("longitude", data.center[0]);
    this.props.set_value("latitude", data.center[1]);
    this.props.set_value("is_location_exist", true);
    this.props.set_value("zoom", 10);
  };

  render() {
    const { search_params } = this.state;
    const { data_location } = this.props.form;

    return (
      <section className="search_location">
        <input
          type="text"
          placeholder="Cari lokasi..."
          name="search_params"
          value={search_params}
          onChange={(e) => this.handleChange(e)}
        />
        {data_location.length !== 0 && (
          <section className="list_location">
            <h1>Hasil : {data_location.length}</h1>
            {data_location.map((data, index) => (
              <p key={index} onClick={() => this.handleClick(data)}>
                {data?.place_name}
              </p>
            ))}
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, { get_data_location, set_value })(
  SearchLocation
);
