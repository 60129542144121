import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconNav from "../common/IconNav";
class NotificationButton extends Component {
  render() {
    const { pathname, version } = this.props.auth;
    const array_menu = [
      {
        title: "Home",
        icon: "home",
        link: "/menu/dashboard",
        link_array: [
          "/menu/dashboard",
          "/menu/available_job_list",
          "/menu/public_form",
          "/menu/project_list",
          "/menu/offline",
          "/menu/paid_form",
        ],
      },
      {
        title: "Notif",
        icon: "notif",
        link: "/menu/notifications",
        link_array: ["/menu/notifications"],
      },
      {
        title: "Chat",
        icon: "chat",
        link: "/menu/chat",
        link_array: ["/menu/chat"],
      },
      {
        title: "Profile",
        icon: "profile",
        link: "/menu/setting",
        link_array: ["/menu/setting", "/menu/withdraw_list"],
      },
    ];
    return (
      <div className="nav_bottom">
        {array_menu.map(({ title, icon, link, link_array }) => {
          const id_content = link_array.includes(pathname)
            ? "active"
            : "not_active";
          const color_icon = link_array.includes(pathname)
            ? "#000000"
            : "#787878";
          return (
            <Link to={link} id={id_content} key={title} className="nav_item">
              <IconNav icon={icon} color={color_icon} />
              {title}
            </Link>
          );
        })}
        <div
          className="button_small"
          style={{ position: "fixed", bottom: "0px", left: "10px" }}
        >
          Version {version}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {})(NotificationButton);
