import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class LayerDetail extends Component {
  state = {};

  render() {
    const { layer_detail } = this.props;
    const { name, link, type } = layer_detail;
    let form_button_content = null;
    if (type === "Point") {
      form_button_content = (
        <Link to={`/menu/chat_layer/${link}`} className="button_pill">
          Buka obrolan
        </Link>
      );
    }
    const itemContent = (
      <div style={{ textAlign: "center" }}>
        <div className="title_big">{name}</div>
        <Link to={`/${link}`} className="button_pill">
          Isi form
        </Link>
        {form_button_content}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
LayerDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});
export default connect(mapStateToProps, {})(LayerDetail);
