import React, { Component } from "react";
class EditIcon extends Component {
  render() {
    return (
      <svg
        viewBox="0 0 7.4730038 6.325403"
        height="6.3254032mm"
        width="7.4730034mm"
        id="back_icon"
      >
        <defs id="defs4681" />
        <g transform="translate(-360.09216,-201.40217)" id="layer1">
          <g
            transform="matrix(0.39735388,0,0,0.39735388,178.62674,105.23104)"
            style={{ opacity: 1, strokeWidth: "2.51664" }}
            id="g869"
          />
          <g
            style={{
              opacity: 1,
              fill: "#ff2a2a",
            }}
            transform="matrix(0.26458333,0,0,0.26458333,299.63119,174.13775)"
            id="g941"
          />
          <g transform="translate(-0.01211312,-0.00814589)" id="g956" />
          <path
            id="path948"
            d="m 363.25486,207.43653 -2.87166,-2.87166 2.87166,-2.87166"
            style={{
              fill: "none",
              stroke: this.props.color,
              strokeWidth: "0.582083",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
            }}
          />
          <path
            id="path950"
            d="m 360.3832,204.56487 h 6.89092"
            style={{
              fill: "none",
              stroke: this.props.color,
              strokeWidth: 0.582083,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
          />
        </g>
      </svg>
    );
  }
}

export default EditIcon;
