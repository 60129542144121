/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import UploadKTP from "../form/UploadKTP";
import Modal from "../common/Modal";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Title from "../form/Title";
import BlogRead from "../form/BlogRead";

/*REDUX FUNCTION*/
import {
  get_feature,
  getFieldsPrivate,
  getDocForm,
  applyForm,
  pushFormOffline,
  getCitarumPos,
  getCitarumRiver,
  getAdministrative,
  set_value,
} from "../../actions/formActions";
import {
  updateFullname,
  updateCity,
  updatePhone,
  setLanguage,
} from "../../actions/authActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/
import icon_check from "../../img/icon_check.svg";
import icon_uncheck from "../../img/icon_uncheck.svg";

/*GENERAL*/
import dict from "../../validation/dict.json";
import ProvinsiKabupaten from "../common/ProvinsiKabupaten.json";
import history from "../../actions/history";
import Kabupaten from "../common/Kabupaten.json";
import uuid from "../../validation/uuid";

const cities_array = Kabupaten.sort();

class Step1 extends Component {
  state = {
    cities_filtered: cities_array,
  };

  on_start = () => {
    const status = this?.props?.form?.geo_layer_wo_geojson?.formStatus?.status;
    //handle jika itu bukan form paid, maka langsung redirect ke step 2 (peta)
    if (status === "not_propose") {
      this.redirect_step_2();
    }
  };

  componentDidMount() {
    this.setState({
      full_name: this?.props?.auth?.user?.full_name || "",
      city: this?.props?.auth?.user?.city || "",
      phone_number_state: this?.props?.auth?.user?.phone_number || "62",
    });

    const link = this?.props?.match?.params?.link;
    const feature_key = this?.props?.match?.params?.feature_key || "";

    //keperluan data pembanding
    const search = window?.location?.search?.substring(1);
    let object_param = {};
    let apraisal_id;
    if (search) {
      object_param = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      apraisal_id = object_param?.apraisal_id;
    }

    if (feature_key) {
      const params = {
        geo_layer_link: link,
        feature_key,
        apraisal_id,
        step: "step_1",
      };
      this.props.get_feature(params);
      this.props.set_value_properties({
        key: "feature_key",
        value: feature_key,
      });
    } else {
      const params = {
        geo_layer_link: link,
        apraisal_id,
      };
      this.props.getFieldsPrivate(params);
    }
    this.on_start();
  }

  componentDidUpdate(prevProps) {
    const id_before = prevProps?.form?.geo_layer_wo_geojson?._id;
    const id_after = this.props?.form?.geo_layer_wo_geojson?._id;
    if (id_before !== id_after) {
      this.on_start();
    }

    if (
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated &&
      this.props.auth.isAuthenticated
    ) {
      this.setState({
        full_name: this?.props?.auth?.user?.full_name || "",
        city: this?.props?.auth?.user?.city || "",
        phone_number_state: this?.props?.auth?.user?.phone_number || "62",
      });

      const link = this?.props?.match?.params?.link;
      const feature_key = this?.props?.match?.params?.feature_key || "";

      if (feature_key) {
        const params = {
          geo_layer_link: link,
          feature_key,
          step: "step_1",
        };
        this.props.get_feature(params);
      } else {
        this.props.getFieldsPrivate(link);
      }
      this.on_start();
    }
  }

  redirect_step_2 = () => {
    const link = this?.props?.match?.params?.link || "";
    const feature_key = this?.props?.match?.params?.feature_key || "";
    history.push(`/${link}/step_2/${feature_key}`);
  };

  handleChange = (e) => {
    e.preventDefault();

    const value = e.target.value;
    const name = e.target.name;

    if (["city"].includes(name)) {
      if (!value) {
        this.setState({
          [name]: value,
          cities_filtered: cities_array,
        });
      } else {
        const cities_filtered = cities_array.filter((city) =>
          city.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
          [name]: value,
          cities_filtered,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };

  toggleIncomplete = (fields) => {
    this.setState({ modal_incomplete: !this.state.modal_incomplete });
    if (fields) {
      this.setState({ fields_incomplete_state: fields });
    }
  };

  toggleOffline = () => {
    this.setState({ modalOffline: !this.state.modalOffline });
  };

  goToMap = () => {
    this.setState({ screen_status: 1 });
  };

  goBack = () => {
    window.history.back();
  };

  onClickOffline = () => {
    let { geo_layer_wo_geojson, longitude, latitude } = this.state;
    geo_layer_wo_geojson.id_offline = uuid();
    geo_layer_wo_geojson.longitude = longitude;
    geo_layer_wo_geojson.latitude = latitude;
    this.props.pushFormOffline(geo_layer_wo_geojson);
    this.setState({ modalOffline: true });
  };

  toggleUserData = () => {
    this.setState({ modalUserData: !this.state.modalUserData });
  };

  toggleApplyPaidSurvey = () => {
    this.setState({ modalApplyPaidSurvey: !this.state.modalApplyPaidSurvey });
  };

  handleApplyPaidSurvey = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const { geo_layer_wo_geojson } = this.props.form;
    const { amount } = this.state;
    const body = {
      user: user._id,
      geo_layer_id: geo_layer_wo_geojson._id,
      amount: parseInt(amount),
    };
    this.props.applyForm(body);
    this.setState({ modalApplyPaidSurvey: false });
  };

  toggleEditName = () => {
    this.setState({ modalEditName: !this.state.modalEditName });
  };

  toggleEditCity = () => {
    this.setState({ modalEditCity: !this.state.modalEditCity });
  };

  toggleEditPhone = () => {
    this.setState({ modalEditPhone: !this.state.modalEditPhone });
  };

  onClickCity = (city) => {
    this.setState({ city });
  };

  handleFixCity = () => {
    const { cities_filtered, city } = this.state;
    if (cities_filtered.length === 0) {
      this.setState({ cities_filtered: cities_array });
    } else if (!cities_filtered.includes(city)) {
      this.setState({ city: cities_filtered[0] ? cities_filtered[0] : "" });
    }
  };

  _onMapLoaded() {
    this.setState({ mapIsLoaded: true });
  }

  handleTrueModalRead = () => {
    this.setState({ modalRead: true }, () => {
      document.getElementById("read_content").style.bottom = "0px";
      const { geo_layer_wo_geojson } = this.props.form;
      this.props.getDocForm(geo_layer_wo_geojson._id);
    });
  };

  handleFalseModalRead = () => {
    this.setState({ modalRead: false, readStatus: true }, () => {
      document.getElementById("read_content").style.bottom = "-100vh";
    });
  };

  handleUpdateFullname = (e) => {
    e.preventDefault();
    const { full_name } = this.state;
    const body = {
      full_name,
    };
    this.props.updateFullname(body);
  };

  handleUpdatePhone = (e) => {
    e.preventDefault();
    const { phone_number_state } = this.state;
    const body = {
      phone_number: phone_number_state,
    };
    this.props.updatePhone(body);
  };

  handleUpdateCity = (e) => {
    e.preventDefault();
    const { cities_filtered, city } = this.state;
    if (!cities_filtered.includes(city)) {
      const body = {
        city: cities_filtered[0] ? cities_filtered[0] : "",
      };
      this.props.updateCity(body);
    } else {
      const body = {
        city,
      };
      this.props.updateCity(body);
    }
  };

  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };

  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  toggleLocationAsk = () => {
    this.setState({ modalLocation: !this.state.modalLocation });
  };

  onGetLocation = () => {
    this.setState({ locationLoading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      return null;
    }
  };

  showPosition = (position) => {
    const latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    const longitude = Number(parseFloat(position.coords.longitude).toFixed(8));

    this.props.set_value("latitude", latitude);
    this.props.set_value("longitude", longitude);

    const accuracy = parseInt(position.coords.accuracy);
    this.setState(
      {
        latitude,
        longitude,
        accuracy,
        locationStatus: true,
        locationLoading: false,
      },
      () => {
        this.toggleLocationAsk();
        this.setState({
          viewport: {
            latitude: latitude,
            longitude: longitude,
            zoom: 15,
          },
        });
      }
    );
  };

  handleToggle = (e) => {
    const target = e.target;
    const name = target.name;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    geo_layer_wo_geojson[name] = !geo_layer_wo_geojson[name];
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      // locationStatus,
      // accuracy,
      // locationLoading,
      // screen_status,
      // mapIsLoaded,
      modalLogin,
      loginStatus,
      full_name,
      phone_number_state,
      readStatus,
      modalEditName,
      modalApplyPaidSurvey,
      amount,
      modalUserData,
      modalEditCity,
      modalEditPhone,
      // modalOffline,
      city,
      cities_filtered,
      // isSatellite,
      // modal_incomplete,
      // fields_incomplete_state,
    } = this.state;

    const feature_key = this?.props?.match?.params?.feature_key || "";

    const { isAuthenticated, isRegistered, user } = this.props.auth;
    const { loading, itemLoading, geo_layer_wo_geojson } = this.props.form;

    const loadingProcessUser = this.props.auth.loadingProcess;
    const itemLoadingUser = this.props.auth.itemLoading;
    const user_id = user?._id;

    const status = geo_layer_wo_geojson?.formStatus?.status;
    const is_form_login_req = geo_layer_wo_geojson?.is_form_login_req;
    const features = geo_layer_wo_geojson?.geojson?.features || [];
    const features_length = features.length;

    const surveyors = geo_layer_wo_geojson?.formPayment?.surveyors || [];
    const surveyors_id = surveyors.map((surveyor) => String(surveyor?.user));

    const full_name_prop = this?.props?.auth?.user?.full_name;
    const city_prop = this?.props?.auth?.user?.city;
    const phone_prop = this?.props?.auth?.user?.phone_number;
    const ktp = this?.props?.auth?.user?.ktp;

    const auth_point = isAuthenticated ? 1 : 0;
    const full_name_point = full_name_prop ? 1 : 0;
    const phone_number_point = phone_prop ? 1 : 0;
    const city_point = city_prop ? 1 : 0;
    const ktp_point = ktp ? 1 : 0;

    const isCity = geo_layer_wo_geojson?.formPayment?.isCity;

    const isProvince = geo_layer_wo_geojson?.formPayment?.isProvince;
    const cities = geo_layer_wo_geojson?.formPayment?.cities || [];
    const provinces = geo_layer_wo_geojson?.formPayment?.provinces || [];
    let cities_final = [];

    const cities_province = provinces
      .map((province, idx) => {
        return ProvinsiKabupaten[province];
      })
      .flat();
    if (isProvince && provinces.length > 0) {
      cities_final = [...cities, ...cities_province];
    }

    let city_match_point = 0;

    const logic_1 = !isCity && !isProvince; //Klien tidak memberikan restriksi lokasi
    const logic_2 = isCity && cities.includes(city_prop); //Klien hanya restrik lokasi kota dan kota surveyor include
    const logic_3 = isProvince && cities_province.includes(city_prop); //Restriksi provinsi dan kota surveyor include
    const logic_4 = isCity && isProvince && cities_final.includes(city_prop); //Restriksi kota & provinsi surveyor masuk
    if (logic_1 || logic_2 || logic_3 || logic_4) {
      city_match_point = 1;
    }

    // const readstatus_point = readStatus ? 1 : 0;
    const total_point =
      auth_point +
      full_name_point +
      city_point +
      ktp_point +
      phone_number_point +
      // selfiektp_point +
      city_match_point;
    // + readstatus_point;
    const percentage_point = (total_point / 6) * 100;

    const amount_props =
      geo_layer_wo_geojson?.formPayment?.surveyors?.[0]?.amount;
    const percentage = parseInt((features_length / amount_props) * 100);

    let button_paid_mode;

    if (
      [
        "propose",
        "rejected",
        "recruit",
        "close_recruit",
        "wait_purchase",
        "user_purchase",
      ].includes(status)
    ) {
      if (surveyors_id.includes(user_id)) {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div className="text_medium" style={{ margin: "auto" }}>
              <Link
                className="button_standard"
                to={`/${geo_layer_wo_geojson?.link}/step_2/${feature_key}`}
              >
                {dict?.["Mulai Survei Berbayar"]?.[language]}
              </Link>
            </div>
          </div>
        );
      } else if (percentage_point === 100) {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={this.toggleApplyPaidSurvey}
              className="button_standard"
            >
              {dict?.["Daftar Jadi Surveyor"]?.[language]}
            </div>
          </div>
        );
      } else {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div className="button_standard" id="grey">
              {dict?.["Daftar Jadi Surveyor"]?.[language]}
            </div>
            <div className="text_medium" style={{ margin: "auto" }}>
              {
                dict?.[
                  "Tombol akan aktif setelah semua pra-syarat terpenuhi"
                ]?.[language]
              }
            </div>
          </div>
        );
      }
    } else if (
      [
        //selesai paid form
        "success_payment",
        "rejected_payment",
        "validation",
        "post_payment",
        "finish",

        //lain
        // "propose",
        // "rejected",
        // "recruit",
        // "close_recruit",
        // "wait_purchase",
        // "user_purchase",
      ].includes(status) &&
      surveyors_id.includes(user_id)
    ) {
      button_paid_mode = (
        <div
          style={{ textAlign: "center" }}
          className="container_light"
          id="white_background"
        >
          <div
            style={{
              height: "10px",
              backgroundColor: "#dedede",
              borderRadius: "2rem",
              padding: "3px",
              margin: "auto",
              width: "80%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: `${percentage > 100 ? 100 : percentage}%`,
                backgroundColor: "#7cc735ff",
                borderRadius: "2rem",
              }}
            />
          </div>
          <div
            className="text_medium"
            style={{ marginTop: "5px", marginBottom: "30px" }}
          >
            {dict?.["Kamu sudah isi"]?.[language]} {features.length}{" "}
            {dict?.["dari"]?.[language]} {amount_props}
            <br />
            {
              dict?.[
                "Jika kamu mengisi lebih dari target kamu ada kemungkinan ada data yang ditolak"
              ]?.[language]
            }
          </div>
          <div onClick={this.handleAddScreenStatus} className="button_standard">
            {dict?.["Mulai Survei Berbayar"]?.[language]}
          </div>
        </div>
      );
    }

    const paid_mode = (
      <main>
        <section
          className="container_light margin_bottom"
          id="white_background"
        >
          {
            dict?.[
              "Untuk mengikuti survei berbayar ada beberapa pra-syarat untuk dipenuhi"
            ]?.[language]
          }
        </section>
        {!isAuthenticated && (
          <main className="container_light" id="white_background">
            <main className="two_container_icon_right">
              <section>
                <div className="text_bold">Log in</div>
                <div className="text_medium">
                  {
                    dict?.[
                      "Silahkan log in atau mendaftar jika belum memiliki akun"
                    ]?.[language]
                  }
                </div>
                {!isAuthenticated && (
                  <div className="button_tight" onClick={this.toggleLogin}>
                    Log in
                  </div>
                )}
              </section>
              <section style={{ textAlign: "right" }}>
                {isAuthenticated ? (
                  <img src={icon_check} width="30px" alt="check" />
                ) : (
                  <img src={icon_uncheck} width="30px" alt="check" />
                )}
              </section>
            </main>
          </main>
        )}
        {isAuthenticated && (
          <div>
            {!!(full_name_point && city_point && ktp_point) && (
              <div
                onClick={this.toggleUserData}
                className="button_standard margin_bottom"
              >
                {modalUserData
                  ? dict?.["Tutup Data Pengguna"]?.[language]
                  : dict?.["Buka Data Pengguna"]?.[language]}
              </div>
            )}
            {(modalUserData ||
              !(full_name_point && city_point && ktp_point)) && (
              <main>
                <section
                  className="container_light margin_bottom"
                  id="white_background"
                >
                  <div className="two_container_icon_right">
                    <div>
                      <p className="text_bold">
                        {dict?.["Nama lengkap"]?.[language]}
                      </p>
                      <p className="text_medium">
                        {
                          dict?.[
                            "Lengkapi akun kamu dengan nama lengkap sesuai yang tercantum di KTP"
                          ]?.[language]
                        }
                      </p>
                      <section className="text_bold">
                        {full_name_prop ? full_name_prop : "Belum diisi"}{" "}
                        <button
                          onClick={this.toggleEditName}
                          className="button_tight"
                        >
                          {dict?.["Edit nama lengkap"]?.[language]}
                        </button>
                      </section>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {full_name_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </section>

                <section
                  className="container_light margin_bottom"
                  id="white_background"
                >
                  <div className="two_container_icon_right">
                    <div>
                      <p className="text_bold">
                        {dict?.["Kota Tinggal"]?.[language]}
                      </p>
                      <p className="text_medium">
                        {
                          dict?.[
                            "Lengkapi akun kamu dengan kota tempat tinggal kamu sekarang"
                          ]?.[language]
                        }
                      </p>
                      <section className="text_bold">
                        {city_prop ? city_prop : "Belum diisi"}{" "}
                        <button
                          onClick={this.toggleEditCity}
                          className="button_tight"
                        >
                          {dict?.["Edit tempat tinggal"]?.[language]}
                        </button>
                      </section>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {city_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </section>

                <section
                  className="container_light margin_bottom"
                  id="white_background"
                >
                  <div className="two_container_icon_right">
                    <div>
                      <p className="text_bold">
                        {dict?.["Nomor Handphone"]?.[language]}
                      </p>
                      <p className="text_medium">
                        {
                          dict?.["Lengkapi akun kamu dengan nomor handphone"]?.[
                            language
                          ]
                        }
                      </p>
                      <section className="text_bold">
                        {phone_prop ? phone_prop : "Belum diisi"}{" "}
                        <button
                          onClick={this.toggleEditPhone}
                          className="button_tight"
                        >
                          {dict?.["Edit nomor handphone"]?.[language]}
                        </button>
                      </section>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {phone_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </section>

                <section
                  className="container_light margin_bottom"
                  id="white_background"
                >
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">
                        {dict?.["Upload KTP"]?.[language]}
                      </div>
                      <div className="text_medium">
                        {
                          dict?.[
                            "Upload foto KTP kamu sesuai dengan contoh di bawah"
                          ]?.[language]
                        }
                      </div>
                      <div
                        className="button_tight"
                        style={{ marginBottom: "7px" }}
                      >
                        {dict?.["Contoh"]?.[language]}
                      </div>
                      <UploadKTP />
                      {ktp && <img src={ktp} width="100px" alt="ktp" />}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {ktp ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </section>

                {/* <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Upload selfie dan KTP</div>
                      <div className="text_medium">
                        Upload foto selfie kamu dan KTP sesuai dengan contoh di
                        bawah
                      </div>
                      <div
                        className="button_tight"
                        style={{ marginBottom: "7px" }}
                      >
                        Contoh
                      </div>
                      <UploadSelfieKTP />
                      {selfiektp && (
                        <img src={selfiektp} width="100px" alt="selfiektp" />
                      )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {selfiektp ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div> */}
              </main>
            )}

            {(isCity || isProvince) && (
              <section
                className="container_light margin_bottom"
                id="white_background"
              >
                <div className="two_container_icon_right">
                  <div>
                    <div className="text_bold">
                      {dict?.["Wilayah Survei"]?.[language]}
                    </div>
                    <div>
                      {
                        dict?.[
                          "Survei ini terbatas untuk kota/provinsi berikut"
                        ]?.[language]
                      }
                    </div>
                    {isCity &&
                      cities.map((city, idx) => {
                        return (
                          <div key={idx} className="badge_normal" id="comfort">
                            {city}
                          </div>
                        );
                      })}
                    {isProvince &&
                      provinces.map((province, idx) => {
                        return (
                          <div key={idx} className="badge_normal" id="comfort">
                            {province}
                          </div>
                        );
                      })}
                    <div style={{ marginTop: "20px" }}>
                      {dict?.["Kota tempat kamu tinggal sekarang"]?.[language]}:
                    </div>
                    <div className="badge_normal" id="comfort">
                      {city_prop}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {city_match_point ? (
                      <img src={icon_check} width="30px" alt="check" />
                    ) : (
                      <img src={icon_uncheck} width="30px" alt="check" />
                    )}
                  </div>
                </div>
              </section>
            )}

            <section
              className="container_light margin_bottom"
              id="white_background"
            >
              <div className="two_container_icon_right">
                <div>
                  <div className="text_bold">
                    {dict?.["Baca Instruksi Survei"]?.[language]}
                  </div>
                  <div className="text_medium">
                    {
                      dict?.[
                        "Baca petunjuk pengisian survei ini untuk menambah potensi data survei kamu diterima"
                      ]?.[language]
                    }
                  </div>
                  <div
                    onClick={this.handleTrueModalRead}
                    className="button_tight"
                    style={{ marginBottom: "7px" }}
                  >
                    {dict?.["Buka Instruksi"]?.[language]}
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  {readStatus ? (
                    <img src={icon_check} width="30px" alt="check" />
                  ) : (
                    <img src={icon_uncheck} width="30px" alt="check" />
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
        {button_paid_mode}
      </main>
    );

    let content;

    if (loading && itemLoading === "getFieldsPrivate") {
      //sedang loading
      content = <main>Loading...</main>;
    } else if (
      [
        "propose",
        "rejected",
        "recruit",
        "close_recruit",
        "wait_purchase",
        "user_purchase",
        "success_payment",
        "rejected_payment",
        "validation",
        "post_payment",
        "finish",
      ].includes(status)
    ) {
      //tampilan awal paid form: register atau pengajuan surveyor berbayar
      content = paid_mode;
    } else if (is_form_login_req) {
      //mode harus login tapi tidak berbayar
      content = (
        <main>
          {!isAuthenticated ? (
            <main className="container_light" id="white_background">
              <main className="two_container_icon_right">
                <section>
                  <div className="text_bold">Log in</div>
                  <div className="text_medium">
                    {
                      dict?.[
                        "Survei ini mengharuskan kamu untuk masuk akun."
                      ]?.[language]
                    }
                  </div>
                  {!isAuthenticated && (
                    <div className="button_tight" onClick={this.toggleLogin}>
                      Log in
                    </div>
                  )}
                </section>
                <section style={{ textAlign: "right" }}>
                  {isAuthenticated ? (
                    <img src={icon_check} width="30px" alt="check" />
                  ) : (
                    <img src={icon_uncheck} width="30px" alt="check" />
                  )}
                </section>
              </main>
            </main>
          ) : (
            <section>
              <div className="container_light" id="white_background">
                {dict?.["Yuk mulai isi"]?.[language]}
              </div>
              <section style={{ textAlign: "center" }}>
                <button
                  onClick={this.handleAddScreenStatus}
                  className="button_standard"
                >
                  {dict?.["Mulai"]?.[language]}
                </button>
              </section>
            </section>
          )}
        </main>
      );
    }

    const modalLoginContent = modalLogin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLogin}
        onClose={this.toggleLogin}
      >
        <div className="box-body">
          {loginStatus || isRegistered ? <Login isNav={true} /> : <Register />}
          {loginStatus || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">Don't have an account?</div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict?.["Daftar di sini"]?.[language]}
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict?.["Sudah memiliki akun MAPID?"]?.[language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                Login
              </div>
            </div>
          )}
        </div>
      </Modal>
    );

    const modalEditNameContent = modalEditName && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditName}
        onClose={this.toggleEditName}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdateFullname}>
            <input
              name="full_name"
              id="full_name"
              type="text"
              value={full_name}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "full_name" ? (
                <div className="button_standard">
                  {dict?.["Menyimpan"]?.[language]}...
                </div>
              ) : (
                <button className="button_standard" type="submit">
                  {dict?.["Simpan nama lengkap"]?.[language]}
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );

    const modalEditCityContent = modalEditCity && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditCity}
        onClose={this.toggleEditCity}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdateCity}>
            <div
              style={{
                borderRadius: "10px",
                border: "1px solid #d8d8d8",
                padding: "15px",
                marginBottom: "20px",
              }}
            >
              <input
                name="city"
                id="city"
                type="text"
                value={city}
                onChange={this.handleChange}
                onBlur={this.handleFixCity}
              />
              <div
                style={{
                  height: "50vh",
                  overflowY: "scroll",
                  marginTop: "20px",
                }}
              >
                {cities_filtered.map((city_element, idx) => {
                  return (
                    <div
                      onClick={this.onClickCity.bind(this, city_element)}
                      key={idx}
                      className="text_medium"
                      style={{
                        marginBottom: "20px",
                        borderBottom: "1px solid #d8d8d8",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        paddingBottom: "10px",
                        height: "30px",
                      }}
                    >
                      {city_element}
                      {city_element === city && (
                        <img
                          src={icon_check}
                          width="25px"
                          alt="check"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                      {!cities_filtered.includes(city) && idx === 0 && (
                        <img
                          src={icon_check}
                          width="25px"
                          alt="check"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "updateCity" ? (
                <div className="button_standard">
                  {dict?.["Menyimpan"]?.[language]}.........
                </div>
              ) : cities_filtered.length === 0 ? (
                <div className="button_standard" id="grey">
                  {dict?.["Simpan tempat tinggal"]?.[language]}
                </div>
              ) : (
                <button className="button_standard" type="submit">
                  {dict?.["Simpan tempat tinggal"]?.[language]}
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );

    const modalEditPhoneContent = modalEditPhone && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditPhone}
        onClose={this.toggleEditPhone}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdatePhone}>
            <input
              name="phone_number_state"
              id="phone_number_state"
              type="text"
              value={phone_number_state}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "updatePhone" ? (
                <div className="button_standard">
                  {dict?.["Menyimpan"]?.[language]}......
                </div>
              ) : (
                <button className="button_standard" type="submit">
                  {dict?.["Simpan nomor handphone"]?.[language]}
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );

    const modalApplyPaidSurveyContent = modalApplyPaidSurvey && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalApplyPaidSurvey}
        onClose={this.toggleApplyPaidSurvey}
      >
        <main className="box-body">
          <div className="text_bold">
            {
              dict?.["Berapa target responden yang akan kamu survei?"]?.[
                language
              ]
            }{" "}
            Minimum {geo_layer_wo_geojson.formPayment.minResponden},{" "}
            {dict?.["maksimum"]?.[language]}{" "}
            {geo_layer_wo_geojson.formPayment.maxResponden}
          </div>
          <form onSubmit={this.handleApplyPaidSurvey}>
            <input
              name="amount"
              id="amount"
              value={amount}
              type="range"
              min={geo_layer_wo_geojson.formPayment.minResponden}
              max={geo_layer_wo_geojson.formPayment.maxResponden}
              onChange={this.handleChange}
            />
            <section className="three_container">
              <div style={{ textAlign: "left" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {geo_layer_wo_geojson.formPayment.minResponden}
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {amount
                    ? amount
                    : dict?.["Belum diisi, geser slider untuk mengisi"]?.[
                        language
                      ]}
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {geo_layer_wo_geojson.formPayment.maxResponden}
                </div>
              </div>
            </section>
            <div style={{ textAlign: "center" }}>
              {loading && itemLoading === "applyForm" ? (
                <div className="button_standard">
                  {dict?.["Mengirim"]?.[language]}...
                </div>
              ) : (
                <button className="button_standard" type="submit">
                  {dict?.["Daftar Jadi Surveyor"]?.[language]}
                </button>
              )}
            </div>
          </form>
        </main>
      </Modal>
    );

    const modal_read_content = (
      <main className="read_content" id="read_content">
        <section className="main_container" style={{ marginBottom: "100px" }}>
          {loading && itemLoading === "getDocForm" ? (
            <p>{dict?.["Memuat instruksi survei"]?.[language]}...</p>
          ) : !geo_layer_wo_geojson?.blog?._id ? (
            <section>
              {dict?.["Dokumen belum dibuat"]?.[language]}
              <div style={{ textAlign: "center" }}>
                <button
                  onClick={this.handleFalseModalRead}
                  className="button_standard"
                >
                  {dict?.["Tutup"]?.[language]}
                </button>
              </div>
            </section>
          ) : (
            <div>
              <BlogRead blog={geo_layer_wo_geojson.blog} />
              <div style={{ textAlign: "center" }}>
                <button
                  onClick={this.handleFalseModalRead}
                  className="button_standard"
                >
                  {dict?.["Mengerti"]?.[language]}
                </button>
              </div>
            </div>
          )}
        </section>
      </main>
    );

    return (
      <main>
        {modal_read_content}
        {modalLoginContent}
        {modalEditNameContent}
        {modalEditCityContent}
        {modalEditPhoneContent}
        {modalApplyPaidSurveyContent}

        <main className="main_container">
          <Title status={status} step={1} />
          {content}
        </main>

        <Helmet>
          <title>
            {geo_layer_wo_geojson?.name
              ? geo_layer_wo_geojson?.name
              : "MAPID FORM"}
          </title>
          <meta
            name="description"
            content={
              geo_layer_wo_geojson?.name
                ? geo_layer_wo_geojson?.name
                : "MAPID FORM"
            }
          />
        </Helmet>
        <style>{`.nav_bottom{ display:none;}`}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});

export default connect(mapStateToProps, {
  //form
  get_feature,
  getFieldsPrivate,
  getDocForm,
  applyForm,
  pushFormOffline,
  getCitarumPos,
  getCitarumRiver,
  getAdministrative,
  set_value,

  //auth
  updateFullname,
  updateCity,
  updatePhone,
  setLanguage,

  //properties
  set_value_properties,
})(Step1);
