/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import UploadKTP from "../form/UploadKTP";

/*REDUX FUNCTION*/
import {
  get_feature,
  getFieldsPrivate,
  getDocForm,
  applyForm,
  pushFormOffline,
  getCitarumPos,
  getCitarumRiver,
  getAdministrative,
  set_value,
} from "../../actions/formActions";
import {
  updateFullname,
  updateCity,
  updatePhone,
  setLanguage,
} from "../../actions/authActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/
import icon_check from "../../img/icon_check.svg";
import icon_uncheck from "../../img/icon_uncheck.svg";

/*GENERAL*/
import ProvinsiKabupaten from "../common/ProvinsiKabupaten.json";

class STEP_1_OFFLINE extends Component {
  render() {
    const { readStatus, modalUserData } = this.props;

    const { isAuthenticated, user } = this.props.auth;
    const user_id = user?._id;
    const { geo_layer_wo_geojson } = this.props.form;

    /*Properti geojson*/
    const features = geo_layer_wo_geojson?.geojson?.features
      ? geo_layer_wo_geojson.geojson.features
      : [];
    const features_length = features.length;

    /*Properti survei berbayar*/
    let isCity, isProvince, cities, provinces, cities_province, surveyors;
    let surveyors_id = [];
    let percentage = 0;
    let cities_final = [];
    const formPayment = geo_layer_wo_geojson?.formPayment;
    let status;
    if (geo_layer_wo_geojson?.formPayment?.date_in) {
      status = geo_layer_wo_geojson?.formStatus?.status;
      surveyors = formPayment.surveyors;
      surveyors_id = surveyors.map((surveyor) => String(surveyor.user));
      isCity = formPayment.isCity;
      isProvince = formPayment.isProvince;
      cities = formPayment.cities;
      provinces = formPayment.provinces;
      cities_province = provinces
        .map((province, idx) => {
          return ProvinsiKabupaten[province];
        })
        .flat();
      if (isProvince && provinces.length > 0) {
        cities_final = [...cities, ...cities_province];
      }
    }
    let amount_props;
    if (geo_layer_wo_geojson?.formPayment?.surveyors.length > 0) {
      surveyors = formPayment.surveyors;
      const surveyor = surveyors[0];
      amount_props = surveyor.amount;
      percentage = parseInt((features_length / amount_props) * 100);
    }
    /*Properti survei berbayar*/
    const full_name_prop = this.props.auth?.user?.full_name;
    const city_prop = this.props.auth?.user?.city;
    const phone_prop = this.props.auth?.user?.phone_number;
    const ktp = this.props.auth?.user?.ktp;
    // const selfiektp = this.props.auth?.user?.selfiektp;
    //Point progress
    const auth_point = isAuthenticated ? 1 : 0;
    const full_name_point = full_name_prop ? 1 : 0;
    const phone_number_point = phone_prop ? 1 : 0;
    const city_point = city_prop ? 1 : 0;
    const ktp_point = ktp ? 1 : 0;
    // const selfiektp_point = selfiektp ? 1 : 0;
    let city_match_point = 0;
    const logic_1 = !isCity && !isProvince; //Klien tidak memberikan restriksi lokasi
    const logic_2 = isCity && cities.includes(city_prop); //Klien hanya restrik lokasi kota dan kota surveyor include
    const logic_3 = isProvince && cities_province.includes(city_prop); //Restriksi provinsi dan kota surveyor include
    const logic_4 = isCity && isProvince && cities_final.includes(city_prop); //Restriksi kota & provinsi surveyor masuk
    if (logic_1 || logic_2 || logic_3 || logic_4) {
      city_match_point = 1;
    }
    const readstatus_point = readStatus ? 1 : 0;
    const total_point =
      auth_point +
      full_name_point +
      city_point +
      ktp_point +
      phone_number_point +
      // selfiektp_point +
      city_match_point +
      readstatus_point;
    const percentage_point = (total_point / 7) * 100;

    let button_paid_mode;
    if (
      [
        "propose",
        "rejected",
        "recruit",
        "close_recruit",
        "wait_purchase",
        "user_purchase",
      ].includes(status)
    ) {
      if (surveyors_id.includes(user_id)) {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div className="text_medium" style={{ margin: "auto" }}>
              Kamu sudah mendaftar, survei akan dimulai setelah pembuat survei
              melakukan pembayaran.
            </div>
          </div>
        );
      } else if (percentage_point === 100) {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div
              onClick={this.toggleApplyPaidSurvey}
              className="button_standard"
            >
              Daftar Jadi Surveyor
            </div>
          </div>
        );
      } else {
        button_paid_mode = (
          <div style={{ textAlign: "center" }}>
            <div className="button_standard" id="grey">
              Daftar Jadi Surveyor
            </div>
            <div className="text_medium" style={{ margin: "auto" }}>
              Tombol akan aktif setelah semua pra-syarat terpenuhi
            </div>
          </div>
        );
      }
    } else if (
      [
        "success_payment",
        "rejected_payment",
        "validation",
        "post_payment",
        "finish",
      ].includes(status) &&
      surveyors_id.includes(user_id)
    ) {
      button_paid_mode = (
        <div
          style={{ textAlign: "center" }}
          className="container_light"
          id="white_background"
        >
          <div
            style={{
              height: "10px",
              backgroundColor: "#dedede",
              borderRadius: "2rem",
              padding: "3px",
              margin: "auto",
              width: "80%",
            }}
          >
            <div
              style={{
                height: "100%",
                width: `${percentage > 100 ? 100 : percentage}%`,
                backgroundColor: "#7cc735ff",
                borderRadius: "2rem",
              }}
            />
          </div>
          <div
            className="text_medium"
            style={{ marginTop: "5px", marginBottom: "30px" }}
          >
            Kamu sudah isi {features.length} dari {amount_props}
            <br />
            Jika kamu mengisi lebih dari target kamu ada kemungkinan ada data
            yang ditolak
          </div>
          <div onClick={this.handleAddScreenStatus} className="button_standard">
            Mulai Survei Berbayar
          </div>
        </div>
      );
    }

    const screen_1_paid_mode = (
      <main>
        <section className="container_light" id="white_background">
          Untuk mengikuti survei berbayar ada beberapa pra-syarat untuk dipenuhi
        </section>
        {!isAuthenticated && (
          <main className="container_light" id="white_background">
            <main className="two_container_icon_right">
              <section>
                <div className="text_bold">Log in</div>
                <div className="text_medium">
                  Silahkan log in atau mendaftar jika belum memiliki akun
                </div>
                {!isAuthenticated && (
                  <div className="button_tight" onClick={this.toggleLogin}>
                    Log in
                  </div>
                )}
              </section>
              <section style={{ textAlign: "right" }}>
                {isAuthenticated ? (
                  <img src={icon_check} width="30px" alt="check" />
                ) : (
                  <img src={icon_uncheck} width="30px" alt="check" />
                )}
              </section>
            </main>
          </main>
        )}
        {isAuthenticated && (
          <div>
            {!!(full_name_point && city_point && ktp_point) && (
              <div onClick={this.toggleUserData} className="button_standard">
                {modalUserData ? "Tutup Data Pengguna" : "Buka Data Pengguna"}
              </div>
            )}
            {(modalUserData ||
              !(full_name_point && city_point && ktp_point)) && (
              <div>
                <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Nama lengkap</div>
                      <div className="text_medium">
                        Lengkapi akun kamu dengan nama lengkap sesuai yang
                        tercantum di KTP
                      </div>
                      <div className="text_bold">
                        {full_name_prop ? full_name_prop : "Belum diisi"}{" "}
                        <div
                          onClick={this.toggleEditName}
                          className="button_tight"
                        >
                          Edit nama lengkap
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {full_name_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Kota Tinggal</div>
                      <div className="text_medium">
                        Lengkapi akun kamu dengan kota tempat tinggal kamu
                        sekarang
                      </div>
                      <div className="text_bold">
                        {city_prop ? city_prop : "Belum diisi"}{" "}
                        <div
                          onClick={this.toggleEditCity}
                          className="button_tight"
                        >
                          Edit tempat tinggal
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {city_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Nomor Handphone</div>
                      <div className="text_medium">
                        Lengkapi akun kamu dengan nomor handphone
                      </div>
                      <div className="text_bold">
                        {phone_prop ? phone_prop : "Belum diisi"}{" "}
                        <div
                          onClick={this.toggleEditPhone}
                          className="button_tight"
                        >
                          Edit nomor handphone
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {phone_prop ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Upload KTP</div>
                      <div className="text_medium">
                        Upload foto KTP kamu sesuai dengan contoh di bawah
                      </div>
                      <div
                        className="button_tight"
                        style={{ marginBottom: "7px" }}
                      >
                        Contoh
                      </div>
                      <UploadKTP />
                      {ktp && <img src={ktp} width="100px" alt="ktp" />}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {ktp ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="container_light" id="white_background">
                  <div className="two_container_icon_right">
                    <div>
                      <div className="text_bold">Upload selfie dan KTP</div>
                      <div className="text_medium">
                        Upload foto selfie kamu dan KTP sesuai dengan contoh di
                        bawah
                      </div>
                      <div
                        className="button_tight"
                        style={{ marginBottom: "7px" }}
                      >
                        Contoh
                      </div>
                      <UploadSelfieKTP />
                      {selfiektp && (
                        <img src={selfiektp} width="100px" alt="selfiektp" />
                      )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {selfiektp ? (
                        <img src={icon_check} width="30px" alt="check" />
                      ) : (
                        <img src={icon_uncheck} width="30px" alt="check" />
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            )}
            {(isCity || isProvince) && (
              <div className="container_light" id="white_background">
                <div className="two_container_icon_right">
                  <div>
                    <div className="text_bold">Wilayah Survei</div>
                    <div>Survei ini terbatas untuk kota/provinsi berikut</div>
                    {isCity &&
                      cities.map((city, idx) => {
                        return (
                          <div key={idx} className="badge_normal" id="comfort">
                            {city}
                          </div>
                        );
                      })}
                    {isProvince &&
                      provinces.map((province, idx) => {
                        return (
                          <div key={idx} className="badge_normal" id="comfort">
                            {province}
                          </div>
                        );
                      })}
                    <div style={{ marginTop: "20px" }}>
                      Kota tempat kamu tinggal sekarang:
                    </div>
                    <div className="badge_normal" id="comfort">
                      {city_prop}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    {city_match_point ? (
                      <img src={icon_check} width="30px" alt="check" />
                    ) : (
                      <img src={icon_uncheck} width="30px" alt="check" />
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="container_light" id="white_background">
              <div className="two_container_icon_right">
                <div>
                  <div className="text_bold">Baca Instruksi Survei</div>
                  <div className="text_medium">
                    Baca petunjuk pengisian survei ini untuk menambah potensi
                    data survei kamu diterima
                  </div>
                  <div
                    onClick={this.handleTrueModalRead}
                    className="button_tight"
                    style={{ marginBottom: "7px" }}
                  >
                    Buka Instruksi
                  </div>
                </div>
                <div style={{ textAlign: "right" }}>
                  {readStatus ? (
                    <img src={icon_check} width="30px" alt="check" />
                  ) : (
                    <img src={icon_uncheck} width="30px" alt="check" />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {button_paid_mode}
      </main>
    );

    const screen_1 = (
      <div>
        {geo_layer_wo_geojson?.formStatus?.status === "not_propose" ? (
          <div>
            <div style={{ textAlign: "center" }}>
              <div
                onClick={this.handleAddScreenStatus}
                className="button_standard"
              >
                Mulai
              </div>
            </div>
          </div>
        ) : (
          screen_1_paid_mode
        )}
      </div>
    );

    return <>{screen_1}</>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});

export default connect(mapStateToProps, {
  //form
  get_feature,
  getFieldsPrivate,
  getDocForm,
  applyForm,
  pushFormOffline,
  getCitarumPos,
  getCitarumRiver,
  getAdministrative,
  set_value,

  //auth
  updateFullname,
  updateCity,
  updatePhone,
  setLanguage,

  //properties
  set_value_properties,
})(STEP_1_OFFLINE);
