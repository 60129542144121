import React, { Component } from "react";
class CheckBox extends Component {
  render() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  marginBottom: "10px",
                  width: "40px",
                }}
              >
                <label className="switch center_switch">
                  <input
                    type="checkbox"
                    onClick={this.props.handle}
                    name={this.props.name ? this.props.name : this.props.text}
                    value={this.props.value}
                    defaultChecked={this.props.value}
                    id={this.props.text}
                  />
                  <span className="slider_check round"></span>
                </label>
                <style>
                  {`.center_switch{
                    display: inline-block;
                    align: center;
                    vertical-align: middle;
                    }`}
                </style>
              </td>
              <td>
                <div style={{ textAlign: "left" }}>{this.props.title}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CheckBox;
