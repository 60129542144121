import isEmpty from "../validation/is-empty";

const initialState = {
  version: process.env.REACT_APP_VERSION,
  isAuthenticated: false,
  isRegistered: false,
  user: {},
  loading: false,
  itemLoading: "",
  loadingDetail: false,
  groups: [],
  group: {},
  user_list: [],
  bank_data: [],
  current_bank_data: {},
  domain: window.location.hostname,
  pathname: window.location.pathname,
  receipt_list: [],
  language: "ina",
  full_name_list: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "get_user_by_badge":
      return {
        ...state,
        full_name_list: action.payload,
      };
    case "clear_full_name_list":
      return {
        ...state,
        full_name_list: [],
      };
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload,
      };
    case "SET_PATHNAME":
      return {
        ...state,
        pathname: action.payload,
      };
    case "AUTH_LOADING":
      return {
        ...state,
        loading: true,
        itemLoading: action.payload,
      };
    case "CLEAR_AUTH_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "GET_BANK_DATA":
      return {
        ...state,
        bank_data: action.payload,
      };
    case "GET_CURRENT_BANK_DATA":
      return {
        ...state,
        current_bank_data: action.payload,
      };
    case "GET_RECEIPT":
      return {
        ...state,
        receipt_list: action.payload,
      };
    case "ADD_BANK_DATA":
      return {
        ...state,
        bank_data: [action.payload, ...state.bank_data],
        current_bank_data: action.payload,
      };
    case "SET_CURRENT_BANK_DATA":
      return {
        ...state,
        current_bank_data: action.payload,
      };
    case "SET_REGISTER_SUCCESS":
      return {
        ...state,
        isRegistered: true,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user: action.payload,
      };
    case "UPDATE_FULLNAME":
      return {
        ...state,
        user: editFullname(state.user, action.payload),
      };
    case "UPDATE_CITY":
      return {
        ...state,
        user: updateCity(state.user, action.payload),
      };
    case "UPDATE_PHONE":
      return {
        ...state,
        user: updatePhone(state.user, action.payload),
      };
    case "UPDATE_PP":
      return {
        ...state,
        user: editPP(state.user, action.payload),
      };
    case "UPDATE_KTP":
      return {
        ...state,
        user: editKTP(state.user, action.payload),
      };
    case "UPDATE_SELFIE_KTP":
      return {
        ...state,
        user: editSelfieKTP(state.user, action.payload),
      };
    case "SEARCH_USERS":
      return {
        ...state,
        user_list: action.payload,
      };
    case "ADD_GROUP":
      return {
        ...state,
        groups: [action.payload, ...state.groups],
      };
    case "GET_GROUPS":
      return {
        ...state,
        groups: action.payload,
      };
    case "GET_GROUP":
      return {
        ...state,
        group: action.payload,
      };
    case "DELETE_GROUP":
      return {
        ...state,
        groups: state.groups.filter((group) => group._id !== action.payload),
      };
    case "EDIT_GROUP":
      return {
        ...state,
        groups: [
          action.payload,
          ...state.groups.filter((group) => group._id !== action.payload._id),
        ],
      };
    default:
      return state;
  }
}
const editFullname = (state, body) => {
  const newState = { ...state };
  const full_name = body.full_name;
  newState.full_name = full_name;
  return newState;
};
const updateCity = (state, body) => {
  const newState = { ...state };
  const city = body.city;
  newState.city = city;
  return newState;
};
const updatePhone = (state, body) => {
  const newState = { ...state };
  const phone_number = body.phone_number;
  newState.phone_number = phone_number;
  return newState;
};
const editPP = (state, body) => {
  const newState = { ...state };
  const profile_picture = body.profile_picture;
  newState.profile_picture = profile_picture;
  return newState;
};
const editKTP = (state, body) => {
  const newState = { ...state };
  const ktp = body.ktp;
  newState.ktp = ktp;
  return newState;
};
const editSelfieKTP = (state, body) => {
  const newState = { ...state };
  const selfiektp = body.selfiektp;
  newState.selfiektp = selfiektp;
  return newState;
};
