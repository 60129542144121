//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function

import domain_list from "../../validation/domain_list";

class SpinnerSimpleLogo extends Component {
  render() {
    //local storage

    //local state

    //global props
    const { domain } = this.props.auth;

    //content
    const logo = domain_list?.[domain]?.logo;

    return (
      <div id="startingLoaderApp">
        <div id="startingLoader_childApp">
          <img id="startingLoader_imageApp" src={logo} alt="Mapid Logo" />
          <div id="startingLoader_spinnerApp"></div>
          <br />
          <div id="startingLoader_titleApp">Memuat...</div>
        </div>
        <style>
          {`
          #startingLoaderApp {
            height: 100%;
            display: grid;
            overflow: hidden;
          }
  
          #startingLoader_childApp {
            margin: auto;
          }
        
          #startingLoader_spinnerApp {
            margin: auto;
            border: 10px solid #2ca1fdff;
            border-radius: 50%;
            border-top: 10px solid #7eb532ff;
            border-right: 10px solid #1e5e96ff;
            width: 100px;
            height: 100px;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
          }
        
          @-webkit-keyframes spin {
            0% {
              -webkit-transform: rotate(0deg);
            }
        
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
        
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
        
            100% {
              transform: rotate(360deg);
            }
          }
        
          #startingLoader_imageApp {
            margin: auto;
            width: 80px;
            float: left;
            position: relative;
            left: calc((50% - 40px));
            top: 20px;
            overflow: hidden;
          }
        
          #startingLoader_titleApp {
            display: block;
            text-align: center;
            margin: auto;
            font-size: 25px;
            font-weight: bold;
            color: #1e5e96ff;
            font-family: "Inter", sans-serif;
            margin-left: 10px;
            margin-right: 10px;
          }
          `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(SpinnerSimpleLogo);
