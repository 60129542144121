import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { Provider } from "react-redux";
import PrivateRoute from "./components/common/PrivateRoute";

import store from "./store";
import moment from "moment";

//Auth
import Home from "./components/auth/Home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";

//Basic
// import Landing from "./components/layout/Landing";
import NavigationBottom from "./components/layout/NavigationBottom";

//Dashboard
import DashboardProject from "./components/dashboard/Dashboard";
import ProjectList from "./components/dashboard/ProjectList";
import PaidForm from "./components/dashboard/PaidForm";
import PublicForm from "./components/dashboard/PublicForm";
import OfflineList from "./components/dashboard/OfflineList";
import PersonalPaidForm from "./components/dashboard/PersonalPaidForm";
import Income from "./components/dashboard/Income";

//Notification
import NotificationList from "./components/notification/NotificationList";

//Chat
import ChatList from "./components/chat/ChatList";
import ChatLayer from "./components/chat/ChatLayer";

// User
import UserDashboard from "./components/user/UserDashboard";
import EditProfile from "./components/user/EditProfile";

//Form
import Redirect from "./components/submit_form/Redirect";
import Step1 from "./components/submit_form/Step1";
import Step2 from "./components/submit_form/Step2";
import Step3 from "./components/submit_form/Step3";

//Form other
import InputDataPembanding from "./components/data_pembanding/InputDataPembanding";
import OfflineForm from "./components/form/OfflineForm";
// import FormEditFeature from "./components/form/FormEditFeature";
import ThankYou from "./components/form/ThankYou";

import {
  setCurrentUser,
  getUserFromToken,
  setPathname,
} from "./actions/authActions";
import history from "./actions/history";

import "./App.css";
import "./Rich.css";
import ForgetPassword from "./components/auth/ForgetPassword";
import RegisterLink from "./components/auth/RegisterLink";
moment.locale("id");

//check for Token
const jwtTokenMapid = localStorage.jwtTokenMapid;
if (jwtTokenMapid) {
  store.dispatch(setCurrentUser(JSON.parse(localStorage.getItem("mapidUser"))));
  store.dispatch(getUserFromToken());
}

class App extends Component {
  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      store.dispatch(setPathname(location.pathname));
    });
  }
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <NavigationBottom />
          {/* mode tambah feature tanpa feature_key */}
          <Route exact path="/:link" component={Redirect} />
          <Route exact path="/:link/step_1" component={Step1} />
          <Route exact path="/:link/step_2" component={Step2} />
          <Route exact path="/:link/step_3" component={Step3} />

          {/* mode edit feature dengan feature key */}
          <Route exact path="/:link/edit/:feature_key" component={Redirect} />
          <Route exact path="/:link/step_1/:feature_key" component={Step1} />
          <Route exact path="/:link/step_2/:feature_key" component={Step2} />
          <Route exact path="/:link/step_3/:feature_key" component={Step3} />

          <Route
            exact
            path="/menu/input_data_pembanding/:layer_id/:feature_key"
            component={InputDataPembanding}
          />

          <Route exact path="/" component={Home} />
          <Route exact path="/menu/login" component={Login} />
          <Route
            exact
            path="/menu/forget-password"
            component={ForgetPassword}
          />

          <Route exact path="/menu/register" component={Register} />
          <Route
            exact
            path="/menu/register/:invite_code"
            component={RegisterLink}
          />

          <Route exact path="/submit_form/:link" component={Redirect} />
          <Route exact path="/offline/:id_offline" component={OfflineForm} />

          {/* <Route
            exact
            path="/menu/edit/:geo_layer_link/:feature_key"
            component={FormEditFeature}
          /> */}
          <Route exact path="/success/response/:link" component={ThankYou} />
          <Route exact path="/menu/offline" component={OfflineList} />

          <Switch>
            <PrivateRoute
              exact
              path="/menu/notifications"
              component={NotificationList}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/menu/chat" component={ChatList} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/chat_layer/:geo_layer_link"
              component={ChatLayer}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/dashboard"
              component={DashboardProject}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/project_list"
              component={ProjectList}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/menu/paid_form" component={PaidForm} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/menu/income" component={Income} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/personal_paid_form"
              component={PersonalPaidForm}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/public_form"
              component={PublicForm}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/setting"
              component={UserDashboard}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/menu/editprofile"
              component={EditProfile}
            />
          </Switch>
          <div className="mainReact">
            <style>{`
        #startingLoader{ display: none;}
        `}</style>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
