/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import FieldsRender from "../universal_form_sub_components/FieldsRender";
import Title from "../form/Title";
import TableOverlay from "../form/TableOverlay";
import NotePembanding from "../data_pembanding/NotePembanding";
import Modal from "../common/Modal";

/*REDUX FUNCTION*/
import {
  //GET
  get_feature,
  getFieldsPrivate,

  //POST
  push_feature,
  submit_rev,

  //NON API
  set_child_array,
  delete_child,
} from "../../actions/formActions";
import {
  set_value_properties,
  one_step_back_child,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../validation/dict.json";
import uuid from "../../validation/uuid";

// data
import hidden_data_types from "../../validation/hidden_data_types.json"

class Step3 extends Component {
  state = {
    modal_warning_incomplete: false,
    modal_delete_child: false,
    child_uuid_state: "",
  };

  toggle_edit_child = () => {
    this.props.set_value_properties({
      key: "modal_edit_child",
      value: !this.props.properties.modal_edit_child,
    });
  };

  toggle_delete_child = (child_uuid_state) => {
    this.setState({ modal_delete_child: !this.state.modal_delete_child });
    if (child_uuid_state) {
      this.setState({ child_uuid_state });
    }
  };

  on_delete_child = (child_uuid) => {
    this.setState({ modal_delete_child: false });
    const body = {
      child_uuid,
    };
    this.props.delete_child(body);

    const { current_level } = this.props.properties;
    if (current_level === 2) {
      this.props.set_value_properties({
        key: "modal_edit_child",
        value: false,
      });
    } else {
      this.props.one_step_back_child();
    }
  };

  on_one_step_back_child = () => {
    this.props.one_step_back_child();
  };

  toggle_warning_incomplete = () => {
    this.setState({
      modal_warning_incomplete: !this.state.modal_warning_incomplete,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { geo_layer_wo_geojson } = this.props.form;
    if (!geo_layer_wo_geojson?._id) {
      const link = this?.props?.match?.params?.link;
      const feature_key = this?.props?.match?.params?.feature_key || "";

      //keperluan data pembanding
      const search = window?.location?.search?.substring(1);
      let object_param = {};
      let apraisal_id;
      if (search) {
        object_param = JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        apraisal_id = object_param?.apraisal_id;
      }

      if (feature_key) {
        const params = {
          geo_layer_link: link,
          feature_key,
          apraisal_id,
          step: "step_3",
        };
        this.props.get_feature(params);
        this.props.set_value_properties({
          key: "feature_key",
          value: feature_key,
        });
      } else {
        const params = {
          geo_layer_link: link,
          apraisal_id,
        };
        this.props.getFieldsPrivate(params);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this?.props?.auth?.isAuthenticated !== prevProps?.auth?.isAuthenticated &&
      this?.props?.auth?.isAuthenticated
    ) {
      this.setState({
        full_name: this?.props?.auth?.user?.full_name || "",
        city: this?.props?.auth?.user?.city || "",
        phone_number_state: this?.props?.auth?.user?.phone_number || "62",
      });

      const link = this?.props?.match?.params?.link;
      const feature_key = this?.props?.match?.params?.feature_key || "";

      if (feature_key) {
        const params = {
          geo_layer_link: link,
          feature_key,
          step: "step_3",
        };
        this.props.get_feature(params);
      } else {
        this.props.getFieldsPrivate(link);
      }
    }
  }

  on_submit = () => {
    //local storage
    const search = window?.location?.search?.substring(1);

    //global props
    const { geo_layer_wo_geojson, latitude, longitude } = this.props.form;

    //content
    const counting_custom_array =
      geo_layer_wo_geojson?.counting_custom_array || [];
    let object_param = {};
    let mode_pembanding, feature_key_appraisal, apraisal_id;
    if (search) {
      object_param = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      mode_pembanding = object_param?.mode_pembanding;
      feature_key_appraisal = object_param?.feature_key_appraisal;
      apraisal_id = object_param?.apraisal_id;
    }

    const fields = geo_layer_wo_geojson?.fields || [];
    const fields_general = fields.filter(
      (f) =>
        !f.parent_uuid &&
        f.type !== "table" &&
        !f.isHide &&
        !f.isStyle &&
        !hidden_data_types.includes(f?.type)
    );

    //STEP 1.a konversikan fields_general menjadi properties
    let properties = {};

    fields_general.forEach((f) => {
      let value = f.value;
      if (f.type === "number" || f.type === "currency" || f.type === "year") {
        value = parseFloat(f.value);
      } else if (f.type === "checklist") {
        let value_array = f?.value_array || [];
        value_array = value_array.filter((v) => v !== "");
        value = value_array.join("\n");
      } else if (f.type === "date") {
        const date_now = new Date(value);
        const date =
          String(date_now.getDate()).length > 1
            ? date_now.getDate()
            : "0" + String(date_now.getDate());
        const month =
          String(date_now.getMonth() + 1).length > 1
            ? date_now.getMonth() + 1
            : "0" + String(date_now.getMonth() + 1);
        const year = date_now.getFullYear();
        value = `${year}-${month}-${date}`;
      } else if (f.type === "phone_number") {
        const phone_code = f.phone_code || 62;
        value = `${phone_code}${f.value}`;
      } else {
        value = f.value;
      }

      properties[f.key] = value;
    });

    //STEP 1.b tambahkan property default untuk geometry type Point
    const properties_default = {
      icon_image: "museum",
      text_field: "point",
      circle_color: "#1E5E96",
      circle_radius: 5,
      circle_stroke_width: 1,
      circle_stroke_color: "#fff",
    };
    properties = Object.assign(properties_default, properties);

    //STEP 2 konversikan child_array fields menjadi child array properties
    let child_array = geo_layer_wo_geojson?.child_array || [];
    child_array = child_array.map((c) => {
      let properties = {};

      const fields_child = c?.fields_child || [];

      fields_child.forEach((f) => {
        let value = f.value;
        if (f.type === "number" || f.type === "currency" || f.type === "year") {
          value = parseFloat(f.value);
        } else if (f.type === "checklist") {
          let value_array = f?.value_array || [];
          value_array = value_array.filter((v) => v !== "");
          value = value_array.join("\n");
        } else if (f.type === "date") {
          const date_now = new Date(value);
          const date =
            String(date_now.getDate()).length > 1
              ? date_now.getDate()
              : "0" + String(date_now.getDate());
          const month =
            String(date_now.getMonth() + 1).length > 1
              ? date_now.getMonth() + 1
              : "0" + String(date_now.getMonth() + 1);
          const year = date_now.getFullYear();
          value = `${year}-${month}-${date}`;
        } else if (f.type === "phone_number") {
          value = `${f.phone_code}${f.value}`;
        } else {
          value = f.value;
        }
        properties[f.key] = value;
      });

      const item = {
        properties,
        parent_uuid: c.parent_uuid,
        parent_uuid_temp: c.parent_uuid_temp,
        child_uuid: c.child_uuid,
      };

      return item;
    });

    const feature_key = this?.props?.match?.params?.feature_key || "";

    if (feature_key) {
      //MODE EDIT
      const type = geo_layer_wo_geojson?.type;
      const geometry_init =
        geo_layer_wo_geojson?.geojson?.features?.[0]?.geometry;
      let geometry = {
        type: "Point",
        coordinates: [],
      };
      //handle jika mode edit dan yang disubmit bukan type Point maka gunakan geometry sebelumnya untuk submit supaya tidak menimpa geometry sebelumnya menjadi geometry kosong (FORM MAPID belum support untuk edit geometry selain Point)
      if (type === "Point") {
        geometry = {
          type: "Point",
          coordinates: [parseFloat(longitude), parseFloat(latitude)],
        };
      } else {
        geometry = geometry_init;
      }

      const body = {
        geo_layer_id: geo_layer_wo_geojson._id,
        link: geo_layer_wo_geojson.link,
        feature_key,
        geometry,
        properties,
        child_array,

        //data pembanding
        mode_pembanding,
        feature_key_appraisal,
        apraisal_id,
      };

      this.props.submit_rev(body);
    } else {
      //MODE TAMBAH
      const feature = {
        key: uuid(),
        id: uuid(),
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [parseFloat(longitude), parseFloat(latitude)],
        },
        properties,
        child_array,
        counting_custom_array,
        isHide: false,
      };

      const body = {
        geo_layer_id: geo_layer_wo_geojson._id,
        link: geo_layer_wo_geojson.link,
        feature: feature,
        src: "form_mapid",
        mode_pembanding,
        feature_key_appraisal,
        apraisal_id,
      };

      this.props.push_feature(body);
    }
  };

  // onClickOffline = () => {
  //   let { longitude, latitude } = this.state;
  //   let geo_layer_wo_geojson = this.props.form.geo_layer_wo_geojson;

  //   geo_layer_wo_geojson.id_offline = uuid();
  //   geo_layer_wo_geojson.longitude = longitude;
  //   geo_layer_wo_geojson.latitude = latitude;
  //   this.props.pushFormOffline(geo_layer_wo_geojson);
  //   this.setState({ modalOffline: true });
  // };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    const search = window?.location?.search?.substring(1);
    let object_param = {};
    let page;
    if (search) {
      object_param = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      page = parseInt(object_param.page);
    }

    /*
    kemungkinan-kemungkinan nilai page, untuk membedakan mode per section/page atau langsung tampil semua fields
    1. undefined
    --> tidak perlu filter fields

    2. "0"
    --> tampilkan fields_no_section, kemudian next ke `/${geo_layer_wo_geojson?.link}/step_3?page=1`

    3. "1"
    --> filter fields sesuai page kemudian next ke `/${geo_layer_wo_geojson?.link}/step_3?page=${page+1}`
    */

    //local state
    const { modal_warning_incomplete, modal_delete_child, child_uuid_state } =
      this.state;

    //global props
    const {
      loading,
      // itemLoading,
      geo_layer_wo_geojson,
      fields_no_section,
      fields_section,
    } = this.props.form;
    const { current_level, level_array, modal_edit_child } =
      this.props.properties;

    //content
    const status = geo_layer_wo_geojson?.formStatus?.status;
    const fields = geo_layer_wo_geojson?.fields || [];
    const feature_key = this?.props?.match?.params?.feature_key || "";
    let fields_page, next_link, section_header, section_nav;
    const item_feature_key = feature_key ? `/${feature_key}` : "";

    const field_selected = level_array?.[current_level]?.field_selected || {};
    const child_uuid_selected =
      level_array?.[current_level]?.child_uuid_selected || "";

    const parent_uuid_selected =
      level_array?.[current_level]?.parent_uuid_selected || "";

    let child_array_edit = geo_layer_wo_geojson?.child_array || [];

    child_array_edit = child_array_edit.filter(
      (item) =>
        item.parent_uuid_temp === parent_uuid_selected &&
        item.parent_uuid === field_selected.uuid
    );

    const fields_child =
      child_array_edit.find((f) => f.child_uuid === child_uuid_selected)
        ?.fields_child || [];

    if (page) {
      const next_page = parseInt(page) + 1;
      next_link =
        `/${geo_layer_wo_geojson?.link}/step_3` +
        item_feature_key +
        `?page=${next_page}`;

      if (page === "0") {
        fields_page = fields.filter((f) => fields_no_section.includes(f.uuid));
        section_header = (
          <main>
            <p className="text_bold">
              First page (Section 0/{fields_section?.length})
            </p>
          </main>
        );
      } else {
        const section_current = fields_section?.[parseInt(page) - 1] || {};
        const fields_uuid_array = section_current?.sub || [];
        fields_page = fields.filter((f) => fields_uuid_array.includes(f.uuid));

        section_header = (
          <main>
            <p className="text_bold inline">{section_current?.name}</p>{" "}
            <span className="badge inline" id="green">
              Section {page}/{fields_section?.length}
            </span>
            <p className="text_inferior">{section_current?.description}</p>
          </main>
        );
      }
    } else {
      fields_page = fields;
      section_header = "";
    }

    if (fields_section.length > 0) {
      section_nav = (
        <main>
          {fields_section.map((f, idx) => {
            return (
              <Link
                className="badge inline"
                id={page === idx + 1 ? "red" : "blue"}
                to={
                  `/${geo_layer_wo_geojson?.link}/step_3` +
                  item_feature_key +
                  `?page=${idx + 1}`
                }
                key={idx}
              >
                {idx + 1}
              </Link>
            );
          })}
        </main>
      );
    }

    const child_array = geo_layer_wo_geojson?.child_array || [];

    const fields_parent = fields_page.filter(
      (f) =>
        !f.parent_uuid &&
        f.type !== "section" &&
        !f.isStyle &&
        !f.isHide &&
        !hidden_data_types.includes(f?.type)
    );

    //cek apakah masih ada pertanyaan yang belum terisi
    let is_complete = false;

    const fields_empty = fields_parent.filter((f, idx) => {
      const { uuid, type, value, isRequired } = f;
      let logic_empty = true;
      //awal logic untuk pengetesan adalah true, artinya setiap pertanyaan diasumsikan belum terisi di awalnya, kemudian akan dicek apakah sudah terisi atau tidak

      /*
      logic_empty text    | valuenya kosong atau undefined atau string kosong
      logic_empty number  | undefined, string kosong, 0 tidak apa - apa
      */

      //kondisi keadaan kosong untuk setiap tipe pertanyaan akan berbeda-beda
      if (type === "text") {
        //1
        //text
        //logic_empty text
        logic_empty = !value;
      } else if (type === "textarea") {
        //2
        //textarea
        //logic_empty text
        logic_empty = !value;
      } else if (type === "number") {
        //3
        //number
        //logic_empty text
        logic_empty = !value;
      } else if (type === "counting_custom") {
        //3
        //number
        //logic_empty text
        logic_empty = value === undefined;
      } else if (type === "range") {
        //4
        //range
        //logic_empty text
        logic_empty = !value;
      } else if (type === "phone_number") {
        //5
        //phone_number
        //logic_empty text
        logic_empty = !value;
      } else if (type === "currency") {
        //6
        //currency
        //logic_empty text
        logic_empty = !value;
      } else if (type === "year") {
        //7
        //year
        //logic_empty text
        logic_empty = !value;
      } else if (type === "date") {
        //8
        //date
        //logic_empty text
        logic_empty = !value;
      } else if (type === "time") {
        //9
        //time
        //logic_empty text
        logic_empty = !value;
      } else if (type === "selection") {
        //10
        //selection
        //logic_empty text
        logic_empty = !value;
      } else if (type === "radio_button") {
        //11
        //radio_button
        //logic_empty text
        logic_empty = !value;
      } else if (type === "checklist") {
        //12
        //checklist
        //value_array.length === 0
        const value_array = f?.value_array || [];
        logic_empty = value_array.length === 0;
      } else if (type === "image") {
        //13
        //image
        //logic_empty text
        logic_empty = !value;
      } else if (type === "document") {
        //14
        //document
        //logic_empty text
        logic_empty = !value;
      } else if (type === "link") {
        //15
        //link
        //logic_empty text
        logic_empty = !value;
      } else if (type === "auto_address") {
        //16
        //auto_address
        //logic_empty text
        logic_empty = !value;
      } else if (type === "auto_address_poi") {
        //17
        //auto_address_poi
        //logic_empty text
        logic_empty = !value;
      } else if (type === "table") {
        //18
        //table
        //belum memiliki child(konten baris table itu)
        const array = child_array.filter((c) => c.parent_uuid === uuid);
        logic_empty = array?.length === 0;
      } else if (type === "counting_poi") {
        //19
        //counting_poi
        //logic_empty text
        logic_empty = !value;
      } else {
        //lainnya
        logic_empty = !value;
      }
      if (!isRequired) logic_empty = false; //jika tidak wajib maka tidak perlu memantau kosong atau tidak
      if (type === "section") logic_empty = false; //section tidak dianggap sebagain pertanyaan

      //logic muncul
      let logic_show = true;

      let term = f?.term || [];
      const term_mode = f.term_mode;
      if (term.length > 0) {
        term = term.map((t) => {
          let t_bool;
          let field_selected = fields.filter((f) => f.uuid === t.uuid);
          if (field_selected.length > 0) {
            field_selected = field_selected[0];
            if (field_selected.type !== "checklist") {
              const value = field_selected.value;
              t_bool = t.value.includes(value);
            } else {
              const value_array = field_selected?.value_array
                ? field_selected.value_array
                : [];
              let value_term = t?.value ? t.value : [];
              value_term = value_term.map((v) => {
                const v_bool = value_array.includes(v);
                return v_bool;
              });
              t_bool = value_term.includes(true);
            }
          } else {
            t_bool = "empty";
          }
          return t_bool;
        });
        term = term.filter((t) => t !== "empty");
        if (term_mode === "and") {
          logic_show = !term.includes(false);
        } else {
          logic_show = term.includes(true);
        }
      }
      return logic_empty && logic_show;
    });

    const fields_empty_uuid = fields_empty.map((f) => f.uuid);

    if (fields_empty_uuid.length === 0) is_complete = true;

    let button_content;

    // if (!window.navigator.onLine) {
    //   button_content = (
    //     <button onClick={this.onClickOffline} className="button_standard">
    //       {dict?.["Simpan offline"]?.[language]}
    //     </button>
    //   );
    // } else
    if (loading) {
      button_content = (
        <div className="button_standard" id="grey">
          {dict?.["Mengirim"]?.[language]}...
        </div>
      );
    } else if (
      is_complete
      // || feature_key
    ) {
      /*
      jika terdapat feature_key diboleh kan untuk submit karena sifatnya menyimpan feature yang sudah ada, jadi bisa submit untuk check point terlebih dahulu
      */
      button_content = (
        <button className="button_standard" onClick={this.on_submit}>
          {dict?.["Kirim"]?.[language]}
        </button>
      );
    } else {
      button_content = (
        <main className="text_left">
          <div
            className="button_standard"
            id="grey"
            onClick={this.toggle_warning_incomplete}
          >
            {dict?.["Kirim"]?.[language]}
          </div>
          <section className="text_medium" style={{ width: "100%" }}>
            {
              dict?.[
              "Tombol kirim akan aktif setelah semua pertanyaan yang wajib terisi"
              ]?.[language]
            }
          </section>
        </main>
      );
    }

    if (page && parseInt(page) < fields_section.length) {
      button_content = (
        <Link to={next_link} className="button" id="blue">
          Next page
        </Link>
      );
    }

    const content = geo_layer_wo_geojson?._id ? (
      <main>
        {section_nav}
        {section_header}
        <FieldsRender
          fields_parent={fields_parent}
          fields={fields}
          is_parent={true}
          connection_mode="online"
        />
        {button_content}
      </main>
    ) : null;

    const modal_warning_incomplete_content = modal_warning_incomplete && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_warning_incomplete}
        onClose={this.toggle_warning_incomplete}
      >
        <div className="box-body">
          Masih ada pertanyaan wajib yang belum Anda isi, harap lengkapi semua
          isian wajib terlebih dahulu.
        </div>
      </Modal>
    );

    const modal_edit_child_content = modal_edit_child && (
      <Modal
        modalSize="small"
        id={child_uuid_selected}
        isOpen={modal_edit_child}
        onClose={this.toggle_edit_child}
      >
        <div className="box-body">
          {current_level > 2 && (
            <button className="button" onClick={this.on_one_step_back_child}>
              Back
            </button>
          )}

          <section className="text_right margin_bottom">
            <button
              className="button"
              id="red"
              onClick={this.toggle_delete_child.bind(this, child_uuid_selected)}
            >
              Hapus
            </button>
          </section>
          <section className="margin_bottom">
            <p className="text_inferior">Nama tabel</p>
            <p className="text_bold">{field_selected?.name}</p>
            <br />
            <p className="text_inferior">Level tabel</p>
            <p className="text_bold">{current_level + 1}</p>
          </section>
          <FieldsRender
            field={field_selected}
            fields={fields_child}
            parent_uuid_temp={child_uuid_selected}
            child_uuid={child_uuid_selected}
            is_parent={false}
            connection_mode="online"
          />
        </div>
      </Modal>
    );

    const modal_delete_child_content = modal_delete_child && (
      <Modal
        modalSize="small"
        id="modal_delete_child"
        isOpen={modal_delete_child}
        onClose={this.toggle_delete_child}
      >
        <div className="box-body text_center">
          <h1 className="text_bold">Konfirmasi hapus item dari tabel</h1>
          <br />
          <br />
          <button
            className="button"
            id="red"
            onClick={this.on_delete_child.bind(this, child_uuid_state)}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    return (
      <div className="main_container">
        {modal_edit_child_content}
        {modal_delete_child_content}
        {modal_warning_incomplete_content}
        <Title status={status} step={3} />
        <NotePembanding />
        <TableOverlay />
        {content}
        <Helmet>
          <title>
            {geo_layer_wo_geojson?.name
              ? geo_layer_wo_geojson?.name
              : "MAPID FORM"}
          </title>
          <meta
            name="description"
            content={
              geo_layer_wo_geojson?.name1
                ? geo_layer_wo_geojson?.name
                : "MAPID FORM"
            }
          />
        </Helmet>

        <style>{`.nav_bottom{ display:none;}`}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  submit_rev,
  get_feature,
  getFieldsPrivate,
  push_feature,
  set_child_array,
  set_value_properties,
  one_step_back_child,
  delete_child,
})(Step3);
