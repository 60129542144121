import React, { Component } from "react";
import { connect } from "react-redux";

class SpinnerSimpleBlue extends Component {
  render() {
    return (
      <div className={`loader_${this.props.unik}`}>
        <style>
          {`
          .loader_${this.props.unik} {
            border: ${this.props.width * this.props.border}px solid #2ca1fdff;
            border-radius: 50%;
            border-top: ${
              this.props.width * this.props.border
            }px solid #1e5e96ff;
            width: ${this.props.width}px;
            height: ${this.props.width}px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
            margin-top:${this.props.marginTop ? this.props.marginTop : "0px"};
            ${this.props.center ? "margin:auto;" : null}
          }
          /* Safari */
          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          `}
        </style>
      </div>
    );
  }
}
SpinnerSimpleBlue.propTypes = {};
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {})(SpinnerSimpleBlue);
