//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

//Personal Component

// Offline Form
import {
  // STEP_1_OFFLINE,
  STEP_2_OFFLINE,
  STEP_3_OFFLINE,
} from "../offline_form";

// import UploadSelfieKTP from "./UploadSelfieKTP";
import BlogRead from "./BlogRead";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Modal from "../common/Modal";
//Redux function
import {
  push_feature,
  getDocForm,
  applyForm,
  setOfflineDetail,
  saveFormOffline,
  pushFormOffline,
} from "../../actions/formActions";
import {
  updateFullname,
  updateCity,
  updatePhone,
} from "../../actions/authActions";
//Picture Asset
import icon_check from "../../img/icon_check.svg";
import icon_uncheck from "../../img/icon_uncheck.svg";
//General Function
import isEqual from "../../validation/is-equal";
import isEmpty from "../../validation/is-empty";
import uuid from "../../validation/uuid";
import Kabupaten from "../common/Kabupaten.json";
import ProvinsiKabupaten from "../common/ProvinsiKabupaten.json";
import history from "../../actions/history";
import { is_point_type } from "../../validation/check_geometry_type";
import dict from "../../validation/dict.json";

const cities_array = Kabupaten.sort();

class OfflineForm extends Component {
  geocoderContainerRef = React.createRef();
  state = {
    // geo_layer_wo_geojson: {},
    modalLocation: true,
    screen_status: 1,
    modalLogin: false,
    loginStatus: true,
    full_name: "",
    city: "",
    phone_number_state: "62",
    modalRead: false,
    readStatus: false,
    modalEditName: false,
    modalEditCity: false,
    modalEditPhone: false,
    modalApplyPaidSurvey: false,
    modalUserData: false,
    amount: 1,
    cities_filtered: cities_array,
    modal_offline: false,
  };

  componentDidMount = async () => {
    this.setState({
      full_name: this.props.auth.user.full_name
        ? this.props.auth.user.full_name
        : "",
      city: this.props.auth.user.city ? this.props.auth.user.city : "",
      phone_number_state: this.props.auth.user.phone_number
        ? this.props.auth.user.phone_number
        : "62",
    });
    const { id_offline } = this.props.match.params;
    this.props.setOfflineDetail(id_offline);
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      this.setState({ modalLogin: false });
      const link = this?.props?.match?.params?.link;
      this.props.getFieldsPrivate(link);
    }
    if (this.props.auth.isRegistered !== prevProps.auth.isRegistered) {
      this.setState({ loginStatus: true });
    }
    if (this.props.auth.user.full_name !== prevProps.auth.user.full_name) {
      this.setState({ full_name: this.props.auth.user.full_name });
    }
    if (
      this.props.auth.user.phone_number !== prevProps.auth.user.phone_number
    ) {
      this.setState({ phone_number: this.props.auth.user.phone_number });
    }
    if (!isEqual(this.props.auth.user, prevProps.auth.user)) {
      this.setState({
        modalEditName: false,
        modalEditCity: false,
        modalEditPhone: false,
      });
    }
  }

  handleChangeHelper = (new_fields) => {
    this.setState((prevState) => ({
      geo_layer_wo_geojson: {
        ...prevState.geo_layer_wo_geojson,
        fields: new_fields,
      },
    }));
  };

  toggleOffline = () => {
    this.setState({ modal_offline: !this.state.modal_offline });
  };

  onSaveOffline = () => {
    let { geo_layer_wo_geojson, latitude, longitude } = this.props.form;
    this.props.saveFormOffline(geo_layer_wo_geojson, { latitude, longitude });
    history.push("/menu/offline");
  };

  onPushOffline = () => {
    let { geo_layer_wo_geojson } = this.props.form;
    const { latitude, longitude } = this.props.form;
    geo_layer_wo_geojson.id_offline = uuid();
    this.props.pushFormOffline(geo_layer_wo_geojson, { latitude, longitude });
    history.push("/menu/offline");
  };
  goBack = () => {
    window.history.back();
  };
  // toggleUserData = () => {
  //   this.setState({ modalUserData: !this.state.modalUserData });
  // };
  toggleApplyPaidSurvey = () => {
    this.setState({ modalApplyPaidSurvey: !this.state.modalApplyPaidSurvey });
  };
  handleApplyPaidSurvey = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const { geo_layer_wo_geojson } = this.props.form;
    const { amount } = this.state;
    const body = {
      user: user._id,
      geo_layer_id: geo_layer_wo_geojson._id,
      amount: parseInt(amount),
    };
    this.props.applyForm(body);
    this.setState({ modalApplyPaidSurvey: false });
  };
  toggleEditName = () => {
    this.setState({ modalEditName: !this.state.modalEditName });
  };
  toggleEditCity = () => {
    this.setState({ modalEditCity: !this.state.modalEditCity });
  };
  toggleEditPhone = () => {
    this.setState({ modalEditPhone: !this.state.modalEditPhone });
  };
  onClickCity = (city) => {
    this.setState({ city });
  };
  handleFixCity = () => {
    const { cities_filtered, city } = this.state;
    if (cities_filtered.length === 0) {
      this.setState({ cities_filtered: cities_array });
    } else if (!cities_filtered.includes(city)) {
      this.setState({ city: cities_filtered[0] ? cities_filtered[0] : "" });
    }
  };

  handleTrueModalRead = () => {
    this.setState({ modalRead: true }, () => {
      document.getElementById("read_content").style.bottom = "0px";
      const { geo_layer_wo_geojson } = this.props.form;
      this.props.getDocForm(geo_layer_wo_geojson._id);
    });
  };
  handleFalseModalRead = () => {
    this.setState({ modalRead: false, readStatus: true }, () => {
      document.getElementById("read_content").style.bottom = "-100vh";
    });
  };
  handleUpdateFullname = (e) => {
    e.preventDefault();
    const { full_name } = this.state;
    const body = {
      full_name,
    };
    this.props.updateFullname(body);
  };
  handleUpdatePhone = (e) => {
    e.preventDefault();
    const { phone_number_state } = this.state;
    const body = {
      phone_number: phone_number_state,
    };
    this.props.updatePhone(body);
  };
  handleUpdateCity = (e) => {
    e.preventDefault();
    const { cities_filtered, city } = this.state;
    if (!cities_filtered.includes(city)) {
      const body = {
        city: cities_filtered[0] ? cities_filtered[0] : "",
      };
      this.props.updateCity(body);
    } else {
      const body = {
        city,
      };
      this.props.updateCity(body);
    }
  };
  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };
  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  set_screen_status = (value) => {
    this.setState({
      screen_status: value,
    });
  };

  handleAddScreenStatus = () => {
    const { screen_status } = this.state;
    this.setState({ screen_status: screen_status + 1 }, () => {
      if (screen_status === 1) {
        const { geo_layer_wo_geojson, latitude, longitude } = this.props.form;
        const { fields } = geo_layer_wo_geojson;
        const type_array = fields.map(({ type }) => {
          return type;
        });
        if (window.navigator.onLine) {
          if (type_array.includes("auto_address")) {
            //Reverse geocode using maptiler API
            axios
              .get(
                `https://api.maptiler.com/geocoding/${longitude},${latitude}.json?key=CVYOeJckfxqURN5wjKEB`
              )
              .then((res) => {
                const { features } = res.data;
                let address_text = "";
                let i = 0;
                const addressSeq = ["street", "subcity", "state", "country"];
                addressSeq.forEach((ad) => {
                  const idxFind = features.findIndex(
                    (f) => f.place_type[0] === ad
                  );
                  if (idxFind >= 0) {
                    address_text += features[idxFind].text;
                    if (i < features.length - 1) {
                      address_text += ", ";
                    }
                    i++;
                  }
                });
                const index = fields.findIndex(
                  (field_element) => field_element.type === "auto_address"
                ); //get index of the geo_layer_wo_geojson in geo_layer_list
                fields[index].defaultValue = address_text;
                geo_layer_wo_geojson.fields = fields;
                this.setState({ geo_layer_wo_geojson });
              });
          }
          if (type_array.includes("auto_address_poi")) {
            //Reverse geocode using maptiler API
            axios
              .get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=poi&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`
              )
              .then((res) => {
                const { features } = res.data;
                let address_text = features[0]?.place_name;
                const index = fields.findIndex(
                  (field_element) => field_element.type === "auto_address_poi"
                ); //get index of the geo_layer_wo_geojson in geo_layer_list
                fields[index].defaultValue = address_text;
                geo_layer_wo_geojson.fields = fields;
                this.setState({ geo_layer_wo_geojson });
              });
          }
        }
      }
    });
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      screen_status,
      modalLogin,
      loginStatus,
      full_name,
      phone_number_state,
      readStatus,
      modalEditName,
      // modalUserData,
      modalApplyPaidSurvey,
      amount,
      modalEditCity,
      modalEditPhone,
      city,
      cities_filtered,
      modal_offline,
    } = this.state;
    const { loading, itemLoading, geo_layer_wo_geojson } = this.props.form;
    const { type } = geo_layer_wo_geojson || {};
    const { isAuthenticated, isRegistered } = this.props.auth;

    /*Properti survei berbayar*/
    let isCity, isProvince, cities, provinces, cities_province;
    let cities_final = [];
    const formPayment = geo_layer_wo_geojson?.formPayment;
    // let status;
    if (geo_layer_wo_geojson?.formPayment?.date_in) {
      // status = geo_layer_wo_geojson?.formStatus?.status;
      // surveyors = formPayment.surveyors;
      // surveyors_id = surveyors.map((surveyor) => String(surveyor.user));
      isCity = formPayment.isCity;
      isProvince = formPayment.isProvince;
      cities = formPayment.cities;
      provinces = formPayment.provinces;
      cities_province = provinces
        .map((province, idx) => {
          return ProvinsiKabupaten[province];
        })
        .flat();
      if (isProvince && provinces.length > 0) {
        cities_final = [...cities, ...cities_province];
      }
    }
    // let amount_props;
    // if (geo_layer_wo_geojson?.formPayment?.surveyors.length > 0) {
    //   surveyors = formPayment.surveyors;
    //   const surveyor = surveyors[0];
    //   amount_props = surveyor.amount;
    //   percentage = parseInt((features_length / amount_props) * 100);
    // }
    /*Properti survei berbayar*/
    const itemLoadingUser = this.props.auth.itemLoading;
    const loadingProcessUser = this.props.auth.loadingProcess;
    const full_name_prop = this.props.auth?.user?.full_name;
    const city_prop = this.props.auth?.user?.city;
    const phone_prop = this.props.auth?.user?.phone_number;
    const ktp = this.props.auth?.user?.ktp;
    // const selfiektp = this.props.auth?.user?.selfiektp;
    //Point progress
    const auth_point = isAuthenticated ? 1 : 0;
    const full_name_point = full_name_prop ? 1 : 0;
    const phone_number_point = phone_prop ? 1 : 0;
    const city_point = city_prop ? 1 : 0;
    const ktp_point = ktp ? 1 : 0;
    // const selfiektp_point = selfiektp ? 1 : 0;
    let city_match_point = 0;
    const logic_1 = !isCity && !isProvince; //Klien tidak memberikan restriksi lokasi
    const logic_2 = isCity && cities.includes(city_prop); //Klien hanya restrik lokasi kota dan kota surveyor include
    const logic_3 = isProvince && cities_province.includes(city_prop); //Restriksi provinsi dan kota surveyor include
    const logic_4 = isCity && isProvince && cities_final.includes(city_prop); //Restriksi kota & provinsi surveyor masuk
    if (logic_1 || logic_2 || logic_3 || logic_4) {
      city_match_point = 1;
    }
    const readstatus_point = readStatus ? 1 : 0;
    const total_point =
      auth_point +
      full_name_point +
      city_point +
      ktp_point +
      phone_number_point +
      // selfiektp_point +
      city_match_point +
      readstatus_point;
    const percentage_point = (total_point / 7) * 100;
    //Field test empty
    // let fields_test_empty;
    // let isComplete = false;

    // if (geo_layer_wo_geojson?.fields) {
    //   const fields_required = geo_layer_wo_geojson.fields.filter(
    //     (field) => field.isRequired && field.type !== "checklist"
    //   );
    //   fields_test_empty = fields_required.map((field) => {
    //     const defaultValue = field.defaultValue ? field.defaultValue : "";
    //     return defaultValue;
    //   });
    //   const check_required = geo_layer_wo_geojson.fields.filter(
    //     (field) => field.isRequired && field.type === "checklist"
    //   );
    //   const check_required_empty = check_required.map((field) => {
    //     const defaultValue = field.value_array ? field.value_array : [];
    //     const length = defaultValue.length;
    //     return length;
    //   });
    //   isComplete =
    //     !fields_test_empty.includes("") && !check_required_empty.includes(0);
    // }

    const payment = geo_layer_wo_geojson?.payment;
    const indicator_array = [
      {
        name: dict["Pengenalan survei"][language],
        paid: `${dict["Pengenalan survei"][language]} (${parseInt(
          percentage_point
        )}%)`,
      },
      {
        name: dict["Aktifkan lokasi kamu"][language],
        paid: dict["Aktifkan lokasi kamu"],
      },
      {
        name: dict["Isi survei"][language],
        paid: dict["Isi survei"][language],
      },
    ];
    const indicatorContent = (
      <main
        style={{
          width: "100%",
          backgroundColor: "#009de3cc",
          backgroundImage: "linear-gradient(to right,#009de3cc, #2bbeffcc)",
          color: "#ffffff",
          borderRadius: "10px",
          paddingTop: "5px",
          paddingBottom: "3px",
          textAlign: "center",
          zIndex: 7,
        }}
      >
        <section className="parent_center" style={{ width: "100%" }}>
          <div className="three_container" style={{ width: "100px" }}>
            {indicator_array.map((content, idx) => {
              const backgroundColor =
                screen_status === idx ? "#7cc735ff" : "#ffffff";
              const color = screen_status === idx ? "#ffffff" : "#cecece";
              return (
                <div
                  className="button_lingkaran"
                  style={{ backgroundColor, color }}
                  key={idx}
                >
                  {idx + 1}
                </div>
              );
            })}
          </div>
        </section>
        <section
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {isEmpty(payment)
            ? indicator_array[screen_status].name
            : indicator_array[screen_status].paid}
        </section>
        {!isEmpty(payment) && screen_status === 0 && (
          <section
            style={{
              width: "50%",
              height: "10px",
              backgroundColor: "#ffffff",
              borderRadius: "2rem",
              padding: "3px",
              margin: "auto",
            }}
          >
            <div
              style={{
                height: "100%",
                width: `${percentage_point}%`,
                backgroundColor: "#7cc735ff",
                borderRadius: "2rem",
              }}
            ></div>
          </section>
        )}
      </main>
    );

    let screen_content;
    // if (screen_status === 0) {
    //   screen_content = (
    //     <STEP_1_OFFLINE
    //       readStatus={readStatus}
    //       modalUserData={modalUserData}
    //     />
    //   );
    // } else

    if (screen_status === 1 && is_point_type(type)) {
      screen_content = (
        <STEP_2_OFFLINE handleAddScreenStatus={this.handleAddScreenStatus} />
      );
    } else if (screen_status === 2 || !is_point_type(type)) {
      screen_content = (
        <STEP_3_OFFLINE
          set_screen_status={this.set_screen_status}
          toggleOffline={this.toggleOffline}
          geo_layer_wo_geojson={geo_layer_wo_geojson}
          handleChangeHelper={this.handleChangeHelper}
        />
      );
    }
    let itemContent;
    if (loading && itemLoading === "getFieldsPrivate") {
      itemContent = (
        <div
          style={{
            position: "sticky",
            top: "30px",
            width: "100%",
            backgroundColor: "#009de3cc",
            backgroundImage: "linear-gradient(to right,#009de3cc, #2bbeffcc)",
            color: "#ffffff",
            borderRadius: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
          }}
        >
          Memuat
        </div>
      );
    } else if (isEmpty(geo_layer_wo_geojson)) {
      itemContent = (
        <main>
          <section
            style={{
              position: "sticky",
              top: "10px",
              width: "100%",
              backgroundColor: "#009de3cc",
              backgroundImage: "linear-gradient(to right,#009de3cc, #2bbeffcc)",
              color: "#ffffff",
              borderRadius: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
              textAlign: "center",
            }}
          >
            Maaf sepertinya survei ini tidak publik
          </section>
          {!isAuthenticated && (
            <main className="container_light" id="white_background">
              <main className="two_container_icon_right">
                <section>
                  <div className="text_bold">Log in</div>
                  <div className="text_medium">
                    Coba masuk akun jika ini survei milik Anda atau grup Anda.
                  </div>
                  {!isAuthenticated && (
                    <div className="button_tight" onClick={this.toggleLogin}>
                      Log in
                    </div>
                  )}
                </section>
                <section style={{ textAlign: "right" }}>
                  {isAuthenticated ? (
                    <img src={icon_check} width="30px" alt="check" />
                  ) : (
                    <img src={icon_uncheck} width="30px" alt="check" />
                  )}
                </section>
              </main>
            </main>
          )}
          <Link
            to="/menu/dashboard"
            className="button_standard"
            style={{ marginTop: "20px" }}
          >
            Kembali ke dasbor
          </Link>
        </main>
      );
    } else {
      itemContent = (
        <main>
          <section
            style={{
              position: "fixed",
              bottom: "10px",
            }}
          >
            <button
              className="button_very_small"
              id="blue"
              style={{ marginRight: "5px" }}
              onClick={this.goBack}
            >
              Dasbor
            </button>
            <button
              onClick={this.handleTrueModalRead}
              className="button_very_small"
              id="blue"
              style={{ marginRight: "5px" }}
            >
              Buka Instruksi
            </button>
          </section>
          {indicatorContent}
          {screen_status !== 1 && geo_layer_wo_geojson?.picture_url && (
            <div
              style={{
                backgroundImage: `url(${geo_layer_wo_geojson?.picture_url})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: "#dedede",
                width: "100%",
                paddingBottom: "25%",
                borderRadius: "10px",
                marginTop: "30px",
              }}
            />
          )}
          <div className="container_light" id="white_background">
            <div
              style={{
                fontSize: "30px",
                fontWeight: "555",
                lineHeight: "0.95",
                letterSpacing: "-1.3px",
                marginBottom: "7px",
              }}
            >
              {geo_layer_wo_geojson?.name}
            </div>
            <div
              style={{
                fontSize: "16px",
                whiteSpace: "pre-wrap",
              }}
            >
              {geo_layer_wo_geojson?.description}
            </div>
          </div>
          {!!(
            screen_status !== 1 && geo_layer_wo_geojson?.picture_url_square[0]
          ) && (
              <div className="container_light" id="white_background">
                <div
                  style={{
                    backgroundImage: `url(${geo_layer_wo_geojson?.picture_url_square
                        ? geo_layer_wo_geojson?.picture_url_square[0]?.url
                        : ""
                      })`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundColor: "#dedede",
                    width: "100%",
                    paddingBottom: "100%",
                    borderRadius: "10px",
                  }}
                />
              </div>
            )}
          {screen_content}
        </main>
      );
    }
    const modalLoginContent = modalLogin && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalLogin}
        onClose={this.toggleLogin}
      >
        <div className="box-body">
          {loginStatus || isRegistered ? <Login isNav={true} /> : <Register />}
          {loginStatus || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">Don't have an account?</div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                Register here
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">Aready have an account?</div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                Login instead
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
    const non_modal = <div className="main_container">{itemContent}</div>;
    const modalReadContent = (
      <div className="read_content" id="read_content">
        <div className="main_container" style={{ marginBottom: "100px" }}>
          {loading && itemLoading === "getDocForm" ? (
            <div>Memuat instruksi survei...</div>
          ) : isEmpty(geo_layer_wo_geojson?.blog) ? (
            <div>
              Dokumen belum dibuat
              <div style={{ textAlign: "center" }}>
                <div
                  onClick={this.handleFalseModalRead}
                  className="button_standard"
                >
                  Tutup
                </div>
              </div>
            </div>
          ) : (
            <div>
              <BlogRead blog={geo_layer_wo_geojson.blog} />
              <div style={{ textAlign: "center" }}>
                <div
                  onClick={this.handleFalseModalRead}
                  className="button_standard"
                >
                  Mengerti
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
    const modalEditNameContent = modalEditName && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditName}
        onClose={this.toggleEditName}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdateFullname}>
            <input
              name="full_name"
              id="full_name"
              type="text"
              value={full_name}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "full_name" ? (
                <div className="button_standard">Menyimpan...</div>
              ) : (
                <button className="button_standard" type="submit">
                  Simpan nama lengkap
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
    const modalEditPhoneContent = modalEditPhone && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditPhone}
        onClose={this.toggleEditPhone}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdatePhone}>
            <input
              name="phone_number_state"
              id="phone_number_state"
              type="text"
              value={phone_number_state}
              onChange={this.handleChange}
            />
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "updatePhone" ? (
                <div className="button_standard">Menyimpan...</div>
              ) : (
                <button className="button_standard" type="submit">
                  Simpan nomor handphone
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
    const modalEditCityContent = modalEditCity && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditCity}
        onClose={this.toggleEditCity}
      >
        <div className="box-body">
          <form onSubmit={this.handleUpdateCity}>
            <div
              style={{
                borderRadius: "10px",
                border: "1px solid #d8d8d8",
                padding: "15px",
                marginBottom: "20px",
              }}
            >
              <input
                name="city"
                id="city"
                type="text"
                value={city}
                onChange={this.handleChange}
                onBlur={this.handleFixCity}
              />
              <div
                style={{
                  height: "50vh",
                  overflowY: "scroll",
                  marginTop: "20px",
                }}
              >
                {cities_filtered.map((city_element, idx) => {
                  return (
                    <div
                      onClick={this.onClickCity.bind(this, city_element)}
                      key={idx}
                      className="text_medium"
                      style={{
                        marginBottom: "20px",
                        borderBottom: "1px solid #d8d8d8",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        paddingBottom: "10px",
                        height: "30px",
                      }}
                    >
                      {city_element}
                      {city_element === city && (
                        <img
                          src={icon_check}
                          width="25px"
                          alt="check"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                      {!cities_filtered.includes(city) && idx === 0 && (
                        <img
                          src={icon_check}
                          width="25px"
                          alt="check"
                          style={{ marginLeft: "10px" }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              {loadingProcessUser && itemLoadingUser === "updateCity" ? (
                <div className="button_standard">Menyimpan...</div>
              ) : cities_filtered.length === 0 ? (
                <div className="button_standard" id="grey">
                  Simpan tempat tinggal
                </div>
              ) : (
                <button className="button_standard" type="submit">
                  Simpan tempat tinggal
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
    const modalApplyPaidSurveyContent = modalApplyPaidSurvey && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalApplyPaidSurvey}
        onClose={this.toggleApplyPaidSurvey}
      >
        <div className="box-body">
          <div className="text_bold">
            Berapa target responden yang akan kamu survei? Minimum{" "}
            {geo_layer_wo_geojson.formPayment.minResponden}, maksimum{" "}
            {geo_layer_wo_geojson.formPayment.maxResponden}
          </div>
          <form onSubmit={this.handleApplyPaidSurvey}>
            <input
              name="amount"
              id="amount"
              value={amount}
              type="range"
              min={geo_layer_wo_geojson.formPayment.minResponden}
              max={geo_layer_wo_geojson.formPayment.maxResponden}
              onChange={this.handleChange}
            />
            <div className="three_container">
              <div style={{ textAlign: "left" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {geo_layer_wo_geojson.formPayment.minResponden}
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {amount ? amount : "Belum diisi, geser slider untuk mengisi"}
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                <div
                  className="badge_normal"
                  style={{ fontSize: "16px", color: "#52606eff" }}
                >
                  {geo_layer_wo_geojson.formPayment.maxResponden}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              {loading && itemLoading === "applyForm" ? (
                <div className="button_standard">Mengirim...</div>
              ) : (
                <button className="button_standard" type="submit">
                  Daftar Jadi Surveyor
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
    const modal_offline_content = modal_offline && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_offline}
        onClose={this.toggleOffline}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <button
            className="button_standard"
            onClick={this.onSaveOffline}
            style={{ marginRight: "5px" }}
          >
            {dict["Timpa isian"][language]}
          </button>
          <button className="button_standard" onClick={this.onPushOffline}>
            {dict["Buat baru"][language]}
          </button>
        </div>
      </Modal>
    );

    return (
      <div>
        {non_modal}
        {modalLoginContent}
        {modalReadContent}
        {modalEditNameContent}
        {modalEditCityContent}
        {modalEditPhoneContent}
        {modalApplyPaidSurveyContent}
        {modal_offline_content}
        <Helmet>
          <title>
            {geo_layer_wo_geojson?.name
              ? geo_layer_wo_geojson?.name
              : "MAPID FORM"}
          </title>
          <meta
            name="description"
            content={
              geo_layer_wo_geojson?.name
                ? geo_layer_wo_geojson?.name
                : "MAPID FORM"
            }
          />
        </Helmet>
        <style>{`
        .nav_bottom{ display:none;}
     `}</style>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});
export default connect(mapStateToProps, {
  push_feature,
  updateFullname,
  updateCity,
  updatePhone,
  getDocForm,
  applyForm,
  setOfflineDetail,
  saveFormOffline,
  pushFormOffline,
})(OfflineForm);
