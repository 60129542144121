//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup, { SelectField } from "../common/TextFieldGroup";

//Redux function
import { registerUser } from "../../actions/authActions";

//Picture Asset

//General Function
import dict from "../../validation/dict.json";
import { industry_list } from "../../validation/dataIndustry";
import sort_array from "../../validation/sort_array";
import history from "../../actions/history";
import domain_list from "../../validation/domain_list";

const industry_list_sorted = sort_array(industry_list, "title", true);

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      industry: "",
      show_password: false,
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      history.push("/menu/dashboard");
    }
  }

  componentDidUpdate(prevProps) {
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated &&
      this.props.auth.isAuthenticated
    ) {
      if (!isNav) {
        history.push("/");
      } else {
        this.props.toggle_login();
      }
    }
  }

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, password2, industry } = this.state;
    let body = {
      name: name.toLowerCase().replace(/\s/g, ""),
      email,
      password,
      password2,
      industry,
      city: "Kota Bandung",
    };
    if (this.props.kode) {
      body = {
        name: name.toLowerCase().replace(/\s/g, ""),
        email,
        password,
        password2,
        industry,
        specialist_code: this.props.kode,
        city: "Kota Bandung",
      };
    }
    // console.log(body);
    this.props.registerUser(body);
  };

  onChange = (e) => {
    if (e.target.name === "name") {
      this.setState({ [e.target.name]: e.target.value.replace(/\s/g, "") });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      errors,
      name,
      email,
      password,
      password2,
      show_password,
      industry,
    } = this.state;

    //global props
    const { loading, domain, version } = this.props.auth;

    //content
    let icon_content;

    const logo_final = domain_list?.[domain]?.logo;

    if (loading) {
      icon_content = (
        <div style={{ marginTop: "30px" }}>
          <div className="button" id="blue">
            {dict["Registering..."][language]}
          </div>
        </div>
      );
    } else {
      icon_content = (
        <div style={{ marginTop: "30px" }}>
          <button className="button" id="blue" type="submit">
            {dict["Register"][language]}
          </button>
        </div>
      );
    }

    return (
      <main style={{ maxWidth: "90vw", margin: "auto" }}>
        <div className="margin_bottom_big">
          <img
            src={logo_final}
            height="70px"
            width="auto"
            alt="FORM MAPID"
            style={{ margin: "0px auto 0px auto" }}
          />
        </div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <TextFieldGroup
            placeholder={dict["User name"][language]}
            name="name"
            value={name.toLowerCase()}
            onChange={this.onChange}
            error={errors.name}
          />
          <br />
          <TextFieldGroup
            type="email"
            placeholder={dict["Email"][language]}
            name="email"
            id="email"
            value={email}
            onChange={this.onChange}
            error={errors.email}
          />
          <br />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Password"][language]}
            name="password"
            id="password"
            value={password}
            onChange={this.onChange}
            error={errors.password}
            toggleShowPassword={this.toggleShowPassword}
          />
          <br />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Confirm password"][language]}
            name="password2"
            id="password2"
            value={password2}
            onChange={this.onChange}
            error={errors.password2}
            toggleShowPassword={this.toggleShowPassword}
          />
          <br />
          <SelectField
            list_array={industry_list_sorted}
            name="industry"
            id="industry"
            value={industry}
            onChange={this.onChange}
            error={errors.industry}
            placeholder={dict["industry"][language]}
          />
          {icon_content}
        </form>
        <style>{`.nav_bottom{ display:none;}`}</style>
        <div className="container_light">Versi: {version}</div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Register);
