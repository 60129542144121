import React, { Component } from "react";

//General Function
import dict from "../../validation/dict.json";
import Modal from "../common/Modal";
import uuid from "../../validation/uuid";
import history from "../../actions/history";

import { pushFormOffline } from "../../actions/formActions";
import { connect } from "react-redux";

class CLONE_BUTTON extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_clone: false,
    };
  }

  toggle_modal_clone = () => {
    const { modal_clone } = this.state;
    this.setState({
      modal_clone: !modal_clone,
    });
  };

  on_push_offline = (is_reset) => {
    const { id_offline } = this.props;
    const form_list_offline = localStorage.form_list_offline
      ? JSON.parse(localStorage.form_list_offline)
      : [];

    if (is_reset) {
      let new_form = form_list_offline.find(
        (form) => form?.geo_layer_wo_geojson?.id_offline === id_offline
      );
      new_form.geo_layer_wo_geojson.id_offline = uuid();
      new_form.geo_layer_wo_geojson.fields =
        new_form.geo_layer_wo_geojson.fields.map((field) => {
          field.value = "";
          return field;
        });
      const { geo_layer_wo_geojson } = new_form;
      this.props.pushFormOffline(geo_layer_wo_geojson);
    } else {
      let new_form = form_list_offline.find(
        (form) => form?.geo_layer_wo_geojson?.id_offline === id_offline
      );
      new_form.geo_layer_wo_geojson.id_offline = uuid();
      const { geo_layer_wo_geojson, latitude, longitude } = new_form;
      this.props.pushFormOffline(geo_layer_wo_geojson, { latitude, longitude });
    }
    history.push("/menu/offline");
    this.toggle_modal_clone();
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //state
    const { modal_clone } = this.state;

    const modal_clone_content = modal_clone && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_clone}
        onClose={this.toggle_modal_clone}
      >
        <main className="box-body" style={{ textAlign: "center" }}>
          <header>
            <label className="gentle_header">
              {dict["Clone survey"][language]}
            </label>
          </header>
          <section>
            {
              dict["Clone surveys to do offline surveys at new location"][
                language
              ]
            }
          </section>
          <footer
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              onClick={() => this.on_push_offline(true)}
              className="button_standard"
              id="blue"
            >
              {dict["Reset the data"][language]}
            </button>
            <button
              onClick={() => this.on_push_offline(false)}
              className="button_standard"
              id="blue"
            >
              {dict["Keep the data"][language]}
            </button>
          </footer>
        </main>
      </Modal>
    );

    return (
      <main>
        <button
          onClick={this.toggle_modal_clone}
          className="button_standard"
          id="blue"
        >
          {dict["Clone this survey"][language]}
        </button>
        {modal_clone_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  pushFormOffline,
})(CLONE_BUTTON);
