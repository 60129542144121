import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import pic_static from "../../img/profile_pic_comment.svg";

class UserList extends Component {
  state = {};

  render() {
    const { users } = this.props;
    const itemContent = (
      <div>
        {users.map((list, idx) => {
          const { _id, name, full_name, profile_picture } = list.user;
          return (
            <div
              key={idx}
              style={{
                textAlign: "left",
                marginBottom: "10px",
                cursor: "default",
              }}
            >
              <div
                alt={_id}
                className={`photo_${_id}`}
                samesite="None"
                secure="true"
              />
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginLeft: "10px",
                  fontSize: "15px",
                }}
              >
                {full_name && full_name !== "" && full_name !== "-"
                  ? full_name
                  : name}
              </div>
              <style>
                {`.photo_${_id}{
              background: url(${
                profile_picture && profile_picture.url_compressed
                  ? profile_picture.url_compressed
                  : profile_picture &&
                    profile_picture.url &&
                    profile_picture.url !==
                      "https://s3-us-west-2.amazonaws.com/geomapid-assets/astronot.png" &&
                    profile_picture.url !== "-" &&
                    profile_picture.url !== "default"
                  ? profile_picture.url
                  : pic_static
              }) no-repeat center center/ cover;
              background-color: #c1c1c1;
              height:40px;
              width:40px;
              border-radius: 50%;
              border: 2px solid #2196f3;
              display: inline-block;
              align: center;
              vertical-align: middle;
}`}
              </style>
            </div>
          );
        })}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}
UserList.propTypes = {
  auth: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});
export default connect(mapStateToProps, {})(UserList);
