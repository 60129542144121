/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import Modal from "../common/Modal";
import MapPembanding from "./MapPembanding";
import DataList from "./DataList";

/*REDUX FUNCTION*/
import { get_data_pembanding } from "../../actions/formActions";

/*PICTURE ASSET*/

/*GENERAL*/

class InputDataPembanding extends Component {
  state = {
    modal_success: false,
  };

  componentDidMount() {
    const layer_id = this?.props?.match?.params?.layer_id;
    const feature_key = this?.props?.match?.params?.feature_key;
    const params = {
      layer_id,
      feature_key,
    };
    this.props.get_data_pembanding(params);
  }

  toggle_success = () => {
    this.setState({ modal_success: !this.state.modal_success });
  };

  render() {
    //local storage

    //local state
    const { modal_success } = this.state;

    //global props

    //content
    const modal_success_content = modal_success && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_success}
        onClose={this.toggle_success}
      >
        <div className="box-body">
          <h1 className="text_header">Berhasil submit data pembanding</h1>
        </div>
      </Modal>
    );

    return (
      <main className="main_container">
        {modal_success_content}
        <style>{`.nav_bottom{ display:none;}`}</style>
        <section className="container_white">
          <h1 className="text_big">
            Pilih atau survei data pembanding (3 titik)
          </h1>
          <p className="text_inferior">
            Anda wajib memilih 3 data pembanding untuk validasi nilai agunan
            yang sudah Anda survey
          </p>
          <Link className="button" id="blue" to="/">
            ⮌ Kembali
          </Link>
        </section>

        <br />
        <MapPembanding />
        <br />
        <DataList
          feature_key={this?.props?.match?.params?.feature_key}
          toggle_success={this.toggle_success}
        />
        <Helmet>
          <title>Submit Data Pembanding</title>
          <meta name="description" content="Submit Data Pembanding" />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, { get_data_pembanding })(
  InputDataPembanding
);
