const generate_color_step = function (array_name, array_color, text) {
  let result = [],
    i,
    l = Math.min(array_name.length, array_color.length),
    circle_color;
  for (i = 0; i < l; i++) {
    result.push(array_name[i], array_color[i]);
  }
  result.push(...array_name.slice(l), ...array_color.slice(l));
  circle_color = ["interpolate", ["linear"], ["to-number", ["get", text]]];
  circle_color = circle_color.concat(result).filter((e) => e !== undefined);
  return circle_color;
};
export default generate_color_step;
