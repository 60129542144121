/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import centroid from "@turf/centroid";

/*PERSONAL COMPONENT*/
import Title from "../form/Title";
import TableOverlay from "../form/TableOverlay";
import NotePembanding from "../data_pembanding/NotePembanding";
import MapForm from "../universal_form_sub_components/MapForm";

/*REDUX FUNCTION*/
import {
  get_feature,
  getFieldsPrivate,
  set_value,
} from "../../actions/formActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../validation/dict.json";
import history from "../../actions/history";

class Step2 extends Component {
  geocoderContainerRef = React.createRef();
  _map = React.createRef();
  state = {
    isFullMap: false,
  };

  componentDidMount = async () => {
    const { geo_layer_wo_geojson } = this.props.form;
    const feature_key = this?.props?.match?.params?.feature_key || "";
    const features = geo_layer_wo_geojson?.geojson?.features || [];

    if (features.length === 1) {
      const feature = features.find((f) => f.key === feature_key);

      const long = feature?.geometry?.coordinates?.[0];
      const lat = feature?.geometry?.coordinates?.[1];

      if (long && lat) {
        this.props.set_value("latitude", lat);
        this.props.set_value("longitude", long);
        this.props.set_value("is_location_exist", true);
      }
    } else if (
      this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.length > 0
    ) {
      const centroid_value = centroid(
        this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.[0]?.geojson
      );

      const long = centroid_value?.geometry?.coordinates?.[0];
      const lat = centroid_value?.geometry?.coordinates?.[1];

      this.props.set_value("longitude", long);
      this.props.set_value("latitude", lat);
      this.props.set_value("is_location_exist", true);
    } else {
    }

    const search = window?.location?.search?.substring(1);
    let object_param = {};
    let apraisal_id;
    if (search) {
      object_param = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );

      apraisal_id = object_param?.apraisal_id;
    }

    const link = this?.props?.match?.params?.link;

    if (link !== this?.props?.form?.geo_layer_wo_geojson?._id) {
      if (feature_key) {
        const params = {
          geo_layer_link: link,
          feature_key,
          apraisal_id,
          step: "step_2",
        };
        await this.props.get_feature(params);
      } else {
        const params = {
          geo_layer_link: link,
          apraisal_id,
        };
        await this.props.getFieldsPrivate(params);
      }
    }

    this.redirect_step_3_if_not_point();
  };

  redirect_step_3_if_not_point = () => {
    const { geo_layer_wo_geojson } = this.props.form;
    const link = this?.props?.match?.params?.link || "";
    const feature_key = this?.props?.match?.params?.feature_key || "";
    const { type } = geo_layer_wo_geojson;
    if (type !== "Point" && type !== "MultiPoint") {
      history.push(`/${link}/step_3/${feature_key}`);
    }
  };

  componentDidUpdate(prevProps) {
    //kemungkinan update 1: user baru saja login karena user mengakses link dalam kondisi belum login maka akses ulang data
    if (
      this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated &&
      this.props.auth.isAuthenticated
    ) {
      this.setState({
        full_name: this?.props?.auth?.user?.full_name || "",
        city: this?.props?.auth?.user?.city || "",
        phone_number_state: this?.props?.auth?.user?.phone_number || "62",
      });

      const link = this?.props?.match?.params?.link;
      const feature_key = this?.props?.match?.params?.feature_key || "";

      if (feature_key) {
        const params = {
          geo_layer_link: link,
          feature_key,
          step: "step_2",
        };
        this.props.get_feature(params);
      } else {
        this.props.getFieldsPrivate(link);
      }
    }

    //ada update data yang awalnya tidak ada data kemudian baru saja di load, maka lakukan konversi-konversi dari database ke state yang diperlukan
    if (
      this?.props?.form?.geo_layer_wo_geojson?._id !==
      prevProps?.form?.geo_layer_wo_geojson?._id
    ) {
      const { geo_layer_wo_geojson } = this.props.form;
      const feature_key = this?.props?.match?.params?.feature_key || "";
      const features = geo_layer_wo_geojson?.geojson?.features || [];

      if (features.length === 1) {
        const feature = features.find((f) => f.key === feature_key);

        const long = feature?.geometry?.coordinates?.[0];
        const lat = feature?.geometry?.coordinates?.[1];

        if (long && lat) {
          this.props.set_value("latitude", lat);
          this.props.set_value("longitude", long);
          this.props.set_value("is_location_exist", true);
        }
      } else if (
        this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.length > 0
      ) {
        const centroid_value = centroid(
          this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.[0]?.geojson
        );

        const long = centroid_value?.geometry?.coordinates?.[0];
        const lat = centroid_value?.geometry?.coordinates?.[1];

        this.props.set_value("longitude", long);
        this.props.set_value("latitude", lat);
        this.props.set_value("is_location_exist", true);
      } else {
      }
    }
  }

  toggle_overlay_visibility_status = () => {
    const { overlay_visibility_status } = this.props.properties;
    this.props.set_value_properties({
      key: "overlay_visibility_status",
      value: !overlay_visibility_status,
    });
  };

  onGetLocation = () => {
    this.setState({ locationLoading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      return null;
    }
  };

  /**
   * mendapakan geojson feature poligon dimana titik berada
   *
   * mendapatkan nama poligon/nama area/nama administrasi dimana titik berada/dimana gps berada
   */
  set_polygon_feature = ({ latitude, longitude }) => {
    const layer_attach =
      this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.[0];
    if (
      layer_attach?.type === "Polygon" ||
      layer_attach?.type === "MultiPolygon"
    ) {
      if (layer_attach?.geojson?.features?.length) {
        const fields_polygon = layer_attach?.fields.filter(
          (f) => !f.isStyle && !f.isHide
        );

        const geojson_polygon = layer_attach?.geojson;
        const geojson_point = {
          type: "Point",
          coordinates: [longitude, latitude],
        };

        const glookup = new GeoJsonGeometriesLookup(geojson_polygon);

        const geojson_polygon_filtered = glookup.getContainers(geojson_point);

        if (geojson_polygon_filtered?.features?.length > 0) {
          this.props.set_value(
            "geojson_polygon_filtered",
            geojson_polygon_filtered
          );
          this.props.set_value("fields_polygon", fields_polygon);
        }
      }
    }
  };

  showPosition = (position) => {
    const latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    const longitude = Number(parseFloat(position.coords.longitude).toFixed(8));

    this.props.set_value("latitude", latitude);
    this.props.set_value("longitude", longitude);

    this.set_polygon_feature({ latitude, longitude });
    this.props.set_value("is_location_exist", true);

    //
    const accuracy = parseInt(position.coords.accuracy);
    this.setState({
      accuracy,
      locationStatus: true,
      locationLoading: false,
    });
  };

  handleFullMap = () => {
    this.setState({
      isFullMap: !this.state.isFullMap,
    });
  };

  set_latitude = (value) => {
    if (value >= -90 && value <= 90) {
      this.props.set_value("latitude", value);
    }
  };

  set_longitude = (value) => {
    if (value >= -180 && value <= 180) {
      this.props.set_value("longitude", value);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    const search = window?.location?.search?.substring(1);

    //local state
    const { locationStatus, accuracy, locationLoading, isFullMap } = this.state;
    const feature_key = this?.props?.match?.params?.feature_key || "";

    //global props
    const {
      geo_layer_wo_geojson,
      fields_section,
      fields_no_section,
      is_location_exist,
    } = this.props.form;
    const { overlay_visibility_status } = this.props.properties;

    /*
    kemungkinan-kemungkinan link step3

    1. `/${geo_layer_wo_geojson?.link}/step_3`
    2. `/${geo_layer_wo_geojson?.link}/step_3?page=0`
    3. `/${geo_layer_wo_geojson?.link}/step_3?page=1`
    4. `/${geo_layer_wo_geojson?.link}/step_3/feature_key`
    5. `/${geo_layer_wo_geojson?.link}/step_3/feature_key?page=0`
    6. `/${geo_layer_wo_geojson?.link}/step_3/feature_key?page=1`
    */

    const item_feature_key = feature_key ? `/${feature_key}` : "";
    let item_page = "";

    if (fields_section.length > 0) {
      if (fields_no_section.length > 0) {
        item_page = "?page=0";
      } else {
        item_page = "?page=1";
      }
    } else {
      item_page = "";
    }

    let search_final = "";

    if (search && search !== undefined) {
      if (item_page) {
        search_final = `&${search}`;
      } else {
        search_final = `?${search}`;
      }
    }

    const link_step_3 =
      `/${geo_layer_wo_geojson?.link}/step_3` +
      item_feature_key +
      item_page +
      search_final;

    const isLocationChangeable = geo_layer_wo_geojson.isLocationChangeable;
    const status = geo_layer_wo_geojson?.formStatus?.status;

    let geolocate_button;

    if (locationLoading) {
      geolocate_button = (
        <div className="button">{dict?.["Mencari"]?.[language]}...</div>
      );
    } else {
      geolocate_button = (
        <div className="button" onClick={this.onGetLocation}>
          {locationStatus
            ? dict?.["Perbarui lokasi"]?.[language]
            : dict?.["Aktifkan lokasi"]?.[language]}
        </div>
      );
    }

    /*
    kemungkinan 1
    sudah ada feature_key, langsung lolos apapun kondisinya karena bisa jadi tidak ingin mengedit lokasi titik

    kemungkinan 2
    peta tidak boleh digeser & gps sudah diaktifkan

    kemungkinan 3
    peta boleh digeser & (peta sudah digeser || gps sudah diaktifkan)
    

    kemungkinan lainnya
    disable button
    */

    let button_content;
    const button_enable = (
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <Link className="button_standard" to={link_step_3}>
          {dict?.["Lanjut"]?.[language]}
        </Link>
      </div>
    );
    const button_disable = (
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <button className="button_standard" id="grey">
          {dict?.["Lanjut"]?.[language]}
        </button>
      </div>
    );

    if (feature_key) {
      button_content = button_enable;
    } else if (isLocationChangeable && is_location_exist) {
      button_content = button_enable;
    } else if (!isLocationChangeable && locationStatus) {
      button_content = button_enable;
    } else {
      button_content = button_disable;
    }

    const content = geo_layer_wo_geojson?._id ? (
      <main>
        <section
          className="container_light"
          id="white_background"
          style={{
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <table style={{ width: "100%", textAlign: "left" }}>
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  {locationStatus
                    ? dict?.["lokasi GPS didapatkan"]?.[language]
                    : dict?.["belum klik lokasi"]?.[language]}
                </td>
              </tr>
              <tr>
                <td>{dict?.["Akurasi"]?.[language]}</td>
                <td>
                  {locationStatus && accuracy
                    ? `${accuracy} meter`
                    : dict?.["belum klik lokasi"]?.[language]}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="margin_bottom margin_top">{geolocate_button}</div>
          {geo_layer_wo_geojson?.layer_form_list?.length > 0 && (
            <button
              className="button"
              onClick={this.toggle_overlay_visibility_status}
            >
              {overlay_visibility_status
                ? dict["Hide overlay data"][language]
                : dict["Show overlay data"][language]}
            </button>
          )}
        </section>

        <TableOverlay />

        <MapForm
          set_polygon_feature={this.set_polygon_feature}
          locationStatus={locationStatus}
          isFullMap={isFullMap}
          handleFullMap={this.handleFullMap}
        />

        <section style={{ textAlign: "center", marginTop: "10px" }}>
          {isLocationChangeable
            ? dict?.["Administrator survei membolehkan titik digeser manual"]?.[
                language
              ]
            : dict?.[
                "Administrator survei tidak membolehkan titik digeser, tombol lanjut akan muncul jika tombol Aktifkan Lokasi telah ditekan dan pengambilan lokasi GPS diijinkan"
              ]?.[language]}
        </section>

        <section className={isFullMap ? "button_full_map" : ""}>
          {button_content}
        </section>
      </main>
    ) : null;

    return (
      <div className="main_container">
        <Title status={status} step={2} />
        <NotePembanding />
        {content}
        <style>{`.nav_bottom{ display:none;}`}</style>
        <Helmet>
          <title>
            {geo_layer_wo_geojson?.name
              ? geo_layer_wo_geojson?.name
              : "MAPID FORM"}
          </title>
          <meta
            name="description"
            content={
              geo_layer_wo_geojson?.name
                ? geo_layer_wo_geojson?.name
                : "MAPID FORM"
            }
          />
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
  map: state.map,
});

export default connect(mapStateToProps, {
  get_feature,
  set_value,
  getFieldsPrivate,
  set_value_properties,
})(Step2);
