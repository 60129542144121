//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function
import history from "../../actions/history";

class Home extends Component {
  state = {};

  componentDidMount() {
    history.push(`/menu/login`);
  }

  render() {
    return <div>Home</div>;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Home);
