/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../validation/dict.json";

class CellReturnValue extends Component {
  state = {};

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const value = this.props.value;
    const field = this.props.field;
    const feature = this.props.feature;

    let content = <>{value}</>;

    switch (field.type) {
      case "table":
        content = "Table";
        break;

      case "text":
        content = <div>{value}</div>;
        break;

      case "jarak_apraisal":
        content = <div>{value} meter</div>;
        break;

      case "nilai_pembanding":
        content = (
          <b>
            Rp.
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(value)}
          </b>
        );
        break;

      case "textarea":
        content = (
          <div
            // className="badge_normal"
            style={{
              fontSize: "13px",
              overflowX: "none",
            }}
          >
            <button onClick={this.props.handlePreviewText.bind(this, value)}>
              {value}
            </button>
          </div>
        );
        break;

      case "number":
        content = (
          <div style={{ textAlign: "right" }}>
            {new Intl.NumberFormat("en-IN", {
              style: "decimal",
            }).format(value)}
          </div>
        );
        break;

      case "slider":
        content = (
          <div style={{ textAlign: "right" }}>
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(value)}
          </div>
        );
        break;

      case "currency":
        content = (
          <div style={{ textAlign: "right" }}>
            {new Intl.NumberFormat("id-ID", {
              style: "decimal",
            }).format(value)}
          </div>
        );
        break;

      case "year":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "date":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "time":
        content = <div style={{ textAlign: "right" }}>{value}</div>;
        break;

      case "link":
        let link;
        if (value?.includes("http")) {
          link = value;
        } else {
          link = `https://${value}`;
        }
        content = (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            // style={{ maxWidth: "400px" }}
          >
            {value}
          </a>
        );
        break;

      case "document":
        content = value ? (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className="button_very_small"
            id="blue"
          >
            {dict["Open file"][language]}
          </a>
        ) : (
          <div className="button_very_small" id="blue">
            {dict["No files"][language]}
          </div>
        );
        break;

      case "image":
        content = value ? (
          <img
            onClick={(params) => {
              if (params.target.src) {
                this.props.handlePreviewImage(params.target.src);
                this.props.toggleImagePreview();
              }
            }}
            alt="GEO MAPID"
            style={{
              maxWidth: "100px",
              maxHeight: "100px",
              cursor: "pointer",
            }}
            src={value}
          />
        ) : (
          <div>No image</div>
        );
        break;

      case "qrcode":
        content = `QR Code: ${value}`;
        break;

      case "driver_tracking":
        if (value) {
          const driver = JSON.parse(value);
          content = (
            <div style={{}}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    borderRadius: "50%",
                    width: "3rem",
                    height: "3rem",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundImage: `url('${driver.image}')`,
                  }}
                ></div>
                <div style={{ marginLeft: "4px" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "1rem",
                      marginBottom: "4px",
                    }}
                  >
                    {driver.name}
                  </span>
                  <br />
                  <span>{driver.phone}</span>
                </div>
              </div>
              <div>
                <button
                  className="buttonSimple2"
                  onClick={() => this.props.deleteDriver(feature?.key)}
                >
                  Hapus Pengemudi
                </button>
              </div>
            </div>
          );
        } else {
          content = <div>-</div>;
        }
        break;

      default:
        content = (
          <div>
            <div>{value}</div>
          </div>
        );
        break;
    }

    return <>{content}</>;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CellReturnValue);
