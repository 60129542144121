//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

//Personal Component
import SpinnerLogo from "../common/SpinnerLogo";
import Modal from "../common/Modal";
import UserList from "./UserList";
import LayerDetail from "./LayerDetail";

//Redux function
import { getNotifications } from "../../actions/formActions";

//Picture Asset

//General Function
import isEmpty from "../../validation/is-empty";

class Notifications extends Component {
  state = {
    modalListUser: false,
    modalLayerDetail: false,
    users: [],
    geo_layer: {},
  };
  toggleListUser = (users) => {
    this.setState({ modalListUser: !this.state.modalListUser }, () => {
      if (!isEmpty(users)) {
        this.setState({ users });
      }
    });
  };
  toggleLayerDetail = (geo_layer) => {
    this.setState({ modalLayerDetail: !this.state.modalLayerDetail }, () => {
      if (!isEmpty(geo_layer)) {
        this.setState({ geo_layer });
      }
    });
  };
  componentDidMount() {
    this.props.getNotifications();
  }
  render() {
    const { notifications, loadingDetail } = this.props.form;
    const notification_comment = notifications.filter(
      (notif) => notif.type === "layer_comment"
    );
    const { user } = this.props.auth;
    const { modalListUser, modalLayerDetail, users, geo_layer } = this.state;
    let itemContent;
    if (loadingDetail && isEmpty(notifications)) {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (notifications.length === 0) {
      itemContent = (
        <div className="container_light">Kamu belum memiliki notifikasi</div>
      );
    } else {
      const notifications_filtered = notification_comment.filter(
        (notification) => notification.gis_layers[0].gis_layer
      );
      itemContent = (
        <main>
          {notifications_filtered.map((notification, idx) => {
            const { _id, users, gis_layers, date } = notification;
            let notif_content;
            const users_filtered = users.filter(
              (user_element) => user_element.user._id !== user._id
            );
            const last_user = users_filtered[users_filtered.length - 1]?.user;
            const first_user_name = last_user?.full_name
              ? last_user?.full_name
              : last_user?.name
              ? last_user?.name
              : "Anda";
            let other_user;
            if (users_filtered.length > 1) {
              other_user = (
                <div className="notif_item">
                  dan{" "}
                  <div
                    className="notif_user_name"
                    onClick={this.toggleListUser.bind(this, users_filtered)}
                  >
                    {users_filtered.length - 1} orang lainnya
                  </div>
                </div>
              );
            }
            const gis_layer = gis_layers[0].gis_layer;
            notif_content = (
              <div>
                <div
                  className="notif_user_name"
                  onClick={this.toggleListUser.bind(this, users_filtered)}
                >
                  {first_user_name}
                </div>{" "}
                {other_user} berkomentar pada{" "}
                <div
                  className="notif_layer"
                  onClick={this.toggleLayerDetail.bind(this, gis_layer)}
                >
                  {gis_layer?.name ? gis_layer?.name : "(Layer telah dihapus)"}
                </div>
              </div>
            );
            return (
              <div className="generic_container" key={_id}>
                {notif_content}
                <div style={{ fontSize: "10px" }}>{moment(date).from()}</div>
              </div>
            );
          })}
        </main>
      );
    }
    const modalListUserContent = modalListUser && (
      <Modal
        modalSize="small"
        id="modal_user_list"
        isOpen={modalListUser}
        onClose={this.toggleListUser}
      >
        <div className="box-body">
          <UserList users={users} />
        </div>
      </Modal>
    );
    const modalLayerDetailContent = modalLayerDetail && (
      <Modal
        modalSize="small"
        id="modal_layer"
        isOpen={modalLayerDetail}
        onClose={this.toggleLayerDetail}
      >
        <div className="box-body">
          <LayerDetail layer_detail={geo_layer} />
        </div>
      </Modal>
    );
    return (
      <div className="main_container">
        <div className="text_bold">Notifikasi</div>
        {itemContent}
        {modalListUserContent}
        {modalLayerDetailContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});
export default connect(mapStateToProps, { getNotifications })(Notifications);
