//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Marker } from "@urbica/react-map-gl";

//Personal Component

//Redux function

//Picture Asset

//General Function

const style_point = {
  width: "7px",
  height: "7px",
  borderRadius: "2em",
  background: "#e31b25",
  borderStyle: "solid",
  borderColor: "#fff",
  borderWidth: 2,
};

class LayerTitikUtama extends Component {
  state = {};
  render() {
    //local storage

    //local state

    //global props
    const { data_pembanding } = this.props.form;

    //content
    const longitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[0];
    const latitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[1];

    let content;

    content = longitude && latitude && (
      <Marker
        longitude={longitude}
        latitude={latitude}
        onDragEnd={this.onDragEnd}
        draggable={false}
      >
        <div style={style_point}></div>
      </Marker>
    );
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {})(LayerTitikUtama);
