// personal components
import { gometry_to_area_or_length } from "../field_function/geometry_to_area_or_length";
import { comma_every_3_digits } from "../field_function/format_number";
import { calculate_formula } from "../field_function/formula_validation";
import { calculate_conditional_statement } from "../field_function/conditional_statement_validation";

export const data_types_that_must_be_calculate_before_render = [
  "simple_referring",
  "calculate_dimension",
  "math_operators",
  "2d_referring",
  "conditional_statement",
];

export const calculate_without_convert = function (features, fields) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );
  const data_list = features.map((feature, index) => {
    const calculated_feature = calculate_by_data_type(
      feature,
      fieldKeysThatNeedToCalculateBeforeRender
    );
    return calculated_feature;
  });
  return data_list;
};

export const calculate_by_data_type = function (
  feature,
  fieldKeysThatNeedToCalculateBeforeRender
) {
  try {
    fieldKeysThatNeedToCalculateBeforeRender.forEach((field) => {
      if (field.type === "simple_referring") {
        const { reference_list, reference_key } = field;
        const properties = feature.properties;
        const value = reference_list.find(
          (ref) =>
            ref?.name?.toLowerCase() ===
            properties[reference_key]?.toLowerCase()
        )?.["value"];
        if (typeof value === "number") {
          feature.properties[field.key] = +value;
          feature.type = "number";
        } else {
          feature.properties[field.key] = value;
          feature.type = "string";
        }
      } else if (field.type === "calculate_dimension") {
        const { geometry } = feature;
        const { value } = gometry_to_area_or_length(geometry, field.unit);
        const final_value = comma_every_3_digits(value);
        feature.properties[field.key] = final_value;
        feature.type = "number";
      } else if (field.type === "math_operators") {
        const value = calculate_formula(field.formula, feature.properties);
        if (field.decimal_digits) {
          if (isNaN(value) || value === "") {
            feature.properties[field.key] = value;
          } else {
            feature.properties[field.key] = parseFloat(value).toFixed(
              +field.decimal_digits
            );
          }
        } else {
          feature.properties[field.key] = value;
        }
        feature.type = "number";
      } else if (field.type === "2d_referring") {
        const index_row_name = field.reference_list_2d?.row_name;
        const index_column_name = field.reference_list_2d?.column_name;
        let value = "";
        if (feature.properties?.[index_column_name]) {
          const selected_row = field.reference_list_2d?.features?.find(
            (row) =>
              String(row?.["index_name"]) ===
              String(feature.properties?.[index_row_name])
          );
          value = selected_row?.[feature.properties?.[index_column_name]];
          feature.properties[field.key] = value;
        }
      } else if (field.type === "conditional_statement") {
        const value = calculate_conditional_statement(
          field.conditional_statement_list,
          feature.properties
        );
        feature.properties[field.key] = value;
      }
    });
    return feature;
  } catch (e) {}
};

export const calculate_row_before_render_for_viewer_only = function (
  features,
  fields
) {
  const fieldKeysThatNeedToCalculateBeforeRender = fields.filter((field) =>
    data_types_that_must_be_calculate_before_render.includes(field.type)
  );
  const data_list = features?.map((feature) => {
    const calculated_feature = calculate_by_data_type(
      feature,
      fieldKeysThatNeedToCalculateBeforeRender
    );
    return calculated_feature;
  });
  return data_list;
};

export const simple_convert_fields_to_headers = function (fields) {
  let headers = fields.map((column) => {
    if (column.name) {
      let result = {
        ...column,
        field: column.key,
        headerName: column.name,
      };
      return result;
    } else {
      return column;
    }
  });
  return headers;
};

export const pack_the_properties_data_to_use_api_edit = function (
  layer_id,
  feature
) {
  return {
    geo_layer_id: layer_id,
    feature_key: feature.row.key,
    properties_key: feature.field,
    properties_value: feature.value,
  };
};
