import axios from "axios";
//import his from "./history";
import isEmpty from "../validation/is-empty";

import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET*/

//Get All Layer Type Point By User for FORM LIST
export const getListLayerTypePoint = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
      },
    };
    dispatch(setLoading("get_layer_point"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/get_layer_point`,
      config
    );
    dispatch({
      type: "GET_LAYER_LIST",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//1. Get Project List By Owner Id & By Group Members Id [NEW]
export const getProjectList = () => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid,
    },
  };
  dispatch(setLoading("get_list_new"));
  axios
    .get(SERVER_URL + `/projects/get_list_new`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_PROJECT_LIST",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//2. Get Project Detail By Link, use token or public in same API [NEW NEW]
export const getProjectDetailByLink = (link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage?.jwtTokenMapid,
    },
  };
  dispatch(setLoading("get_detail_by_link_new"));
  axios
    .get(SERVER_URL + `/projects/get_detail_by_link_new/${link}`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_PROJECT_DETAIL",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//3. Get Layers By link Project [NEW]
export const setProject = (geo_project) => async (dispatch) => {
  try {
    if (isEmpty(geo_project?.geo_layer_list)) {
      const config = {
        headers: {
          accesstoken: localStorage?.jwtTokenMapid,
        },
      };
      dispatch(setLoading("pushListLayerToProject"));
      const res = await axios.get(
        SERVER_URL + `/layers/get_list_by_link_project_new/${geo_project.link}`,
        config
      );
      dispatch(clearLoading());
      dispatch({
        type: "PUSH_LAYER_LIST",
        payload: {
          geo_project: geo_project,
          geo_layer_list: res.data,
        },
      });
    } else {
      dispatch({
        type: "GET_PROJECT_DETAIL",
        payload: geo_project,
      });
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//4. Get public form list
export const getPublicFormList = () => async (dispatch) => {
  try {
    dispatch(setLoading("public_form_list"));
    const res = await axios.get(SERVER_URL + `/layers/public_form_list`);
    dispatch({
      type: "GET_PUBLIC_FORM_LIST",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//5. Get paid form list
export const getPaidFormList = () => async (dispatch) => {
  try {
    dispatch(setLoading("paid_form_list"));
    const res = await axios.get(SERVER_URL + `/layers/paid_form_list`);
    dispatch({
      type: "GET_PAID_FORM_LIST",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//6. Get layer list by surveyor
export const getListLayerBySurveyor = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    dispatch(setLoading("get_list_layer_by_surveyor"));
    const res = await axios.get(
      SERVER_URL + `/layers_new/get_list_layer_by_surveyor`,
      config
    );
    dispatch({
      type: "GET_FORM_LIST_SURVEYOR",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*NON API*/
export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_PROJECT",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_PROJECT",
  };
};

export const setCloseFolder = (folder_id) => {
  return {
    type: "SET_CLOSE_FOLDER",
    payload: folder_id,
  };
};

export const setHeightFolders = (height_array) => {
  return {
    type: "SET_HEIGHT_FOLDERS",
    payload: height_array,
  };
};
