//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function
import history from "../../actions/history";

class Redirect extends Component {
  state = {};

  componentDidMount() {
    const link = this.props.match.params.link;
    history.push(`/${link}/step_1`);
  }

  render() {
    return (
      <div>
        Redirect
        <style>{`
        .nav_bottom{ display:none;}
     `}</style>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Redirect);
