//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import FormEditPaid from "../form/FormEditPaid";
import SpinnerLogo from "../common/SpinnerLogo";
// import IconBack from "../common/IconBack";
import Modal from "../common/Modal";

//Redux function
import { getListLayerBySurveyor } from "../../actions/projectActions";
import { setLayerWOgeojson } from "../../actions/formActions";
import {
  getBankData,
  addBank,
  getCurrentBankData,
  setCurrentBankData,
} from "../../actions/authActions";

//Picture Asset
import icon_check from "../../img/icon_check.svg";
import icon_revision_success from "../../img/icon_revision_success.svg";

//General Function
import dict from "../../validation/dict.json";
import isEqual from "../../validation/is-equal";
import Kabupaten from "../common/Bank_list.json";
import isEmpty from "../../validation/is-empty";
const e_money = ["GO-PAY", "OVO"];
const cities_array_pre = Kabupaten.sort();
const cities_array = e_money.concat(cities_array_pre);

class PaidList extends Component {
  state = {
    modalAddBank: false,
    banks_filtered: cities_array,
    type_bank: "GO-PAY",
    no_bank: "",
    name_bank: "",
    modalListBank: true,
    mode_bank: "list",
    modal_revision: false,
    modal_edit: false,
    feature: {},
  };

  componentDidMount() {
    this.props.getListLayerBySurveyor();
    this.props.getBankData();
    this.props.getCurrentBankData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.auth.bank_data, prevProps.auth.bank_data)) {
      this.setState({ mode_bank: "list" });
    }
    if (
      !isEqual(
        this.props.form.geo_layer_wo_geojson,
        prevProps.form.geo_layer_wo_geojson
      )
    ) {
      this.setState({ modal_edit: false });
    }
  }

  toggleEdit = (feature) => {
    this.setState({ modal_edit: !this.state.modal_edit });
    if (!!feature?.key) {
      this.setState({ feature });
    }
  };

  closeAllModal = () => {
    this.setState({ modal_edit: false, modal_revision: false });
  };

  submitSetBank = (bank_data) => {
    this.props.setCurrentBankData(bank_data);
  };

  setModeBank = (mode_bank) => {
    this.setState({ mode_bank });
  };

  activateModalListBank = () => {
    this.setState({ modalListBank: true });
  };

  inActivateModalListBank = () => {
    this.setState({ modalListBank: false });
  };

  toggleAddBank = () => {
    this.setState({ modalAddBank: !this.state.modalAddBank });
  };

  toggleRevision = (layer) => {
    this.setState({ modal_revision: !this.state.modal_revision });
    if (!!layer?._id) {
      this.props.setLayerWOgeojson({
        geo_layer: layer,
      });
    }
  };

  onClickLayer = (layer) => {
    this.props.setLayerWOgeojson({
      geo_layer: layer,
    });
  };

  onClickBank = (type_bank) => {
    this.setState({ type_bank });
  };

  handleFixBank = () => {
    const { banks_filtered, type_bank } = this.state;
    if (banks_filtered.length === 0) {
      this.setState({ banks_filtered: cities_array });
    } else if (
      !banks_filtered.includes(type_bank) &&
      !cities_array.includes(type_bank)
    ) {
      this.setState({
        type_bank: banks_filtered[0] ? banks_filtered[0] : "",
      });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (["type_bank"].includes(name)) {
      this.activateModalListBank();
      if (!value) {
        this.setState({
          [name]: value,
          banks_filtered: cities_array,
        });
      } else {
        const banks_filtered = cities_array.filter((type_bank) =>
          type_bank.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
          [name]: value,
          banks_filtered,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmitBank = (e) => {
    e.preventDefault();
    const { banks_filtered, no_bank, name_bank, type_bank } = this.state;
    let type_bank_final;
    if (!!no_bank && !!name_bank && !!type_bank) {
      if (!banks_filtered.includes(type_bank)) {
        type_bank_final = banks_filtered[0];
      } else {
        type_bank_final = type_bank;
      }
      const body = {
        no_bank,
        name_bank,
        type_bank: type_bank_final,
      };
      this.props.addBank(body);
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { status } = this.props.errors;
    const { geo_layer_wo_geojson } = this.props.form;
    const {
      modalAddBank,
      modalListBank,
      type_bank,
      banks_filtered,
      no_bank,
      name_bank,
      mode_bank,
      modal_revision,
      modal_edit,
      feature,
    } = this.state;
    const { bank_data, current_bank_data } = this.props.auth;
    const loading_user = this.props.auth.loading;
    const itemLoading_user = this.props.auth.itemLoading;
    const { form_list_surveyor, loading, itemLoading } = this.props.project;

    let content = <div className="container_light">-</div>;
    if (loading && itemLoading === "paid_form_list") {
      content = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (form_list_surveyor.length > 0) {
      content = (
        <div style={{ marginTop: "10px" }}>
          {form_list_surveyor.map((layer) => {
            const {
              _id,
              link,
              name,
              picture_url,
              geojson,
              // formPayment
            } = layer;
            // const { per_insentif } = formPayment;
            const features = geojson?.features ? geojson?.features : [];
            let features_success = [];
            let features_pending = [];
            // let features_length = 0;
            // let earning = 0;
            // let earning_dec = "";
            let features_rejected = [];
            if (features.length > 0) {
              features_success = features.filter((feature) =>
                ["success", "paid_off"].includes(feature?.formStatus?.status)
              );
              // features_length = features_success.length;
              // earning = features_length * per_insentif;
              // earning_dec = new Intl.NumberFormat("id-ID", {
              //   style: "decimal",
              // }).format(earning);
              features_rejected = features.filter((feature) =>
                ["rejected"].includes(feature?.formStatus?.status)
              );
              features_pending = features.filter((feature) =>
                ["pending"].includes(feature?.formStatus?.status)
              );
            }
            let status_form;
            switch (layer?.formStatus?.status) {
              case "close_recruit":
                status_form = "Menunggu klien melakukan pembayaran";
                break;
              case "success_payment":
                status_form = "Survei dimulai !";
                break;
              case "post_payment":
                status_form = "Survei telah selesai";
                break;
              default:
                status_form = "Status survei";
                break;
            }
            return (
              <div key={_id}>
                <div
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "20px",
                    cursor: "pointer",
                    borderRadius: "20px",
                    boxShadow: "0 0 6px #9d9d9d53",
                  }}
                >
                  <Link
                    onClick={this.onClickLayer.bind(this, layer)}
                    to={`/${link}`}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${picture_url})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundColor: "#dedede",
                        width: "100%",
                        paddingBottom: "25%",
                        borderRadius: "20px 20px 0px 0px",
                      }}
                    />
                  </Link>
                  <div style={{ padding: "15px" }}>
                    <div
                      className="text_medium"
                      style={{ maxHeight: "40px", overflowY: "hidden" }}
                    >
                      {name}
                    </div>
                    <section className="badge_normal">{status_form}</section>
                    <br />
                    <Link
                      onClick={this.onClickLayer.bind(this, layer)}
                      to={`/${link}`}
                      className="button_standard"
                      style={{ marginRight: "5px", marginTop: "5px" }}
                    >
                      Isi Survei
                    </Link>
                    {/* {!!earning && (
                      <div
                        onClick={this.toggleAddBank.bind(this, layer)}
                        className="button_standard"
                        id="green"
                        style={{ marginRight: "5px", marginTop: "5px" }}
                      >
                        Kamu dapat Rp {earning_dec} !
                      </div>
                    )} */}
                    {!!features_success.length && (
                      <div
                        className="button_standard"
                        id="green"
                        style={{ marginRight: "5px", marginTop: "5px" }}
                        onClick={this.toggleAddBank.bind(this, layer)}
                      >
                        {features_success.length} isian telah diverifikasi
                      </div>
                    )}
                    {!!features_rejected.length && (
                      <div
                        className="button_standard"
                        id="red"
                        style={{ marginRight: "5px", marginTop: "5px" }}
                        onClick={this.toggleRevision.bind(this, layer)}
                      >
                        {features_rejected.length} isian perlu revisi
                      </div>
                    )}
                    {!!features_pending.length && (
                      <div
                        className="button_standard"
                        id="grey"
                        style={{ marginRight: "5px", marginTop: "5px" }}
                      >
                        {features_pending.length} isian menunggu verifikasi
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    let button_content;
    button_content = (
      <button type="submit" className="button_block">
        Tambahkan bank
      </button>
    );
    if (loading_user && itemLoading_user === "addBank") {
      button_content = <div className="button_block">Menambahkan..</div>;
    } else if (!type_bank || !name_bank || !no_bank) {
      button_content = (
        <div className="button_block" id="grey">
          Tambahkan bank
        </div>
      );
    }
    const add_bank_content = (
      <form onSubmit={this.handleSubmitBank}>
        {bank_data.length > 0 && (
          <div
            onClick={this.setModeBank.bind(this, "list")}
            className="button_circle"
            id="blue_disable"
            style={{ cursor: "pointer" }}
          >
            <div className="button_circle_inside">x</div>
          </div>
        )}
        <div
          className="container_select_input"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="text_small">Bank</div>
          <input
            name="type_bank"
            id="type_bank"
            type="text"
            value={type_bank}
            onChange={this.handleChange}
            onBlur={this.handleFixBank}
            onFocus={this.activateModalListBank}
          />
          {modalListBank && (
            <div
              style={{
                height: "23vh",
                overflowY: "auto",
                marginTop: "20px",
              }}
            >
              {banks_filtered.map((bank_element, idx) => {
                return (
                  <div
                    onClick={this.onClickBank.bind(this, bank_element)}
                    key={idx}
                    className="text_medium container_select_content noselect"
                  >
                    {bank_element}
                    {bank_element === type_bank && (
                      <img
                        src={icon_check}
                        width="25px"
                        alt="check"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                    {!banks_filtered.includes(type_bank) && idx === 0 && (
                      <img
                        src={icon_check}
                        width="25px"
                        alt="check"
                        style={{ marginLeft: "10px" }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="container_select_input"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="text_small" style={{ marginTop: "10px" }}>
            {e_money.includes(type_bank) ? "Nomor handphone" : "Nomor rekening"}
          </div>
          <input
            type="number"
            placeholder="Nomor rekening"
            name="no_bank"
            id="no_bank"
            value={no_bank}
            onChange={this.handleChange}
            onFocus={this.inActivateModalListBank}
          />
          <div className="text_small" style={{ marginTop: "10px" }}>
            Atas nama pemilik
          </div>
          <input
            type="text"
            placeholder="Atas nama pemilik"
            name="name_bank"
            id="name_bank"
            value={name_bank}
            onChange={this.handleChange}
            onFocus={this.inActivateModalListBank}
          />
        </div>
        {button_content}
      </form>
    );
    const select_bank_content = (
      <div>
        {bank_data.map((bank_data, idx) => {
          const logic =
            current_bank_data.type_bank === bank_data.type_bank &&
            current_bank_data.no_bank === bank_data.no_bank;
          return (
            <div
              onClick={this.submitSetBank.bind(this, bank_data)}
              key={idx}
              className="text_medium container_select_content noselect"
            >
              {bank_data.type_bank.toUpperCase()}
              {"  "}
              {bank_data.name_bank.toUpperCase()}
              {logic && (
                <img
                  src={icon_check}
                  width="25px"
                  alt="check"
                  style={{ marginLeft: "10px" }}
                />
              )}
            </div>
          );
        })}
        <div
          onClick={this.setModeBank.bind(this, "add")}
          className="button_block"
          style={{ marginTop: "30px" }}
        >
          Tambahkan bank lain
        </div>
      </div>
    );
    let bank_content = select_bank_content;
    if (bank_data.length === 0 || mode_bank === "add") {
      bank_content = add_bank_content;
    }
    const modalAddBankContent = modalAddBank && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalAddBank}
        onClose={this.toggleAddBank}
      >
        <div className="box-body">
          <div style={{ minHeight: "60vh" }}>
            <div className="text_thin" style={{ marginBottom: "30px" }}>
              Selamat! Cairkan uang yang kamu dapatkan dengan memasukan nomor
              rekening bank atau e-money
            </div>
            {bank_content}
          </div>
        </div>
      </Modal>
    );
    let list_revision;
    if (!isEmpty(geo_layer_wo_geojson)) {
      const { geojson } = geo_layer_wo_geojson;
      const features = geojson?.features ? geojson.features : [];
      const features_rejected = features.filter((feature) =>
        ["rejected"].includes(feature?.formStatus?.status)
      );
      list_revision = (
        <table style={{ width: "100%" }}>
          <tbody>
            {features_rejected.map((feature, idx) => {
              const { formStatus } = feature;
              const fields = geo_layer_wo_geojson.fields;
              const field = fields.find(
                (f) => f.uuid === formStatus?.revision_list?.[0]?.field_uuid
              );
              return (
                <tr key={idx}>
                  {/* <td>{message}</td> */}
                  <td>{field?.name}</td>
                  <td>{formStatus?.revision_list?.[0]?.message}</td>
                  <td style={{ textAlign: "right" }}>
                    <div
                      onClick={this.toggleEdit.bind(this, feature)}
                      className="button_tight"
                      id="blue"
                    >
                      Submit revison
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    const modal_revision_content = modal_revision && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_revision}
        onClose={this.toggleRevision}
      >
        <div className="box-body">
          <div style={{ minHeight: "60vh" }}>
            <div className="text_thin" style={{ marginBottom: "30px" }}>
              Ada yang perlu direvisi nih
            </div>
            {list_revision}
          </div>
        </div>
      </Modal>
    );
    const success_content = (
      <div style={{ textAlign: "center" }}>
        <img alt="Revisi sukses" src={icon_revision_success} width="100%" />
        <div className="text_bold" style={{ marginTop: "30px" }}>
          Revisi Terkirim
        </div>
        <div className="text_medium">
          Tunggu pembuat survei memeriksa revisi data dari kamu, jika data sudah
          sesuai, kamu akan mendapatkan insentif
        </div>
        <div
          onClick={this.closeAllModal}
          className="button_standard"
          style={{ marginTop: "30px" }}
        >
          Tutup
        </div>
      </div>
    );
    const modal_edit_content = modal_edit && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_edit}
        onClose={this.toggleEdit}
      >
        <div className="box-body">
          <div style={{ minHeight: "60vh" }}>
            {!status ? <FormEditPaid feature={feature} /> : success_content}
          </div>
        </div>
      </Modal>
    );
    return (
      <main
        className="main_container margin_bottom"
        style={{ marginTop: "20px" }}
      >
        {modalAddBankContent}
        {modal_revision_content}
        {modal_edit_content}

        <p className="text_header">{dict["Paid Form"][language]}</p>

        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  form: state.form,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getListLayerBySurveyor,
  setLayerWOgeojson,
  getBankData,
  addBank,
  getCurrentBankData,
  setCurrentBankData,
})(PaidList);
