/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import UploadPicture from "../form/UploadPicture";
import UploadFile from "../form/UploadFile";
import TableParent from "../submit_form/TableParent";
import TableChild from "../submit_form/TableChild";
import TableInfinity from "../submit_form/TableInfinity";

/*REDUX FUNCTION*/
import {
  /*PARENT*/

  //1
  change_text,
  //2
  click_check,
  //3
  remove_check,
  //4
  select_other,
  //5
  change_phone_code,

  /*CHILD*/

  //1
  change_child,
  //2
  click_check_child,
  //3
  remove_check_child,
  //4
  select_other_child,
  //5
  change_phone_code_child,

  /*OTHER*/
  counting_poi,
  get_administrasi,

  // Counting Custom
  get_counting_custom,
} from "../../actions/formActions";
import {
  get_geocode_address,
  get_geocode_address_poi,
} from "../../actions/propertiesActions";

/*PICTURE ASSET*/
import icon_check_solid from "../../img/icon_check_solid.svg";
import icon_close from "../../img/icon_close.svg";

/*GENERAL*/
import isEmpty from "../../validation/is-empty";
import dict from "../../validation/dict.json";
import PhoneNumber from "../common/PhoneNumber.json";
import hidden_data_types from "../../validation/hidden_data_types.json"

/*NON IMPORT*/
const phone_number_list = PhoneNumber.sort();

class FieldsRender extends Component {
  state = {
    mode_rev: "all", //all || only_rev
  };

  on_get_geocode_address = (item) => {
    const { latitude, longitude } = this.props.form;
    const child_uuid = this.props.child_uuid;
    const { uuid } = item;
    const params = {
      long: longitude,
      lat: latitude,
      uuid,
      child_uuid,
    };

    this.props.get_geocode_address(params);
  };

  on_get_geocode_address_poi = (item) => {
    const { latitude, longitude } = this.props.form;
    const child_uuid = this.props.child_uuid;
    const { uuid } = item;
    const params = {
      long: longitude,
      lat: latitude,
      uuid,
      child_uuid,
    };
    this.props.get_geocode_address_poi(params);
  };

  on_change_file = (id, base_64_pack) => {
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const body = {
        uuid: id,
        value: base_64_pack,
      };
      this.props.change_text(body);
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const body = {
        parent_uuid,
        uuid: id,
        value: base_64_pack,
        child_uuid,
      };
      this.props.change_child(body);
    }
  };

  //1
  on_change = (e) => {
    e.preventDefault();
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        uuid: id,
        value,
      };
      this.props.change_text(body);
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        parent_uuid,
        uuid: id,
        value,
        child_uuid,
      };
      this.props.change_child(body);
    }
  };

  //2
  on_click_check = (item, type) => {
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const { value, uuid } = item;
      const body = {
        uuid,
        value,
      };
      this.props.click_check(body);
      if (type === "checklist") this.props.change_text({ uuid, value: "" });
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const { value, uuid } = item;
      const body = {
        parent_uuid,
        uuid,
        value,
        child_uuid,
      };
      this.props.click_check_child(body);
      if (type === "checklist") this.props.change_child({ ...body, value: "" });
    }
  };

  //3
  on_remove_check = (item, type) => {
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const { value, uuid } = item;
      const body = {
        uuid,
        value,
      };
      this.props.remove_check(body);
      if (type === "checklist") this.props.change_text({ uuid, value: "" });
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const { value, uuid } = item;
      const body = {
        parent_uuid,
        uuid,
        value,
        child_uuid,
      };
      this.props.remove_check_child(body);
      if (type === "checklist") this.props.change_child({ ...body, value: "" });
    }
  };

  //4
  on_change_selection = (e) => {
    e.preventDefault();
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        uuid: id,
        value,
      };
      this.props.change_text(body);
      let body_other = {};
      if (value !== "") {
        body_other = {
          uuid: id,
          value: false,
        };
      } else {
        body_other = {
          uuid: id,
          value: true,
        };
      }
      this.props.select_other(body_other);
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        parent_uuid,
        uuid: id,
        value,
        child_uuid,
      };
      this.props.change_child(body);
      let body_other = {};
      if (value !== "") {
        body_other = {
          parent_uuid,
          uuid: id,
          value: false,
          child_uuid,
        };
      } else {
        body_other = {
          parent_uuid,
          uuid: id,
          value: true,
          child_uuid,
        };
      }
      this.props.select_other_child(body_other);
    }
  };

  //5
  on_change_phone_code = (e) => {
    e.preventDefault();
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        uuid: id,
        value,
      };
      this.props.change_phone_code(body);
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const value = e.target.value;
      const id = e.target.id;
      const body = {
        parent_uuid,
        uuid: id,
        value,
        child_uuid,
      };
      this.props.change_phone_code_child(body);
    }
  };

  //6
  on_click_radio = (item) => {
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const { value, uuid } = item;
      const body = {
        uuid,
        value,
      };
      this.props.change_text(body);
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const { value, uuid } = item;
      const body = {
        parent_uuid,
        uuid,
        value,
        child_uuid,
      };
      this.props.change_child(body);
    }
  };

  //7
  handle_key_down(e, type) {
    const is_parent = this.props.is_parent; //true || false
    if (is_parent) {
      const value = e.target.value;
      const id = e.target.id;
      if (e.keyCode === 13 && value) {
        const body = {
          uuid: id,
          value,
        };
        this.props.click_check(body);
        if (type === "checklist")
          this.props.change_text({ uuid: id, value: "" });
      }
    } else {
      const field = this.props.field;
      const parent_uuid = field.uuid;
      const child_uuid = this.props.child_uuid;
      const value = e.target.value;
      const id = e.target.id;
      if (e.keyCode === 13 && value) {
        const body = {
          parent_uuid,
          uuid: id,
          value,
          child_uuid,
        };
        this.props.click_check_child(body);
        if (type === "checklist")
          this.props.change_child({ ...body, value: "" });
      }
    }
  }

  set_mode_rev = (mode_rev) => {
    this.setState({ mode_rev });
  };

  on_get_administrasi = (type, uuid) => {
    const { latitude, longitude } = this.props.form;
    const params = {
      mode: type,
      uuid,
      lat: latitude,
      long: longitude,
      child_uuid: this.props.child_uuid,
    };
    this.props.get_administrasi(params);
  };

  on_counting_poi = (poi_type, poi_rad, uuid) => {
    const { latitude, longitude } = this.props.form;
    const params = {
      poi_type,
      poi_rad,
      uuid,
      lat: latitude,
      long: longitude,
      child_uuid: this.props.child_uuid,
    };
    this.props.counting_poi(params);
  };

  on_counting(uuid) {
    const { latitude, longitude, geo_layer_wo_geojson } = this.props.form;
    const body = {
      geo_layer_id: geo_layer_wo_geojson._id,
      field_uuid: uuid,
      lat: latitude,
      long: longitude,
    };
    this.props.get_counting_custom(body);
  }

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { mode_rev } = this.state;

    //global props
    const {
      loading,
      itemLoading,
      image_field,
      geo_layer_wo_geojson,
      uuid_process,
    } = this.props.form;
    const { feature_key } = this.props.properties;

    //content
    const fields_original = geo_layer_wo_geojson?.fields || [];
    let fields_final = [];

    const is_parent = this.props.is_parent; //true || false

    let revision_list = [];
    let child_array = geo_layer_wo_geojson?.child_array || [];

    if (is_parent) {
      /*VARIABLE YANG DIGUNAKAN JIKA MODE PARENT*/
      let fields_parent = this.props.fields_parent;
      if (feature_key) {
        const features = geo_layer_wo_geojson?.geojson?.features || [];
        const feature = features.find((item) => item.key === feature_key);
        revision_list = feature?.formStatus?.revision_list || [];
      }
      fields_parent = fields_parent.filter(f => !hidden_data_types.includes(f?.type)).map((field) => {
        const rev_object = revision_list.find(
          (item) => item.field_uuid === field.uuid
        );
        field.rev_object = rev_object;
        return field;
      });
      if (mode_rev === "only_rev") {
        fields_parent = fields_parent.filter(
          (item) => item?.rev_object !== undefined
        );
      }
      fields_final = fields_parent;
    } else {
      /*VARIABLE YANG DIGUNAKAN JIKA MODE BUKAN PARENT*/
      let fields_current = this.props.fields || [];

      fields_current = fields_current.filter(
        (f) =>
          !f.isHide &&
          !f.isStyle &&
          !hidden_data_types.includes(f?.type)
      );
      fields_final = fields_current;
    }

    return (
      <main>
        {is_parent && (
          <>
            <br />
            {revision_list.length > 0 && (
              <section>
                <button
                  className="button margin"
                  id={mode_rev === "all" ? "black_absolute" : "outline_black"}
                  onClick={this.set_mode_rev.bind(this, "all")}
                >
                  Semua pertanyaan
                </button>
                <button
                  className="button"
                  id={
                    mode_rev === "only_rev" ? "black_absolute" : "outline_black"
                  }
                  onClick={this.set_mode_rev.bind(this, "only_rev")}
                >
                  Hanya revisi saja
                </button>
              </section>
            )}
            <br />
          </>
        )}

        <main>
          {fields_final.map((field, idx) => {
            let {
              _id,
              array_templates,
              check_poi_status,
              description,
              isAllowOtherAnswer,
              isRequired,
              max,
              min,
              name,
              phone_code,
              poi_rad,
              poi_type,
              select_other,
              step,
              type,
              uuid,
              value,
            } = field;
            let input_form;
            let term = field?.term || [];
            const term_mode = field.term_mode;
            let bool_show = true;
            if (term.length > 0) {
              term = term.map((t) => {
                let t_bool;
                let field_selected = fields_original.filter(
                  (f) => f.uuid === t.uuid
                );
                if (field_selected.length > 0) {
                  field_selected = field_selected[0];
                  if (field_selected.type !== "checklist") {
                    const value = field_selected.value;
                    t_bool = t.value.includes(value);
                  } else {
                    const value_array = field_selected?.value_array
                      ? field_selected.value_array
                      : [];
                    let value_term = t?.value ? t.value : [];
                    value_term = value_term.map((v) => {
                      const v_bool = value_array.includes(v);
                      return v_bool;
                    });
                    t_bool = value_term.includes(true);
                  }
                } else {
                  t_bool = "empty";
                }
                return t_bool;
              });
              term = term.filter((t) => t !== "empty");
              if (term_mode === "and") {
                bool_show = !term.includes(false);
              } else {
                bool_show = term.includes(true);
              }
            }

            //26 Januari 2023 --> 19 tipe pertanyaan

            let logic_empty = true;
            //awal logic untuk pengetesan adalah true, artinya setiap pertanyaan diasumsikan belum terisi di awalnya, kemudian akan dicek apakah sudah terisi atau tidak

            /*
          logic text    | valuenya kosong atau undefined atau string kosong
          logic number  | logic_empty = value === undefined;
          */

            const logic_empty_number = value === undefined || value === "";
            const logic_empty_text = value === "";

            if (type === "text") {
              //1
              //text
              //logic_empty text
              logic_empty = logic_empty_text;
              value = value !== undefined ? value : "";
              input_form = (
                <main>
                  <input
                    list={_id}
                    name="fields"
                    id={uuid}
                    type={type}
                    value={value}
                    onChange={this.on_change}
                  />
                  {!isEmpty(array_templates) && (
                    <datalist id={_id}>
                      {array_templates.map(({ name }, idx) => {
                        return (
                          <option key={idx} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </datalist>
                  )}
                </main>
              );
            } else if (type === "textarea") {
              //2
              //textarea
              //logic_empty text
              logic_empty = logic_empty_text;
              value = value !== undefined ? value : "";
              input_form = (
                <textarea
                  rows="4"
                  name="fields"
                  id={uuid}
                  type="text"
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.on_change}
                />
              );
            } else if (type === "number") {
              //3
              //number
              //logic_empty text
              logic_empty = logic_empty_number;

              // value = value !== undefined ? value : "";

              input_form = (
                <main>
                  <input
                    name="fields"
                    id={uuid}
                    type={type}
                    value={value}
                    onChange={this.on_change}
                  />
                  {value >= 1000 && (
                    <section className="badge_normal">
                      {dict?.["Desimal"]?.[language]}:{" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(value)}
                    </section>
                  )}
                </main>
              );
            } else if (type === "range") {
              //4
              //range
              //logic_empty text
              logic_empty = logic_empty_number;

              value = value !== undefined ? value : "";
              input_form = (
                <div>
                  <input
                    name="fields"
                    id={uuid}
                    type={type}
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={this.on_change}
                  />
                  <div className="three_container">
                    <div style={{ textAlign: "left" }}>
                      <div className="badge" id="green">
                        {min}
                      </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <div className="badge" id={value ? "green" : "red"}>
                        {value
                          ? value
                          : dict?.["Belum diisi, geser slider untuk mengisi"]?.[
                          language
                          ]}
                      </div>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <div className="badge" id="green">
                        {max}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (type === "phone_number") {
              //5
              //phone_number
              //logic_empty text
              logic_empty = logic_empty_text;
              value = value !== undefined ? value : "";
              input_form = (
                <section className="phone_number">
                  <select
                    name="phone_code"
                    id={uuid}
                    value={phone_code ? phone_code : 62}
                    onChange={this.on_change_phone_code}
                  >
                    {phone_number_list.map((phone, idx) => {
                      return (
                        <option value={phone} key={idx}>
                          {phone}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    name="phone_number"
                    id={uuid}
                    type="number"
                    value={value}
                    onChange={this.on_change}
                  />
                </section>
              );
            } else if (type === "currency") {
              //6
              //currency
              //logic_empty text
              logic_empty = logic_empty_number;
              value = value !== undefined ? value : "";
              input_form = (
                <main>
                  <input
                    name="fields"
                    id={uuid}
                    type="number"
                    value={value}
                    onChange={this.on_change}
                  />
                  {value >= 1000 && (
                    <section className="badge_normal">
                      {dict?.["Desimal"]?.[language]}: Rp{" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(value)}
                    </section>
                  )}
                </main>
              );
            } else if (type === "year") {
              //7
              //year
              //logic_empty text
              logic_empty = logic_empty_number;
              value = value !== undefined ? value : "";
              input_form = (
                <input
                  name="fields"
                  id={uuid}
                  type="number"
                  value={value}
                  onChange={this.on_change}
                />
              );
            } else if (type === "date") {
              //8
              //date
              //logic_empty text
              logic_empty = logic_empty_number;
              value = value !== undefined ? value : "";
              input_form = (
                <input
                  name="fields"
                  id={uuid}
                  type={type}
                  value={value}
                  onChange={this.on_change}
                />
              );
            } else if (type === "time") {
              //9
              //time
              //logic_empty text
              logic_empty = logic_empty_number;
              value = value !== undefined ? value : "";
              input_form = (
                <input
                  name="fields"
                  id={uuid}
                  type={type}
                  value={value}
                  onChange={this.on_change}
                />
              );
            } else if (type === "selection") {
              //10
              //selection
              //logic_empty text
              logic_empty = logic_empty_text;
              value = value || "";
              input_form = (
                <>
                  <select
                    name="fields"
                    id={uuid}
                    type={type}
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={this.on_change_selection}
                  >
                    <option value="">
                      {dict?.["Silahkan pilih dari pilihan"]?.[language]}
                    </option>
                    {array_templates.map(({ name }, idx) => {
                      return (
                        <option key={idx} value={name}>
                          {name}
                        </option>
                      );
                    })}
                    {isAllowOtherAnswer && (
                      <option value="">{dict?.["Lainnya"]?.[language]}</option>
                    )}
                  </select>
                  {select_other && (
                    <>
                      <p className="text_inferior">Lainnya: </p>
                      <input
                        name="fields"
                        id={uuid}
                        type="text"
                        value={value}
                        onChange={this.on_change}
                      />
                    </>
                  )}
                </>
              );
            } else if (type === "radio_button") {
              //11
              //radio_button
              //logic_empty text
              logic_empty = logic_empty_number;
              value = value || "";
              const array_templates_values = array_templates.map(
                ({ name }) => name
              );
              input_form = (
                <main onChange={this.on_change.bind(this)}>
                  {array_templates.map(({ name }, idx_c) => {
                    const item = {
                      value: name,
                      uuid: uuid,
                    };
                    return (
                      <main
                        key={idx_c}
                        style={{
                          marginBottom: "5px",
                          display: "grid",
                          gridTemplateColumns: "30px auto",
                        }}
                      >
                        {value === name ? (
                          <section
                            className="radio_button"
                            id="active_strong"
                            onClick={this.on_click_radio.bind(this, item)}
                          >
                            ●
                          </section>
                        ) : (
                          <section
                            className="radio_button"
                            id="not_active"
                            onClick={this.on_click_radio.bind(this, item)}
                          ></section>
                        )}
                        <section className="break_word">{name}</section>
                      </main>
                    );
                  })}
                  {isAllowOtherAnswer && (
                    <main className="phone_number" style={{ height: "45px" }}>
                      <section>
                        {!array_templates_values.includes(value) ? (
                          <section
                            className="radio_button"
                            id="active_strong"
                            onClick={this.on_click_radio.bind(this, {
                              value: "",
                              uuid: uuid,
                            })}
                          >
                            ●{" "}
                          </section>
                        ) : (
                          <section
                            className="radio_button"
                            id="not_active"
                            onClick={this.on_click_radio.bind(this, {
                              value: "",
                              uuid: uuid,
                            })}
                          ></section>
                        )}
                        <section
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                          }}
                        >
                          {dict?.["Lainnya"]?.[language]}
                        </section>
                      </section>
                      <section>
                        {!array_templates_values.includes(value) && (
                          <input
                            name="fields"
                            id={uuid}
                            type="text"
                            value={value}
                            onChange={this.on_change}
                          />
                        )}
                      </section>
                    </main>
                  )}
                </main>
              );
            } else if (type === "checklist") {
              //12
              //checklist
              //value_array.length === 0

              const value_array = field?.value_array ? field?.value_array : [];
              logic_empty = value_array.length === 0;

              const array_templates_value = array_templates.map((e) => e.name);
              const other_array = value_array
                .filter((e) => !array_templates_value.includes(e))
                .filter((e) => e !== "");

              input_form = (
                <main>
                  {array_templates.map(({ name }, idx_c) => {
                    const item = {
                      value: name,
                      uuid: uuid,
                    };
                    return (
                      <main key={idx_c} style={{ marginBottom: "5px" }}>
                        {value_array.includes(name) ? (
                          <section
                            className="multi_check"
                            id="active_strong"
                            onClick={() => this.on_remove_check(item, type)}
                          >
                            ✓
                          </section>
                        ) : (
                          <section
                            className="multi_check"
                            id="not_active"
                            onClick={() => this.on_click_check(item, type)}
                          />
                        )}
                        <section
                          htmlFor={name}
                          style={{ display: "inline-block" }}
                        >
                          {name}
                        </section>
                      </main>
                    );
                  })}
                  {isAllowOtherAnswer && (
                    <main style={{ marginTop: "20px" }}>
                      <section>
                        <label
                          style={{
                            display: "inline-block",
                          }}
                        >
                          {dict?.["Lainnya"]?.[language]}:
                        </label>
                        {other_array.map((name, idx_c) => {
                          const item = {
                            value: name,
                            uuid,
                          };
                          return (
                            <main key={idx_c} style={{ marginBottom: "5px" }}>
                              {value_array.includes(name) ? (
                                <section
                                  className="multi_check"
                                  id="active_strong"
                                  onClick={() =>
                                    this.on_remove_check(item, type)
                                  }
                                >
                                  {" "}
                                  ✓{" "}
                                </section>
                              ) : (
                                <section
                                  className="multi_check"
                                  id="not_active"
                                  onClick={() =>
                                    this.on_click_check(item, type)
                                  }
                                />
                              )}
                              <section
                                htmlFor={name}
                                style={{ display: "inline-block" }}
                              >
                                {name}
                              </section>
                            </main>
                          );
                        })}
                      </section>

                      <section style={{ display: "flex", gap: "5px" }}>
                        <div
                          className="center_perfect"
                          style={{
                            height: "35px",
                            width: "35px",
                          }}
                        >
                          <section
                            className="multi_check"
                            id={"not_active"}
                            onClick={() => {
                              if (value)
                                this.on_click_check({ value, uuid }, type);
                            }}
                          />
                        </div>
                        <input
                          style={{ height: "35px" }}
                          name="checklist"
                          id={uuid}
                          type="text"
                          value={value}
                          onChange={this.on_change}
                          onKeyDown={(e) => this.handle_key_down(e, type)}
                          onBlur={(e) => {
                            this.on_click_check(
                              {
                                value: e.target.value,
                                uuid,
                              },
                              type
                            );
                          }}
                        />

                        <div
                          className="center_perfect"
                          style={{
                            height: "35px",
                            width: "35px",
                          }}
                        >
                          {value && (
                            <section
                              style={{
                                fontSize: "25px",
                                height: "20px",
                                width: "15px",
                              }}
                              className="button center_perfect"
                              id="active_strong"
                              onClick={() => {
                                this.on_click_check(
                                  {
                                    value: value,
                                    uuid,
                                  },
                                  type
                                );
                              }}
                            >
                              +
                            </section>
                          )}
                        </div>
                      </section>
                    </main>
                  )}
                </main>
              );

              // const value_array = field?.value_array ? field?.value_array : [];
              // logic_empty = value_array.length === 0;

              // const array_templates_value = array_templates.map((e) => e.name);
              // const other_array = value_array
              //   .filter((e) => !array_templates_value.includes(e))
              //   .filter((e) => e !== "");

              // input_form = (
              //   <main>
              //     {array_templates.map(({ name }, idx_c) => {
              //       const item = {
              //         value: name,
              //         uuid: uuid,
              //       };
              //       return (
              //         <main key={idx_c} style={{ marginBottom: "5px" }}>
              //           {value_array.includes(name) ? (
              //             <section
              //               className="multi_check"
              //               id="active_strong"
              //               onClick={this.on_remove_check.bind(this, item)}
              //             >
              //               ✓
              //             </section>
              //           ) : (
              //             <section
              //               className="multi_check"
              //               id="not_active"
              //               onClick={this.on_click_check.bind(this, item)}
              //             />
              //           )}
              //           <section
              //             htmlFor={name}
              //             style={{ display: "inline-block" }}
              //           >
              //             {name}
              //           </section>
              //         </main>
              //       );
              //     })}
              //     {isAllowOtherAnswer && (
              //       <main style={{ marginTop: "20px" }}>
              //         <section style={{ marginBottom: "5px" }}>
              //           {value_array.includes("") ? (
              //             <section
              //               className="multi_check"
              //               id="active_strong"
              //               onClick={this.on_remove_check.bind(this, {
              //                 value: "",
              //                 uuid,
              //               })}
              //             >
              //               ✓
              //             </section>
              //           ) : (
              //             <section
              //               className="multi_check"
              //               id="not_active"
              //               onClick={this.on_click_check.bind(this, {
              //                 value: "",
              //                 uuid,
              //               })}
              //             />
              //           )}
              //           <section
              //             style={{
              //               display: "inline-block",
              //             }}
              //           >
              //             {dict?.["Lainnya"]?.[language]}:
              //           </section>
              //         </section>
              //         <section>
              //           {other_array.map((name, idx_c) => {
              //             const item = {
              //               value: name,
              //               uuid,
              //             };
              //             return (
              //               <main key={idx_c} style={{ marginBottom: "5px" }}>
              //                 {value_array.includes(name) ? (
              //                   <section
              //                     className="multi_check"
              //                     id="active_strong"
              //                     onClick={this.on_remove_check.bind(
              //                       this,
              //                       item
              //                     )}
              //                   >
              //                     {" "}
              //                     ✓{" "}
              //                   </section>
              //                 ) : (
              //                   <section
              //                     className="multi_check"
              //                     id="not_active"
              //                     onClick={this.on_click_check.bind(this, item)}
              //                   />
              //                 )}
              //                 <section
              //                   htmlFor={name}
              //                   style={{ display: "inline-block" }}
              //                 >
              //                   {name}
              //                 </section>
              //               </main>
              //             );
              //           })}
              //         </section>
              //         {value_array.includes("") && (
              //           <main>
              //             <main
              //               className="check_container"
              //               style={{ height: "45px" }}
              //             >
              //               <section />
              //               <input
              //                 name="checklist"
              //                 id={uuid}
              //                 type="text"
              //                 value={value}
              //                 onChange={this.on_change}
              //                 onKeyDown={this.handle_key_down.bind(this)}
              //               />
              //             </main>
              //             {value && (
              //               <section
              //                 className="multi_check"
              //                 id="active_strong"
              //                 onClick={this.on_click_check.bind(this, {
              //                   value: value,
              //                   uuid,
              //                 })}
              //               >
              //                 +
              //               </section>
              //             )}
              //           </main>
              //         )}
              //       </main>
              //     )}
              //   </main>
              // );
            } else if (type === "image") {
              //13
              //image
              //logic_empty text
              logic_empty = logic_empty_text;

              let image_content = null;
              let image_src = "";
              if (!isEmpty(value)) {
                const base_64 = value?.base_64;
                if (typeof value === "object" && base_64) {
                  image_src = base_64;
                } else {
                  image_src = value;
                }

                if (image_src) {
                  image_content = (
                    <img
                      src={image_src}
                      alt={name}
                      style={{
                        display: "block",
                        maxWidth: "230px",
                        maxHeight: "95px",
                        width: "auto",
                        height: "auto",
                      }}
                    />
                  );
                }
              }

              if (
                loading &&
                itemLoading === "pushImageDataset" &&
                image_field._id === _id
              ) {
                input_form = (
                  <div>
                    <div>Uploading...</div>
                    {image_content}
                  </div>
                );
              } else {
                input_form = (
                  <div>
                    <div>
                      <UploadPicture
                        field={field}
                        child_uuid={this.props.child_uuid}
                        connection_mode={this.props.connection_mode}
                        on_change_file={this.on_change_file}
                      />
                    </div>
                    {image_content}
                  </div>
                );
              }
            } else if (type === "document") {
              //14
              //document
              //logic_empty text
              logic_empty = logic_empty_text;

              let file_content = null;

              if (!isEmpty(value)) {
                const base_64 = value?.base_64;
                const filename = value?.filename;
                if (typeof value === "object" && base_64) {
                  file_content = (
                    <section>{filename?.split("_%")?.[1]}</section>
                  );
                } else {
                  file_content = <section>{field?.src_name}</section>;
                }
              }

              if (
                loading &&
                itemLoading === "pushImageDataset" &&
                image_field._id === _id
              ) {
                input_form = (
                  <main>
                    <section>Uploading...</section>
                    {file_content}
                  </main>
                );
              } else {
                input_form = (
                  <main>
                    <section>
                      <UploadFile
                        field={field}
                        child_uuid={this.props.child_uuid}
                        connection_mode={this.props.connection_mode}
                        on_change_file={this.on_change_file}
                      />
                    </section>
                    {file_content}
                  </main>
                );
              }
            } else if (type === "link") {
              //15
              //link
              //logic_empty text
              logic_empty = logic_empty_text;

              value = value || "";
              input_form = (
                <main>
                  <main className="phone_number">
                    <section>https://</section>
                    <input
                      name="fields"
                      id={uuid}
                      type={type}
                      value={value}
                      onChange={this.on_change}
                    />
                  </main>
                  {value && (
                    <a
                      href={`https://${value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="badge_normal"
                    >
                      Link: {value}
                    </a>
                  )}
                </main>
              );
            } else if (type === "auto_address") {
              //16
              //auto_address
              //logic_empty text
              logic_empty = logic_empty_text;

              value = value || "";
              input_form = (
                <div>
                  {loading && itemLoading === "get_geocode_address" ? (
                    <div className="button margin_bottom">Loading...</div>
                  ) : (
                    <button
                      className="button margin_bottom"
                      onClick={this.on_get_geocode_address.bind(this, field)}
                    >
                      Perbarui isian
                    </button>
                  )}

                  <textarea
                    name="fields"
                    id={uuid}
                    type="text"
                    value={value}
                    onChange={this.on_change}
                    rows="7"
                  />
                </div>
              );
            } else if (type === "auto_address_poi") {
              //17
              //auto_address_poi
              //logic_empty text
              logic_empty = logic_empty_text;

              value = value || "";
              input_form = (
                <div>
                  {loading && itemLoading === "get_geocode_address_poi" ? (
                    <div className="button margin_bottom">Loading...</div>
                  ) : (
                    <button
                      className="button margin_bottom"
                      onClick={this.on_get_geocode_address_poi.bind(
                        this,
                        field
                      )}
                    >
                      Perbarui isian
                    </button>
                  )}

                  <textarea
                    name="fields"
                    id={uuid}
                    type="text"
                    value={value}
                    onChange={this.on_change}
                    rows="7"
                  />
                </div>
              );
            } else if (type === "table") {
              //18
              //table
              //belum memiliki child(konten baris table itu)

              const child_uuid = this.props.child_uuid;

              const parent_iteration = (parent_uuid_init) => {
                let parent_array = [];
                const generate_parent_array = (parent_uuid) => {
                  const parent_one_step = fields_original.find(
                    (item) => item?.uuid === parent_uuid && item?.parent_uuid
                  );
                  if (parent_one_step) parent_array.push(parent_one_step);
                  const new_parent_uuid = parent_one_step?.parent_uuid;
                  if (new_parent_uuid !== undefined) {
                    //recursion started
                    generate_parent_array(new_parent_uuid);
                  }
                };
                generate_parent_array(parent_uuid_init);
                return parent_array;
              };

              const parent_array = parent_iteration(uuid);
              const level_child = parent_array.length;

              if (level_child === 0) {
                const array = child_array.filter((c) => c.parent_uuid === uuid);
                logic_empty = array?.length === 0;
                input_form = (
                  <TableParent field={field} level_child={level_child} />
                );
              } else {
                const array = child_array.filter(
                  (c) => c.parent_uuid_temp === this.props.child_uuid
                );

                logic_empty = array?.length === 0;

                if (level_child === 1) {
                  input_form = (
                    <TableChild
                      field={field}
                      parent_uuid_temp={child_uuid}
                      level_child={level_child}
                    />
                  );
                } else {
                  input_form = (
                    <TableInfinity
                      field={field}
                      parent_uuid_temp={child_uuid}
                      level_child={level_child}
                    />
                  );
                }
              }
            } else if (type === "counting_poi") {
              //19
              //counting_poi
              //logic_empty text
              logic_empty = logic_empty_number;

              value = value !== undefined ? value : "";
              input_form = (
                <>
                  <section
                    className="container_light margin_bottom"
                    id="white_background"
                  >
                    <p>
                      Kategori POI: <b>{poi_type}</b>
                    </p>
                    <p>
                      Radius POI:{" "}
                      <b>
                        {poi_rad} km
                        {poi_rad < 1 && ` (${poi_rad * 1000} meter)`}
                      </b>
                    </p>
                    <p>(Diatur oleh admin)</p>
                    <br />
                    {loading &&
                      itemLoading === "counting_poi" &&
                      uuid === uuid_process ? (
                      <div className="button margin_right" id="grey">
                        Loading...
                      </div>
                    ) : (
                      <button
                        className="button margin_right"
                        onClick={this.on_counting_poi.bind(
                          this,
                          poi_type,
                          poi_rad,
                          uuid
                        )}
                      >
                        Cek poi
                      </button>
                    )}
                    {check_poi_status && value === 0 && (
                      <div className="button" id="red">
                        Tidak ditemukan POI x
                      </div>
                    )}

                    {value > 0 && (
                      <div className="button" id="green">
                        Sudah ditemukan POI ✔
                      </div>
                    )}
                  </section>

                  <input
                    name="fields"
                    id={uuid}
                    type="number"
                    // min={min}
                    // max={max}
                    // step={step}
                    value={value}
                    onChange={this.on_change}
                  />
                </>
              );
            } else if (type === "counting_custom") {
              value = value !== undefined ? value : "";
              logic_empty = logic_empty_number;
              input_form = (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p>custom_counting_id : {field.custom_counting_id}</p>
                  <p>poi_rad : {field.poi_rad} meter</p>
                  <div>
                    <button
                      className="button"
                      onClick={this.on_counting.bind(this, uuid)}
                    >
                      Generate Counting Custom
                    </button>
                  </div>
                  <input
                    name="fields"
                    id={uuid}
                    type={type}
                    value={value}
                    onChange={this.on_change}
                  />
                </div>
              );
            } else if (
              ["provinsi", "kota", "kecamatan", "kelurahan"].includes(type)
            ) {
              //25
              //kota
              //logic_empty text
              logic_empty = logic_empty_text;
              value = value !== undefined ? value : "";
              input_form = (
                <>
                  <section
                    className="container_light margin_bottom"
                    id="white_background"
                  >
                    <p>
                      Auto generate: <b>{type}</b>
                    </p>
                    <br />
                    {loading &&
                      itemLoading === "get_kota" &&
                      uuid === uuid_process ? (
                      <div className="button_small margin_right" id="grey">
                        Loading...
                      </div>
                    ) : (
                      <button
                        className="button_small margin_right"
                        onClick={this.on_get_administrasi.bind(
                          this,
                          type,
                          uuid
                        )}
                      >
                        Auto-generate {type}
                      </button>
                    )}
                  </section>
                  <input
                    name="fields"
                    id={uuid}
                    type="text"
                    value={value}
                    onChange={this.on_change}
                  />
                </>
              );
            } else {
              //1
              //text
              //logic_empty text
              logic_empty = logic_empty_text;

              value = value !== undefined ? value : "";
              input_form = (
                <main>
                  <input
                    list={_id}
                    name="fields"
                    id={uuid}
                    type={type}
                    value={value}
                    onChange={this.on_change}
                  />
                  {!isEmpty(array_templates) && (
                    <datalist id={_id}>
                      {array_templates.map(({ name }, idx) => {
                        return (
                          <option key={idx} value={name}>
                            {name}
                          </option>
                        );
                      })}
                    </datalist>
                  )}
                </main>
              );
            }

            let final_content = null;
            if (bool_show) {
              let text_operation;
              let function_operation;
              let id_digit = "red_text";
              let img_digit = icon_close;
              if (field?.digit_term?.length > 0) {
                switch (field?.digit_term?.[0]?.operation) {
                  case "===":
                    text_operation = dict?.["sama dengan"]?.[language];
                    function_operation =
                      String(value)?.length ===
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                  case ">":
                    text_operation = dict?.["lebih dari"]?.[language];
                    function_operation =
                      String(value)?.length >
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                  case "<":
                    text_operation = dict?.["kurang dari"]?.[language];
                    function_operation =
                      String(value)?.length <
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                  case ">=":
                    text_operation =
                      dict?.["lebih dari sama dengan"]?.[language];
                    function_operation =
                      String(value)?.length >=
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                  case "<=":
                    text_operation =
                      dict?.["kurang dari sama dengan"]?.[language];
                    function_operation =
                      String(value)?.length <=
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                  default:
                    text_operation = dict?.["sama dengan"]?.[language];
                    function_operation =
                      String(value)?.length ===
                      parseInt(field?.digit_term?.[0]?.number);
                    break;
                }
                if (function_operation) {
                  id_digit = "green_text";
                  img_digit = icon_check_solid;
                }
              }

              const rev_object = field?.rev_object;

              final_content = (
                <main key={idx}>
                  {field.picture_url && (
                    <section
                      className="container_light"
                      style={{
                        backgroundColor: "#ffffff",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        src={field.picture_url}
                        alt="pertanyaan"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </section>
                  )}
                  <section
                    className="container_light margin_bottom"
                    id="white_background"
                  >
                    <section className="text_bold">{name}</section>
                    {field?.digit_term?.length > 0 && (
                      <section className="text_inferior" id={id_digit}>
                        {dict?.["Jumlah karakter harus"]?.[language]}{" "}
                        <b>{text_operation}</b>{" "}
                        <b>{field?.digit_term?.[0]?.number}</b>{" "}
                        <img alt="icon" src={img_digit} width="13px" />
                      </section>
                    )}
                    <section
                      className="text_inferior"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {description}
                    </section>
                    {isRequired && (
                      <div
                        className="badge margin_top_minor"
                        id={!logic_empty ? "blue" : "pink"}
                      >
                        {dict?.["Wajib diisi"]?.[language]}
                      </div>
                    )}
                    <div className="badge margin_top_minor" id="green">
                      {field.type}
                    </div>
                    {input_form}
                    {rev_object?.field_uuid && (
                      <section className="container_light" id="red_outline">
                        <p>Pesan revisi: {rev_object?.message}</p>
                      </section>
                    )}
                  </section>
                </main>
              );
            }
            return final_content;
          })}
        </main>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  /*PARENT*/

  //1
  change_text,
  //2
  click_check,
  //3
  remove_check,
  //4
  select_other,
  //5
  change_phone_code,

  /*CHILD*/

  //1
  change_child,
  //2
  click_check_child,
  //3
  remove_check_child,
  //4
  select_other_child,
  //5
  change_phone_code_child,

  /*OTHER*/
  counting_poi,
  get_administrasi,

  // Counting Custom
  get_counting_custom,

  get_geocode_address,
  get_geocode_address_poi,
})(FieldsRender);
