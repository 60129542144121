import { combineReducers } from "redux";
import formReducer from "./formReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import projectReducer from "./projectReducer";
import propertiesReducer from "./propertiesReducer";
import mapReducer from "./mapReducer";

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  errors: errorReducer,
  project: projectReducer,
  properties: propertiesReducer,
  map: mapReducer,
});
