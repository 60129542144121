//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";

//Redux function
import {
  RegisterEmailInvitation,
  UpdateStatusGroup,
} from "../../actions/authActions";

//Picture Asset

//General Function
import dict from "../../validation/dict.json";
import domain_list from "../../validation/domain_list";

const id = window.location.pathname.substring(
  window.location.pathname.lastIndexOf("/") + 1
);

class RegisterLink extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      password2: "",
      errors: {},
      show_password: false,
    };
  }

  componentDidMount = () => {
    this.props.UpdateStatusGroup(id);
  };

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { name, password, password2 } = this.state;

    if (password !== password2) {
      alert("Password tidak sama");
    } else {
      const body = {
        uuid: id,
        name: name.toLowerCase().replace(/\s/g, ""),
        password,
        industry: "Surveyor",
      };
      console.log(body);
      this.props.RegisterEmailInvitation(body);
    }
  };

  onChange = (e) => {
    if (e.target.name === "name") {
      this.setState({ [e.target.name]: e.target.value.replace(/\s/g, "") });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      errors,
      name,

      password,
      password2,
      show_password,
    } = this.state;

    //global props
    const { loading, domain } = this.props.auth;

    //content
    const logo = domain_list?.[domain]?.logo;

    let icon_content;

    if (loading) {
      icon_content = (
        <div style={{ marginTop: "30px" }}>
          <button className="button_pill" type="submit">
            {dict["Registering..."][language]}
          </button>
        </div>
      );
    } else {
      icon_content = (
        <div style={{ marginTop: "30px" }}>
          <button className="button_pill" type="submit">
            {dict["Register"][language]}
          </button>
        </div>
      );
    }

    return (
      <main style={{ maxWidth: "90vw", margin: "auto" }}>
        <div className="margin_bottom_big">
          <img
            src={logo}
            height="70px"
            width="auto"
            alt="FORM MAPID"
            style={{ margin: "0px auto 0px auto" }}
          />
        </div>
        <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
          <TextFieldGroup
            placeholder={dict["User name"][language]}
            name="name"
            value={name.toLowerCase()}
            onChange={this.onChange}
            error={errors.name}
          />
          <br />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Password"][language]}
            name="password"
            id="password"
            value={password}
            onChange={this.onChange}
            error={errors.password}
            toggleShowPassword={this.toggleShowPassword}
          />
          <br />
          <TextFieldGroup
            type={show_password ? "text" : "password"}
            isPassword={true}
            placeholder={dict["Confirm password"][language]}
            name="password2"
            id="password2"
            value={password2}
            onChange={this.onChange}
            error={errors.password2}
            toggleShowPassword={this.toggleShowPassword}
          />
          {icon_content}
        </form>
        <style>{`.nav_bottom{ display:none;}`}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  RegisterEmailInvitation,
  UpdateStatusGroup,
})(RegisterLink);
