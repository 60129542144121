//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import FieldsRender from "../universal_form_sub_components/FieldsRender";
import Modal from "../common/Modal";

//Redux function
import {
  set_child_index,
  add_child,
  delete_child,
} from "../../actions/formActions";
import { set_child_index_new } from "../../actions/propertiesActions";

//Picture Asset

//General Function

class TableChild extends Component {
  state = {
    modal_edit_child: false,
    modal_delete_child: false,
    child_uuid_state: "",
  };

  toggle_delete_child = (child_uuid_state) => {
    this.setState({ modal_delete_child: !this.state.modal_delete_child });
    if (child_uuid_state) {
      this.setState({ child_uuid_state });
    }
  };

  on_set_child_index = (child_uuid, parent_uuid_temp, field) => {
    const body = {
      parent_uuid: parent_uuid_temp,
      child_uuid,
      level: this.props.level_child,
      field,
    };
    this.props.set_child_index(body);
    this.props.set_child_index_new(body);
  };

  on_add_child = (field) => {
    if (field?.uuid) {
      const parent_uuid_temp = this.props.parent_uuid_temp;
      const uuid = field.uuid;
      const body = {
        parent_uuid: uuid,
        parent_uuid_temp,
        status: "child",
        level: this.props.level_child,
      };
      this.props.add_child(body);
    }
  };

  on_delete_child = (child_uuid) => {
    const body = {
      child_uuid,
    };
    this.props.delete_child(body);
    this.setState({ modal_delete_child: false });
  };

  render() {
    //local storage

    //local state
    const { child_uuid_state, modal_delete_child } = this.state;

    //global props
    const { geo_layer_wo_geojson } = this.props.form;
    const {
      parent_uuid_temp,
      /*test*/
      field,
      level_child,
    } = this.props;

    //content
    const { child_uuid_selected } = geo_layer_wo_geojson;
    const parent_uuid = field?.uuid || "";
    let child_array = geo_layer_wo_geojson?.child_array || [];
    child_array = child_array.filter(
      (item) => item.parent_uuid === parent_uuid
    );
    if (parent_uuid_temp) {
      child_array = child_array.filter(
        (item) => item.parent_uuid_temp === parent_uuid_temp
      );
    }

    const fields =
      child_array.find((f) => f.child_uuid === child_uuid_selected)
        ?.fields_child || [];
    const child_uuid = child_array.find(
      (f) => f.child_uuid === child_uuid_selected
    )?.child_uuid;

    const modal_delete_child_content = modal_delete_child && (
      <Modal
        modalSize="small"
        id="modal_delete_child"
        isOpen={modal_delete_child}
        onClose={this.toggle_delete_child}
      >
        <div className="box-body text_center">
          <h1 className="text_bold">Konfirmasi hapus item dari tabel</h1>
          <br />
          <br />
          <button
            className="button"
            id="red"
            onClick={this.on_delete_child.bind(this, child_uuid_state)}
          >
            Hapus
          </button>
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_delete_child_content}
        <div className="badge" id="orange">
          Tabel level: {level_child + 1}
        </div>
        <section className="carousellGroup">
          {child_array.map((item, idx) => {
            const content =
              item.child_uuid === child_uuid_selected ? (
                <section key={idx}>
                  <div
                    className="button_tab margin_right"
                    id="black"
                    key={idx}
                    onClick={this.on_set_child_index.bind(
                      this,
                      "",
                      item.parent_uuid_temp,
                      field
                    )}
                  >
                    {field.name} {idx + 1}
                  </div>
                </section>
              ) : (
                <section key={idx}>
                  <div
                    className="button_tab margin_right"
                    id="white"
                    key={idx}
                    onClick={this.on_set_child_index.bind(
                      this,
                      item.child_uuid,
                      item.parent_uuid_temp,
                      field
                    )}
                  >
                    {field.name} {idx + 1}
                  </div>
                </section>
              );
            return content;
          })}
          <button
            onClick={this.on_add_child.bind(this, field)}
            className="button_tab margin_right"
            id="blue"
          >
            +
          </button>
        </section>

        {child_uuid ? (
          <section>
            <section className="text_right margin_top margin_bottom">
              <button
                className="button"
                id="red"
                onClick={this.toggle_delete_child.bind(this, child_uuid)}
              >
                Hapus
              </button>
            </section>

            <FieldsRender
              field={field}
              fields={fields}
              parent_uuid_temp={child_uuid}
              child_uuid={child_uuid}
            />
          </section>
        ) : (
          <section className="margin_top margin_bottom">
            <p className="text_bold">Belum ada item terpilih</p>
            <p className="text_inferior">
              Pilih item dengan klik tab di atas untuk mengedit atau klik "+"
              untuk menambah data.
            </p>
          </section>
        )}
        <br />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_child_index,
  add_child,
  delete_child,
  set_child_index_new,
})(TableChild);
