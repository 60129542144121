//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux function

//Picture Asset

//General Function

class TableOverlay extends Component {
  state = {};

  render() {
    const { geojson_polygon_filtered, fields_polygon } = this.props.form;

    const content = geojson_polygon_filtered?.features?.length > 0 && (
      <table className="table_lite margin_bottom">
        <tbody>
          {fields_polygon.map((item, idx) => {
            return (
              <tr key={idx}>
                <td>{item.name}</td>
                <td>
                  {
                    geojson_polygon_filtered?.features?.[0]?.properties?.[
                      item.key
                    ]
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );

    return content;
  }
}

const mapStateToProps = (state) => ({ form: state.form });

export default connect(mapStateToProps, {})(TableOverlay);
