//Library
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import UploadPicture from "./UploadPicture";
import UploadFile from "./UploadFile";

//Redux function
import { editProperties } from "../../actions/formActions";

//Picture Asset

//General Function
import isEqual from "../../validation/is-equal";
import isEmpty from "../../validation/is-empty";
import PhoneNumber from "../common/PhoneNumber.json";
import Kabupaten from "../common/Kabupaten.json";
import uuid from "../../validation/uuid";

const phone_number_list = PhoneNumber.sort();
const cities_array = Kabupaten.sort();

class FormEditPaid extends Component {
  state = {
    geo_layer_wo_geojson: {},
    feature: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(
        nextProps.form.geo_layer_wo_geojson,
        prevState.geo_layer_wo_geojson
      ) ||
      !isEqual(nextProps.feature, prevState.feature)
    ) {
      let geo_layer_wo_geojson = nextProps.form.geo_layer_wo_geojson;
      const feature = nextProps.feature;
      const { properties } = feature;
      const fields_default_props = nextProps.form.geo_layer_wo_geojson.fields;
      const fields = fields_default_props.filter(function (field) {
        return !field.isHide || !field.isStyle;
      });
      const fields_with_value = fields.map((field) => {
        let field_clone = { ...field };
        if (field.type === "checklist") {
          const value = properties[field.key];
          const value_array = value.split("\n");
          field_clone.defaultValue = "";
          field_clone.value_array = value_array;
        } else {
          field_clone.defaultValue = properties[field.key];
        }
        return field_clone;
      });
      geo_layer_wo_geojson.fields = fields_with_value;
      return {
        geo_layer_wo_geojson,
        feature: nextProps.feature,
      };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    //handle image
    if (!isEqual(this.props.form.image_field, prevProps.form.image_field)) {
      const editFunction = (fields, field) => {
        const new_fields = fields.slice();
        const index = new_fields.findIndex(
          (field_element) => field_element._id === field._id
        );
        new_fields.splice(index, 1, field);
        return new_fields;
      };
      const image_field = this.props.form.image_field;
      let { geo_layer_wo_geojson } = this.state;
      const { fields } = geo_layer_wo_geojson;
      const new_field = editFunction(fields, image_field);
      geo_layer_wo_geojson.fields = new_field;
      this.setState({ geo_layer_wo_geojson });
    }
  }

  handleSubmit = () => {
    let { geo_layer_wo_geojson } = this.state;
    const feature = this.props.feature;
    const fields = geo_layer_wo_geojson.fields.filter(
      (field) => !field.isStyle
    );
    const new_fields = fields.map(
      (
        { key, name, type, min, max, step, array_templates, _id, defaultValue },
        idx
      ) => {
        let field;
        if (type === "currency") {
          const new_number = Number(defaultValue);
          field = {
            key,
            name,
            type,
            min,
            max,
            step,
            array_templates,
            _id,
            defaultValue: new_number,
          };
        } else if (type === "date") {
          const date_now = new Date(defaultValue);
          const date =
            String(date_now.getDate()).length > 1
              ? date_now.getDate()
              : "0" + String(date_now.getDate());
          const month =
            String(date_now.getMonth() + 1).length > 1
              ? date_now.getMonth() + 1
              : "0" + String(date_now.getMonth() + 1);
          const year = date_now.getFullYear();
          const date_formated = `${year}-${month}-${date}`;
          field = {
            key,
            name,
            type,
            min,
            max,
            step,
            array_templates,
            _id,
            defaultValue: date_formated,
          };
        } else if (
          type === "number" ||
          type === "year" ||
          type === "phone_number"
        ) {
          const new_number = Number(defaultValue);
          field = {
            key,
            name,
            type,
            min,
            max,
            step,
            array_templates,
            _id,
            defaultValue: new_number,
          };
        } else {
          field = {
            key,
            name,
            type,
            min,
            max,
            step,
            array_templates,
            _id,
            defaultValue: defaultValue ? defaultValue : "",
          };
        }
        return field;
      }
    );
    let properties = {};
    new_fields.map(({ key, defaultValue }, idx) => {
      return (properties[key] = defaultValue);
    });
    const properties_default = {
      icon_image: "museum",
      text_field: "point",
      circle_color: "#1E5E96",
      circle_radius: 5,
      circle_stroke_width: 1,
      circle_stroke_color: "#fff",
    };
    properties = Object.assign(properties_default, properties);
    const body = {
      geo_layer_id: geo_layer_wo_geojson._id,
      feature_key: feature.key,
      properties: properties,
    };
    this.props.editProperties(body);
  };

  toggleIncomplete = (fields) => {
    this.setState({ modal_incomplete: !this.state.modal_incomplete });
    if (fields) {
      this.setState({ fields_incomplete_state: fields });
    }
  };

  toggleOffline = () => {
    this.setState({ modalOffline: !this.state.modalOffline });
  };

  goToMap = () => {
    this.setState({ screen_status: 1 });
  };

  goBack = () => {
    window.history.back();
  };

  onClickOffline = () => {
    let { geo_layer_wo_geojson, longitude, latitude } = this.state;
    geo_layer_wo_geojson.id_offline = uuid();
    geo_layer_wo_geojson.longitude = longitude;
    geo_layer_wo_geojson.latitude = latitude;
    this.props.pushFormOffline(geo_layer_wo_geojson);
    this.setState({ modalOffline: true });
  };

  toggleUserData = () => {
    this.setState({ modalUserData: !this.state.modalUserData });
  };

  toggleApplyPaidSurvey = () => {
    this.setState({ modalApplyPaidSurvey: !this.state.modalApplyPaidSurvey });
  };

  handleApplyPaidSurvey = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const { geo_layer_wo_geojson } = this.props.form;
    const { amount } = this.state;
    const body = {
      user: user._id,
      geo_layer_id: geo_layer_wo_geojson._id,
      amount: parseInt(amount),
    };
    this.props.applyForm(body);
    this.setState({ modalApplyPaidSurvey: false });
  };

  toggleEditName = () => {
    this.setState({ modalEditName: !this.state.modalEditName });
  };

  toggleEditCity = () => {
    this.setState({ modalEditCity: !this.state.modalEditCity });
  };

  toggleEditPhone = () => {
    this.setState({ modalEditPhone: !this.state.modalEditPhone });
  };

  onClickCity = (city) => {
    this.setState({ city });
  };

  handleFixCity = () => {
    const { cities_filtered, city } = this.state;
    if (cities_filtered.length === 0) {
      this.setState({ cities_filtered: cities_array });
    } else if (!cities_filtered.includes(city)) {
      this.setState({ city: cities_filtered[0] ? cities_filtered[0] : "" });
    }
  };

  _onMapLoaded() {
    this.setState({ mapIsLoaded: true });
  }

  handleTrueModalRead = () => {
    this.setState({ modalRead: true }, () => {
      document.getElementById("read_content").style.bottom = "0px";
      const { geo_layer_wo_geojson } = this.props.form;
      this.props.getDocForm(geo_layer_wo_geojson._id);
    });
  };

  handleFalseModalRead = () => {
    this.setState({ modalRead: false, readStatus: true }, () => {
      document.getElementById("read_content").style.bottom = "-100vh";
    });
  };

  handleUpdateFullname = (e) => {
    e.preventDefault();
    const { full_name } = this.state;
    const body = {
      full_name,
    };
    this.props.updateFullname(body);
  };

  handleUpdatePhone = (e) => {
    e.preventDefault();
    const { phone_number_state } = this.state;
    const body = {
      phone_number: phone_number_state,
    };
    this.props.updatePhone(body);
  };

  handleUpdateCity = (e) => {
    e.preventDefault();
    const { cities_filtered, city } = this.state;
    if (!cities_filtered.includes(city)) {
      const body = {
        city: cities_filtered[0] ? cities_filtered[0] : "",
      };
      this.props.updateCity(body);
    } else {
      const body = {
        city,
      };
      this.props.updateCity(body);
    }
  };

  toggleLogin = () => {
    this.setState({ modalLogin: !this.state.modalLogin });
  };

  toggleLoginRegister = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  toggleLocationAsk = () => {
    this.setState({ modalLocation: !this.state.modalLocation });
  };

  onGetLocation = () => {
    this.setState({ locationLoading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      return null;
    }
  };

  showPosition = (position) => {
    const latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    const longitude = Number(parseFloat(position.coords.longitude).toFixed(8));
    const accuracy = parseInt(position.coords.accuracy);
    this.setState(
      {
        latitude,
        longitude,
        accuracy,
        locationStatus: true,
        locationLoading: false,
      },
      () => {
        this.toggleLocationAsk();
        this.setState({
          viewport: {
            latitude: latitude,
            longitude: longitude,
            zoom: 15,
          },
        });
      }
    );
  };

  onRemoveCheck = (item) => {
    const { value, uuid } = item;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const idx = fields.findIndex((f) => f.uuid === uuid);
    let value_array = fields[idx]?.value_array ? fields[idx].value_array : [];
    value_array = value_array.filter((v) => v !== value);
    if (value === "") {
      let other_array = fields[idx]?.other_array ? fields[idx].other_array : [];
      value_array = value_array.filter(
        (v) => v !== value && !other_array.includes(v)
      );
    }
    fields[idx].value_array = value_array;
    geo_layer_wo_geojson.fields = fields;
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  onClickCheck = (item) => {
    const { value, uuid } = item;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const idx = fields.findIndex((f) => f.uuid === uuid);
    let value_array = fields[idx]?.value_array ? fields[idx].value_array : [];
    value_array.push(value);
    fields[idx].value_array = value_array;
    geo_layer_wo_geojson.fields = fields;
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  handleKeyDown(e) {
    // e.preventDefault();
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const target = e.target;
    const value = target.value;
    const id = target.id;
    const idx = fields.findIndex((f) => f.uuid === id);
    if (e.keyCode === 13 && value) {
      let value_array = fields[idx]?.value_array ? fields[idx].value_array : [];
      let other_array = fields[idx]?.other_array ? fields[idx].other_array : [];
      value_array.push(value);
      other_array.push(value);
      fields[idx].value_array = value_array;
      fields[idx].other_array = other_array;
      fields[idx].defaultValue = "";
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    }
  }

  pushOtherArray = (item) => {
    const { value, uuid } = item;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const idx = fields.findIndex((f) => f.uuid === uuid);
    let value_array = fields[idx]?.value_array ? fields[idx].value_array : [];
    let other_array = fields[idx]?.other_array ? fields[idx].other_array : [];
    value_array.push(value);
    other_array.push(value);
    fields[idx].value_array = value_array;
    fields[idx].other_array = other_array;
    fields[idx].defaultValue = "";
    geo_layer_wo_geojson.fields = fields;
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  onClickRadio = (item) => {
    const { value, uuid } = item;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const index = fields.findIndex((f) => f.uuid === uuid);
    fields[index].defaultValue = value;
    geo_layer_wo_geojson.fields = fields;
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const type = target.type;
    const name = target.name;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    let fields = geo_layer_wo_geojson.fields;
    const id = target.id;
    const index = fields.findIndex((f) => f.uuid === id);
    if (["fields"].includes(name)) {
      fields[index].defaultValue = value;
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    } else if (["checklist"].includes(name)) {
      fields[index].defaultValue = value;
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    } else if (["radio"].includes(type)) {
      fields[index].defaultValue = value;
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    } else if (["phone_code"].includes(name)) {
      const phone_number = fields[index].phone_number;
      fields[index].phone_code = value;
      fields[index].defaultValue = String(value) + String(phone_number);
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    } else if (["phone_number"].includes(name)) {
      const phone_code = fields[index].phone_code
        ? fields[index].phone_code
        : "62";
      fields[index].phone_number = value;
      fields[index].defaultValue = String(phone_code) + String(value);
      geo_layer_wo_geojson.fields = fields;
      this.setState({
        geo_layer_wo_geojson,
      });
    } else if (["city"].includes(name)) {
      if (!value) {
        this.setState({
          [name]: value,
          cities_filtered: cities_array,
        });
      } else {
        const cities_filtered = cities_array.filter((city) =>
          city.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
          [name]: value,
          cities_filtered,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleToggle = (e) => {
    const target = e.target;
    const name = target.name;
    let geo_layer_wo_geojson = this.state.geo_layer_wo_geojson;
    geo_layer_wo_geojson[name] = !geo_layer_wo_geojson[name];
    this.setState({
      geo_layer_wo_geojson,
    });
  };

  handleToggleMap = (e) => {
    this.setState({
      isSatellite: e.target.checked,
      mapStyle: e.target.checked
        ? "mapbox://styles/mapbox/satellite-streets-v11"
        : "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
    });
  };

  onDragEnd = (lngLat) => {
    this.setState({ latitude: lngLat.lat, longitude: lngLat.lng });
  };

  render() {
    const { geo_layer_wo_geojson, feature } = this.state;
    const { loading, itemLoading, image_field } = this.props.form;
    // let fields_test_empty;
    // let isComplete = false;
    let isComplete = true;
    // if (geo_layer_wo_geojson?.fields) {
    //   const fields_required = geo_layer_wo_geojson.fields.filter(
    //     (field) => field.isRequired
    //   );
    //   fields_test_empty = fields_required.map((field) => {
    //     const defaultValue = field.defaultValue ? field.defaultValue : "";
    //     return defaultValue;
    //   });
    //   isComplete = !fields_test_empty.includes("");
    // }
    let fields = geo_layer_wo_geojson.fields ? geo_layer_wo_geojson.fields : [];

    const { formStatus } = feature;
    const revision_list = formStatus?.revision_list || [];
    const revision_list_uuid = revision_list.map((r) => r?.field_uuid);

    fields = fields.filter((f) => revision_list_uuid.includes(f.uuid));

    let buttonContent;
    if (loading && itemLoading === "edit_properties_form") {
      buttonContent = <div className="button_standard">Mengirim data...</div>;
    } else if (isComplete) {
      buttonContent = (
        <div className="button_standard" onClick={this.handleSubmit}>
          Kirim
        </div>
      );
    } else {
      buttonContent = (
        <div>
          <div className="button_standard" id="grey">
            Kirim
          </div>
          <div
            className="text_medium"
            style={{ width: "150px", margin: "auto" }}
          >
            Tombol submit akan aktif setelah semua kolom terisi
          </div>
        </div>
      );
    }
    const fieldContent = (
      <main>
        {fields.map((field, idx) => {
          const {
            name,
            description,
            type,
            min,
            max,
            step,
            array_templates,
            _id,
            defaultValue,
            phone_code,
            phone_number,
            isRequired,
            isAllowOtherAnswer,
            uuid,
          } = field;
          let input_form;
          const value = defaultValue ? defaultValue : "";
          let term = field?.term ? field.term : [];
          const term_mode = field.term_mode;
          let bool_show = true;
          if (term.length > 0) {
            term = term.map((t) => {
              let t_bool;
              let field_selected = fields.filter((f) => f.uuid === t.uuid);
              if (field_selected.length > 0) {
                field_selected = field_selected[0];
                if (field_selected.type !== "checklist") {
                  const value = field_selected.defaultValue;
                  t_bool = t.value.includes(value);
                } else {
                  const value_array = field_selected?.value_array
                    ? field_selected.value_array
                    : [];
                  let value_term = t?.value ? t.value : [];
                  value_term = value_term.map((v) => {
                    const v_bool = value_array.includes(v);
                    return v_bool;
                  });
                  t_bool = value_term.includes(true);
                }
              } else {
                t_bool = "empty";
              }
              return t_bool;
            });
            term = term.filter((t) => t !== "empty");
            if (term_mode === "and") {
              bool_show = !term.includes(false);
            } else {
              bool_show = term.includes(true);
            }
          }
          if (type === "text") {
            //text
            input_form = (
              <main>
                <input
                  list={_id}
                  name="fields"
                  id={uuid}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.handleChange}
                />
                {!isEmpty(array_templates) && (
                  <datalist id={_id}>
                    {array_templates.map(({ name }, idx) => {
                      return (
                        <option key={idx} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </datalist>
                )}
              </main>
            );
          } else if (type === "textarea") {
            //textarea
            input_form = (
              <textarea
                rows="4"
                name="fields"
                id={uuid}
                type="text"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={this.handleChange}
              />
            );
          } else if (type === "number") {
            //number
            input_form = (
              <main>
                <input
                  name="fields"
                  id={uuid}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.handleChange}
                />
                {value >= 1000 && (
                  <section className="badge_normal">
                    Desimal:{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(value)}
                  </section>
                )}
              </main>
            );
          } else if (type === "phone_number") {
            //phone_number
            input_form = (
              <main>
                <section className="phone_number">
                  <select
                    name="phone_code"
                    id={uuid}
                    value={phone_code ? phone_code : 62}
                    onChange={this.handleChange}
                  >
                    {phone_number_list.map((phone, idx) => {
                      return (
                        <option value={phone} key={idx}>
                          {phone}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    name="phone_number"
                    id={uuid}
                    type="number"
                    min={min}
                    max={max}
                    step={step}
                    value={phone_number ? phone_number : ""}
                    onChange={this.handleChange}
                  />
                </section>
                <section className="badge_normal">
                  {/* {phone_code ? phone_code : 62}-{phone_number} */}
                  {defaultValue}
                </section>
              </main>
            );
          } else if (type === "currency") {
            //currency
            input_form = (
              <main>
                <input
                  name="fields"
                  id={uuid}
                  type="number"
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.handleChange}
                />
                {value >= 1000 && (
                  <section className="badge_normal">
                    Desimal: Rp{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(value)}
                  </section>
                )}
              </main>
            );
          } else if (type === "year") {
            //year
            input_form = (
              <input
                name="fields"
                id={uuid}
                type="number"
                value={value}
                onChange={this.handleChange}
              />
            );
          } else if (type === "date" || type === "time") {
            //date, time
            input_form = (
              <input
                name="fields"
                id={uuid}
                type={type}
                value={value}
                onChange={this.handleChange}
              />
            );
          } else if (type === "selection") {
            //selection
            input_form = (
              <select
                name="fields"
                id={uuid}
                type={type}
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={this.handleChange}
              >
                <option value="">Silahkan pilih dari pilihan</option>
                {array_templates.map(({ name }, idx) => {
                  return (
                    <option key={idx} value={name}>
                      {name}
                    </option>
                  );
                })}
                {isAllowOtherAnswer && <option value="lainnya">Lainnya</option>}
              </select>
            );
          } else if (type === "radio_button") {
            const array_templates_values = array_templates.map(
              ({ name }) => name
            );
            input_form = (
              <main onChange={this.handleChange.bind(this)}>
                {array_templates.map(({ name }, idx_c) => {
                  const item = {
                    value: name,
                    uuid: uuid,
                  };
                  return (
                    <main key={idx_c} style={{ marginBottom: "5px" }}>
                      {defaultValue === name ? (
                        <section
                          className="radio_button"
                          id="active_strong"
                          onClick={this.onClickRadio.bind(this, item)}
                        >
                          ●
                        </section>
                      ) : (
                        <section
                          className="radio_button"
                          id="not_active"
                          onClick={this.onClickRadio.bind(this, item)}
                        ></section>
                      )}
                      <section
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        {name}
                      </section>
                    </main>
                  );
                })}
                {isAllowOtherAnswer && (
                  <main className="phone_number" style={{ height: "45px" }}>
                    <section>
                      {!array_templates_values.includes(defaultValue) ? (
                        <section
                          className="radio_button"
                          id="active_strong"
                          onClick={this.onClickRadio.bind(this, {
                            value: "",
                            uuid: uuid,
                          })}
                        >
                          ●{" "}
                        </section>
                      ) : (
                        <section
                          className="radio_button"
                          id="not_active"
                          onClick={this.onClickRadio.bind(this, {
                            value: "",
                            uuid: uuid,
                          })}
                        ></section>
                      )}
                      <section
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                        }}
                      >
                        Lainnya
                      </section>
                    </section>
                    <section>
                      {!array_templates_values.includes(value) && (
                        <input
                          name="fields"
                          id={uuid}
                          type="text"
                          value={value}
                          onChange={this.handleChange}
                        />
                      )}
                    </section>
                  </main>
                )}
              </main>
            );
          } else if (type === "checklist") {
            const value_array = field?.value_array ? field?.value_array : [];
            const other_array = field?.other_array ? field?.other_array : [];
            input_form = (
              <main>
                {array_templates.map(({ name }, idx_c) => {
                  const item = {
                    value: name,
                    uuid: uuid,
                  };
                  return (
                    <main key={idx_c} style={{ marginBottom: "5px" }}>
                      {value_array.includes(name) ? (
                        <section
                          className="multi_check"
                          id="active_strong"
                          onClick={this.onRemoveCheck.bind(this, item)}
                        >
                          ✓
                        </section>
                      ) : (
                        <section
                          className="multi_check"
                          id="not_active"
                          onClick={this.onClickCheck.bind(this, item)}
                        />
                      )}
                      <section
                        htmlFor={name}
                        style={{ display: "inline-block" }}
                      >
                        {name}
                      </section>
                    </main>
                  );
                })}
                {isAllowOtherAnswer && (
                  <main style={{ marginTop: "20px" }}>
                    <section style={{ marginBottom: "5px" }}>
                      {value_array.includes("") ? (
                        <section
                          className="multi_check"
                          id="active_strong"
                          onClick={this.onRemoveCheck.bind(this, {
                            value: "",
                            uuid,
                          })}
                        >
                          ✓
                        </section>
                      ) : (
                        <section
                          className="multi_check"
                          id="not_active"
                          onClick={this.onClickCheck.bind(this, {
                            value: "",
                            uuid,
                          })}
                        />
                      )}
                      <section
                        style={{
                          display: "inline-block",
                        }}
                      >
                        Lainnya:
                      </section>
                    </section>
                    <section>
                      {other_array.map((name, idx_c) => {
                        const item = {
                          value: name,
                          uuid,
                        };
                        return (
                          <main key={idx_c} style={{ marginBottom: "5px" }}>
                            {value_array.includes(name) ? (
                              <section
                                className="multi_check"
                                id="active_strong"
                                onClick={this.onRemoveCheck.bind(this, item)}
                              >
                                {" "}
                                ✓{" "}
                              </section>
                            ) : (
                              <section
                                className="multi_check"
                                id="not_active"
                                onClick={this.onClickCheck.bind(this, item)}
                              />
                            )}
                            <section
                              htmlFor={name}
                              style={{ display: "inline-block" }}
                            >
                              {name}
                            </section>
                          </main>
                        );
                      })}
                    </section>
                    {value_array.includes("") && (
                      <main>
                        <main
                          className="check_container"
                          style={{ height: "45px" }}
                        >
                          <section></section>
                          <section>
                            <input
                              name="checklist"
                              id={uuid}
                              type="text"
                              value={value}
                              onChange={this.handleChange}
                              onKeyDown={this.handleKeyDown.bind(this)}
                            />
                          </section>
                        </main>
                        {value && (
                          <section
                            className="multi_check"
                            id="active_strong"
                            onClick={this.pushOtherArray.bind(this, {
                              value: value,
                              uuid,
                            })}
                          >
                            +
                          </section>
                        )}
                      </main>
                    )}
                  </main>
                )}
              </main>
            );
          } else if (type === "image") {
            //image
            let image_content;
            if (defaultValue) {
              image_content = (
                <img
                  src={defaultValue}
                  alt={name}
                  style={{
                    display: "block",
                    maxWidth: "230px",
                    maxHeight: "95px",
                    width: "auto",
                    height: "auto",
                  }}
                />
              );
            } else {
              image_content = null;
            }
            if (
              loading &&
              itemLoading === "pushImageDataset" &&
              image_field._id === _id
            ) {
              input_form = (
                <div>
                  <div>Uploading...</div>
                  {image_content}
                </div>
              );
            } else {
              input_form = (
                <div>
                  <div>
                    <UploadPicture field={field} />
                  </div>
                  {image_content}
                </div>
              );
            }
          } else if (type === "document") {
            //image
            let image_content;
            if (defaultValue) {
              image_content = <section>{field?.src_name}</section>;
            } else {
              image_content = null;
            }
            if (
              loading &&
              itemLoading === "pushImageDataset" &&
              image_field._id === _id
            ) {
              input_form = (
                <main>
                  <section>Uploading...</section>
                  {image_content}
                </main>
              );
            } else {
              input_form = (
                <main>
                  <section>
                    <UploadFile field={field} />
                  </section>
                  {image_content}
                </main>
              );
            }
          } else if (type === "link") {
            //link
            input_form = (
              <main>
                <main className="phone_number">
                  <section>https://</section>
                  <input
                    name="fields"
                    id={uuid}
                    type={type}
                    value={value}
                    onChange={this.handleChange}
                  />
                </main>
                {value && (
                  <a
                    href={`https://${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="badge_normal"
                  >
                    Link: {value}
                  </a>
                )}
              </main>
            );
          } else if (type === "range") {
            //range
            input_form = (
              <div>
                <input
                  name="fields"
                  id={uuid}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.handleChange}
                />
                <div className="three_container">
                  <div style={{ textAlign: "left" }}>
                    <div
                      className="badge_normal"
                      style={{ fontSize: "16px", color: "#52606eff" }}
                    >
                      {min}
                    </div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <div
                      className="badge_normal"
                      style={{ fontSize: "9px", color: "#52606eff" }}
                    >
                      {value
                        ? value
                        : "Belum diisi, geser slider untuk mengisi"}
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <div
                      className="badge_normal"
                      style={{ fontSize: "16px", color: "#52606eff" }}
                    >
                      {max}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else if (type === "auto_address") {
            //auto_address
            input_form = (
              <div>
                {!value && (
                  <div className="badge_normal">
                    Mendapat detail lokasi alamat jalan...
                  </div>
                )}
                <textarea
                  name="fields"
                  id={uuid}
                  type="text"
                  value={value}
                  onChange={this.handleChange}
                  rows="7"
                />
              </div>
            );
          } else if (type === "auto_address_poi") {
            //auto_address_poi
            input_form = (
              <div>
                {!value && (
                  <div className="badge_normal">
                    Mendapat detail lokasi alamat POI...
                  </div>
                )}
                <textarea
                  name="fields"
                  id={uuid}
                  type="text"
                  value={value}
                  onChange={this.handleChange}
                  rows="7"
                />
              </div>
            );
          } else {
            input_form = (
              <div>
                <input
                  list={_id}
                  name="fields"
                  id={uuid}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  value={value}
                  onChange={this.handleChange}
                />
                {!isEmpty(array_templates) && (
                  <datalist id={_id}>
                    {array_templates.map(({ name }, idx) => {
                      return (
                        <option key={idx} value={name}>
                          {name}
                        </option>
                      );
                    })}
                  </datalist>
                )}
              </div>
            );
          }
          let final_content = null;
          if (bool_show) {
            final_content = (
              <main
                key={idx}
                className="container_light"
                id="white_background"
                style={{ marginBottom: "10px" }}
              >
                <section className="text_bold">{name}</section>
                <section
                  className="text_medium"
                  style={{
                    fontSize: "16px",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {description}
                </section>
                {isRequired && <div className="text_medium">Wajib diisi</div>}
                {input_form}
              </main>
            );
          }
          return final_content;
        })}
        <section style={{ textAlign: "center" }}>
          <button
            className="button_standard"
            style={{ marginRight: "5px", verticalAlign: "top" }}
            onClick={this.goToMap}
          >
            {"<"}
          </button>
          <section style={{ display: "inline-block", verticalAlign: "top" }}>
            {buttonContent}
          </section>
        </section>
      </main>
    );
    return <div>{fieldContent}</div>;
  }
}
const mapStateToProps = (state) => ({
  form: state.form,
});
export default connect(mapStateToProps, { editProperties })(FormEditPaid);
