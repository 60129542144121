//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common/Modal";
import SpinnerSimpleLogo from "../common/SpinnerSimpleLogo";

//Redux function
import { updateProfile } from "../../actions/authActions";

//Picture Asset

//General Function

class EditProfile extends Component {
  state = {
    userId: "",
    full_name: "",
    alias_name: "",
    email: "",
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      Object.entries(nextProps.auth.user).length !== 0 &&
      nextProps.auth.user.constructor === Object &&
      nextProps.auth.user._id !== prevState.userId
    ) {
      var user = nextProps.auth.user;
      var userId = user._id;
      var full_name = user.full_name;
      var alias_name = user.alias_name;
      var email = user.email;
      return {
        userId: userId,
        full_name: full_name,
        alias_name: alias_name,
        email: email,
      };
    } else return null;
  }
  handleChange = (e) => {
    e.preventDefault();
    if (e.target.id === "full_name") {
      this.setState({ full_name: e.target.value });
    }
    if (e.target.id === "alias_name") {
      this.setState({ alias_name: e.target.value });
    }
    if (e.target.id === "email") {
      this.setState({ email: e.target.value });
    }
  };
  handleSubmit = () => {
    this.toggle();
    setTimeout(
      function () {
        var { full_name, alias_name, email } = this.state;
        const newUser = {
          full_name,
          alias_name,
          email,
        };
        this.props.updateProfile(newUser);
      }.bind(this),
      1000
    );
  };
  render() {
    var { full_name, alias_name, email } = this.state;
    var { loading } = this.props.auth;
    var itemContent;
    if (loading) {
      itemContent = (
        <div style={{ textAlign: "center" }}>
          <p>Collecting data, please wait...</p>
          <SpinnerSimpleLogo
            width={200}
            unik="loading_item_list"
            marginTop="0px"
          />
        </div>
      );
    } else {
      itemContent = (
        <div className="dashboardSpecial">
          <br />
          <div>Email</div>
          <input
            className="form-control"
            type="text"
            id="email"
            key="email"
            value={email}
            onChange={this.handleChange.bind(this)}
          />
          <br />
          <div>Full name</div>
          <input
            className="form-control"
            type="text"
            id="full_name"
            key="full_name"
            value={full_name}
            onChange={this.handleChange.bind(this)}
          />
          <br />
          <div>Chat name</div>
          <input
            className="form-control"
            type="text"
            id="alias_name"
            key="alias_name"
            value={alias_name}
            onChange={this.handleChange.bind(this)}
          />
          <br />
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              type="submit"
              className="buttonShadow"
              style={{ fontSize: "160%" }}
              onClick={this.handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      );
    }
    const modalContent = this.state.modal && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={this.state.modal}
        onClose={this.toggle}
      >
        <div className="box-body">
          <div style={{ textAlign: "center" }}>
            <h1>Successfully updated</h1>
            <p>You will redirect to dashboard</p>
            <br />
            <SpinnerSimpleLogo
              width={200}
              unik="loading_item_list"
              marginTop="0px"
            />
          </div>
        </div>
      </Modal>
    );
    return (
      <div>
        {itemContent}
        {modalContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { updateProfile })(EditProfile);
