//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import TextFieldGroup from "../common/TextFieldGroup";

//Redux function
import {
  loginUser,
  sendLink,
  email_forget_password,
  forget_password,
  code_forget,
} from "../../actions/authActions";

//Picture Asset

//General Function
import dict from "../../validation/dict.json";
import history from "../../actions/history";
import domain_list from "../../validation/domain_list";

const Messages = ({ color, title }) => {
  return (
    <p
      style={{
        fontSize: "0.7rem",
        color: `${color}`,
      }}
    >
      {title}
    </p>
  );
};

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      errors: {},
      width: window.innerWidth,
      show_password: false,
      is_forget_password: false,
      code: "",
      time: {},
      seconds: 10,
      showcode: false,
      countdown: false,
      inputPassword: false,
      username: "",
      password: "",
      password3: "",
      password2: "",
      messageCode: "",
      notSamePassword: "",
    };
    this.timer = 0;
    this.startTimer = this.handleSubmitEmail.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  componentDidMount() {
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (!isNav) {
      if (this.props.auth.isAuthenticated) {
        history.push("/");
      }
    } else {
      return null;
    }
    if (this.props.auth.verify_link_status === "send") {
      this.setState({ modal_success: true });
    }
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidUpdate(prevProps) {
    if (
      !!(
        this.props.auth.verify_link_status !== prevProps.auth.verify_link_status
      ) &&
      this.props.auth.verify_link_status === "send"
    ) {
      this.setState({ modal_success: true });
    }
    const isNav = this.props.isNav ? this.props.isNav : null;
    if (!isNav) {
      if (this.props.auth.isAuthenticated) {
        history.push("/");
      }
    } else {
      return null;
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  toggleForgetPassword = () => {
    this.setState({
      is_forget_password: !this.state.is_forget_password,
      errors: {},
    });
  };

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {});
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleSubmitEmail = async (e) => {
    e.preventDefault();

    if (this.state.email === "" || this.state.username === "") {
      this.setState({
        messageEmail: 303,
      });
    } else {
      const { email, username } = this.state;
      const lang = localStorage.getItem("language");
      let bodyLang = "en";
      if (lang === "ina") {
        bodyLang = "id";
      }
      const body = {
        email: email,
        username: username,
        lang: bodyLang,
      };
      try {
        await email_forget_password(body);
        this.setState({
          showcode: true,
          messageEmail: 202,
          intervalTime: setInterval(this.countDown, 1000),
          countdown: true,
          seconds: 11,
        });
      } catch (error) {
        this.setState({
          messageEmail: 404,
        });
      }
    }
  };

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.state.intervalTime);
      this.setState({
        countdown: false,
      });
    } else {
      this.setState({
        countdown: true,
      });
    }
  }

  handleSubmitCode = async (e) => {
    e.preventDefault();

    if (this.state.code === "") {
      this.setState({
        messageCode: 202,
      });
    } else {
      const { email, username, code } = this.state;
      const body = {
        username,
        email,
        code,
      };
      try {
        await code_forget(body);
        this.setState({
          inputPassword: !this.state.inputPassword,
        });
      } catch (error) {
        this.setState({
          messageCode: 303,
        });
      }
    }
  };

  handleSubmitPassword = (e) => {
    e.preventDefault();
    const { password3, password2, email, username } = this.state;

    if (password2 === "" || password3 === "") {
      this.setState({
        notSamePassword: 404,
      });
    } else if (password2.length <= 8) {
      this.setState({
        notSamePassword: 505,
      });
    } else {
      if (password2 === password3) {
        // if (this.props.auth.user.is_email_confirm === "not_propose") {
        this.setState({
          inputPassword: !this.state.inputPassword,
        });
        // }
        const body = {
          email,
          username,
          password1: password2,
          password2: password3,
        };
        this.setState({
          is_forget_password: false,
        });
        this.props.forget_password(body);
        history.push("/menu/login");
      } else {
        this.setState({
          notSamePassword: 303,
        });
      }
    }
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      errors,
      width,
      email,
      code,
      password2,
      password3,
      username,
      show_password,
    } = this.state;

    //global props
    const { domain } = this.props.auth;

    //content
    const logo = domain_list?.[domain]?.logo;

    let item_content = (
      <div>
        {this.state.inputPassword ? (
          <div>
            <form>
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder={dict["Confirm password"][language]}
                name="password2"
                id="password2"
                value={password2}
                onChange={this.handleChange}
                error={errors.password2}
                toggleShowPassword={this.toggleShowPassword}
                pattern="[0-9a-zA-Z]{8,}"
              />
              <TextFieldGroup
                type={show_password ? "text" : "password"}
                isPassword={true}
                placeholder={dict["Password"][language]}
                name="password3"
                id="password3"
                value={password3}
                onChange={this.handleChange}
                error={errors.password3}
                toggleShowPassword={this.toggleShowPassword}
                pattern="[0-9a-zA-Z]{8,}"
              />
              {this.state.notSamePassword === 303 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Password not the same"][language]}
                />
              ) : this.state.notSamePassword === 404 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Please input password"][language]}
                />
              ) : this.state.notSamePassword === 505 ? (
                <Messages
                  color="#ff0000"
                  title={dict["Minimum 8 characters"][language]}
                />
              ) : null}
              <button
                className="button_pill"
                style={{ height: "50%" }}
                onClick={this.handleSubmitPassword}
              >
                {dict["Sending"][language]}
              </button>
            </form>
          </div>
        ) : (
          <>
            {this.state.countdown ? (
              <>
                <TextFieldGroup
                  placeholder={dict["User name"][language]}
                  type="username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={this.handleChange}
                  error={errors.username}
                  disabled
                />
                <TextFieldGroup
                  placeholder={dict["Email"][language]}
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  error={errors.email}
                  disabled
                />
                {this.state.messageEmail === 202 ? (
                  <Messages
                    color="#008000"
                    title={dict["Code verification"][language]}
                  />
                ) : this.state.messageEmail === 303 ? (
                  <Messages
                    color="#ff0000"
                    title={
                      dict["Please input your username or email"][language]
                    }
                  />
                ) : this.state.messageEmail === 404 ? (
                  <Messages
                    color="#ff0000"
                    title={dict["Email not found"][language]}
                  />
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p style={{ fontSize: "15px" }}>
                    0{this.state.time.m}:{this.state.time.s}
                  </p>
                </div>
              </>
            ) : (
              <>
                <TextFieldGroup
                  placeholder={dict["User name"][language]}
                  type="username"
                  name="username"
                  id="username"
                  value={username}
                  onChange={this.handleChange}
                  error={errors.username}
                />
                <TextFieldGroup
                  placeholder={dict["Email"][language]}
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  error={errors.email}
                />
                {this.state.messageEmail === 202 ? (
                  <Messages
                    color="#008000"
                    title={dict["Code verification"][language]}
                  />
                ) : this.state.messageEmail === 303 ? (
                  <Messages
                    color="#ff0000"
                    title={
                      dict["Please input your username or email"][language]
                    }
                  />
                ) : this.state.messageEmail === 404 ? (
                  <Messages
                    color="#ff0000"
                    title={dict["Email not found"][language]}
                  />
                ) : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="button_pill"
                    style={{ height: "50%" }}
                    onClick={this.handleSubmitEmail}
                  >
                    {dict["Sending"][language]}
                  </button>
                </div>
              </>
            )}
            {this.state.showcode && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <TextFieldGroup
                    placeholder="code"
                    name="code"
                    id="code"
                    value={code}
                    onChange={this.handleChange}
                    error={errors.code}
                  />
                  <button
                    className="button_pill"
                    style={{ height: "50%" }}
                    onClick={this.handleSubmitCode}
                  >
                    {dict["Sending"][language]}
                  </button>
                </div>
                <div>
                  {this.state.messageCode === 202 ? (
                    <Messages
                      color="#ff0000"
                      title={dict["please input your code"][language]}
                    />
                  ) : this.state.messageCode === 303 ? (
                    <Messages
                      color="#ff0000"
                      title={dict["Code not match"][language]}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
    return (
      <main
        style={{
          textAlign: width < 600 || this.props.isNav ? "center" : "left",
          maxWidth: "90vw",
          margin: "auto",
        }}
      >
        <div className="margin_bottom_big">
          <img
            src={logo}
            height="70px"
            width="auto"
            alt="FORM MAPID"
            style={{ margin: "0px auto 0px auto" }}
          />
        </div>
        {item_content}
        <style>{`.nav_bottom{ display:none;}`}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  sendLink,
  email_forget_password,
  forget_password,
})(ForgetPassword);
