/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Source, Layer } from "@urbica/react-map-gl";
import { circle } from "@turf/turf";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class LayerRadiusPembanding extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //local storage

    //local state

    //global props
    const { data_pembanding } = this.props.form;

    //content
    const coordinates =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry?.coordinates;

    const long =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[0];
    const lat =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[1];

    const _id = data_pembanding?.layer?.geojson?.features?.[0]?._id;
    const radius_pembanding = data_pembanding?.radius_pembanding || 500;

    let layer_render = <></>;

    if (long && lat) {
      let center = coordinates;
      let options = {
        steps: 64,
        units: "meters",
        properties: {},
      };
      let data_radius = circle(center, radius_pembanding, options);

      layer_render = (
        <>
          <Source
            key={`Radius_${`Radius_${_id}`}`}
            id={`Radius_${_id}`}
            type="geojson"
            data={data_radius}
          />
          <Layer
            id={`Radius_${_id}`}
            before=""
            type={"fill"}
            source={`Radius_${_id}`}
            layout={{ visibility: "visible" }}
            paint={{
              "fill-color": "#244aa1",
              "fill-opacity": 0.4,
            }}
          />
        </>
      );
    }

    return layer_render;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
});

export default connect(mapStateToProps, {})(LayerRadiusPembanding);
