import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 2000;
  width: ${(props) => {
    switch (props.modalSize) {
      case "large":
        return "1200px";
      case "small":
        return "480px";
      case "medium":
        return "900px";
      default:
        return "480px";
    }
  }};
  max-width: 90vw;
  max-height: 80vh;
  margin: auto;
  &.fade-in {
    opacity: 1;
    -webkit-transition: 0.01s;
    -moz-transition: 0.01s;
    -o-transition: 0.01s;
    transition: 0.01s;
  }
  &.fade-out {
    opacity: 0;
    -webkit-transition: 0.01s;
    -moz-transition: 0.01s;
    -o-transition: 0.01s;
    transition: 0.01s;
  }
  .background {
    background: #00022493;
    ${(props) => {
      switch (props.blur) {
        case false:
          return "";
        case true:
          return `-webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          filter: blur(3px);`;
        default:
          return `-webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          filter: blur(3px);`;
      }
    }};
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-header {
    text-align: right;
  }
  .box-dialog {
    border-radius: 7px;
    z-index: 1050;
    width: 100%;
    background-color: ${(props) => {
      if (props.backgroundColor) {
        return props.backgroundColor;
      } else {
        return "#fff";
      }
    }};
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
    .box-body {
      font-size: 14px;
      padding: 0px 24px 0px 24px;
      overflow-y: auto;
      overflow-x: auto;
      max-height: 80vh;
      padding-bottom: 20px;
    }
    .x-close {
      font-size: 35px;
      line-height: 35px;
      font-weight: 400;
      text-shadow: none;
      color: black;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    .box-footer {
      padding-right: 24px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid #c7c7c7;
    }
  }
`;

export default Modal;
