import React from "react";
import { Source, Layer } from "@urbica/react-map-gl";

const Paragraf = (title, value, idx) => {
  return (
    <div className="valueNewPopup" key={idx}>
      <p>{title}</p>
      <h3>{value}</h3>
    </div>
  );
};

const Display = ({
  title,
  data,
  paint,
  type,
  viewStatus,
  layerOnClick,
  icon_image,
}) => {
  let layout = { visibility: viewStatus ? "visible" : "none" };
  if (type === "symbol") {
    layout = { ...layout, "icon-image": icon_image, "icon-size": 1.5 };
  }
  return (
    <>
      <Source key={title} id={title} type="geojson" data={data} />
      <Layer
        id={title}
        type={type}
        source={title}
        paint={type === "symbol" ? {} : paint}
        layout={layout}
        onClick={layerOnClick}
      />
    </>
  );
};

function CircleColor(getColor, type) {
  let paint = {
    "circle-color":
      type === "pda"
        ? [
            "match",
            ["get", "status"],
            "siaga1",
            "#ff0000",
            "siaga2",
            "#ffff00",
            "siaga3",
            "#0000ff",
            "normal",
            "#008000",
            "Offline",
            "#000000",
            "delay",
            "#8C0000",
            "tanpa",
            "#808080",
            "#0ca5eb",
          ]
        : type === ""
        ? getColor
        : getColor,
    "circle-radius":
      type === "pch" || type === "pda" ? 5 : ["get", "circle_radius"],
    "circle-stroke-width":
      type === "pch" || type === "pda" ? 2 : ["get", "circle_stroke_width"],
    "circle-stroke-color":
      type === "pch" || type === "pda"
        ? "#fff"
        : ["get", "circle_stroke_color"],
  };
  return paint;
}

function LineColor(getColor) {
  let paint = {
    "line-color": getColor,
    "line-width": ["get", "line_width"],
    "line-opacity": ["get", "opacity"],
    "line-gap-width": ["get", "line_gap_width"],
  };
  return paint;
}

function FillColor(getColor) {
  let paint = {
    "fill-color": getColor,
    "fill-opacity": ["get", "opacity"],
    "fill-outline-color": "rgba(0,0,0,0.3)",
  };

  return paint;
}

function FuncIfType(name, typeOne, typeTwo) {
  return (name =
    typeTwo === "pch"
      ? "symbol"
      : typeOne === "Point" || typeOne === "MultiPoint"
      ? "circle"
      : typeOne === "LineString" || typeOne === "MultiLineString"
      ? "line"
      : typeOne === "Polygon" || typeOne === "MultiPolygon"
      ? "fill"
      : "circle");
}

export { Paragraf, Display, CircleColor, LineColor, FillColor, FuncIfType };
