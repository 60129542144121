//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import SpinnerLogo from "../common/SpinnerLogo";

//Redux function
import { getPublicFormList } from "../../actions/projectActions";
import { setLayerWOgeojson } from "../../actions/formActions";

//Picture Asset

//General Function

class ProjectList extends Component {
  state = {};

  componentDidMount() {
    this.props.getPublicFormList();
  }

  onClickLayer = (layer) => {
    this.props.setLayerWOgeojson({
      geo_layer: layer,
    });
  };

  render() {
    const { form_list_public, loading, itemLoading } = this.props.project;
    let itemContent = (
      <div className="container_light">
        Belum ada survei public, tunggu ya survei publik untuk kamu.
      </div>
    );
    if (
      loading &&
      itemLoading === "public_form_list" &&
      form_list_public.length === 0
    ) {
      itemContent = (
        <SpinnerLogo
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (form_list_public.length > 0) {
      itemContent = (
        <main style={{ marginTop: "30px" }}>
          {form_list_public.map((layer) => {
            const { _id, link, name, picture_url } = layer;
            return (
              <Link
                to={`/${link}`}
                onClick={this.onClickLayer.bind(this, layer)}
                key={_id}
              >
                <main
                  style={{
                    backgroundColor: "#fff",
                    marginBottom: "20px",
                    cursor: "pointer",
                    borderRadius: "20px",
                    boxShadow: "0 0 6px #9d9d9d53",
                  }}
                >
                  <section
                    style={{
                      backgroundImage: `url(${picture_url})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundColor: "#dedede",
                      width: "100%",
                      paddingBottom: "25%",
                      borderRadius: "20px 20px 0px 0px",
                    }}
                  />
                  <section style={{ padding: "15px" }}>
                    <div
                      className="text_medium"
                      style={{ maxHeight: "40px", overflowY: "hidden" }}
                    >
                      {name}
                    </div>
                    <div className="button_standard">Isi form</div>
                  </section>
                </main>
              </Link>
            );
          })}
        </main>
      );
    }
    return (
      <div className="main_container">
        <div className="text_bold">Form Publik</div>
        <div className="text_medium">
          Klik banner untuk melihat mengikuti form publik
        </div>
        {itemContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getPublicFormList,
  setLayerWOgeojson,
})(ProjectList);
