import React, { Component } from "react";
import MapGL, { Marker } from "@urbica/react-map-gl";

/*PERSONAL COMPONENT*/
import CheckBox from "../common/CheckBox";
import LayerBasic from "../layer/LayerBasic";
import SearchLocation from "../submit_form/SearchLocation";
// import GeoJsonGeometriesLookup from "geojson-geometries-lookup";

/*REDUX FUNCTION*/
import { set_value } from "../../actions/formActions";

/*PICTURE ASSET*/
import icon_pin from "../../img/icon_sini.svg";
import icon_full_screen_no from "../../img/icon_full_screen_no.svg";
import icon_full_screen_active from "../../img/icon_full_screen_active.svg";
import icon_location from "../../img/location.svg";

/*GENERAL */
import dict from "../../validation/dict.json";
import { connect } from "react-redux";

const style = {
  padding: "5px",
  color: "#fff",
  cursor: "pointer",
  background: "#0ca5eb",
  borderRadius: "50%",
  borderStyle: "solid",
  borderColor: "#fff",
  borderWidth: 2,
};

const accessToken =
  "pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g";

const map_styles = {
  default:
    "https://api.maptiler.com/maps/streets/style.json?key=CVYOeJckfxqURN5wjKEB",
  satellite: "mapbox://styles/mapbox/satellite-streets-v11",
};

const default_viewport = {
  latitude: -6.1784072398481555,
  longitude: 106.84251626772414,
  zoom: 7,
};

class MapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSatellite: false,
      viewport: default_viewport,
      isMapLoaded: false,
    };
  }

  componentDidMount = () => {
    const { latitude, longitude } = this.props.form;
    this.set_lat_long_first_time({ latitude, longitude });
  };

  set_lat_long_first_time = ({ latitude, longitude }) => {
    if (this.is_lat_long_true({ latitude, longitude })) {
      this.setState({
        viewport: {
          latitude,
          longitude,
          zoom: default_viewport.zoom,
        },
      });
    } else {
      this.setState({
        viewport: default_viewport,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const prev_lat = prevProps?.form?.latitude;
    const prev_long = prevProps?.form?.longitude;
    const { latitude, longitude } = this.props.form;
    if (prev_lat !== latitude || prev_long !== longitude) {
      const { set_polygon_feature } = this.props;
      if (this.is_location_has_been_obtained() && set_polygon_feature) {
        set_polygon_feature({ latitude, longitude });
        const { geo_layer_wo_geojson } = this.props.form;
        const isLocationChangeable = geo_layer_wo_geojson?.isLocationChangeable;
        if (isLocationChangeable) {
          this.props.set_value("is_location_exist", true);
        }
      }
    }
  };

  /** perubahan view map (menggeser map, zoom in, zoom out) */
  change_viewport = (e) => {
    const { isFullMap, set_polygon_feature } = this.props;

    const { latitude_init, longitude_init, geo_layer_wo_geojson } =
      this.props.form;

    if (isFullMap) {
      const transform = e.target.transform;
      const lat = transform._center.lat;
      const long = transform._center.lng;

      const isLocationChangeable = geo_layer_wo_geojson?.isLocationChangeable;

      if (isLocationChangeable) {
        this.props.set_value("latitude", lat);
        this.props.set_value("longitude", long);

        if (long !== longitude_init && lat !== latitude_init) {
          this.props.set_value("is_location_exist", true);
          this.setState({ locationStatus: true });
        }

        if (set_polygon_feature) {
          set_polygon_feature({ latitude: lat, longitude: long });
        }
      }
    }
  };

  setMapIsLoaded = (value) => {
    this.setState({
      isMapLoaded: value,
    });
  };

  handleToggleMap = (e) => {
    this.setState({
      isSatellite: e.target.checked,
    });
  };

  is_lat_long_true = ({ latitude, longitude }) => {
    if (!latitude || !longitude) return false;
    if (isNaN(latitude) || isNaN(longitude)) return false;

    if (
      latitude >= -90 &&
      latitude <= 90 &&
      longitude >= -180 &&
      longitude <= 180
    ) {
      return true;
    }

    return false;
  };

  is_location_has_been_obtained = () => {
    const { latitude, longitude } = this.props.form;
    return this.is_lat_long_true({ latitude, longitude });
  };

  onDragEnd = (lngLat) => {
    this.props.set_value("latitude", lngLat.lat);
    this.props.set_value("longitude", lngLat.lng);
  };

  set_view_port = (viewport) => {
    this.setState({
      viewport: {
        latitude: viewport.latitude,
        longitude: viewport.longitude,
        zoom: viewport.zoom,
      },
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { isFullMap, locationStatus, handleFullMap } = this.props;

    const { latitude, longitude, geo_layer_wo_geojson } = this.props.form;
    const isLocationChangeable = geo_layer_wo_geojson.isLocationChangeable;
    const is_dragable = isFullMap ? false : isLocationChangeable;
    const { zoom } = this.state.viewport;
    const { isMapLoaded, isSatellite } = this.state;

    const layer_attach = geo_layer_wo_geojson?.layer_form_list?.[0];

    return (
      <section
        style={{
          width: "100%",
          height: "350px",
          zIndex: 3,
        }}
      >
        <div
          style={{
            width: isMapLoaded ? 0 : "100%",
            height: isMapLoaded ? 0 : "350px",
            zIndex: 4,
            visibility: isMapLoaded ? "hidden" : "visible",
            backgroundColor: "#FAF5DD",
            textAlign: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <b>{dict?.["Peta sedang dimuat"]?.[language]}</b>
          </div>
        </div>

        {isLocationChangeable && (
          <div
            ref={this.geocoderContainerRef}
            style={{
              position: "absolute",
              top: 10,
              left: "50%",
              zIndex: 2,
              width: "90%",
              transform: "translate(-50%,0)",
            }}
          />
        )}
        <div
          id="satellite-toggle"
          style={{
            position: "absolute",
            top: 10,
            right: 0,
            zIndex: 2,
            color: isSatellite ? "white" : "black",
          }}
        ></div>
        <MapGL
          style={
            isFullMap
              ? {
                  width: "100%",
                  height: "100%",
                  visibility: isMapLoaded ? "visible" : "hidden",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  overflow: "hidden",
                }
              : {
                  width: "100%",
                  height: "350px",
                  visibility: isMapLoaded ? "visible" : "hidden",
                }
          }
          mapStyle={isSatellite ? map_styles.satellite : map_styles.default}
          accessToken={accessToken}
          latitude={latitude}
          longitude={longitude}
          zoom={zoom}
          onViewportChange={(viewport) => {
            this.set_view_port(viewport);
          }}
          attributionControl={false}
          onMoveend={(e) => {
            this.change_viewport(e);
          }}
          {...this.props}
          ref={this._map}
          onLoad={() => {
            this.setMapIsLoaded(true);
          }}
        >
          {layer_attach?.geojson?.features?.length > 0 && (
            <LayerBasic layer_attach={layer_attach} />
          )}

          {isLocationChangeable && <SearchLocation />}

          <section className="lat_long_map">
            <section className="sub_lat_long_map">
              <label style={{ color: isSatellite ? "white" : "black" }}>
                <strong>Lat</strong>
              </label>
              <input
                id="lat"
                type="number"
                value={latitude}
                placeholder="Latitude"
                onChange={(e) => this.set_latitude(e?.target?.value)}
              />
            </section>
            <section className="sub_lat_long_map">
              <label style={{ color: isSatellite ? "white" : "black" }}>
                <strong>Long</strong>
              </label>
              <input
                ig="long"
                type="number"
                value={longitude}
                placeholder="Longitude"
                onChange={(e) => this.set_longitude(e?.target?.value)}
              />
            </section>
          </section>

          <table
            style={{
              position: "absolute",
              right: "5px",
              top: "10px",
              fontSize: "15px",
            }}
          >
            <tbody>
              <tr>
                <td style={{ color: isSatellite ? "white" : "black" }}>
                  <b>Satellite</b>
                </td>
                <td>
                  <CheckBox
                    text="isSatellite"
                    title={""}
                    value={isSatellite}
                    handle={this.handleToggleMap}
                  />
                </td>
              </tr>
              {isFullMap && (
                <>
                  <tr>
                    <td></td>
                    <td>
                      <button
                        text="locationStatus"
                        className="button_action_full_map"
                        title={""}
                        value={locationStatus}
                        onClick={() => this.onGetLocation()}
                      >
                        <img src={icon_location} alt="location" />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <button
                        className="button_action_full_map"
                        onClick={() => handleFullMap()}
                      >
                        <img src={icon_full_screen_no} alt="full_map" />
                      </button>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>

          <Marker
            longitude={longitude}
            latitude={latitude}
            onDragEnd={this.onDragEnd}
            draggable={is_dragable}
          >
            {/* {!isFullMap && } */}
            <div style={style}></div>
          </Marker>
          {isFullMap ? (
            <div className="icon_full_map">
              <img src={icon_pin} alt="icon_pin" />
            </div>
          ) : (
            <button className="button_full" onClick={() => handleFullMap()}>
              <img src={icon_full_screen_active} alt="full_map" />
            </button>
          )}
        </MapGL>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {
  set_value,
})(MapForm);
