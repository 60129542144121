//LIBRARY
import axios from "axios";
import imageCompression from "browser-image-compression";

//NON LIBRARY
import PhoneNumber from "../components/common/PhoneNumber.json";
import his from "./history";
import calc_distance from "../validation/calc_distance";
import sort_array from "../validation/sort_array";
import { calculate_without_convert } from "../validation/convert_data";
import { geoServerBaseUrl } from "./baseUrl";

const SERVER_URL = geoServerBaseUrl;

/*GET METHODE*/

//get_counting_custom
export const get_counting_custom = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    const res = await axios.get(
      SERVER_URL +
      `/forms/generate_counting_custom?geo_layer_id=${params.geo_layer_id}&field_uuid=${params.field_uuid}&lat=${params.lat}&long=${params.long}`,
      config
    );
    const number = res.data.number;
    const counting_custom_array = res?.data?.counting_custom_array || [];

    const item = {
      field_uuid: params?.field_uuid,
      feature_key_array: counting_custom_array,
    };

    dispatch({
      type: "edit_counting_custom_array",
      payload: item,
    });

    dispatch({
      type: "change_text",
      payload: {
        uuid: params.field_uuid,
        value: number,
      },
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//get_data_location
export const get_data_location = (params) => async (dispatch) => {
  try {
    const res = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${params}.json?language=id&&access_token=pk.eyJ1IjoibWFwaWQiLCJhIjoiY2pqNWtnaW10MGNnMjNrcWg5MHQwY21nNiJ9.voWwMqU73TCDDua3mGCb8g`
    );

    dispatch({
      type: "get_data_location",
      payload: {
        data: res?.data?.features,
        key: params,
      },
    });
    dispatch(clearLoading());
  } catch (e) {
    dispatch(clearLoading());
  }
};

//get_data_pembanding
export const get_data_pembanding = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("get_data_pembanding"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    const res = await axios.get(
      SERVER_URL +
      `/forms/get_data_pembanding?layer_id=${params.layer_id}&feature_key=${params.feature_key}`,
      config
    );

    let data_pembanding = res.data;

    const radius_pembanding = data_pembanding?.radius_pembanding || 500;

    const layer = data_pembanding?.layer_data_list?.[0] || {};

    let features = layer?.geojson?.features || [];
    let fields = layer?.fields || [];

    const fields_highlight = fields.filter(
      (f) => f.isHighlight && !f.isStyle && !["section"].includes(f?.type)
    );

    const fields_all = fields.filter(
      (f) => !f.isStyle && !["section"].includes(f?.type)
    );

    let fields_final = [];

    if (fields_highlight.length === 0) {
      fields_final = fields_all;
    } else {
      fields_final = fields_highlight;
    }

    //melakukan filter data yang ditampilkan hanya yang masuk radius yang di atur admin
    const longitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[0];
    const latitude =
      data_pembanding?.layer?.geojson?.features?.[0]?.geometry
        ?.coordinates?.[1];

    const feature_key_pembanding_list =
      data_pembanding?.layer?.geojson?.features?.[0]?.data_pembanding_list ||
      [];

    features = features.map((feature) => {
      const long_2 = feature?.geometry?.coordinates?.[0];
      const lat_2 = feature?.geometry?.coordinates?.[1];
      let distance_km, distance_m, distance_m_string;
      if (latitude !== 0 && longitude !== 0) {
        distance_km = calc_distance(latitude, longitude, lat_2, long_2);
        distance_m = parseInt(distance_km * 1000);
        distance_m_string = new Intl.NumberFormat("id-ID", {
          style: "decimal",
        }).format(distance_m);
      } else {
        distance_km = "?";
        distance_m = "?";
        distance_m_string = "?";
      }

      feature.distance_km = distance_km;
      feature.distance_m = distance_m;
      feature.distance_m_string = distance_m_string;
      return feature;
    });

    features = features.filter(
      (feature) =>
        feature.distance_m <= radius_pembanding ||
        feature_key_pembanding_list.includes(feature.key)
    );

    features = sort_array(features, "distance_km", true);

    /* 
      mengisi feature kosong dgn hasil kalkulasi menggunakan formula seperti:
      -simple_referring,
      -calculate_dimension
      -math_operators,
      -2d_referring,
      -conditional_statement
    */

    features = calculate_without_convert(features, fields_final);

    if (layer) {
      data_pembanding.layer_data_list[0].geojson.features = features;
    }

    dispatch({
      type: "get_data_pembanding",
      payload: data_pembanding,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//get_feature
export const get_feature = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("get_feature"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    //STEP 1 mengambil data dari server
    const res = await axios.get(
      SERVER_URL +
      `/forms/get_feature?geo_layer_link=${params.geo_layer_link}&feature_key=${params.feature_key}`,
      config
    );
    let layer = res.data;

    //STEP 2 konversi string checklist ke array checklist untuk fields
    let fields_original = layer?.fields || [];

    const properties = layer?.geojson?.features?.[0]?.properties || {};

    fields_original = fields_original.map((item) => {
      const type = item.type;
      const parent_uuid = item.parent_uuid;
      const key = item.key;
      // let value = properties?.[key]; //properties.key

      let value;
      if ((item.value || item.value === 0) && properties?.[key] === undefined) {
        value = item.value;
      } else {
        value = properties?.[key];
      }

      let phone_code = 62;

      if (type === "phone_number") {
        const searchTheLocalNumber = (value, index) => {
          return String(value).slice(String(PhoneNumber[index]).length);
        };

        const searchTheCountryCode = (value) => {
          const n_of_number = PhoneNumber.length;
          for (let index = 0; index < n_of_number; index++) {
            const countryCode = Number(
              String(value).slice(0, String(PhoneNumber[index]).length)
            );

            if (PhoneNumber[index] === countryCode) {
              const value_number = Number(searchTheLocalNumber(value, index));
              return { phone_code: countryCode, value: value_number };
            }
          }
        };

        const object = searchTheCountryCode(value);

        value = object?.value || "";
        phone_code = object?.phone_code || 62;
      }

      let value_array = [];
      if (type === "checklist" && !parent_uuid) {
        value_array = value.split("\n");
      }
      return { ...item, value, value_array, phone_code };
    });
    layer.fields = fields_original;

    //STEP 3 konversi string checklist ke array checklist untuk child_array
    let child_array = layer?.geojson?.features?.[0]?.child_array || [];
    child_array = child_array.map((child) => {
      const properties = child?.properties || {};
      //filter fields_child hanya yang child_uuid nya sama
      let fields_child = fields_original.filter(
        (f) => f.parent_uuid === child.parent_uuid
      );
      //masukan value ke dalam fields_child
      fields_child = fields_child.map((item) => {
        const type = item.type;
        const key = item.key;
        let value = properties?.[key];

        let phone_code = 62;

        if (type === "phone_number") {
          const searchTheLocalNumber = (value, index) => {
            return String(value).slice(String(PhoneNumber[index]).length);
          };

          const searchTheCountryCode = (value) => {
            const n_of_number = PhoneNumber.length;
            for (let index = 0; index < n_of_number; index++) {
              const countryCode = Number(
                String(value).slice(0, String(PhoneNumber[index]).length)
              );

              if (PhoneNumber[index] === countryCode) {
                const value_number = Number(searchTheLocalNumber(value, index));
                return { phone_code: countryCode, value: value_number };
              }
            }
          };

          const object = searchTheCountryCode(value);

          value = object?.value || "";
          phone_code = object?.phone_code || 62;
        }

        let value_array = [];
        if (type === "checklist") {
          value_array = value.split("\n");
        }
        return { ...item, value, value_array, new_value: value, phone_code };
      });
      child.fields_child = fields_child;
      return child;
    });

    layer.child_array = child_array;

    dispatch({
      type: "SET_LAYER_WO_GEOJSON",
      payload: layer,
    });
    dispatch({
      type: "get_feature",
      payload: layer,
    });

    //STEP 4: Generate fields_section untuk pemisahan page jika ada section
    let fields_section = [...layer.fields];
    const idx_final = fields_section.length;
    fields_section = fields_section.map((field, idx_start) => {
      field.idx_start = idx_start;
      return field;
    });
    fields_section = fields_section.filter((field) => field.type === "section");
    //generate idx_end dengan ambil idx section setelahnya
    fields_section = fields_section.map((field, idx) => {
      const idx_start = field.idx_start;
      const idx_end = fields_section?.[idx + 1]?.idx_start || idx_final;
      field.idx_end = idx_end;
      const sub = fields_original
        .slice(idx_start + 1, idx_end)
        .map((field) => field.uuid);
      field.sub = sub;
      return field;
    });
    const idx_first_section = fields_section?.[0]?.idx_start;
    let fields_no_section = [];
    if (idx_first_section !== 0) {
      //generate fields_no_section
      fields_no_section = fields_original
        .slice(0, idx_first_section)
        .map((field) => field.uuid);
    }
    dispatch({
      type: "set_value",
      payload: {
        key: "fields_section",
        value: fields_section,
      },
    });
    dispatch({
      type: "set_value",
      payload: {
        key: "fields_no_section",
        value: fields_no_section,
      },
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const counting_poi = (params) => async (dispatch) => {
  //counting_poi
  /*
  params = {
    lat,
    long,
    poi_type,
    poi_rad, //in km
    uuid,
    child_uuid
  }
  */
  const { lat, long, poi_type, poi_rad, uuid, child_uuid } = params;
  try {
    dispatch(setLoading("counting_poi"));
    //step 1 ambil data dari api counting
    dispatch({
      type: "set_value",
      payload: {
        key: "uuid_process",
        value: uuid,
      },
    });
    const res = await axios.get(
      SERVER_URL +
      `/sini/generate_counting_poi?poi_rad=${poi_rad}&lat=${lat}&long=${long}&poi_type=${poi_type}`
    );
    //step 2 masukan respon ke dalam value field yang bersangkutan
    const value = res?.data || 0;
    const body = { uuid, value };
    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: value,
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: value,
          child_uuid,
        },
      });
    }
    dispatch(clearLoading());
    dispatch({
      type: "check_poi_status",
      payload: body,
    });
  } catch (e) {
    const body = { uuid, value: 0 };
    dispatch({
      type: "change_text",
      payload: body,
    });
    dispatch({
      type: "check_poi_status",
      payload: body,
    });
    dispatch(clearLoading());
  }
};

export const get_administrasi = (params) => async (dispatch) => {
  /*
  get_administrasi
  contoh:https://data.mapid.io/counting_poi?km_rad=0.3&lat=-6.1784072398481555&long=106.84251626772414&mode=kota

params = {
  lat,
  long,
  mode
}
*/
  dispatch(setLoading("get_kota"));
  const { lat, long, mode, uuid, child_uuid } = params;
  try {
    //step 1 ambil data dari api counting

    dispatch({
      type: "set_value",
      payload: {
        key: "uuid_process",
        value: uuid,
      },
    });

    const res = await axios.get(
      SERVER_URL +
      `/sini/generate_city_name?lat=${lat}&long=${long}&mode=${mode}`
    );

    //step 2 masukan respon ke dalam value field yang bersangkutan
    const value = res?.data || "Not found";

    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: value,
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: value,
          child_uuid,
        },
      });
    }

    dispatch(clearLoading());
  } catch (e) {
    const body = { uuid, value: "Not found" };

    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: uuid,
          value: "Not found",
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: uuid,
          value: "Not found",
          child_uuid,
        },
      });
    }

    dispatch({
      type: "check_poi_status",
      payload: body,
    });
    dispatch(clearLoading());
  }
};

//get_task_list
export const get_task_list = () => async (dispatch) => {
  try {
    dispatch(setLoading("get_task_list"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    const res = await axios.get(
      SERVER_URL + `/layers_new/get_task_list`,
      config
    );

    dispatch({
      type: "get_task_list",
      payload: res.data,
    });

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//getFieldsPrivate
export const getFieldsPrivate = (params) => async (dispatch) => {
  try {
    dispatch(setLoading("getFieldsPrivate"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid
          ? localStorage.jwtTokenMapid
          : "",
      },
    };

    const res = await axios.get(
      SERVER_URL +
      `/forms/get_field_private_by_link?geo_layer_link=${params?.geo_layer_link
      }&apraisal_id=${params?.apraisal_id && params?.apraisal_id !== undefined
        ? params?.apraisal_id
        : ""
      }`,
      config
    );

    let layer = res.data;

    const fields = layer?.fields || [];
    let fields_section = [...layer.fields];
    const idx_final = fields_section.length;

    fields_section = fields_section.map((field, idx_start) => {
      field.idx_start = idx_start;
      return field;
    });

    fields_section = fields_section.filter((field) => field.type === "section");

    //generate idx_end dengan ambil idx section setelahnya
    fields_section = fields_section.map((field, idx) => {
      const idx_start = field.idx_start;

      const idx_end = fields_section?.[idx + 1]?.idx_start || idx_final;
      field.idx_end = idx_end;

      const sub = fields
        .slice(idx_start + 1, idx_end)
        .map((field) => field.uuid);
      field.sub = sub;
      return field;
    });

    const idx_first_section = fields_section?.[0]?.idx_start;

    let fields_no_section = [];

    if (idx_first_section !== 0) {
      //generate fields_no_section
      fields_no_section = fields
        .slice(0, idx_first_section)
        .map((field) => field.uuid);
    }

    dispatch({
      type: "set_value",
      payload: {
        key: "fields_section",
        value: fields_section,
      },
    });

    dispatch({
      type: "set_value",
      payload: {
        key: "fields_no_section",
        value: fields_no_section,
      },
    });

    dispatch({
      type: "SET_LAYER_WO_GEOJSON",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//getNotifications
export const getNotifications = () => (dispatch) => {
  dispatch(setLoading("getNotifications"));
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid ? localStorage.jwtTokenMapid : "",
    },
  };
  axios
    .get(SERVER_URL + `/notifications/get_by_user`, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "GET_NOTIFICATION",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//getDocForm
export const getDocForm = (geo_layer_id) => async (dispatch) => {
  try {
    dispatch(setLoading("getDocForm"));
    const res = await axios.get(
      SERVER_URL + `/blog/get_detail_by_layer_form/${geo_layer_id}/FORM`
    );
    dispatch({
      type: "GET_DOC_FORM",
      payload: res.data,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//getDetailLayerByLinkWOgeojson
export const getDetailLayerByLinkWOgeojson = (link) => (dispatch) => {
  const config = {
    headers: {
      accesstoken: localStorage?.jwtTokenMapid,
    },
  };
  axios
    .get(SERVER_URL + `/layers/get_detail_wo_geojson_by_link/${link}`, config)
    .then((res) => {
      dispatch({
        type: "SET_LAYER_WO_GEOJSON",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: "SET_MESSAGE",
        payload: err && err.response ? err.response.data : {},
      });
    });
};

//getCitarumPos
export const getCitarumPos = (latitude, longitude) => {
  return new Promise((resolve) => {
    const url = `${SERVER_URL}/geocode/citarum?lat=${latitude}&long=${longitude}`;
    axios
      .get(url)
      .then((res) => {
        resolve({ msg: "success", data: res.data });
      })
      .catch((err) => {
        resolve({ msg: "error" });
      });
  });
};

//getCitarumRiver
export const getCitarumRiver = (latitude, longitude) => {
  return new Promise((resolve) => {
    const url = `${SERVER_URL}/geocode/citarum/river?lat=${latitude}&long=${longitude}`;
    axios
      .get(url)
      .then((res) => {
        resolve({ msg: "success", data: res.data });
      })
      .catch((err) => {
        resolve({ msg: "error" });
      });
  });
};

//getAdministrative Get Data Kel/Kec/Kot/Prov
export const getAdministrative = (latitude, longitude) => {
  return new Promise((resolve) => {
    const url = `https://data.mapid.io/get_polygon_adm?mode=kel&lat=${latitude}&long=${longitude}`;
    axios
      .get(url)
      .then((res) => {
        resolve({ msg: "success", data: res.data });
      })
      .catch((err) => {
        resolve({ msg: "error" });
      });
  });
};

/*POST METHODE*/

export const submit_data_pembanding = (body) => async (dispatch) => {
  //submit_data_pembanding
  /*
body = {
  geo_layer_id,
  feature_key,
  data_pembanding_list
}
*/
  try {
    dispatch(setLoading("submit_data_pembanding"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    await axios.post(
      SERVER_URL + `/forms/submit_data_pembanding`,
      body,
      config
    );

    // his.push(`?status=success`);

    // window.location.href = `${url}?status=success`;

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

export const submit_rev = (body) => async (dispatch) => {
  //submit_rev
  /*
body = {
  geo_layer_id,
  feature_key,
  geometry,
  properties,
  child_array
}
*/
  try {
    dispatch(setLoading("submit_rev"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };

    await axios.post(SERVER_URL + `/forms/submit_rev`, body, config);
    dispatch(clearLoading());

    his.push(`/success/response/${body.link}`);
  } catch (error) {
    dispatch(clearLoading());
  }
};

//push_feature
export const push_feature = (item) => async (dispatch) => {
  try {
    dispatch(setLoading("pushFeature"));
    const config = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid
          ? localStorage.jwtTokenMapid
          : "",
      },
    };
    const body = { ...item, src: "form_mapid" };
    const res = await axios.post(
      SERVER_URL + "/forms/push_feature",
      body,
      config
    );

    dispatch({
      type: "PUSH_FEATURE",
      payload: item,
    });
    dispatch({
      type: "SET_MESSAGE",
      payload: res.data,
    });
    dispatch(clearLoading());

    if (item?.feature_key_appraisal && item?.apraisal_id) {
      his.push(
        `/menu/input_data_pembanding/${item?.apraisal_id}/${item?.feature_key_appraisal}`
      );
    } else {
      his.push(`/success/response/${item.link}`);
    }
  } catch (error) {
    dispatch(clearLoading());
  }
};

//editProperties
export const editProperties = (item) => (dispatch) => {
  dispatch(setLoading("edit_properties_form"));
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid ? localStorage.jwtTokenMapid : "",
    },
  };
  axios
    .post(SERVER_URL + "/layers/edit_properties_form_new", item, config)
    .then((res) => {
      dispatch({
        type: "EDIT_PROPERTIES",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      dispatch(clearLoading());
      if (item.isRegular) {
        his.push(`/success/response/${item.link}`);
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//editComplete
export const editComplete = (item) => (dispatch) => {
  dispatch(setLoading("edit_feature_complete"));
  const config = {
    headers: {
      accesstoken: localStorage.jwtTokenMapid ? localStorage.jwtTokenMapid : "",
    },
  };
  axios
    .post(SERVER_URL + "/layers/edit_feature_complete", item, config)
    .then((res) => {
      dispatch({
        type: "EDIT_COMPLETE",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
      dispatch(clearLoading());
      if (item.isRegular) {
        his.push(`/success/response/${item.link}`);
      }
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//commentToLayer
export const commentToLayer = (item) => (dispatch) => {
  dispatch(setLoading("commentToLayer"));
  const config = {
    headers: {
      accesstoken: localStorage?.jwtTokenMapid,
    },
  };
  axios
    .post(SERVER_URL + "/notifications/push_comment_to_layer", item, config)
    .then((res) => {
      dispatch(clearLoading());
      dispatch({
        type: "COMMENT_TO_LAYER",
        payload: item,
      });
      dispatch({
        type: "SET_MESSAGE",
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(clearLoading());
    });
};

//applyForm Surveyor mendaftar ikut serta form
export const applyForm = (body) => async (dispatch) => {
  try {
    dispatch(setLoading("applyForm"));
    const config = {
      headers: {
        accesstoken: localStorage?.jwtTokenMapid,
      },
    };
    await axios.post(SERVER_URL + `/layers/apply_form`, body, config);
    dispatch({
      type: "APPLY_FORM",
      payload: body,
    });
    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

//pushImageDataset
export const pushImageDataset = (item) => async (dispatch) => {
  try {
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(setLoading("pushImageDataset"));

    let field = item.field;
    let field_clone = Object.assign({}, field);
    field_clone.defaultValue = "";
    dispatch({
      type: "SET_IMAGE",
      payload: field_clone,
    });
    const { objParams } = item;
    const options_compression = {
      maxSizeMB: 1.6,
      maxWidthOrHeight: 1600,
      useWebWorker: true,
    };

    //Original file
    const file = objParams.Body;

    //Compressed file
    const compressedFile = await imageCompression(file, options_compression);
    const file_name = item.file_name
    // objParams.Body.name;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", compressedFile);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );

    console.log("res image", resultBigUpload?.data?.data)
    const url_new = resultBigUpload.data.data.Location;
    console.log("url_new", url_new)


    const { child_uuid } = item;

    if (!child_uuid) {
      console.log('1')
      dispatch({
        type: "change_text",
        payload: {
          uuid: field.uuid,
          value: url_new,
        },
      });
    } else {
      console.log('2')
      dispatch({
        type: "change_child",
        payload: {
          uuid: field.uuid,
          value: url_new,
          child_uuid,
        },
      });
    }

    dispatch(clearLoading());
    return url_new;
  } catch (error) {
    dispatch(clearLoading());
  }
};

//stright_push_file
export const stright_push_file = (item, extension) => async (dispatch) => {
  try {
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(setLoading("pushImageDataset"));

    const file = item?.Body;
    const filename = file?.name;

    const formData = new FormData();
    formData.append("original_file_name", `${filename}`);
    formData.append("file", file);
    formData.append("extension", extension);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );

    const url_new = resultBigUpload.data.data.Location;
    dispatch(clearLoading());
    return url_new;
  } catch (error) {
    dispatch(clearLoading());
  }
};

//push_file
export const push_file = (item) => async (dispatch) => {
  try {
    const config_header_upload = {
      headers: {
        accesstoken: localStorage.jwtTokenMapid,
        "Content-Type": "multipart/form-data",
      },
    };

    dispatch(setLoading("pushImageDataset"));

    let field = item.field;
    let field_clone = Object.assign({}, field);
    field_clone.defaultValue = "";
    dispatch({
      type: "SET_IMAGE",
      payload: field_clone,
    });
    const { objParams } = item;
    //Original file
    const file = objParams.Body;
    //Compressed file
    const file_name = item.file_name;
    const formData = new FormData();
    formData.append("original_file_name", `${file_name}`);
    formData.append("file", file);
    const resultBigUpload = await axios.post(
      SERVER_URL + "/upload",
      formData,
      config_header_upload
    );
    const url_new = resultBigUpload.data.data.Location;

    const { child_uuid } = item;

    if (!child_uuid) {
      dispatch({
        type: "change_text",
        payload: {
          uuid: field.uuid,
          value: url_new,
        },
      });
    } else {
      dispatch({
        type: "change_child",
        payload: {
          uuid: field.uuid,
          value: url_new,
          child_uuid,
        },
      });
    }

    dispatch(clearLoading());
  } catch (error) {
    dispatch(clearLoading());
  }
};

/*NON API*/

export const setLayerWOgeojson = (content) => {
  return {
    type: "SET_LAYER_WO_GEOJSON",
    payload: content.geo_layer,
  };
};

export const setLoading = (itemLoading) => {
  return {
    type: "SET_LOADING_FORM",
    payload: itemLoading,
  };
};

export const clearLoading = () => {
  return {
    type: "CLEAR_LOADING_FORM",
  };
};

//set_value
export const set_value = (key, value) => (dispatch) => {
  dispatch({
    type: "set_value",
    payload: { key, value },
  });
};

/*-----CHILD-----*/

//set_child_index
/*
body = { parent_uuid, child_uuid, level }
*/
export const set_child_index = (body) => {
  return {
    type: "set_child_index",
    payload: body,
  };
};

//add_child
/*
body = { parent_uuid, parent_uuid_temp, parent_uuid_table, status }
*/
export const add_child = (body) => {
  return {
    type: "add_child",
    payload: body,
  };
};

//delete_child
/*
body = { child_uuid }
*/
export const delete_child = (body) => {
  return {
    type: "delete_child",
    payload: body,
  };
};

//1. change_child
/*
body = { uuid, value, child_uuid }
*/
export const change_child = (body) => {
  return {
    type: "change_child",
    payload: body,
  };
};

//2. click_check_child
/*
body = { uuid, value, child_uuid }
*/
export const click_check_child = (body) => {
  return {
    type: "click_check_child",
    payload: body,
  };
};

//3. remove_check_child
/*
body = { uuid, value, child_uuid }
*/
export const remove_check_child = (body) => {
  return {
    type: "remove_check_child",
    payload: body,
  };
};

//4. select_other_child
/*
body = { uuid, value, child_uuid }
*/
export const select_other_child = (body) => {
  return {
    type: "select_other_child",
    payload: body,
  };
};

//5. change_phone_code_child
/*
body = { uuid, value, child_uuid }
*/
export const change_phone_code_child = (body) => {
  return {
    type: "change_phone_code_child",
    payload: body,
  };
};

/*-----PARENT-----*/

//1. change_text
/*
body = { uuid, value }
*/
export const change_text = (body) => {
  return {
    type: "change_text",
    payload: body,
  };
};

//2. click_check
/*
body = { uuid, value }
*/
export const click_check = (body) => {
  return {
    type: "click_check",
    payload: body,
  };
};

//3. remove_check
/*
body = { uuid, value }
*/
export const remove_check = (body) => {
  return {
    type: "remove_check",
    payload: body,
  };
};

//4. select_other
/*
body = { uuid, value: true || false }
*/
export const select_other = (body) => {
  return {
    type: "select_other",
    payload: body,
  };
};

//5. change_phone_code
/*
body = { uuid, value }
*/
export const change_phone_code = (body) => {
  return {
    type: "change_phone_code",
    payload: body,
  };
};

/*OFFLINE MODE*/

//Delete form offline
export const deleteFormOffline = (id_offline) => {
  let form_list_offline = localStorage.form_list_offline
    ? JSON.parse(localStorage.form_list_offline)
    : [];
  if (form_list_offline.length > 0) {
    form_list_offline = form_list_offline.filter(
      (element) => element?.geo_layer_wo_geojson?.id_offline !== id_offline
    );
  }
  localStorage.setItem("form_list_offline", JSON.stringify(form_list_offline));
  return {
    type: "SET_FORM_OFFLINE",
    payload: form_list_offline,
  };
};

//Save form offline (overwrite)
export const saveFormOffline = (item, coordinate) => {
  const { latitude, longitude } = coordinate;
  let form_list_offline = localStorage.form_list_offline
    ? JSON.parse(localStorage.form_list_offline)
    : [];

  const body_form = {
    geo_layer_wo_geojson: item,
    latitude,
    longitude
  }

  if (form_list_offline.length > 0) {
    const index = form_list_offline.findIndex(
      (element) => element?.geo_layer_wo_geojson?.id_offline === item.id_offline
    );
    form_list_offline.splice(index, 1, body_form);
  }
  localStorage.setItem("form_list_offline", JSON.stringify(form_list_offline));
  return {
    type: "SET_FORM_OFFLINE",
    payload: form_list_offline,
  };
};

//Push layer to offline list
export const pushFormOffline = (item, coordinate = {}) => {
  const { latitude, longitude } = coordinate;
  let form_list_offline = localStorage.form_list_offline
    ? JSON.parse(localStorage.form_list_offline)
    : [];

  const body_form = {
    geo_layer_wo_geojson: item,
    latitude,
    longitude
  }

  form_list_offline.push(body_form);
  localStorage.setItem("form_list_offline", JSON.stringify(form_list_offline));
  return {
    type: "SET_FORM_OFFLINE",
    payload: form_list_offline,
  };
};

//Delete all form offline
export const clearFormOffline = () => {
  localStorage.setItem("form_list_offline", JSON.stringify([]));
  return {
    type: "SET_FORM_OFFLINE",
    payload: [],
  };
};

//set offline detail
export const setOfflineDetail = (id_offline) => (dispatch) => {
  let form_list_offline = localStorage.form_list_offline
    ? JSON.parse(localStorage.form_list_offline)
    : [];
  let form = {};
  if (form_list_offline.length > 0) {
    const index = form_list_offline.findIndex(
      (element) => element?.geo_layer_wo_geojson?.id_offline === id_offline
    );
    form = form_list_offline[index];
  }

  if (form?.longitude && form?.latitude && form?.geo_layer_wo_geojson?.type === "Point") {
    dispatch({
      type: "set_value",
      payload: {
        key: "longitude",
        value: form?.longitude,
      },
    });

    dispatch({
      type: "set_value",
      payload: {
        key: "latitude",
        value: form?.latitude,
      },
    });
  }

  dispatch({
    type: "SET_LAYER_WO_GEOJSON",
    payload: form?.geo_layer_wo_geojson,
  });
};

export const set_child_array = (body) => {
  //set_child_array
  /*
body = {
  child_array
}
*/
  return {
    type: "set_child_array",
    payload: body,
  };
};

export const push_feature_key_pembanding_list = (feature_key) => {
  //push_feature_key_pembanding_list
  return {
    type: "push_feature_key_pembanding_list",
    payload: feature_key,
  };
};

export const pull_feature_key_pembanding_list = (feature_key) => {
  //pull_feature_key_pembanding_list
  return {
    type: "pull_feature_key_pembanding_list",
    payload: feature_key,
  };
};
