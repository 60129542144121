/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  get_feature,
  getFieldsPrivate,
  set_value,
} from "../../actions/formActions";
import { set_value_properties } from "../../actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/
import MapForm from "../universal_form_sub_components/MapForm";
import TableOverlay from "../form/TableOverlay";
import dict from "../../validation/dict.json";

class STEP_2_OFFLINE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullMap: false,
      locationLoading: false,
      locationStatus: false,
      accuracy: 0,
    };
  }

  handleFullMap = () => {
    const { isFullMap } = this.state;
    this.setState({
      isFullMap: !isFullMap,
    });
  };

  onGetLocation = () => {
    this.setState({ locationLoading: true });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      return null;
    }
  };

  showPosition = (position) => {
    const latitude = Number(parseFloat(position.coords.latitude).toFixed(5));
    const longitude = Number(parseFloat(position.coords.longitude).toFixed(8));
    const accuracy = parseInt(position.coords.accuracy);
    this.props.set_value("latitude", latitude);
    this.props.set_value("longitude", longitude);

    this.set_polygon_feature({ latitude, longitude });

    this.setState({
      accuracy,
      locationStatus: true,
      locationLoading: false,
    });
  };

  set_polygon_feature = ({ latitude, longitude }) => {
    const layer_attach =
      this?.props?.form?.geo_layer_wo_geojson?.layer_form_list?.[0];
    if (
      layer_attach?.type === "Polygon" ||
      layer_attach?.type === "MultiPolygon"
    ) {
      if (layer_attach?.geojson?.features?.length) {
        const fields_polygon = layer_attach?.fields.filter(
          (f) => !f.isStyle && !f.isHide
        );

        const geojson_polygon = layer_attach?.geojson;
        const geojson_point = {
          type: "Point",
          coordinates: [longitude, latitude],
        };

        const glookup = new GeoJsonGeometriesLookup(geojson_polygon);

        const geojson_polygon_filtered = glookup.getContainers(geojson_point);

        if (geojson_polygon_filtered?.features?.length > 0) {
          this.props.set_value(
            "geojson_polygon_filtered",
            geojson_polygon_filtered
          );
          this.props.set_value("fields_polygon", fields_polygon);
        }
      }
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const { accuracy, locationLoading, locationStatus, handleAddScreenStatus } =
      this.props;
    const { geo_layer_wo_geojson } = this.props.form;
    const { isFullMap } = this.state;

    const isLocationChangeable = geo_layer_wo_geojson?.isLocationChangeable;

    let geolocate_button;
    if (locationLoading) {
      geolocate_button = (
        <div className="button_standard">{dict["Mencari"][language]}...</div>
      );
    } else {
      geolocate_button = (
        <div className="button_standard" onClick={this.onGetLocation}>
          {locationStatus
            ? dict["Perbarui lokasi"][language]
            : dict["Aktifkan lokasi"][language]}
        </div>
      );
    }

    const screen_2 = (
      <div>
        <div
          className="container_light"
          id="white_background"
          style={{
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          <table style={{ width: "100%", textAlign: "left" }}>
            <tbody>
              <tr>
                <td>Status</td>
                <td>
                  {locationStatus
                    ? dict["lokasi GPS didapatkan"][language]
                    : dict["belum klik lokasi"][language]}
                </td>
              </tr>
              <tr>
                <td>{dict["Akurasi"][language]}</td>
                <td>
                  {locationStatus ? `${accuracy} meter` : "belum klik lokasi"}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: "10px" }}>{geolocate_button}</div>
        </div>

        <TableOverlay />

        <MapForm
          set_polygon_feature={this.set_polygon_feature}
          locationStatus={locationStatus}
          isFullMap={isFullMap}
          handleFullMap={this.handleFullMap}
        />

        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {isLocationChangeable
            ? "Administrator survei membolehkan titik digeser manual"
            : "Administrator survei tidak membolehkan titik digeser, tombol lanjut akan muncul jika tombol Aktifkan Lokasi telah ditekan dan pengambilan lokasi GPS diijinkan"}
        </div>
        {(locationStatus || isLocationChangeable) && (
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <div onClick={handleAddScreenStatus} className="button_standard">
              {dict["Lanjut"][language]}
            </div>
          </div>
        )}
      </div>
    );
    return screen_2;
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
});

export default connect(mapStateToProps, {
  get_feature,
  set_value,
  getFieldsPrivate,
  set_value_properties,
})(STEP_2_OFFLINE);
