import React from "react";

export const mediaBlockRenderer = (block) => {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }
  return null;
};

const Image = (props) => {
  if (!!props.src) {
    return <img src={props.src} alt="MAPID" className="textImage" />;
  }
  return null;
};

const Link = (props) => {
  if (!!props.link_url) {
    return (
      <a
        href={String(props.link_url)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.link_text}
      </a>
    );
  }
  return null;
};

const Media = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();
  let media;
  if (type === "image") {
    const { src } = entity.getData();
    media = <Image src={src} />;
  } else if (type === "link") {
    const { link_text, link_url } = entity.getData();
    media = <Link link_text={link_text} link_url={link_url} />;
  }
  return media;
};
