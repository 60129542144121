//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Modal from "../common/Modal";
import RevMessage from "./RevMessage";

//Redux function
import { get_task_list } from "../../actions/formActions";
import { set_value_properties } from "../../actions/propertiesActions";

//Picture Asset

//General Function
import dict from "../../validation/dict.json";

class TaskList extends Component {
  state = {
    modal_message: false,
    manifest_id: "",
    feature_key: "",
    status_filter: {
      is_active: false,
      progress: {
        is_active: true,
        upload: true,
        digitasi: true,
        assign: true,
        submit_rev: true,
        on_rev: true,
        accepted: false,
      },
    },
  };

  componentDidMount() {
    this.props.get_task_list();
  }

  toggle_filter_container = () => {
    const { status_filter } = this.state;
    this.setState({
      status_filter: {
        ...status_filter,
        is_active: !status_filter?.is_active,
      },
    });
  };

  toggle_message = (body) => {
    this.setState({ modal_message: !this.state.modal_message });
    if (body) {
      this.props.set_value_properties({
        key: "manifest_id",
        value: body.manifest_id,
      });
      this.props.set_value_properties({
        key: "feature_key",
        value: body.feature_key,
      });
    }
  };

  toggle_status_progress = () => {
    const { status_filter } = this.state;
    this.setState({
      status_filter: {
        ...status_filter,
        progress: {
          ...status_filter?.progress,
          is_active: !status_filter?.progress?.is_active,
        },
      },
    });
  };

  toggle_status_list = (key) => {
    const { status_filter } = this.state;
    this.setState({
      status_filter: {
        ...status_filter,
        progress: {
          ...status_filter?.progress,
          [key]: !status_filter?.progress?.[key],
        },
      },
    });
  };

  get_status = () => {
    const { progress } = this.state?.status_filter;
    if (progress?.is_active) {
      const keys = Object.keys(progress).filter(
        (key) => progress?.[key] === true
      );
      return keys;
    }
    return progress?.accepted ? ["accepted"] : [];
  };

  render() {
    //local storage
    const { language } = this.props;

    //local state
    const { modal_message, status_filter } = this.state;
    const { progress, is_active } = status_filter;

    //global props
    const { task_list } = this.props.form;

    //content
    const status_array = [
      {
        name: "upload",
        step: 1,
      },
      {
        name: "digitasi",
        step: 1,
      },
      {
        name: "assign",
        step: 1,
      },
      {
        name: "submit_rev",
        step: 2,
      },
      {
        name: "on_rev",
        step: 3,
      },
      {
        name: "accepted",
        step: 4,
      },
    ];

    const step_array = [
      {
        step: 1,
        name: "Penugasan",
        eng: "Assignment",
      },
      {
        step: 2,
        name: "Submit",
        eng: "Submit",
      },
      {
        step: 3,
        name: "Revisi",
        eng: "Revision",
      },
      {
        step: 4,
        name: "Selesai",
        eng: "Finish",
      },
    ];

    let content = null;

    if (task_list?.length === 0) {
      content = <main className="container_light">-</main>;
    } else {
      content = (
        <main>
          {task_list.map((manifest, idx) => {
            const layer = manifest.layer;
            const features =
              layer?.geojson?.features?.filter((feature) =>
                this.get_status()?.includes(feature?.formStatus?.status)
              ) || [];
            const fields = layer?.fields || [];

            let fields_top = fields.filter(
              (f) =>
                f?.isHighlight &&
                ![
                  "calculate_dimension",
                  "simple_referring",
                  "math_operators",
                ].includes(f?.type)
            );

            if (fields_top.length === 0) {
              fields_top = fields?.slice(0, 1);
            }

            let list_task;

            if (features.length === 0) {
              list_task = (
                <main className="container_light">
                  {
                    dict["There are no assignments for you in this survey yet"][
                      language
                    ]
                  }
                </main>
              );
            } else {
              list_task = (
                <section>
                  {features.map((feature, idx) => {
                    const status = feature?.formStatus?.status;
                    const step = status_array.find(
                      (item) => item?.name === status
                    )?.step;

                    const body = {
                      manifest_id: manifest?._id,
                      feature_key: feature?.key,
                    };

                    return (
                      <div key={idx} className="container_light margin_bottom">
                        <button
                          className="button_circle margin_right"
                          id="green"
                        >
                          {idx + 1}
                        </button>

                        <table className="table_2">
                          <tbody>
                            <tr>
                              <td>
                                <section>
                                  {fields_top.map((f, idx) => {
                                    return (
                                      <div key={idx} className="margin_bottom">
                                        <p className="text_inferior">
                                          {f?.name}
                                        </p>
                                        <p className="text_bold">
                                          {feature?.properties?.[f?.key] || "-"}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </section>
                              </td>
                              <td>
                                <section className="text_right">
                                  <Link
                                    className="button margin_bottom text_left"
                                    id="blue"
                                    to={`/${manifest?.link}/step_1/${feature?.key}`}
                                    style={{ minWidth: "90px" }}
                                  >
                                    {dict["Survey"][language]}
                                    <br />
                                    Appraisal
                                  </Link>
                                  {manifest?.layer_data_list?.length > 0 && (
                                    <>
                                      <br />
                                      <Link
                                        className="button text_left"
                                        id="blue"
                                        to={`/menu/input_data_pembanding/${manifest?._id}/${feature?.key}`}
                                        style={{ minWidth: "90px" }}
                                      >
                                        {dict["Data Survey"][language]}
                                        <br />
                                        {dict["Comparator"][language]}
                                      </Link>
                                    </>
                                  )}
                                </section>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <br />

                        {step_array.map((item, idx) => {
                          let arrow =
                            idx < step_array?.length - 1 ? (
                              <div className="arrow_right grid_item" />
                            ) : null;
                          const content = (
                            <div className="grid_item" key={idx}>
                              <button
                                className="button_small text_center grid_item margin"
                                id={
                                  step === item?.step
                                    ? "black_absolute"
                                    : "outline_black"
                                }
                              >
                                {language === "ina" ? item?.name : item?.eng}
                              </button>
                              {arrow}
                            </div>
                          );
                          return content;
                        })}
                        {feature?.formStatus?.revision_list?.length > 0 && (
                          <section>
                            <button
                              className="button margin_top"
                              id="red"
                              onClick={this.toggle_message.bind(this, body)}
                            >
                              {dict["Open revision message"][language]}
                            </button>
                          </section>
                        )}
                      </div>
                    );
                  })}
                </section>
              );
            }

            return (
              <main key={idx}>
                <p className="text_bold margin_bottom">{layer?.name}</p>
                {list_task}
                <hr />
                <br />
              </main>
            );
          })}
        </main>
      );
    }

    const filter_status_content = (
      <main className="container_white border noselect">
        <header className="gentle_header flex justify_between">
          <h1>Filter</h1>
          <button className="button" onClick={this.toggle_filter_container}>
            {is_active ? dict["Close"][language] : dict["Open"][language]}
          </button>
        </header>

        {is_active && (
          <section className="container_checkbox">
            <div>
              <div className="container_checkbox">
                <label>{dict["Finish"][language]}</label>
                <input
                  className="checkmark"
                  type="checkbox"
                  checked={progress?.accepted}
                  onChange={() => this.toggle_status_list("accepted")}
                />
              </div>
              <div className="container_checkbox">
                <label>{dict["Progress"][language]}</label>
                <input
                  className="checkmark"
                  type="checkbox"
                  checked={progress?.is_active}
                  onChange={this.toggle_status_progress}
                />
              </div>
            </div>
            {progress?.is_active && (
              <div>
                <div className="container_checkbox">
                  <label>{dict["Upload"][language]}</label>
                  <input
                    className="checkmark"
                    type="checkbox"
                    checked={progress?.upload}
                    onChange={() => this.toggle_status_list("upload")}
                  />
                </div>
                <div className="container_checkbox">
                  <label>{dict["Digitization"][language]}</label>
                  <input
                    className="checkmark"
                    type="checkbox"
                    checked={progress?.digitasi}
                    onChange={() => this.toggle_status_list("digitasi")}
                  />
                </div>
                <div className="container_checkbox">
                  <label>{dict["Assign"][language]}</label>
                  <input
                    className="checkmark"
                    type="checkbox"
                    checked={progress?.assign}
                    onChange={() => this.toggle_status_list("assign")}
                  />
                </div>

                <div className="container_checkbox">
                  <label>{dict["Submit"][language]}</label>
                  <input
                    className="checkmark"
                    type="checkbox"
                    checked={progress?.submit_rev}
                    onChange={() => this.toggle_status_list("submit_rev")}
                  />
                </div>
                <div className="container_checkbox">
                  <label>{dict["Revision"][language]}</label>
                  <input
                    className="checkmark"
                    type="checkbox"
                    checked={progress?.on_rev}
                    onChange={() => this.toggle_status_list("on_rev")}
                  />
                </div>
              </div>
            )}
          </section>
        )}
      </main>
    );

    const modal_message_content = modal_message && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_message}
        onClose={this.toggle_message}
      >
        <div className="box-body">
          <RevMessage />
        </div>
      </Modal>
    );

    return (
      <div className="margin_bottom" style={{ marginTop: "20px" }}>
        <p className="text_header">{dict["Survey Assignment"][language]}</p>
        <br />
        {filter_status_content}
        <br />
        {content}
        {modal_message_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  get_task_list,
  set_value_properties,
})(TaskList);
