//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import Modal from "../common/Modal";
import SpinnerWhite from "../common/SpinnerWhite";
import TextFieldGroupTight from "../common/TextFieldGroupTight";
import UploadPP from "./UploadPP";

//Redux function
import {
  logoutUser,
  getUserFromToken,
  updateFullname,
} from "../../actions/authActions";

//Picture Asset
import badge from "../../img/badge_notification.png";
import icon_profile from "../../img/icon_profile.svg";

//General Function
import isEqual from "../../validation/is-equal";
import isEmpty from "../../validation/is-empty";
import checkBrowser from "../../validation/check-browser";
import checkDevice from "../../validation/check-device";
import { isNotif, urlB64ToUint8Array } from "../../validation/worker-stuff";
import domain_list from "../../validation/domain_list";

import dict from "../../validation/dict.json";

const domain = window.location.hostname;

const logo = domain_list?.[domain]?.logo;

function displayNotif() {
  if ("serviceWorker" in navigator) {
    var options = {
      body: "Anda akan menerima notifikasi dari MAPID",
      icon: logo,
      // image: image,
      dir: "ltr",
      lang: "en-US", //BCP 47
      vibrate: [100, 50, 200],
      badge: badge,
      tag: "MAPID",
      renotify: true,
      actions: [{ action: "ok", title: "Okay", icon: logo }],
    };
    navigator.serviceWorker.ready.then(function (swreg) {
      swreg.showNotification("MAPID", options);
    });
  }
}
const SERVER_URL = "https://api.mapid.io";
function configurePushSub() {
  if (!("serviceWorker" in navigator)) {
    return;
  }
  var reg;
  navigator.serviceWorker.ready
    .then(function (swreg) {
      reg = swreg;
      return swreg.pushManager.getSubscription();
    })
    .then(function (sub) {
      if (sub === null) {
        var vapidPublicKey =
          "BJweY8HYxau5mgEUWF7F-k0E3S_bLn1px6v9cI28_Cp0lLASaE51OJbnuQpwVlOw1Xx4bva9i4mYTwdJbSLYcb8";
        var convertedKey = urlB64ToUint8Array(vapidPublicKey);
        return reg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedKey,
        });
      } else {
        displayNotif();
      }
    })
    .then(function (newSub) {
      const browser = checkBrowser();
      const device = checkDevice();
      const other = { browser, device };
      const stringSub = JSON.stringify(newSub);
      const jsonSub = JSON.parse(stringSub);
      const newSubGabungan = Object.assign(jsonSub, other);
      return fetch(SERVER_URL + "/users/sub", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          accesstoken: localStorage.jwtTokenMapid,
        },
        body: JSON.stringify(newSubGabungan),
      });
    })
    .then(function (res) {
      if (res.ok) {
        displayNotif();
      }
    })
    .catch(function (err) { });
}

class UserDashboard extends Component {
  state = {
    isSupportNotif: isNotif(),
    modalWithdraw: false,
    modalEditName: false,
    full_name: "",
    errors: {},
  };
  componentDidMount() {
    this.props.getUserFromToken();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.auth.user, prevProps.auth.user)) {
      this.setState({ modalEditName: false });
    }
  }
  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleUpdateFullname = (e) => {
    e.preventDefault();
    const { full_name } = this.state;
    const body = {
      full_name,
    };
    this.props.updateFullname(body);
  };
  toggleEditName = () => {
    const { user } = this.props.auth;
    const full_name = user?.full_name ? user?.full_name : "";
    this.setState({ modalEditName: !this.state.modalEditName, full_name });
  };
  toggleWithdraw = () => {
    this.setState({ modalWithdraw: !this.state.modalWithdraw });
  };
  onLogoutClick() {
    this.props.logoutUser();
  }
  askNotification = () => {
    Notification.requestPermission((result) => {
      if (result !== "granted") {
      } else {
        configurePushSub();
      }
    });
  };
  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    const { isSupportNotif, modalEditName, full_name, errors } = this.state;
    const { loadingDetail, user, itemLoading, loadingProcess } =
      this.props.auth;
    let itemContent;
    if (loadingDetail && isEmpty(user)) {
      itemContent = <div>Loading</div>;
    } else {
      const { full_name, email, profile_picture } = user;
      // const saldo = user.saldo ? user.saldo : 0;
      // const saldo_final = saldo - payment_amount;
      const url = profile_picture?.url;
      itemContent = (
        <div>
          <div
            style={{
              backgroundImage: `url(${url ? url : icon_profile})`,
              backgroundColor: "#c0e4ffff",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {loadingProcess && itemLoading === "photo_profile" && (
              <SpinnerWhite
                width={30}
                unik="loading_item_list"
                marginTop="0px"
                center={true}
                border={0.1}
              />
            )}
          </div>
          <UploadPP />
          <div
            className="text_bold"
            style={{ marginTop: "10px", marginBottom: "30px" }}
          >
            {full_name}
            <div
              onClick={this.toggleEditName}
              className="button_tight"
              style={{
                marginLeft: "5px",
                backgroundColor: "#cecece",
                fontSize: "10px",
              }}
            >
              Edit
            </div>
            <div className="text_medium">{email}</div>
          </div>
          {isSupportNotif ? (
            <div
              onClick={this.askNotification}
              className="button_standard"
              style={{ marginBottom: "10px" }}
            >
              {dict["Enable web notifications"][language]}
            </div>
          ) : null}
          {/* <div className="container_glossy" style={{ height: "150px" }}>
            <div className="two_container_equal" style={{ height: "100%" }}>
              <div
                style={{
                  margin: "auto",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Rp{" "}
                    {new Intl.NumberFormat("id-ID", {
                      style: "decimal",
                    }).format(saldo_final)}
                  </div>
                  {payment_amount !== 0 && (
                    <div
                      className="container_light"
                      style={{ marginTop: "5px" }}
                    >
                      Dalam proses penarikan: Rp{" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(payment_amount)}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ margin: "auto" }}>
                <div
                  onClick={this.toggleWithdraw}
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  <IconWithdraw />
                  <div style={{ marginTop: "5px" }}>Tarik saldo</div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/withdraw_list"
            className="button_standard"
            style={{
              marginTop: "10px",
            }}
          >
            Histori penarikan
          </Link> */}
          <br />
          <div
            onClick={this.onLogoutClick.bind(this)}
            className="button_standard"
            style={{ marginTop: "10px", backgroundColor: "#d34c4c" }}
          >
            {dict["Sign out"][language]}
          </div>
        </div>
      );
    }
    const buttonContent =
      loadingProcess && itemLoading === "full_name" ? (
        <div className="button_block_tight">
          <SpinnerWhite
            width={30}
            unik="loading_item_list"
            marginTop="0px"
            center={true}
            border={0.1}
          />
        </div>
      ) : (
        <button className="button_block" type="submit">
          Simpan nama lengkap
        </button>
      );
    const modalEditNameContent = modalEditName && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalEditName}
        onClose={this.toggleEditName}
      >
        <div className="box-body">
          <form
            noValidate
            autoComplete="off"
            onSubmit={this.handleUpdateFullname}
          >
            <TextFieldGroupTight
              type="text"
              placeholder="Nama lengkap sesuai kartu identitas"
              name="full_name"
              id="full_name"
              value={full_name}
              onChange={this.handleChange}
              error={errors.name}
            />
            {buttonContent}
          </form>
        </div>
      </Modal>
    );
    return (
      <div className="main_container" style={{ textAlign: "center" }}>
        {itemContent}
        {modalEditNameContent}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  logoutUser,
  getUserFromToken,
  updateFullname,
})(UserDashboard);
